import { Component, EventEmitter, Input, Output } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { Pagination, TypedSort } from '@app/types/core';
import { PopoverFilterOption } from '@app/shared/filters';
import { IRequest } from '../../types/request';
import { RequestListFilters } from '../../types/request-filters';

@Component({
   selector: 'app-request-list',
   templateUrl: './request-list.component.html',
   styleUrls: ['./request-list.component.scss']
})
export class RequestListComponent {
   @Input() requests: IRequest[];
   @Input() legalTeamOptions: PopoverFilterOption[];
   @Input() funderOptions: PopoverFilterOption[];
   @Input() statusOptions: PopoverFilterOption[];
   @Input() convertedToOptions: PopoverFilterOption[];
   @Input() filters: RequestListFilters;
   @Input() loading = false;
   @Input() count: number;
   @Input() page: Pagination;
   @Input() sort: TypedSort<Request>;
   @Output() createRequest = new EventEmitter<Request>();
   @Output() deleteRequest = new EventEmitter<Request>();
   @Output() filtersChange = new EventEmitter<RequestListFilters>();
   @Output() sortChange = new EventEmitter<TypedSort<Request>>();
   @Output() pageChange = new EventEmitter<Pagination>();
   @Output() clearFilters = new EventEmitter();

   public columnsToDisplay: string[] = ['number', 'caller', 'pwd', 'intakeStaff', 'requested', 'reviewed', 'status', 'proBonoAttorney', 'more'];

   onFilter(value: Partial<RequestListFilters>): void {
      this.filtersChange.emit({ ...this.filters, ...value });
   }

   onPage({ pageIndex, pageSize }: PageEvent): void {
      this.pageChange.emit({ page: pageIndex + 1, pageSize });
   }

   onSort(sort: Sort): void {
      this.sortChange.emit(sort as TypedSort<Request>);
   }
}
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { merge } from 'rxjs';
import { debounceTime, filter, map, switchMap, tap } from 'rxjs/operators';

import { PreferenceService } from '../../core';
import { objectFactory } from '@uirouter/angular-hybrid';

@Component({
   selector: 'am-timeslip-calendar',
   templateUrl: 'timeslip-calendar.component.html',
   styleUrls: ['timeslip-calendar.component.scss']
})
export class TimeslipCalendarComponent implements OnInit {
   @Input() currentUserId: string;

   filterForm: FormGroup;
   usersFilter = [];
   showFilters = false;
   users: {id: string}[];

   constructor(
      private formBuilder: FormBuilder,
      private preferenceService: PreferenceService) {
   }

   ngOnInit() {
      this.initialize();
      this.subscribe();
      this.getTimeslips();
   }

   toggleFilters() {
      this.showFilters = !this.showFilters;
   }

   getParameters() {
      this.usersFilter = this.filterForm.get('usersFilter').value;
      this.savePreferences();
   }

   private subscribe() {
      merge(this.filterForm.valueChanges)
      .pipe(
         debounceTime(500)
      )
      .subscribe(() => this.getTimeslips());
   }

   private getTimeslips() {
      this.getParameters();
      this.users = this.usersFilter.map((u) => ({id: u}));
   }

   private initialize() {
      this.loadPreferences();

      this.filterForm = this.formBuilder.group({
         usersFilter: [this.usersFilter]
      });
   }

   private loadPreferences(): any {
      const preferences: any = this.preferenceService.getPreference('timeslipList');
      if (preferences) {
         this.usersFilter = preferences.usersFilter;
      }

      if (this.usersFilter.length === 0) {
         this.usersFilter = [this.currentUserId];
      }
   }

   private savePreferences() {
      let preferences: any = this.preferenceService.getPreference('timeslipList');
      preferences = preferences || {};
      preferences.usersFilter = this.usersFilter;
      this.preferenceService.setPreference('timeslipList', preferences);
   }

   clearFilters() {
      this.filterForm.patchValue({
         usersFilter: []
      });
   }
}




















import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivitiesRepositoryService } from '@app/activities/activities.repository';
import { StatusChange } from '@app/activities/types';
import { ConfigurationService } from '@app/configuration/configuration.service';
import { LookupModel } from '@app/configuration/models/configuration.models';
import { NotificationService } from '@app/core';
import { InterruptService } from '@app/shared';
import { StateService } from '@uirouter/core';
import * as moment from 'moment';
import { finalize } from 'rxjs/operators';


@Component({
   selector: 'am-activities-status-change',
   templateUrl: 'status-change.component.html',
   styleUrls: ['status-change.component.scss']
})
export class ActivitiesStatusChangeComponent implements OnInit {
   @Input() involvementId: string;
   @Input() source: string;
   @Input() sourceId: string;
   @Input() activityId: string;

   form: FormGroup;
   today: moment.Moment;
   maxDate: moment.Moment;
   submitting: boolean;
   statuses: LookupModel[];

   constructor(
      private formBuilder: FormBuilder,
      private activitiesService: ActivitiesRepositoryService,
      private configurationService: ConfigurationService,
      private interruptService: InterruptService,
      private notifier: NotificationService,
      private stateService: StateService) {
   }

   ngOnInit() {
      this.today = moment().startOf('day');
      this.maxDate = this.today;
      this.getStatuses();
      this.form = this.formBuilder.group({
         activityDate: [{ value: this.today, disabled: true }, Validators.required],
         status: [undefined, Validators.required],
         statusDescription: undefined
      });
   }

   private getStatuses() {
      let caseType: string;

      if (this.source == 'clientcases') caseType = 'client-case'
      else if (this.source == 'informationandreferrals') caseType = 'i-and-r'
      else if (this.source == 'requests') caseType = 'request'
      else if (this.source == 'systemiccases') caseType = 'systemic-case'
      else caseType = this.source

      this.configurationService.getLookupType(`${caseType}-status`).subscribe(lt => {
         this.statuses = lt.lookups;
      });
   }

   cancel() {
      this.stateService.go('.^');
      this.interruptService.allowNavigation();
   }

   submit() {
      if (this.form.invalid) { return; }
      this.submitting = true;

      const { source } = this
      const { activityDate, status, statusDescription } = this.form.getRawValue();

      const data: StatusChange = {
         id: this.activityId,
         involvementId: this.involvementId,
         sourceId: this.sourceId,
         activityDate,
         status,
         statusDescription: statusDescription,
         description: status === 'Other' ? `${status} - ${statusDescription}` : status
      };

      this.activitiesService.createStatusChange(data, source)
         .pipe(finalize(() => this.submitting = false))
         .subscribe(result => {
            if (result) {
               this.submitting = false;
               this.interruptService.allowNavigation();
               this.stateService.go('.^', {}, { reload: true });
               this.notifier.notify('Status Changed', 'Done');
            }
         });
   }
}
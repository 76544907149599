import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable()
export class NotificationService {
   constructor(private snackBar: MatSnackBar) { }

   notify(message, action?: string) {
      return this.snackBar.open(message, action, {
         duration: 3000
      }).onAction();
   }
}

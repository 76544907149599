import { ChangeDetectionStrategy, Component, forwardRef, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { FilterControl } from '../filter-control';
import { NumberFilterOperator, PopoverFilterNumberValue, PopoverFilterType } from '../popover-filter.models';

const isBetweenValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
   const { operator, number, number2 } = (control as FormGroup).getRawValue();
   const invalid = operator === NumberFilterOperator.isBetween &&
      !!number &&
      !!number2 &&
      number2 < number;
   return invalid ? { isBetween: true } : null;
};

@Component({
   selector: 'au-popover-filter-number',
   changeDetection: ChangeDetectionStrategy.OnPush,
   providers: [{ provide: FilterControl, useExisting: forwardRef(() => NumberFilterComponent) }],
   templateUrl: './number-filter.component.html',
   styleUrls: ['./number-filter.component.scss']
})
export class NumberFilterComponent extends FilterControl implements OnInit {
   ngOnInit(): void {
      const operatorControl = new FormControl('IsEqualTo', Validators.required);
      const numberControl = new FormControl(null, Validators.required);
      const number2Control = new FormControl({ value: null, disabled: true });

      this.form = new FormGroup({
         operator: operatorControl,
         number: numberControl,
         number2: number2Control
      }, isBetweenValidator);

      operatorControl.valueChanges.pipe(
         map((value: NumberFilterOperator) => value === NumberFilterOperator.isBetween),
         distinctUntilChanged()
      ).subscribe(enabled => enabled ? number2Control.enable() : number2Control.disable());
   }

   clear(): void {
      this.form.setValue({
         operator: NumberFilterOperator.isEqualTo,
         number: null,
         number2: null
      });
   }

   isBetween(): boolean {
      return this.form.value.operator === NumberFilterOperator.isBetween;
   }

   registerOnChanges(fn: (value: any) => any): void {
      this.form.valueChanges.pipe(
         map((value): PopoverFilterNumberValue | null => value.number || value.number === 0 ? ({
            type: PopoverFilterType.number,
            ...value
         }) : null)
      ).subscribe(fn);
   }

   writeValue(value: any): void {
      const {
         operator = NumberFilterOperator.isEqualTo,
         number = null,
         number2 = null
      } = value ?? {};

      this.form.setValue({ operator, number, number2 }, { onlySelf: false });
   }
}

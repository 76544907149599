import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { PaginationCriteria } from '@app/core/models';
import { TypedSort } from '@app/types/core';
import { distinctUntilChanged, filter, tap } from 'rxjs/operators';
import { Entity } from '../..';
import { EntityNameSearchResultsComponent } from '../components';
import { EntityNameSearchStore } from '../state/entity-name-search.store';


@Component({
  selector: 'app-entity-name-search',
  templateUrl: './entity-name-search.view.html',
  styleUrls: ['./entity-name-search.view.scss'],
  providers: [EntityNameSearchStore]
})
export class EntityNameSearchView implements OnInit {
  @ViewChild(EntityNameSearchResultsComponent, { static: true }) list: EntityNameSearchResultsComponent;
  vm$ = this.store.vm$;
  entityType: string[] = ['Person', 'Agency'];

  constructor(
    private formBuilder: FormBuilder,
    private readonly store: EntityNameSearchStore
  ) { }

  ngOnInit(): void {
    this.setupForm()
  }

  form: FormGroup;
  submitting: boolean;

  private setupForm() {
    this.form = this.formBuilder.group({
      name: null,
      type: 'Person'
    });
    
    this.form.get('name').valueChanges.pipe(
      tap(d => !d && this.store.updateEntities([])),
      filter(value => value),
      distinctUntilChanged()
    ).subscribe(q => this.store.updateQuery(q));

    this.form.get('type').valueChanges.pipe(
      tap(d => !d && this.store.updateEntities([])),
      filter(value => value),
      distinctUntilChanged()
    ).subscribe(type => this.store.updateType(type));
  }

  onPageChange(page: PaginationCriteria) {
    this.store.updatePage(page);
  }

  onSortChange(sort: TypedSort<Entity>) {
    this.store.updateSort(sort);
  }
}
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { HttpService } from '../core';
import * as models from './models';

@Injectable()
export class FundingService {
   static injectName = 'fundingRepository';

   constructor(private http: HttpService) { }

   getPAATIntakeInfo(enrollmentId: string): Observable<models.PAATIntakeInfoModel> {
      const url = `api/funding/enrollments/${enrollmentId}/paat/intake`;
      return this.http.get<models.PAATIntakeInfoModel>(url);
   }
}

import { NgModule } from '@angular/core';
import { UIRouterUpgradeModule } from '@uirouter/angular-hybrid';
import { FiltersModule } from '../filters/filters.module';
import { SharedModule } from '../shared/shared.module';
import { MonitoringActivitiesSummaryComponent } from './activities-summary/monitoring-activities-summary.component';
import { MonitoringContactComponent } from './contact/contact.component';
import { CreateMonitoringComponent } from './create-monitoring/create-monitoring.component';
import { MonitoringDetailsComponent } from './details/monitoring-details.component';
import { MonitoringIssueComponent } from './issue/issue.component';
import { MonitoringListModule } from './list/monitoring-list.module';
import { MonitoringMeetingComponent } from './meeting/meeting.component';
import { MonitoringRepositoryService } from './monitoring.repository';
import { states } from './monitoring.states';
import { MonitoringComponent } from './monitoring/monitoring.component';
import { MonitoringOverviewComponent } from './overview/overview.component';
import { MonitoringSummaryComponent } from './summary/monitoring-summary.component';


@NgModule({
   imports: [
      UIRouterUpgradeModule.forChild({ states }),
      SharedModule,
      FiltersModule,
      MonitoringListModule
   ],
   declarations: [
      MonitoringComponent,
      MonitoringOverviewComponent,
      CreateMonitoringComponent,
      MonitoringDetailsComponent,
      MonitoringActivitiesSummaryComponent,
      MonitoringSummaryComponent,
      MonitoringContactComponent,
      MonitoringMeetingComponent,
      MonitoringIssueComponent
   ],
   exports: [
   ],
   providers: [
      MonitoringRepositoryService
   ],
   entryComponents: [
      MonitoringContactComponent,
      MonitoringMeetingComponent,
      MonitoringIssueComponent
   ]
})
export class MonitoringModule {

}

import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatRadioChange } from '@angular/material/radio';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { IdentityService, UserModel } from '@app/core';
import * as moment from 'moment';
import { InvolvementsService } from '../involvements.service';


export interface HasStatutesOfLimitationsDialogData {
    involvementId: string;
    description: string;
}

@Component({
    selector: 'am-has-statute-of-limitations-dialog',
    templateUrl: './has-statutes-of-limitations-dialog.component.html',
    styleUrls: ['./has-statutes-of-limitations-dialog.component.scss']
})
export class HasStatutesOfLimitationsDialogComponent implements OnInit {
    form: FormGroup;
    submitting = false;
    user: UserModel;
    hideDescription: boolean = true;
    today: moment.Moment;

    constructor(
        private formBuilder: FormBuilder,
        private dialogRef: MatDialogRef<HasStatutesOfLimitationsDialogData>,
        private involvementsService: InvolvementsService,
        private userService: IdentityService,
        @Inject(MAT_DIALOG_DATA) public dialogData: HasStatutesOfLimitationsDialogData) { }

    ngOnInit() {
        this.today = moment().startOf('day');
        this.form = this.formBuilder.group({
            hasStatutesOfLimitations: [null, Validators.required],
            description: null,
            default: null
        });
        this.userService.getCurrentUser().subscribe(result => this.user = result);
    }

    cancel() {
        this.dialogRef.close();
    }

    onChange(radio: MatRadioChange) {
        const { hasStatutesOfLimitations } = this.form.getRawValue();
        this.hideDescription = hasStatutesOfLimitations;

        if (!radio.value) this.form.get('description').setValidators(Validators.required)
        else this.form.get('description').setValidators(null)
    }

    onDefault(toggle: MatSlideToggleChange) {
        if (toggle.checked) {
            this.form.controls['description'].disable();
            this.form.patchValue({ description: `On ${this.today.format("MM/DD/YYYY")} ${this.user.fullName} indicated this case has no Statutes of Limitations` });
        }
        else {
            this.form.controls['description'].enable();
            this.form.patchValue({ description: null });
        }
    }

    submit() {
        this.submitting = true;
        const { hasStatutesOfLimitations, description } = this.form.getRawValue();

        const data = {
            hasStatutesOfLimitations,
            description
        };

        this.involvementsService
            .setHasStatutesOfLimitations(this.dialogData.involvementId, data)
            .subscribe(() => {
                this.submitting = false;
                this.dialogRef.close(data.hasStatutesOfLimitations);
            });
    }
}

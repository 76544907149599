import { SelectionModel } from '@angular/cdk/collections';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatAutocomplete, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent, MatChipList } from '@angular/material/chips';
import { Observable, of } from 'rxjs';
import { debounceTime, startWith, switchMap } from 'rxjs/operators';
import { highlight } from '../../shared/shared.helpers';
import { UserModel } from '../models';


@Component({
   selector: 'am-user-chips',
   templateUrl: './user-chips.component.html',
   styleUrls: ['./user-chips.component.scss'],
   host: {
      class: 'am-user-chips'
   }
})
export class UserChipsComponent implements OnInit {
   filteredUsers: Observable<UserModel[]>;
   formControl = new FormControl();
   highlight = highlight;
   selection = new SelectionModel<UserModel>(true, []);

   @Input() users: (q: string) => Observable<UserModel[]>;
   @Input() required: boolean;
   @Input() initialUsers: UserModel[];

   @ViewChild('input', { static: true }) input: ElementRef<HTMLInputElement>;
   @ViewChild(MatAutocomplete, { static: true }) auto: MatAutocomplete;
   @ViewChild(MatChipList, { static: true }) chipList: MatChipList;

   @Output() changed = new EventEmitter<UserModel[]>();

   ngOnInit() {
      this.filteredUsers = this.formControl.valueChanges.pipe(
         startWith(''),
         debounceTime(750),
         switchMap(q => q ? this.users(q) : of([])));

      this.selection.changed.subscribe(result => {
         this.changed.emit(result.source.selected);
         this.chipList.errorState = result.source.isEmpty();
      });
   }

   ngOnChanges(changes: SimpleChanges) {
      for (const propName in changes) {
         if (changes.hasOwnProperty(propName)) {
            switch (propName) {
               case 'initialUsers': {
                  if (this.initialUsers) {
                     this.selection.select(...this.initialUsers);
                     this.changed.emit(this.selection.selected);
                  }
               }
            }
         }
      }
   }

   onChipInputTokenEnd(event: MatChipInputEvent) {
      if (!this.auto.isOpen) {
         event.input.value = '';
         this.formControl.setValue(undefined);
         this.chipList.errorState = this.selection.isEmpty();
      }
   }

   selected(event: MatAutocompleteSelectedEvent) {
      this.selection.select(event.option.value);
      this.input.nativeElement.value = '';
      this.formControl.setValue(undefined);
   }
}

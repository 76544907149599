import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { ConfigurationService } from './configuration.service';

@NgModule({
   imports: [
      SharedModule
   ],
   declarations: [

   ],
   exports: [

   ],
   providers: [
      ConfigurationService
   ]
})
export class ConfigurationModule {
}

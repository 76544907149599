import { Component, OnInit } from '@angular/core';

@Component({
   selector: 'am-monitoring-activities-summary',
   templateUrl: './monitoring-activities-summary.component.html',
   styleUrls: ['./monitoring-activities-summary.component.scss']
})
export class MonitoringActivitiesSummaryComponent implements OnInit {

   ngOnInit() {

   }

}

import { Injectable } from '@angular/core';
import { HttpService, PagedList } from '../core/index';
import { GetGrievanceListParams, GrievanceListItemModel, GrievanceModel } from './models/grievances.models';
import { Observable } from 'rxjs';
import { HttpResponse } from '@angular/common/http';
import { tap } from 'rxjs/operators';
import { getFileNameFromResponseContentDisposition, saveFile } from '../shared';

@Injectable()
export class GrievancesRepositoryService {
   static injectName = 'grievancesService';
   private baseUrl = 'api/grievances';

   constructor(private http: HttpService) { }

   getGrievance(projectId: string) {
      const url = `${this.baseUrl}/${projectId}`;
      return this.http.get<GrievanceModel>(url);
   }

   getGrievanceList(data: GetGrievanceListParams): Observable<PagedList<GrievanceListItemModel>> {
      const url = `${this.baseUrl}/list`;
      return this.http.post<PagedList<GrievanceListItemModel>>(url, data);
   }

   getGrievanceListDownload(data: GetGrievanceListParams) {
      const url = `${this.baseUrl}/list/csv`;
      return this.http.post<HttpResponse<Blob>>(url, data, { responseType: 'blob', observe: 'response' }).pipe(
         tap(res => {
            const fileName = getFileNameFromResponseContentDisposition(res);
            saveFile(res.body, fileName);
         })
      );
   }

   deleteGrievance(id: string) {
      return this.http.delete(`${this.baseUrl}/${id}`);
   }
}

import { Transition } from '@uirouter/core';
import { UserService } from '../users';
import { TimeslipCalendarComponent } from './timeslip-calendar/timeslip-calendar.component';
import { TimeslipListComponent } from './timeslip-list/timeslip-list.component';

export const states = [
   {
      name: 'app.timeslipList',
      component: TimeslipListComponent,
      url: '/timeslip-list',
      resolve: {
         currentUserId: currentUserIdResolver
      },
      data: {
         title: 'List',
         amPermissions: ['timeslips.view']
      }
   },
   {
      name: 'app.timeslipCalendarv2',
      component: TimeslipCalendarComponent,
      url: '/timeslip-calendarv2',
      resolve: {
         currentUserId: currentUserIdResolver
      },
      data: {
         title: 'List',
         amPermissions: ['timeslips.view']
      }
   },
];

currentUserIdResolver.$inject = ['$transition$'];
export function currentUserIdResolver(transition: Transition) {
   const userService: UserService = transition.injector().get(UserService);
   return userService.getCurrentUser().toPromise().then(
      u => u.id
   );
}



import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Sort } from '@angular/material/sort';
import { MonitoringListComponent } from '@app/monitoring/list/components';
import { MonitoringCreateDialog } from '@app/monitoring/list/dialogs';
import { MonitoringStateStore } from '@app/monitoring/list/state/monitoring-list.store';
import { Monitoring } from '@app/monitoring/list/types/monitoring';
import { MonitoringListFilters } from '@app/monitoring/list/types/monitoring-filters';
import { ConfirmationService } from '@app/shared';
import { Pagination } from '@app/types/core';
import { UserModel } from '@app/users';
import { UIRouter } from '@uirouter/core';
import { filter } from 'rxjs/operators';


@Component({
   selector: 'app-monitoring-list.view',
   templateUrl: './monitoring-list.view.html',
   styleUrls: ['./monitoring-list.view.scss'],
   providers: [MonitoringStateStore]
})
export class MonitoringListView implements OnInit {
   @ViewChild(MonitoringListComponent, { static: true }) list: MonitoringListComponent;
   vm$ = this.store.vm$;

   constructor(
      private readonly dialog: MatDialog,
      private router: UIRouter,
      private readonly store: MonitoringStateStore,
      private readonly confirmation: ConfirmationService
   ) { }

   ngOnInit(): void {
      const {
         current: { name: viewId }
      } = this.router.globals;

      this.store.init(viewId);
   }

   showMonitoringCreateDialog() {
      MonitoringCreateDialog.open(this.dialog, {
         openDate: null
      }).afterClosed().pipe(
         filter(value => !!value)
      ).subscribe(Monitoring => this.store.addMonitoring(Monitoring));
   }

   showDeleteDialog(monitoring: Monitoring) {
      const { number } = monitoring;
      this.confirmation.confirm(`Delete Monitoring (${number})?`, 'DELETE', 'CANCEL').pipe(
         filter(ok => ok)
      ).subscribe(() => this.store.deleteMonitoring(monitoring.involvementId));
   }

   onFiltersChange(filters: MonitoringListFilters, page: Pagination): void {
      const pageSize = page?.pageSize ?? 25;
      this.store.patchViewState({ filters, pagination: { page: 1, pageSize } });
   }

   onSelectedChange(users: UserModel[], roles: string[]) {
      this.store.patchViewState({ selected: { users, roles } });
   }

   onSearchChange(search: string) {
      this.store.patchViewState({ search });
   }

   onActiveOnlyChange(activeOnly: boolean) {
      this.store.patchViewState({ activeOnly });
   }

   onPageChange(pagination: Pagination) {
      this.store.patchViewState({ pagination });
   }

   onSortChange(sort: Sort) {
      this.store.patchViewState({ sort });
   }

   onToggleChange(value: boolean): void {
      this.store.patchState({ toggle: value });
   }

   onClearFilters(page: Pagination) {
      const filters: MonitoringListFilters =
      {
         number: null,
         caseName: null,
         client: null,
         primaryStaff: null,
         legalTeam: null,
         funder: null,
         status: null,
         opened: null,
         closed: null
      }
      this.onFiltersChange(filters, page)
   }
}
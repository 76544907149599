import { SelectionModel } from '@angular/cdk/collections';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatAutocomplete, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent, MatChipList } from '@angular/material/chips';
import { Observable, of } from 'rxjs';
import { debounceTime, startWith, switchMap } from 'rxjs/operators';

import { highlight } from '../../shared/shared.helpers';
import { UserModel } from '../models';
import { map } from 'rxjs/operators';
import { SimpleChanges } from '@angular/core';
import { DocumentModel } from '../../documents/models/documents.models';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { forwardRef } from '@angular/core';
import { NG_VALIDATORS } from '@angular/forms';
import { ControlContainer } from '@angular/forms';
import { FormGroupDirective } from '@angular/forms';
import { ControlValueAccessor } from '@angular/forms';
import { Validator } from '@angular/forms';

@Component({
   selector: 'am-document-chips',
   templateUrl: './document-chips.component.html',
   styleUrls: ['./document-chips.component.scss'],
   host: {
      class: 'am-document-chips'
   },
   providers: [{
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DocumentChipsComponent),
      multi: true
   }, {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => DocumentChipsComponent),
      multi: true
   }],
   viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }]
})
export class DocumentChipsComponent implements OnInit, ControlValueAccessor, Validator  {
   filteredDocuments: Observable<DocumentModel[]>;
   control: FormControl;
   highlight = highlight;
   selection = new SelectionModel<DocumentModel>(true, []);

   @Input() documents: (q: string) => Observable<DocumentModel[]>;
   @Input() required: boolean;
   @Input() placeholder: string;

   @ViewChild('input', { static: true }) input: ElementRef<HTMLInputElement>;
   @ViewChild(MatAutocomplete, { static: true }) auto: MatAutocomplete;
   @ViewChild(MatChipList, { static: true }) chipList: MatChipList;

   ngOnInit() {
      this.control = new FormControl();
      this.filteredDocuments = this.control.valueChanges.pipe(
      startWith(''),
      debounceTime(750),
      switchMap(q => q ? this.documents(q) : of([])));
   }

   registerOnChange(fn) {
      this.selection.changed.subscribe(result => fn(result.source.selected.map(d => d.id)));
   }

   registerOnTouched() { }

   setDisabledState(disabled: boolean) {
      disabled ? this.control.disable() : this.control.enable();
   }

   validate() {
      return this.control.errors;
   }

   writeValue(value: any) {
      this.control.setValue(value);
   }

   onChipInputTokenEnd(event: MatChipInputEvent) {
      if (!this.auto.isOpen) {
         event.input.value = '';
         this.control.setValue(undefined);
         if (this.required) {
            this.chipList.errorState = this.selection.isEmpty();
         }
      }
   }

   selected(event: MatAutocompleteSelectedEvent) {
      this.selection.select(event.option.value);
      this.input.nativeElement.value = '';
      this.control.setValue(undefined);
   }
}

import { CommonModule } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, NgModule, Optional, SkipSelf } from '@angular/core';
import { ErrorsHandler } from './error-handling/errors-handler';
import { throwIfAlreadyLoaded } from './guards/module-import.guard';
import { appInitializer } from './initializers/app.initializer';
import { ApiBaseUrlInterceptor } from './interceptors/api-base-url.interceptor';
import { AuthInterceptor } from './interceptors/auth.interceptor';
import { DisableCachingInterceptor } from './interceptors/disable-caching.interceptor';
import { HttpProgressInterceptor } from './interceptors/progress.interceptor';
import * as services from './services';


@NgModule({
   imports: [
      CommonModule,
      HttpClientModule
   ],
   providers: [
      services.AuthService,
      services.BrowserService,
      services.CacheService,
      services.ConfigurationProviderService,
      services.HttpService,
      services.HttpProgressService,
      services.IdentityService,
      services.MonitoringService,
      services.NotificationService,
      services.OidcService,
      services.PreferenceService,
      { provide: APP_INITIALIZER, useFactory: appInitializer, deps: [services.ConfigurationProviderService, services.MonitoringService], multi: true },
      { provide: ErrorHandler, useClass: ErrorsHandler },
      { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
      { provide: HTTP_INTERCEPTORS, useClass: ApiBaseUrlInterceptor, multi: true },
      { provide: HTTP_INTERCEPTORS, useClass: DisableCachingInterceptor, multi: true },
      { provide: HTTP_INTERCEPTORS, useClass: HttpProgressInterceptor, multi: true }
   ]
})
export class CoreModule {
   constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
      throwIfAlreadyLoaded(parentModule, 'CoreModule');
   }
}

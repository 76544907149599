import { downgradeComponent, downgradeInjectable } from '@angular/upgrade/static';
import { ActivitiesStatusChangeComponent } from '@app/activities/status-change/status-change.component';
import { ActivitiesRepositoryService } from './activities.repository';

export function activitiesDowngrader(app: any) {
   app.factory(ActivitiesRepositoryService.injectName, downgradeInjectable(ActivitiesRepositoryService));
   app.directive('amActivitiesStatusChange', downgradeComponent({
      component: ActivitiesStatusChangeComponent,
      inputs: ['involvementId', 'activityId', 'sourceId', 'source']
   }));
}
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Sort } from '@angular/material/sort';
import { GrievancesListComponent } from '@app/grievances/list/components';
import { GrievancesFormDialog } from '@app/grievances/list/dialogs';
import { GrievanceStateStore } from '@app/grievances/list/state';
import { Grievance, GrievanceListFilters } from '@app/grievances/list/types';
import { ConfirmationService } from '@app/shared';
import { Pagination } from '@app/types/core';
import { UserModel } from '@app/users';
import { UIRouter } from '@uirouter/core';
import { filter } from 'rxjs/operators';


@Component({
   selector: 'app-grievances-list.view',
   templateUrl: './grievances-list.view.html',
   styleUrls: ['./grievances-list.view.scss'],
   providers: [GrievanceStateStore]
})
export class GrievancesListView implements OnInit {
   @Input() rolesFilter: string[] = [];
   @Input() usersFilter: UserModel[] = [];
   @Input() initializeSelected: boolean = false;
   @ViewChild(GrievancesListComponent, { static: true }) list: GrievancesListComponent;
   vm$ = this.store.vm$;

   constructor(
      private readonly dialog: MatDialog,
      private router: UIRouter,
      private readonly store: GrievanceStateStore,
      private readonly confirmation: ConfirmationService
   ) { }

   ngOnInit(): void {
      const {
         current: { name: viewId }
      } = this.router.globals;

      this.store.init(viewId);

      if (this.initializeSelected) {
         this.onActiveOnlyChange(true)
         this.onClearFilters({ page: 1, pageSize: 25 });
      };
   }

   showGrievanceFormDialog(grievance: Grievance): void {
      GrievancesFormDialog.open(this.dialog, grievance)
         .afterClosed().pipe(
            filter(value => !!value)
         ).subscribe(grievance => {
            this.store.addGrievance(grievance)
            this.store.patchViewState({ search: null })
         });
   }

   showDeleteDialog(grievance: Grievance): void {
      const { caseType, idNumber } = grievance;
      this.confirmation.confirm(`Delete Grievance (${caseType} ${idNumber})?`, 'DELETE', 'CANCEL').pipe(
         filter(ok => ok)
      ).subscribe(() => this.store.deleteGrievance(grievance.id));
   }

   onFiltersChange(filters: GrievanceListFilters, page: Pagination): void {
      const pageSize = page?.pageSize ?? 25;
      this.store.patchViewState({ filters, pagination: { page: 1, pageSize } });
   }

   onSearchChange(search: string): void {
      this.store.patchViewState({ search });
   }

   onActiveOnlyChange(activeOnly: boolean): void {
      this.store.patchViewState({ activeOnly });
   }

   onPageChange(pagination: Pagination): void {
      this.store.patchViewState({ pagination });
   }

   onSortChange(sort: Sort): void {
      this.store.patchViewState({ sort });
   }

   onToggleChange(value: boolean): void {
      this.store.patchState({ toggle: value });
   }

   onClearFilters(page: Pagination): void {
      const filters: GrievanceListFilters =
      {
         grievant: null,
         case: null,
         fileDate: null,
         escalationDate: null,
         replyDueDate: null,
         actualReplyDate: null,
         funder: null
      }
      this.onFiltersChange(filters, page)
   }
}
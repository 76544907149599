import { EligibilityFormViewComponent } from '@app/funding/eligibility/form';
import { Transition } from '@uirouter/core';
import { SourceModel } from '../core/models/source.model';
import { CreateEmailComponent } from '../mail/create-email/create-email.component';
import { CreateMonitoringComponent } from './create-monitoring/create-monitoring.component';
import { MonitoringListView } from './list/view/monitoring-list.view';
import { MonitoringRepositoryService } from './monitoring.repository';


export const states = [
   {
      name: 'app.createMonitoring',
      component: CreateMonitoringComponent,
      url: '/create-monitoring',
      data: {
         title: 'Create Monitoring',
         amPermissions: ['monitoring.edit']
      }
   },
   {
      name: 'app.monitoringList',
      component: MonitoringListView,
      url: '/monitoring-list',
      data: {
         title: 'Monitoring-List',
         amPermissions: ['monitoring.view']
      }
   },
   {
      name: 'app.monitoring.emails.create',
      component: CreateEmailComponent,
      url: '/new/email',
      resolve: {
         source: resolveSource
      },
      data: {
         title: 'New Message',
         amPermissions: ['mail.send']
      }
   },
   {
      name: 'app.monitoring.funding.eligibility.eligibilityFormView',
      url: '/form',
      params: {
         involvementId: null,
         eligibility: {},
         eligibilityList: [],
         readOnly: false,
         sourceType: 'Monitoring'
      },
      component: EligibilityFormViewComponent,
      resolve: {
         involvementId: involvementIdResolver,
         eligibility: eligibilityResolver,
         eligibilityList: eligibilityListResolver,
         readOnly: readOnlyResolver,
         sourceType: sourceTypeResolver
      },
      data: {
         title: 'Eligibility Form',
         amPermissions: ['funding.create']
      }
   }
];

eligibilityResolver.$inject = ['$transition$'];
export function eligibilityResolver($transition$) {
   const { eligibility } = $transition$.params();
   return eligibility;
}

eligibilityListResolver.$inject = ['$transition$'];
export function eligibilityListResolver($transition$) {
   const { eligibilityList } = $transition$.params();
   return eligibilityList;
}

involvementIdResolver.$inject = ['$transition$'];
export function involvementIdResolver($transition$) {
   const { involvementId } = $transition$.params();
   return involvementId;
}

sourceTypeResolver.$inject = ['$transition$'];
export function sourceTypeResolver() {
   const sourceType = 'Monitoring';
   return sourceType;
}

readOnlyResolver.$inject = ['$transition$'];
export function readOnlyResolver($transition$) {
   const { readOnly } = $transition$.params();
   return readOnly;
}

resolveSource.$inject = ['$transition$'];
export function resolveSource(transition: Transition) {
   const { monitoringId } = transition.params();
   const repository: MonitoringRepositoryService = transition.injector().get(MonitoringRepositoryService);
   return repository.getBasicInfo(monitoringId).toPromise().then(
      m => {
         return {
            source: 'Monitoring',
            sourceId: m.id,
            involvementId: m.involvementId,
            sourceNumber: m.idNumber,
            sourceName: m.name
         } as SourceModel;
      }
   );
}
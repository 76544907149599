import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';

import { GrievancesListModule } from '@app/grievances/list/grievances-list.module';
import { UIRouterUpgradeModule } from '@uirouter/angular-hybrid';
import { FiltersModule } from '../filters/filters.module';
import { CreateGrievanceButtonUpgradedDirective } from './grievance-buttons/create-grievance-button-upgrader.directive';
import { EditGrievanceButtonUpgradedDirective } from './grievance-buttons/edit-grievance-button-upgrader.directive';
import { ViewGrievanceButtonUpgradedDirective } from './grievance-buttons/view-grievance-button-upgrader.directive.1';
import { GrievanceListComponent } from './grievance-list/grievance-list.component';
import { GrievancesRepositoryService } from './grievances.repository';
import { states } from './grievances.states';


@NgModule({
   imports: [
      UIRouterUpgradeModule.forChild({ states }),
      SharedModule,
      FiltersModule,
      GrievancesListModule
   ],
   declarations: [
      GrievanceListComponent,
      CreateGrievanceButtonUpgradedDirective,
      EditGrievanceButtonUpgradedDirective,
      ViewGrievanceButtonUpgradedDirective
   ],
   exports: [
   ],
   providers: [
      GrievancesRepositoryService
   ]
})
export class GrievancesModule {
}

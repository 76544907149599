import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandler, Injectable, Injector } from '@angular/core';

import { NotificationService } from '../services/notification.service';

@Injectable()
export class ErrorsHandler implements ErrorHandler {
   constructor(private injector: Injector) { }

   handleError(error: Error | HttpErrorResponse) {
      const notificationService = this.injector.get(NotificationService);

      if (error instanceof HttpErrorResponse) {
         // Server error happened
         if (!navigator.onLine) {
            // No Internet connection
            return notificationService.notify('No Internet Connection');
         }
         // Http Error
         return notificationService.notify(`${error.status} - ${error.message}`);
      } else {
         // Client Error Happend
         // TODO: Navigation to error view
      }
      console.error(error);
   }
}

import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';

import { UIRouterUpgradeModule } from '@uirouter/angular-hybrid';
import { FiltersModule } from '../filters/filters.module';
import { ClientCasesRepositoryService } from './client-cases.repository';
import { states } from './client-cases.states';
import { ClientCaseCovidVaccineComponent } from './covid-vaccine/covid-vaccine.component';
import { ClientCasesListModule } from './list/client-cases-list.module';
import { RepresentationQuestionComponent } from './representation/representation-radiobutton-group/representation-question.component';
import { ClientCaseRepresentationComponent } from './representation/representation.component';

@NgModule({
   imports: [
      UIRouterUpgradeModule.forChild({ states }),
      SharedModule,
      FiltersModule,
      ClientCasesListModule
   ],
   declarations: [
      ClientCaseRepresentationComponent,
      ClientCaseCovidVaccineComponent,
      RepresentationQuestionComponent
   ],
   exports: [
   ],
   providers: [
      ClientCasesRepositoryService
   ],
   entryComponents: [
      ClientCaseRepresentationComponent,
      ClientCaseCovidVaccineComponent,
      RepresentationQuestionComponent
   ]
})
export class ClientCasesModule {

}
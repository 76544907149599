import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Sort } from '@angular/material/sort';
import { RequestListComponent } from '@app/requests/list/components';
import { RequestCreateDialog } from '@app/requests/list/dialogs';
import { RequestStateStore } from '@app/requests/list/state/request-list.store';
import { IRequest } from '@app/requests/list/types/request';
import { RequestListFilters } from '@app/requests/list/types/request-filters';
import { ConfirmationService } from '@app/shared';
import { Pagination } from '@app/types/core';
import { UserModel } from '@app/users';
import { UIRouter } from '@uirouter/core';
import { filter } from 'rxjs/operators';

@Component({
   selector: 'app-request-list.view',
   templateUrl: './request-list.view.html',
   styleUrls: ['./request-list.view.scss'],
   providers: [RequestStateStore]
})
export class RequestListView implements OnInit {
   @Input() rolesFilter: string[] = [];
   @Input() usersFilter: UserModel[] = [];
   @Input() initializeSelected: boolean = false;
   @ViewChild(RequestListComponent, { static: true }) list: RequestListComponent;
   vm$ = this.store.vm$;

   constructor(
      private readonly dialog: MatDialog,
      private router: UIRouter,
      private readonly store: RequestStateStore,
      private readonly confirmation: ConfirmationService
   ) { }

   ngOnInit(): void {
      const {
         current: { name: viewId }
      } = this.router.globals;

      this.store.init(viewId);

      if (this.initializeSelected) {
         if (!this.rolesFilter.includes('Intake Staff')) this.rolesFilter.push('Intake Staff');
         this.onActiveOnlyChange(true)
         this.onSelectedChange(this.usersFilter, this.rolesFilter);
         this.onClearFilters({ page: 1, pageSize: 25 });
      }
   }

   showRequestCreateDialog() {
      RequestCreateDialog.open(this.dialog, {
         requestedAt: null,
         requestMethod: null,
         urgentRequest: null,
         legalTeamId: null,
         region: null,
         description: null
      }).afterClosed().pipe(
         filter(value => !!value)
      ).subscribe(request => this.store.addRequest(request));
   }

   showDeleteDialog(request: IRequest) {
      const { number } = request;

      this.confirmation.confirm(`Delete Request (${number})?`, 'DELETE', 'CANCEL').pipe(
         filter(ok => ok)
      ).subscribe(() => this.store.deleteRequest(request.involvementId));
   }

   onFiltersChange(filters: RequestListFilters, page: Pagination): void {
      const pageSize = page?.pageSize ?? 25;
      this.store.patchViewState({ filters, pagination: { page: 1, pageSize } });
   }

   onSelectedChange(users: UserModel[], roles: string[]) {
      this.store.patchViewState({ selected: { users, roles } });
   }

   onSearchChange(search: string) {
      this.store.patchViewState({ search });
   }

   onActiveOnlyChange(activeOnly: boolean) {
      this.store.patchViewState({ activeOnly });
   }

   onPageChange(pagination: Pagination) {
      this.store.patchViewState({ pagination });
   }

   onSortChange(sort: Sort) {
      this.store.patchViewState({ sort });
   }

   onToggleChange(value: boolean): void {
      this.store.patchState({ toggle: value });
   }

   onClearFilters(page: Pagination) {
      const filters: RequestListFilters =
      {
         number: null,
         caller: null,
         pwd: null,
         legalTeam: null,
         requested: null,
         followedUp: null,
         reviewed: null,
         urgentRequest: null,
         status: null,
         convertedTo: null,
         proBonoAttorney: null
      }
      this.onFiltersChange(filters, page)
   }
}
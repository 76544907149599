import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ConfirmationService } from '@app/shared';
import { filter, tap } from 'rxjs/operators';
import { EligibilityListStore } from '../../list/state';
import { Eligibility } from '../../types';
import { EligibilityListComponent } from '../components/eligibility-list/eligibility-list.component';
import { EligibilityListFilters } from '../types';

@Component({
   selector: 'app-eligibility-list-view',
   templateUrl: './eligibility-list-view.component.html',
   styleUrls: ['./eligibility-list-view.component.scss'],
   providers: [EligibilityListStore]
})
export class EligibilityListViewComponent implements OnInit {
   @Input() involvementId: string;
   @Input() sourceType: string;
   @ViewChild(EligibilityListComponent, { static: true }) list: EligibilityListComponent;
   vm$ = this.store.vm$;

   constructor(
      private readonly store: EligibilityListStore,
      private readonly confirmation: ConfirmationService
   ) { }

   ngOnInit(): void {
      this.store.updateInvolvementId(this.involvementId)
   }

   onFiltersChange(filters: EligibilityListFilters) {
      this.store.updateFilters(filters);
   }

   showDeleteDialog(eligibility: Eligibility) {
      const { id, fundingProgramName } = eligibility;
      this.confirmation.confirm(`Delete ${fundingProgramName}?`, 'DELETE', 'CANCEL')
         .pipe(
            filter(ok => ok),
            tap(() => this.store.deleteEligibility(eligibility)),
            tap(() => this.store.onDelete(id)),
         ).subscribe()
   }
}
import { Transition, TransitionService } from '@uirouter/core';

export function windowTitleHandler(transitionService: TransitionService) {
   const setTitleCriteria = {
      to: state => state.data && state.data.title
   };

   const setWindowTitle = (transition: Transition) => {
      let { title } = transition.to().data;

      if (title instanceof Array) {
         const args: any[] = [];
         for (let i = 0; i < title.length - 1; i++) {
            args.push(transition.injector().get(title[i]));
         }

         title = title[title.length - 1].call(transition.to(), ...args);
      }

      window.document.title = title + ' | PANDA';
   };

   transitionService.onSuccess(setTitleCriteria, setWindowTitle);
}

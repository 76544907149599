import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SystemicCase } from '@app/systemic-cases/list/types/systemic-case';
import { HttpService, PagedList } from '@app/core/index';
import { CaseListFilterOptions } from '@app/shared/types/case-list-filter-options';
import { getFileNameFromResponseContentDisposition, saveFile } from '@app/shared';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { SystemicCaseCreate } from '../types/systemic-case-create';
import { GetSystemicCaseListPayload } from '../types/systemic-case-params';


@Injectable({
    providedIn: 'root'
})
export class SystemicCaseListService {
    static injectName = 'systemicCaseService';
    private baseUrl = 'api/systemiccases';

    constructor(private http: HttpService) { }

    getSystemicCasesList(criteria: GetSystemicCaseListPayload): Observable<PagedList<SystemicCase>> {
        const url = `${this.baseUrl}/list/filtered`;
        return this.http.post<PagedList<SystemicCase>>(url, criteria);
    }

    getSystemicCasesExport(criteria: GetSystemicCaseListPayload) {
        const url = `${this.baseUrl}/list/export`;
        return this.http.post<HttpResponse<Blob>>(url, criteria, { responseType: 'blob', observe: 'response' }).pipe(
            tap(res => {
                const fileName = getFileNameFromResponseContentDisposition(res);
                saveFile(res.body, fileName);
            })
        );
    }

    createSystemicCase(data: SystemicCaseCreate): Observable<SystemicCase> {
        const url = `${this.baseUrl}`;
        return this.http.post<SystemicCase>(url, data);
    }

    deleteSystemicCase(id: string) {
        const url = `${this.baseUrl}/${id}`;
        return this.http.delete(url);
    }

    getFilterOptions(): Observable<CaseListFilterOptions> {
        const url = `api/configuration/list/options`;
        return this.http.post<CaseListFilterOptions>(url, { CaseType: 'systemic-case' });
    }
}
import { Component, Input, OnInit } from '@angular/core';
import { StateService } from '@uirouter/core';

import { PAATIntakeInfoModel } from '../../models';
import { FundingService } from '../../funding.service';

@Component({
   selector: 'pam-paat-intake-info',
   templateUrl: './paat-intake-info.component.html',
   styleUrls: ['./paat-intake-info.component.scss']
})
export class PAATIntakeInfoComponent implements OnInit {
   @Input() enrollmentId: string;
   intake: PAATIntakeInfoModel;

   constructor (
      private fundingRepositoryService: FundingService,
      private stateService: StateService
   ) { }

   ngOnInit() {
      this.getIntake();
   }

   private getIntake = () => {
      this.fundingRepositoryService.getPAATIntakeInfo(this.enrollmentId).subscribe(result => {
         this.intake = result;
      });
   }

}

const isDev = window.location.port.indexOf('4200') > -1 || window.location.port.indexOf('5000') > -1;

const getHost = () => {
   const { protocol, host } = window.location;
   return `${protocol}//${host}/`;
};

const BASE_URL = getHost();
const API_URL = isDev ? 'http://localhost:5001/v1/' : BASE_URL.replace('panda', 'pandaapi') + 'v1/';
const tenantName = "pandaequipforequality.onmicrosoft.com";
const ADB2C_URL = "https://pandaequipforequality.b2clogin.com";
const OID_SCOPE="openid https://pandaequipforequality.onmicrosoft.com/api/user_impersonation"

export const ENV = {
   isDev,
   BYPASS_AUTH: isDev,
   BASE_URL,
   API_URL,
   tenantName,
   ADB2C_URL,
   OID_SCOPE
};

import { Injectable } from '@angular/core';
import { HttpService, PagedList } from '../core/index';
import { ContactModel, CreateMonitoringModel, GetMonitoringListParams, IssueModel, MeetingModel, MonitoringBasicInfoModel, MonitoringListItemModel } from './models/monitoring.models';
import { Observable } from 'rxjs';
import { HttpResponse } from '@angular/common/http';
import { tap } from 'rxjs/operators';
import { getFileNameFromResponseContentDisposition, saveFile } from '../shared/helpers';

@Injectable()
export class MonitoringRepositoryService {
   static injectName = 'monitoringService';
   private baseUrl = 'api/monitoring';

   constructor(private http: HttpService) { }

   createMonitoring(data: CreateMonitoringModel): Observable<{id: string}> {
      const url = `${this.baseUrl}`;
      return this.http.post<{id: string}>(url, data);
   }

   getMonitoringList(data: GetMonitoringListParams): Observable<PagedList<MonitoringListItemModel>> {
      const url = `${this.baseUrl}/list`;
      return this.http.post<PagedList<MonitoringListItemModel>>(url, data);
   }

   getMonitoringListDownload(data: GetMonitoringListParams) {
      const url = `${this.baseUrl}/list/csv`;
      return this.http.post<HttpResponse<Blob>>(url, data, { responseType: 'blob', observe: 'response' }).pipe(
         tap(res => {
            const fileName = getFileNameFromResponseContentDisposition(res);
            saveFile(res.body, fileName);
         })
      );
   }

   getBasicInfo(monitoringId: string) {
      const url = `${this.baseUrl}/${monitoringId}/basicinfo`;
      return this.http.get<MonitoringBasicInfoModel>(url);
   }

   getStatus(monitoringId: string) {
      const url = `${this.baseUrl}/${monitoringId}/status`;
      return this.http.get<{status: string}>(url);
   }

   deleteMonitoring(id: string) {
      return this.http.delete(`${this.baseUrl}/${id}`);
   }

   createIssue(data: IssueModel): Observable<IssueModel> {
      const url = `${this.baseUrl}/${data.monitoringId}/issues`;
      return this.http.post<IssueModel>(url, data);
   }

   getIssue(id: string) {
      const url = `${this.baseUrl}/issues/${id}`;
      return this.http.get<IssueModel>(url);
   }

   updateIssue(data: IssueModel) {
      const url = `${this.baseUrl}/issues/${data.id}`;
      return this.http.put<IssueModel>(url, data);
   }
   createContact(data: ContactModel): Observable<ContactModel> {
      const url = `${this.baseUrl}/${data.monitoringId}/contacts`;
      return this.http.post<ContactModel>(url, data);
   }

   getContact(id: string) {
      const url = `${this.baseUrl}/contacts/${id}`;
      return this.http.get<ContactModel>(url);
   }

   updateContact(data: ContactModel) {
      const url = `${this.baseUrl}/contacts/${data.id}`;
      return this.http.put<ContactModel>(url, data);
   }
   createMeeting(data: MeetingModel): Observable<MeetingModel> {
      const url = `${this.baseUrl}/${data.monitoringId}/meetings`;
      return this.http.post<MeetingModel>(url, data);
   }

   getMeeting(id: string) {
      const url = `${this.baseUrl}/meetings/${id}`;
      return this.http.get<MeetingModel>(url);
   }

   updateMeeting(data: MeetingModel) {
      const url = `${this.baseUrl}/meetings/${data.id}`;
      return this.http.put<MeetingModel>(url, data);
   }
}

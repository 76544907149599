import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpService, PagedList } from '@app/core/index';
import { GetGrievanceListPayload, Grievance, GrievanceFormModel, GrievanceListFilterOptions } from '@app/grievances/list/types';
import { getFileNameFromResponseContentDisposition, saveFile } from '@app/shared';
import { CaseListModel } from '@app/shared/types/case-list';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';


@Injectable({
   providedIn: 'root'
})
export class GrievanceListService {
   static injectName = 'grievanceService';
   private baseUrl = 'api/grievances';

   constructor(private http: HttpService) { }

   getGrievancesList(criteria: GetGrievanceListPayload): Observable<PagedList<Grievance>> {
      const url = `${this.baseUrl}/list/filtered`;
      return this.http.post<PagedList<Grievance>>(url, criteria);
   }

   getGrievancesExport(criteria: GetGrievanceListPayload) {
      const url = `${this.baseUrl}/list/export`;
      return this.http.post<HttpResponse<Blob>>(url, criteria, { responseType: 'blob', observe: 'response' }).pipe(
         tap(res => {
            const fileName = getFileNameFromResponseContentDisposition(res);
            saveFile(res.body, fileName);
         })
      );
   }

   getGrievanceListOptions(): Observable<GrievanceListFilterOptions> {
      const url = `${this.baseUrl}/list/options`;
      return this.http.post<GrievanceListFilterOptions>(url, { CaseType: null });
   }

   getGrievantCases(entityId: string): Observable<CaseListModel[]> {
      const url = `${this.baseUrl}/cases`;
      return this.http.post<CaseListModel[]>(url, { entityId });
   }

   createGrievance(data: GrievanceFormModel): Observable<Grievance> {
      const url = `${this.baseUrl}`;
      return this.http.post<Grievance>(url, data);
   }

   updateGrievance(data: GrievanceFormModel): Observable<Grievance> {
      const url = `${this.baseUrl}/${data.id}`;
      return this.http.put<Grievance>(url, data);
   }

   deleteGrievance(id: string) {
      const url = `${this.baseUrl}/${id}`;
      return this.http.delete(url);
   }
}
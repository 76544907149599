import * as angular from 'angular';

export const appModule = angular.module('app', [
      'app.core',
      'app.config',
      'app.data',
      'app.layout',
      'app.activities',
      'app.admin',
      'app.alerts',
      'app.analysis',
      'app.calendars',
      'app.documentManagement',
      'app.identity',
      'app.involvements',
      'app.persons',
      'app.preferences',
      'app.reports',
      'app.requests',
      'app.clientCases',
      'app.search',
      'app.utils',
      'app.mail',
      'app.filters',
      'app.users',
      'app.clientCases',
      'app.systemicCases',
      'app.informationAndReferrals',
      'app.time',
      'app.fiscal',
      'app.training',
      'app.projects',
      'app.funding',
      'app.common',
      'app.agencies',
      'app.entities',
      'app.cases',
      'app.grievance',
      'app.monitoring'
   ]);

export interface IDictionary<T> {
   [key: string]: T;
}

export * from './api.models';
export * from './app-configuration.model';
export * from './constants';
export * from './feature.model';
export * from './permission.model';
export * from './role.model';
export * from './tenant.model';
export * from './user.model';

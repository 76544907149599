import { Transition, TransitionService } from '@uirouter/core';
import { AuthService } from '../services';


export function featureGuard(transitionService: TransitionService) {
   const requiresFeatureCriteria = {
      to: (state) => state.data && state.data.feature
   };

   const redirectToAccessDenied = (transition: Transition) => {
      const { data } = transition.to();
      const $state = transition.router.stateService;
      const auth: AuthService = transition.injector().get(AuthService);

      return auth.authorize().then(() => {
         if (data.feature && !auth.checkFeature(data.feature)) {
            return $state.target('403');
         }
      });
   };

   transitionService.onBefore(requiresFeatureCriteria, redirectToAccessDenied, { priority: 20 });
}
import { Injectable } from '@angular/core';

import { RequestsRepositoryService } from '../../requests/requests.repository';
import { IAndRsRepositoryService } from '../../i-and-rs/i-and-rs.repository';
import { ClientCasesRepositoryService } from '../../client-cases/client-cases.repository';
import { SystemicCasesRepositoryService } from '../../systemic-cases/systemic-cases.repository';
import { forkJoin } from 'rxjs';

@Injectable()
export class HomepageCaseService {
   constructor(
      private requestsRepository: RequestsRepositoryService,
      private iAndRsRepository: IAndRsRepositoryService,
      private clientCasesRepository: ClientCasesRepositoryService,
      private systemicCasesRepository: SystemicCasesRepositoryService,
   ) { }


   getCaseData(userIds: string[], roles: string[]) {
      return forkJoin(
         this.requestsRepository.getActiveRequestCount(userIds),
         this.iAndRsRepository.getActiveCaseCount(userIds, roles),
         this.clientCasesRepository.getActiveCaseCount(userIds, roles),
         this.systemicCasesRepository.getActiveCaseCount(userIds, roles)
      );
   }
}

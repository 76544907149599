import { Pipe, PipeTransform } from '@angular/core';

/*
 * Displays specified text if the piped value is null.
 * Usage:
 *   string | nullText:'Not Available'
 *   boolean | yesNo | nullText:'N/A'
 * Example:
 *   {{ '' | nullText:'N/A' }}
 *   formats to: N/A
*/
@Pipe({ name: 'nullText' })
export class NullTextPipe implements PipeTransform {
   transform(value: any, text: string): string {
      if (value === undefined || value === null || value === '') return text;
      else return value;
   }
}

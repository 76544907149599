import { Injectable } from '@angular/core';
import { HomepageStateModel } from '../models/home-page.models';
import { of } from 'rxjs';

@Injectable()
export class HomepageStateService {
   constructor() {
      this.loadState();
   }
   state: HomepageStateModel;

   save() {
      localStorage.setItem('homepageState', JSON.stringify(this.state));
   }

   update(state: HomepageStateModel) {
      this.state = state;
      this.save();
   }

   loadState() {
      const data = localStorage.getItem('homepageState');
      this.state = data ? JSON.parse(data) : { users: [] };
   }

   getUsers() {
      return this.state.users;
   }

   getUserIds() {
      return this.state.users.map(u => u.id);
   }
}

import { Pipe, PipeTransform } from '@angular/core';

/*
 * Convert a boolean True/False value to equivalent Yes/No value.
 * Usage:
 *   boolean | yesNo
 * Example:
 *   {{ true |  yesNo}}
 *   formats to: Yes
*/
@Pipe({ name: 'yesNo' })
export class YesNoPipe implements PipeTransform {
   transform(value: boolean): string {
      if (value === undefined) return '';

      if (value) return 'Yes';
      else return 'No';
   }
}

import { Injectable } from '@angular/core';
import { HomepageStateService } from './home-page-state.service';
import { forkJoin } from 'rxjs';
import { ClientCasesRepositoryService } from '../../client-cases/client-cases.repository';
import { TimeRepositoryService } from '../../time/time.repository';

@Injectable()
export class HomepageTimeslipService {
   constructor(
      private clientCasesRepository: ClientCasesRepositoryService,
      private timeRepository: TimeRepositoryService) {
   }

   getTimeslipData(userIds: string[]) {
      return forkJoin(
         this.clientCasesRepository.getCasesWithNoTimeslips(userIds),
         this.timeRepository.getTimeslipsPerPayPeriod(userIds),
         this.timeRepository.getTimeslipsPerWorkWeek(userIds)
      );
   }
}


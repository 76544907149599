import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';

import { UIRouterUpgradeModule } from '@uirouter/angular-hybrid';
import { FiltersModule } from '../filters/filters.module';
import { SystemicCasesListModule } from './list/systemic-cases-list.module';
import { SystemicCasesRepositoryService } from './systemic-cases.repository';
import { states } from './systemic-cases.states';

@NgModule({
   imports: [
      UIRouterUpgradeModule.forChild({ states }),
      SharedModule,
      FiltersModule,
      SystemicCasesListModule
   ],
   declarations: [
   ],
   exports: [
   ],
   providers: [
      SystemicCasesRepositoryService
   ]
})
export class SystemicCasesModule {
}

import { Injectable } from '@angular/core';
import { HttpService, PagedList } from '../core/index';
import { CLEListItemModel, CLEModel, GetCLEListParams } from './models/cle.models';
import { Observable } from 'rxjs';
import { HttpResponse } from '@angular/common/http';
import { tap } from 'rxjs/operators';
import { getFileNameFromResponseContentDisposition, saveFile } from '../shared';

@Injectable()
export class CLERepositoryService {
   static injectName = 'cleServie';
   private baseUrl = 'api/trainings/cle';

   constructor(private http: HttpService) { }

   getCLE(cleId: string) {
      const url = `${this.baseUrl}/${cleId}`;
      return this.http.get<CLEModel>(url);
   }

   getCLEList(data: GetCLEListParams): Observable<PagedList<CLEListItemModel>> {
      const url = `${this.baseUrl}/list`;
      return this.http.post<PagedList<CLEListItemModel>>(url, data);
   }

   getCLEListDownload(data: GetCLEListParams) {
      const url = `${this.baseUrl}/list/csv`;
      return this.http.post<HttpResponse<Blob>>(url, data, { responseType: 'blob', observe: 'response' }).pipe(
         tap(res => {
            const fileName = getFileNameFromResponseContentDisposition(res);
            saveFile(res.body, fileName);
         })
      );
   }

   deleteCLE(id: string) {
      return this.http.delete(`${this.baseUrl}/${id}`);
   }
}

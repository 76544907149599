import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ConfigurationService } from '@app/configuration/configuration.service';
import { StateService } from '@uirouter/core';
import { forkJoin, Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { Next10CalendarEventsModel } from '../../calendars/models/calendars.models';
import { CasesWithNoTimeslipsCountsModel } from '../../client-cases/models/client-cases.models';
import { TimeslipsPerPayPeriodModel, TimeslipsPerWorkWeekModel } from '../../time/models/time.models';
import { UserModel } from '../../users/models/user.model';
import { UserService } from '../../users/user.service';
import { HomepageCalendarService } from '../services/home-page-calendar.service';
import { HomepageCaseService } from '../services/home-page-case.service';
import { HomepageStateService } from '../services/home-page-state.service';
import { HomepageTimeslipService } from '../services/home-page-timeslip.service';

@Component({
   selector: 'home-page',
   templateUrl: './home-page.component.html',
   styleUrls: ['./home-page.component.scss']
})
export class HomePageComponent implements OnInit {
   selectedUsers: UserModel[] = [];
   userIds: string[];
   roles: string[];
   rolesControl: FormControl;
   initialUsers: UserModel[];
   isBusy = true;
   requestCount: number;
   iAndRCount: number;
   clientCaseCount: number;
   systemicCaseCount: number;
   clientCasesWithNoTimeslips: CasesWithNoTimeslipsCountsModel;
   timeslipsPerPayPeriod: TimeslipsPerPayPeriodModel[];
   timeslipsPerWorkWeek: TimeslipsPerWorkWeekModel[];
   next10CalendarEvents: Next10CalendarEventsModel[];

   constructor(
      private userService: UserService,
      private homepageStateService: HomepageStateService,
      private caseService: HomepageCaseService,
      private configurationService: ConfigurationService,
      private timeService: HomepageTimeslipService,
      private calendarService: HomepageCalendarService,
      private states: StateService
   ) { }

   ngOnInit() {
      this.rolesControl = new FormControl();
      this.rolesControl.setValue(["Primary Staff", "Secondary Staff"]);
      const initialUsers = this.homepageStateService.getUsers();
      this.getRoles();
      if (initialUsers.length > 0) {
         this.initialUsers = initialUsers;
      } else {
         this.userService.getCurrentUser()
            .subscribe(result => this.initialUsers = [result]);
      }
      this.rolesControl.valueChanges.pipe(
         switchMap(roles => this.caseService.getCaseData(this.userIds, roles))
      ).subscribe(roleCounts => {
         this.iAndRCount = roleCounts[1];
         this.clientCaseCount = roleCounts[2];
         this.systemicCaseCount = roleCounts[3];
      }
      );
   }

   getSupervisedUsers = (q: string): Observable<UserModel[]> => {
      return this.userService.getSupervisedUsers(q);
   }

   usersUpdated(users: UserModel[]) {
      this.isBusy = true;

      this.selectedUsers = users;
      this.homepageStateService.update({ users });
      this.userIds = this.homepageStateService.getUserIds();

      if (this.selectedUsers.length > 0) {
         this.getData(this.userIds, this.rolesControl.value);
      }
   }

   getData(userIds: string[], roles?: string[]) {
      forkJoin(
         this.caseService.getCaseData(userIds, roles),
         this.timeService.getTimeslipData(userIds),
         this.calendarService.getCalendarData(userIds)
      ).subscribe(data => this.processData(data));
   }

   getRoles() {
      this.configurationService.getLookupType('involvement-user-role').pipe(
         //Use rxjs and array map operators to extract lookup values
         map(({ lookups }) => lookups.map(l => l.value))
      ).subscribe(roles => this.roles = roles)
   };

   processData([[requestCount, iAndRCount, clientCaseCount, systemicCaseCount],
      [casesWithNoTimeslipsCounts, timeslipsPerPayPeriod, timeslipsPerWorkWeek],
      next10CalendarEvents]) {

      this.requestCount = requestCount;
      this.iAndRCount = iAndRCount;
      this.clientCaseCount = clientCaseCount;
      this.systemicCaseCount = systemicCaseCount;

      this.clientCasesWithNoTimeslips = casesWithNoTimeslipsCounts;
      this.timeslipsPerPayPeriod = timeslipsPerPayPeriod;
      this.timeslipsPerWorkWeek = timeslipsPerWorkWeek;

      this.next10CalendarEvents = next10CalendarEvents;
      this.isBusy = false;
   }

   private getListingParams() {
      return {
         usersFilter: this.homepageStateService.getUsers(),
         rolesFilter: this.rolesControl.value,
         usePreferences: false,
         initializeSelected: true
      };
   }

   showRequestListing() {
      this.states.go('app.requestListDashboard', this.getListingParams());
   }
   showIAndRListing() {
      this.states.go('app.iAndRListDashboard', this.getListingParams());
   }
   showClientCaseListing() {
      this.states.go('app.clientCaseListDashboard', this.getListingParams());
   }
   showSystemicCaseListing() {
      this.states.go('app.systemicCaseListDashboard', this.getListingParams());
   }
}

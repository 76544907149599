import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';

import { FiscalService } from './fiscal.service';
import { UIRouterUpgradeModule } from '@uirouter/angular-hybrid';
import { states } from './fiscal.states';
import { CheckListComponent } from './check-list/check-list.component';
import { FiltersModule } from '../filters/filters.module';
import { EditCheckButtonUpgradedDirective } from './check-button/edit-check-button-upgrader.directive';
import { ViewCheckButtonUpgradedDirective } from './check-button/view-check-button-upgrader.directive.1';
import { CreateCheckButtonUpgradedDirective } from './check-button/create-check-button-upgrader.directive';

@NgModule({
   imports: [
      UIRouterUpgradeModule.forChild({ states }),
      SharedModule,
      FiltersModule
   ],
   declarations: [
      CheckListComponent,
      CreateCheckButtonUpgradedDirective,
      EditCheckButtonUpgradedDirective,
      ViewCheckButtonUpgradedDirective
   ],
   exports: [
   ],
   providers: [
      FiscalService
   ]
})
export class FiscalModule {
}

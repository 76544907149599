import { OverlayRef } from '@angular/cdk/overlay';

export class MoreDetailPopupRef {

   constructor(private overlayRef: OverlayRef) { }

   close() {
      this.overlayRef.dispose();
   }
}

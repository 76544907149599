import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';

import { CalendarsRepositoryService } from './calendars.repository';

@NgModule({
   imports: [
      SharedModule
   ],
   declarations: [
   ],
   exports: [
   ],
   providers: [
      CalendarsRepositoryService
   ]
})
export class CalendarsModule {
}

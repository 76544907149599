import { Injectable } from '@angular/core';
import { forkJoin } from 'rxjs';
import { first, switchMap } from 'rxjs/operators';

import { IdentityService } from './identity.service';
import { OidcService } from './oidc.service';

@Injectable()
export class AuthService {
   authorizeResolver: (user?: any) => void;
   authorizePromise = new Promise(resolve => {
      this.authorizeResolver = resolve;
   });
   currentViewPermissions: string[];
   get accessToken() { return this.identityService.accessToken; }
   get user() { return this.identityService.user; }
   get permissions() { return this.identityService.permissions; }

   constructor(
      private oidc: OidcService,
      private identityService: IdentityService) {
   }

   authorize() {
      return this.authorizePromise;
   }

   checkFeature(feature: string) {
      return !!this.user.features.find(f => f.toLowerCase() === feature.toLowerCase());
   }

   checkPermissions(...requiredPermissions: string[]) {
      return this.checkPermissionsCore(requiredPermissions, true);
   }

   checkPermissionsAny(...requiredPermissions: string[]) {
      return this.checkPermissionsCore(requiredPermissions, false);
   }

   checkPermissionsAtAnyLocation(...requiredPermissions: string[]) {
      if (this.isAdministrator()) {
         return true;
      }

      const actualPermissions = this.identityService.permissions
         .map(p => p.permission)
         .filter((value, index, self) => self.indexOf(value) === index);

      return requiredPermissions.every(p => actualPermissions.indexOf(p) > -1);
   }

   checkRoles(...roles: string[]) {
      if (!(roles && roles.length)) return true;

      for (const role of roles) {
         if (!this.user.roles.some(r => r.id === role)) {
            return false;
         }
      }

      return true;
   }

   login() {
      this.oidc.login().pipe(
         first(),
         switchMap(token => this.identityService.init(token, this.oidc.accessTokenObservable)),
         switchMap(() => this.identityService.getCurrentUser())
      ).subscribe(() => {
         this.authorizeResolver(this.user);
      });
   }

   logout() {
      this.oidc.logout();
   }

   loginSilent() {
      return this.oidc.loginSilent();
   }

   private checkPermissionsCore(requiredPermissions: string[], enforceAll: boolean) {
      if (this.isAdministrator()) {
         return true;
      }

      const actualPermissions: string[] = this.identityService.user.roles
         .map(r => r.permissions.map(p => p.permission))
         .reduce((acc, curr) => {
            acc.push(...curr);
            return acc;
         }, [])
         .filter((p, i, self) => self.indexOf(p) === i);

      const success = enforceAll
         ? requiredPermissions.every(p => actualPermissions.indexOf(p) > -1)
         : requiredPermissions.some(p => actualPermissions.indexOf(p) > -1);

      return success;
   }

   private isAdministrator() {
      return !!this.user.roles.find(r => r.id === 'Administrator');
   }
}

import { NgModule } from '@angular/core';
import { UIRouterUpgradeModule } from '@uirouter/angular-hybrid';
import { FiltersModule } from '../filters/filters.module';
import { SharedModule } from '../shared/shared.module';
import { RequestListModule } from './list/request-list.module';
import { RequestsRepositoryService } from './requests.repository';
import { states } from './requests.states';


@NgModule({
   imports: [
      FiltersModule,
      UIRouterUpgradeModule.forChild({ states }),
      SharedModule,
      RequestListModule
   ],
   declarations: [
   ],
   exports: [
   ],
   providers: [
      RequestsRepositoryService
   ]
})
export class RequestsModule {
}

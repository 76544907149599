import { Transition, TransitionService } from '@uirouter/core';

import { ConfirmationService, InterruptService } from '../../shared';

export function interruptHandler(transitionService: TransitionService) {
   const interrupter = (transition: Transition) => {
      const opts = transition.options().custom || {};
      const interruptService: InterruptService = transition.injector().get(InterruptService);

      if (!opts.bypassInterrupt && interruptService.isNavigationPrevented()) {
         const confirmation: ConfirmationService = transition.injector().get(ConfirmationService);
         return confirmation
            .confirm('Continue without saving changes?', 'CONTINUE', 'CANCEL')
            .toPromise()
            .then((result: boolean) => {
               if (result) interruptService.allowNavigation();
               return result;
            });
      } else {
         interruptService.allowNavigation();
         return true;
      }
   };

   transitionService.onBefore({}, interrupter);
}

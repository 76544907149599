import { Component, ElementRef, Input, ViewChild } from '@angular/core';

@Component({
   selector: 'am-file-selector',
   templateUrl: './file-selector.component.html',
   styleUrls: ['./file-selector.component.scss']
})
export class FileSelectorComponent {
   @ViewChild('file', { static: true }) file: ElementRef;
   @Input() disabled = false;
   @Input() files: Set<File>;

   addFiles() {
      this.file.nativeElement.click();
   }

   onFilesAdded() {
      const files: { [key: string]: File } = this.file.nativeElement.files;
      for (const key in files) {
         if (!isNaN(parseInt(key))) {
            this.files.add(files[key]);
         }
      }
      this.file.nativeElement.value = null;
   }

   onFilesDropped(files: File[]) {
      files.forEach(file => this.files.add(file));
   }

   removeFile(file: File) {
      this.files.delete(file);
   }
}

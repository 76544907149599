import { Injectable } from '@angular/core';

@Injectable()
export class PreferenceService {
   constructor() {
      this.loadFromStorage();
   }
   state: any;

   deletePreference(name: string) {
      delete this.state[name];
      this.writeToStorage();
   }

   getPreference<T>(name: string) {
      return this.state[name] as T;
   }

   patchValue(name: string, value: any) {
      const currentValue = this.getPreference<any>(name) || {};
      const newValue = Object.assign({}, currentValue, value);
      this.setPreference(name, newValue);
   }

   setPreference<T>(name: string, value: T) {
      this.state[name] = value;
      this.writeToStorage();
   }

   private loadFromStorage() {
      const data = localStorage.getItem('preferences');
      this.state = data ? JSON.parse(data) : {};
   }

   private writeToStorage() {
      localStorage.setItem('preferences', JSON.stringify(this.state));
   }
}

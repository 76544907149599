import { Component, OnInit } from '@angular/core';

@Component({
   selector: 'am-monitoring',
   templateUrl: './monitoring.component.html',
   styleUrls: ['./monitoring.component.scss']
})
export class MonitoringComponent implements OnInit {

   ngOnInit() {

   }

}

import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';

import { HttpService } from '../../core';
import { ENV } from '../../env.config';
import { LookupType } from '../models';

@Injectable()
export class LookupService {
   constructor(private http: HttpService) { }

   getLookupType(lookupType: string, activeOnly = true) {
      const params = new HttpParams()
         .set('activeOnly', `${activeOnly}`);

      const requestUri = `${ENV.API_URL}/configuration/lookup-types/${lookupType}`;
      return this.http.get<LookupType>(requestUri, { params }).pipe(map(result => result ? result.lookups.map(l => l.value) : []));
   }
}

import { Pipe, PipeTransform } from '@angular/core';

/*
 * Convert an unformatted US phone number to a formatted US phone number.
 * Usage:
 *   phoneNumber | phoneNumber
 * Example:
 *   {{ +12223334444 |  phoneNumber}}
 *   formats to: (222) 333-4444
*/
@Pipe({ name: 'phoneNumber' })
export class PhoneNumberPipe implements PipeTransform {
   transform(phoneNumber: string, transformType: string): string {
      let formattedPhoneNumber: string;

      if (transformType === '-') {
         formattedPhoneNumber = phoneNumber.substr(2, 3) + '-' +
                                phoneNumber.substr(5, 3) + '-' +
                                phoneNumber.substr(8, 4);

      } else {
         formattedPhoneNumber = '(' +
                                phoneNumber.substr(2, 3) + ') ' +
                                phoneNumber.substr(5, 3) + '-' +
                                phoneNumber.substr(8, 4);
      }

      return formattedPhoneNumber;
   }
}

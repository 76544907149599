import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Sort } from '@angular/material/sort';
import { ConfirmationService } from '@app/shared';
import { SystemicCasesListComponent } from '@app/systemic-cases/list/components';
import { SystemicCasesCreateDialog } from '@app/systemic-cases/list/dialogs';
import { SystemicCaseStateStore } from '@app/systemic-cases/list/state/systemic-case-list.store';
import { SystemicCase } from '@app/systemic-cases/list/types/systemic-case';
import { SystemicCasesListFilters } from '@app/systemic-cases/list/types/systemic-cases-filters';
import { Pagination } from '@app/types/core';
import { UserModel } from '@app/users';
import { UIRouter } from '@uirouter/core';
import { filter } from 'rxjs/operators';


@Component({
  selector: 'app-systemic-cases-list.view',
  templateUrl: './systemic-cases-list.view.html',
  styleUrls: ['./systemic-cases-list.view.scss'],
  providers: [SystemicCaseStateStore]
})
export class SystemicCasesListView implements OnInit {
  @Input() rolesFilter: string[] = [];
  @Input() usersFilter: UserModel[] = [];
  @Input() initializeSelected: boolean = false;
  @ViewChild(SystemicCasesListComponent, { static: true }) list: SystemicCasesListComponent;
  vm$ = this.store.vm$;

  constructor(
    private readonly dialog: MatDialog,
    private router: UIRouter,
    private readonly store: SystemicCaseStateStore,
    private readonly confirmation: ConfirmationService
  ) { }

  ngOnInit(): void {
    const {
      current: { name: viewId },
    } = this.router.globals;

    this.store.init(viewId);

    if (this.initializeSelected) {
      this.onActiveOnlyChange(true)
      this.onSelectedChange(this.usersFilter, this.rolesFilter);
      this.onClearFilters({ page: 1, pageSize: 25 });
    };
  }

  showSystemicCaseCreateDialog() {
    SystemicCasesCreateDialog.open(this.dialog, {
      openDate: null
    }).afterClosed().pipe(
      filter(value => !!value)
    ).subscribe(systemicCase => this.store.addSystemicCase(systemicCase));
  }

  showDeleteDialog(systemicCase: SystemicCase) {
    const { number } = systemicCase;
    this.confirmation.confirm(`Delete Systemic Case (${number})?`, 'DELETE', 'CANCEL').pipe(
      filter(ok => ok)
    ).subscribe(() => this.store.deleteSystemicCase(systemicCase.involvementId));
  }

  onFiltersChange(filters: SystemicCasesListFilters, page: Pagination): void {
    const pageSize = page?.pageSize ?? 25;
    this.store.patchViewState({ filters, pagination: { page: 1, pageSize } });
  }

  onSelectedChange(users: UserModel[], roles: string[]) {
    this.store.patchViewState({ selected: { users, roles } });
  }

  onSearchChange(search: string) {
    this.store.patchViewState({ search });
  }

  onActiveOnlyChange(activeOnly: boolean) {
    this.store.patchViewState({ activeOnly });
  }

  onPageChange(pagination: Pagination) {
    this.store.patchViewState({ pagination });
  }

  onSortChange(sort: Sort) {
    this.store.patchViewState({ sort });
  }

  onToggleChange(value: boolean): void {
    this.store.patchState({ toggle: value });
  }

  onClearFilters(page: Pagination) {
    const filters: SystemicCasesListFilters =
    {
      number: null,
      caseName: null,
      primaryStaff: null,
      legalTeam: null,
      funder: null,
      status: null,
      opened: null,
      closed: null
    }
    this.onFiltersChange(filters, page)
  }
}
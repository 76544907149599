import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ClientCase } from '@app/client-cases/list/types/client-case';
import { HttpService, PagedList } from '@app/core/index';
import { getFileNameFromResponseContentDisposition, saveFile } from '@app/shared';
import { CaseListFilterOptions } from '@app/shared/types/case-list-filter-options';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ClientCaseCreate } from '../types/client-case-create';
import { GetClientCaseListPayload } from '../types/client-case-params';

@Injectable({
   providedIn: 'root'
})
export class ClientCaseListService {
   static injectName = 'clientCaseService';
   private baseUrl = 'api/clientcases';

   constructor(private http: HttpService) { }

   getClientCasesList(criteria: GetClientCaseListPayload): Observable<PagedList<ClientCase>> {
      const url = `${this.baseUrl}/list/filtered`;
      return this.http.post<PagedList<ClientCase>>(url, criteria);
   }

   getClientCasesExport(criteria: GetClientCaseListPayload) {
      const url = `${this.baseUrl}/list/export`;
      return this.http.post<HttpResponse<Blob>>(url, criteria, { responseType: 'blob', observe: 'response' }).pipe(
         tap(res => {
            const fileName = getFileNameFromResponseContentDisposition(res);
            saveFile(res.body, fileName);
         })
      );
   }

   createClientCase(data: ClientCaseCreate): Observable<ClientCase> {
      const url = `${this.baseUrl}`;
      return this.http.post<ClientCase>(url, data);
   }

   deleteClientCase(id: string) {
      const url = `${this.baseUrl}/${id}`;
      return this.http.delete(url);
   }

   getFilterOptions(): Observable<CaseListFilterOptions> {
      const url = `api/configuration/list/options`;
      return this.http.post<CaseListFilterOptions>(url, { CaseType: 'client-case' });
   }
}
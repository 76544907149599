import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpService, PagedList } from '@app/core/index';
import { getFileNameFromResponseContentDisposition, saveFile } from '@app/shared';
import { CaseListFilterOptions } from '@app/shared/types/case-list-filter-options';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { IRequest } from '../types/request';
import { RequestCreate } from '../types/request-create';
import { GetRequestListPayload } from '../types/request-params';

@Injectable({
   providedIn: 'root'
})
export class RequestListService {
   static injectName = 'requestService';
   private baseUrl = 'api/requests';

   constructor(private http: HttpService) { }

   getRequestList(criteria: GetRequestListPayload): Observable<PagedList<IRequest>> {
      const url = `${this.baseUrl}/list/filtered`;
      return this.http.post<PagedList<IRequest>>(url, criteria);
   }

   getRequestExport(criteria: GetRequestListPayload) {
      const url = `${this.baseUrl}/list/export`;
      return this.http.post<HttpResponse<Blob>>(url, criteria, { responseType: 'blob', observe: 'response' }).pipe(
         tap(res => {
            const fileName = getFileNameFromResponseContentDisposition(res);
            saveFile(res.body, fileName);
         })
      );
   }

   createRequest(data: RequestCreate): Observable<IRequest> {
      const url = `${this.baseUrl}`;
      return this.http.post<IRequest>(url, data);
   }

   deleteRequest(id: string) {
      const url = `${this.baseUrl}/${id}`;
      return this.http.delete(url);
   }

   getFilterOptions(): Observable<CaseListFilterOptions> {
      const url = `api/configuration/list/options`;
      return this.http.post<CaseListFilterOptions>(url, { CaseType: 'request' });
   }
}
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FiltersModule } from '@app/filters/filters.module';
import { SharedModule } from '@app/shared';
import { PopoverFilterModule } from '@app/shared/filters';
import { IAndRListToolbarComponent, IAndRsListComponent } from './components';
import { IAndRCreateDialog } from './dialogs';
import { IAndRListView } from './view/i-and-rs-list.view';

@NgModule({
   declarations: [
      IAndRListView,
      IAndRCreateDialog,
      IAndRsListComponent,
      IAndRListToolbarComponent
   ],
   imports: [
      CommonModule,
      SharedModule,
      FiltersModule,
      PopoverFilterModule
   ],
   exports: [
      IAndRListView,
      IAndRCreateDialog,
      IAndRsListComponent,
      IAndRListToolbarComponent
   ]
})
export class IAndRListModule { }
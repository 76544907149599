import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FiltersModule } from '@app/filters/filters.module';
import { ProjectsCreateDialog } from '@app/projects/list/dialogs';
import { ProjectsListView } from '@app/projects/list/view';
import { SharedModule } from '@app/shared';
import { PopoverFilterModule } from '@app/shared/filters';
import { ProjectsListComponent, ProjectsListToolbarComponent } from './components';


@NgModule({
   declarations: [
      ProjectsListView,
      ProjectsCreateDialog,
      ProjectsListComponent,
      ProjectsListToolbarComponent
   ],
   imports: [
      CommonModule,
      SharedModule,
      FiltersModule,
      PopoverFilterModule
   ],
   exports: [
      ProjectsListView,
      ProjectsCreateDialog,
      ProjectsListComponent,
      ProjectsListToolbarComponent
   ]
})
export class ProjectsListModule { }
import { Component, Input } from '@angular/core';

@Component({
   selector: 'text-with-icon-cell',
   styleUrls: ['text-with-icon-cell.component.scss'],
   template: `
<div fxLayout fxLayoutAlign="start center">
   <mat-icon color="primary"
             [ngClass]="setClass()">{{icon}}</mat-icon>
   <span>{{value}}</span>
   <ng-content></ng-content>
</div>`
})
export class TextWithIconCellComponent {
   @Input() icon: string;
   @Input() value: string;
   @Input() iconColor: string;

   public setClass() {
      return { [this.iconColor]: true };
   }
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FiltersModule } from '@app/filters/filters.module';
import { SharedModule } from '@app/shared';
import { PopoverFilterModule } from '@app/shared/filters';
import { MonitoringListComponent, MonitoringListToolbarComponent } from './components';
import { MonitoringCreateDialog } from './dialogs';
import { MonitoringListView } from './view/monitoring-list.view';

@NgModule({
   declarations: [
      MonitoringListView,
      MonitoringCreateDialog,
      MonitoringListComponent,
      MonitoringListToolbarComponent
   ],
   imports: [
      CommonModule,
      SharedModule,
      FiltersModule,
      PopoverFilterModule
   ],
   exports: [
      MonitoringListView,
      MonitoringCreateDialog,
      MonitoringListComponent,
      MonitoringListToolbarComponent
   ]
})
export class MonitoringListModule { }
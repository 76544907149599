import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ConfigurationProviderService } from '../services';

@Injectable()
export class ApiBaseUrlInterceptor implements HttpInterceptor {
   private apiBaseUrl: string;

   constructor(private configurationProvider: ConfigurationProviderService) {
   }

   intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
      let url = request.url;
      if (url.match(/^api\//i)) {
         url = this.configurationProvider.config.apiRootUrl + '/' + url.replace(/^api\//i, '');
         request = request.clone({ url });
      }
      return next.handle(request);
   }
}

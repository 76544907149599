import { Directive, ElementRef, EventEmitter, Injector, Input, Output } from '@angular/core';
import { UpgradeComponent } from '@angular/upgrade/static';

@Directive({
   // tslint:disable-next-line:directive-selector
   selector: 'am-edit-check-button'
})
export class EditCheckButtonUpgradedDirective extends UpgradeComponent {
   @Input() check: any;
   @Output() refresh: EventEmitter<any>;

   constructor(elementRef: ElementRef, injector: Injector) {
      super('amEditCheckButton', elementRef, injector);
   }
}

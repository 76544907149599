import { Directive, ElementRef, Renderer2 } from '@angular/core';

@Directive({ selector: 'input' })
export class AutocompleteOffDirective {
   constructor(private elementRef: ElementRef, private renderer: Renderer2) {
      __ngRendererSetElementAttributeHelper(this.renderer, this.elementRef.nativeElement, 'autocomplete', 'off');
   }
}

type AnyDuringRendererMigration = any;

function __ngRendererSplitNamespaceHelper(name: AnyDuringRendererMigration) {
    if (name[0] === ":") {
        const match = name.match(/^:([^:]+):(.+)$/);
        return [match[1], match[2]];
    }
    return ["", name];
}

function __ngRendererSetElementAttributeHelper(renderer: AnyDuringRendererMigration, element: AnyDuringRendererMigration, namespaceAndName: AnyDuringRendererMigration, value?: AnyDuringRendererMigration) {
    const [namespace, name] = __ngRendererSplitNamespaceHelper(namespaceAndName);
    if (value != null) {
        renderer.setAttribute(element, name, value, namespace);
    }
    else {
        renderer.removeAttribute(element, name, namespace);
    }
}

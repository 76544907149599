import { AfterViewInit, Directive, ElementRef, Input } from '@angular/core';

import { AuthService } from '../../../core';

@Directive({ selector: '[amFeature]' })
export class FeatureDirective implements AfterViewInit {
   constructor(
      private elementRef: ElementRef,
      private auth: AuthService) { }

   @Input('amFeature') feature: string;

   ngAfterViewInit() {
      if (!this.auth.checkFeature(this.feature)) {
         this.elementRef.nativeElement.style.display = 'none';
      }
   }
}

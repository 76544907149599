import { Component, EventEmitter, forwardRef, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { ControlContainer, ControlValueAccessor, FormBuilder, FormControl, FormGroup, FormGroupDirective, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';
import { Moment } from 'moment';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';

export interface AmountFilterModel {
   fromAmount: number;
   toAmount: number;
}

@Component({
   selector: 'am-amount-filter',
   templateUrl: './amount-filter.component.html',
   styleUrls: ['./amount-filter.component.scss'],
   providers: [{
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AmountFilterComponent),
      multi: true
   }],
})
export class AmountFilterComponent implements OnInit, ControlValueAccessor {
   @Input() fromAmount: number;
   @Input() toAmount: number;
   filterForm: FormGroup;

   constructor(
      private formBuilder: FormBuilder) {
   }

   ngOnInit() {
      this.filterForm = this.formBuilder.group({
         fromAmount: this.fromAmount,
         toAmount: this.toAmount,
      });
   }

   registerOnChange(fn) {
      this.filterForm.valueChanges.subscribe(result => {
         fn(result); });
   }

   registerOnTouched() { }

   writeValue(value: any) {
      this.filterForm.patchValue({
         fromAmount: value ? value.fromAmount : null,
         toAmount:  value ? value.toAmount : null
      });
   }
}

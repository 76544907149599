import { Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import {
   AfterContentInit,
   ChangeDetectionStrategy,
   ChangeDetectorRef,
   Component,
   ContentChild,
   ContentChildren,
   ElementRef,
   EventEmitter,
   Input,
   OnInit,
   Output,
   QueryList,
   TemplateRef,
   ViewChild,
   ViewContainerRef
} from '@angular/core';
import { FilterControl } from '../filter-control';
import { PopoverFilterContentDirective } from '../popover-filter-content/popover-filter-content.directive';

@Component({
   selector: 'au-popover-filter',
   changeDetection: ChangeDetectionStrategy.OnPush,
   templateUrl: './popover-filter.component.html',
   styleUrls: ['./popover-filter.component.scss']
})
export class PopoverFilterComponent implements OnInit, AfterContentInit {
   @Input() disabled = false;
   @Input() disabledTooltip: string | null = null;
   @Input() triggerDisabled = false;
   @Input() triggerTooltip: string = '';
   @Input() value: any = {};
   @Input() ariaLabel: string = '';
   @Output() changes = new EventEmitter<any>();
   @ContentChild(PopoverFilterContentDirective) content!: PopoverFilterContentDirective;
   @ContentChildren(FilterControl) controls!: QueryList<FilterControl>;
   @ViewChild('popover') popover!: TemplateRef<any>;

   get active(): boolean {
      return this.overlayRef.hasAttached() || Object.values(this.value).some(v => !!v);
   }

   private overlayRef!: OverlayRef;
   private _value: any = {};

   constructor(
      private elementRef: ElementRef,
      private viewContainerRef: ViewContainerRef,
      private cdRef: ChangeDetectorRef,
      private overlay: Overlay
   ) {
   }

   ngOnInit(): void {
      const positionStrategy = this.overlay.position()
         .flexibleConnectedTo(this.elementRef)
         .withPositions([
            { originX: 'start', originY: 'bottom', overlayX: 'end', overlayY: 'top', offsetX: 40 },
            { originX: 'start', originY: 'bottom', overlayX: 'start', overlayY: 'top' }
         ]);

      const overlayConfig: OverlayConfig = {
         positionStrategy,
         hasBackdrop: true,
         backdropClass: 'app-overlay-backdrop-transparent'
      };

      this.overlayRef = this.overlay.create(overlayConfig);
      this.overlayRef.backdropClick().subscribe(() => {
         this.closePopover();
      });
   }

   ngAfterContentInit(): void {
      this.controls.changes.subscribe(() => {
         this.controls.forEach(c => {
            c.disabled = this.disabled;
            c.setDisabledState(this.disabled);
            c.writeValue(this.value[c.filterControlName]);
            c.registerOnChanges((value: any) => this.onValueChange(c.filterControlName, value));
            this.cdRef.markForCheck();
         });
      });
   }

   onClick(): void {
      this.overlayRef.attach(new TemplatePortal(this.popover, this.viewContainerRef));
      this.cdRef.markForCheck();
   }

   onClear(): void {
      this.controls.forEach(c => c.clear());
      this.onSubmit();
   }

   onSubmit(): void {
      this.value = this._value;
      this.changes.emit(this.value);
      this.closePopover();
   }

   private closePopover(): void {
      if (this.overlayRef.hasAttached()) {
         this.overlayRef.detach();
      }
      this.cdRef.markForCheck();
   }

   private onValueChange(name: string, value: any): void {
      this._value = { ...this._value, [name]: value };
   }
}

import { AfterViewInit, Directive, ElementRef, HostBinding, Input } from '@angular/core';

import { AuthService } from '../../../core';

@Directive({ selector: '[amPermissions]' })
export class PermissionsDirective implements AfterViewInit {
   private _isDenied = false;
   private _isDisabled = false;

   constructor(
      private elementRef: ElementRef,
      private auth: AuthService   ) {
   }

   @Input('amPermissions') permissions: string | string[];
   @HostBinding('class.disabled') hasDisabledClass: boolean;
   @HostBinding('title') title = '';

   get disabledFallback() { return this._isDisabled; }
   @Input('disabledFallback')
   set disabledFallback(value: boolean) {
      this._isDisabled = value;
      this.elementRef.nativeElement.disabled = this._isDenied || this._isDisabled;
   }

   ngAfterViewInit() {
      let permissions = this.permissions;
      if (typeof permissions === 'string') {
         permissions = [permissions];
      }

      if (!this.auth.checkPermissions(...permissions)) {
         this._isDenied = true;
         this.elementRef.nativeElement.disabled = true;
         this.title = 'Additional permissions required';
         this.hasDisabledClass = true;

         // TODO: Add additional custom handling per tag type
         if (this.elementRef.nativeElement.tagName === 'TEXTAREA') {
            this.elementRef.nativeElement.readOnly = true;
         }
      } else {
         this.elementRef.nativeElement.disabled = this._isDisabled;
      }
   }
}

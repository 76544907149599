import { Component, EventEmitter, forwardRef, OnInit } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ConfigurationService } from '../../configuration/configuration.service';
import { LegalTeamModel } from '../../configuration/models/configuration.models';

@Component({
   selector: 'am-legal-team-filter',
   templateUrl: './legal-team-filter.component.html',
   styleUrls: ['./legal-team-filter.component.scss'],
   providers: [{
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => LegalTeamFilterComponent),
      multi: true
   }],
})
export class LegalTeamFilterComponent implements OnInit, ControlValueAccessor {
   legalTeams: LegalTeamModel[];
   legalTeamIds: FormControl;
   valueChanged: EventEmitter<string[]> = new EventEmitter<string[]>();

   constructor(
      private configurationService: ConfigurationService) {
   }

   ngOnInit() {
      this.legalTeamIds = new FormControl();
      this.configurationService.getLegalTeams(false).subscribe(result => this.legalTeams = result);
      this.legalTeamIds.setValue([]);
   }

   registerOnChange(fn) {
      this.legalTeamIds.valueChanges.subscribe(result => fn(result));
   }

   registerOnTouched() { }

   writeValue(value: any) {
      this.legalTeamIds.setValue(value || []);
   }
}

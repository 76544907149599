import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { Grievance, GrievanceListFilters } from '@app/grievances/list/types';
import { Pagination, TypedSort } from '@app/types/core';
import { PopoverFilterOption } from '@app/shared/filters';


@Component({
   selector: 'app-grievances-list',
   templateUrl: './grievances-list.component.html',
   styleUrls: ['./grievances-list.component.scss'],
   animations: [
      trigger('detailExpand', [
         state('collapsed', style({ height: '0px', minHeight: '0' })),
         state('expanded', style({ height: '*', borderTop: '1px rgba(0, 0, 0, .12) solid' })),
         transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
      ]),
   ],
})
export class GrievancesListComponent {
   @Input() grievances: Grievance[];
   @Input() funderOptions: PopoverFilterOption[];
   @Input() statusOptions: PopoverFilterOption[];
   @Input() filters: GrievanceListFilters;
   @Input() loading = false;
   @Input() count: number;
   @Input() page: Pagination;
   @Input() sort: TypedSort<Grievance>;
   @Output() editGrievance = new EventEmitter<Grievance>();
   @Output() deleteGrievance = new EventEmitter<Grievance>();
   @Output() filtersChange = new EventEmitter<GrievanceListFilters>();
   @Output() sortChange = new EventEmitter<TypedSort<Grievance>>();
   @Output() pageChange = new EventEmitter<Pagination>();
   @Output() clearFilters = new EventEmitter();

   columnsToDisplay: string[] = ['expand', 'grievant', 'case', 'funder', 'status', 'fileDate', 'escalationDate', 'replyDueDate', 'actualReplyDate', 'more'];
   expandedRow: Grievance | null;

   onFilter(value: Partial<GrievanceListFilters>): void {
      this.filtersChange.emit({ ...this.filters, ...value });
   }

   onPage({ pageIndex, pageSize }: PageEvent): void {
      this.pageChange.emit({ page: pageIndex + 1, pageSize });
   }

   onSort(sort: Sort): void {
      this.sortChange.emit(sort as TypedSort<Grievance>);
   }
}
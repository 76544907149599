import { Injectable } from '@angular/core';
import { HttpService } from '@app/core/index';
import { Observable } from 'rxjs';
import { Eligibility } from '@app/funding/eligibility/types';


@Injectable({
   providedIn: 'root'
})
export class EligibilityListService {
   static injectName = 'eligibilityService';
   private baseUrl = 'api/funding/eligibility';

   constructor(private http: HttpService) { }

   getEligibilityList(involvementId: string): Observable<Eligibility[]> {
      const url = `${this.baseUrl}/${involvementId}`;
      return this.http.get<Eligibility[]>(url);
   }

   deleteEligibility(id: string) {
      const url = `${this.baseUrl}/${id}`;
      return this.http.delete(url).subscribe(() => console.log("eligibility deleted"));
   }
}
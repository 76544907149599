import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { HttpService, PagedList } from '../core/index';
import { getFileNameFromResponseContentDisposition, saveFile } from '../shared/helpers';
import { RepresentationModel, RepresentationOption } from './models/client-cases-representation.models';
import { CasesWithNoTimeslipsCountsModel, ClientCaseBasicInfoModel, ClientCaseListItemModel, CovidVaccineModel, GetClientCaseListParams } from './models/client-cases.models';

@Injectable()
export class ClientCasesRepositoryService {
   clientCaseDowngrader
   static injectName = 'clientCasesService';
   private baseUrl = 'api/clientcases';

   constructor(private http: HttpService) { }

   getClientCaseList(data: GetClientCaseListParams): Observable<PagedList<ClientCaseListItemModel>> {
      const url = `${this.baseUrl}/list`;
      return this.http.post<PagedList<ClientCaseListItemModel>>(url, data);
   }

   getClientCaseListDownload(data: GetClientCaseListParams) {
      const url = `${this.baseUrl}/list/csv`;
      return this.http.post<HttpResponse<Blob>>(url, data, { responseType: 'blob', observe: 'response' }).pipe(
         tap(res => {
            const fileName = getFileNameFromResponseContentDisposition(res);
            saveFile(res.body, fileName);
         })
      );
   }

   getBasicInfo(clientCaseId: string) {
      const url = `${this.baseUrl}/${clientCaseId}/basicinfo`;
      return this.http.get<ClientCaseBasicInfoModel>(url);
   }

   getActiveCaseCount(userIds: string[], roles: string[]) {
      const url = `${this.baseUrl}/activeCaseCount`;
      return this.http.get<number>(url, { params: { userIds, roles } });
   }

   getCasesWithNoTimeslips(userIds: string[]) {
      const url = `${this.baseUrl}/casesWithNoTimeslipsCounts`;
      return this.http.get<CasesWithNoTimeslipsCountsModel>(url, { params: { userIds: userIds } });
   }

   deleteClientCase(id: string) {
      return this.http.delete(`${this.baseUrl}/${id}`);
   }

   getRepresentationOptions(): Observable<RepresentationOption[]> {
      const url = `${this.baseUrl}/activities/specialeducation/options`;
      return this.http.get<RepresentationOption[]>(url);
   }

   getRepresentation(ActivityId: string) {
      const url = `${this.baseUrl}/activities/representation/${ActivityId}`;
      return this.http.get<RepresentationModel>(url);
   }

   createRepresentation(data: RepresentationModel): Observable<RepresentationModel> {
      const url = `${this.baseUrl}/activities/${data.caseId}/representation`;
      return this.http.post<RepresentationModel>(url, data);
   }

   updateRepresentation(data: RepresentationModel) {
      const url = `${this.baseUrl}/activities/representation/${data.activityId}`;
      return this.http.put<RepresentationModel>(url, data);
   }

   getCovidVaccine(ActivityId: string) {
      const url = `${this.baseUrl}/activities/covidVaccine/${ActivityId}`;
      return this.http.get<CovidVaccineModel>(url);
   }

   createCovidVaccine(data: CovidVaccineModel): Observable<CovidVaccineModel> {
      const url = `${this.baseUrl}/activities/${data.caseId}/covidVaccine`;
      return this.http.post<CovidVaccineModel>(url, data);
   }

   updateCovidVaccine(data: CovidVaccineModel) {
      const url = `${this.baseUrl}/activities/covidVaccine/${data.activityId}`;
      return this.http.put<CovidVaccineModel>(url, data);
   }
}
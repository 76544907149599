import { Component } from '@angular/core';
import { Input } from '@angular/core';
import { StateService } from '@uirouter/core';


@Component({
   selector: 'am-no-timeslips',
   templateUrl: './no-timeslips.component.html',
   styleUrls: ['./no-timeslips.component.scss']
})
export class NoTimeslipsComponent {
   @Input() counts: any[];
   @Input() userIds: string[];

    constructor(
        private states: StateService
    ) { }

   showListing(c) {
       const bucket = c.substring(0, 2);
       this.states.go('app.clientCaseNoTimeslips', { bucket: bucket, userIds: this.userIds });
   }
}

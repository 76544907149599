import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpService, PagedList } from '@app/core/index';
import { GetProjectListPayload, Project, ProjectCreate } from '@app/projects/list/types';
import { CaseListFunderOptions, getFileNameFromResponseContentDisposition, saveFile } from '@app/shared';
import { CaseListFilterOptions } from '@app/shared/types/case-list-filter-options';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';


@Injectable({
   providedIn: 'root'
})
export class ProjectListService {
   static injectName = 'projectService';
   private baseUrl = 'api/projects';

   constructor(private http: HttpService) { }

   getProjectsList(criteria: GetProjectListPayload): Observable<PagedList<Project>> {
      const url = `${this.baseUrl}/list/filtered`;
      return this.http.post<PagedList<Project>>(url, criteria);
   }

   getProjectsExport(criteria: GetProjectListPayload) {
      const url = `${this.baseUrl}/list/export`;
      return this.http.post<HttpResponse<Blob>>(url, criteria, { responseType: 'blob', observe: 'response' }).pipe(
         tap(res => {
            const fileName = getFileNameFromResponseContentDisposition(res);
            saveFile(res.body, fileName);
         })
      );
   }

   createProject(data: ProjectCreate): Observable<Project> {
      const url = `${this.baseUrl}`;
      return this.http.post<Project>(url, data);
   }

   deleteProject(id: string) {
      const url = `${this.baseUrl}/${id}`;
      return this.http.delete(url);
   }

   getFilterOptions(): Observable<CaseListFunderOptions> {
      const url = `api/configuration/list/funders`;
      return this.http.post<CaseListFilterOptions>(url, { CaseType: 'project' });
   }
}
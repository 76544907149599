import { Transition } from '@uirouter/core';
import { SourceModel } from '../core/models/source.model';
import { CreateEmailComponent } from '../mail/create-email/create-email.component';
import { RequestListView } from './list/view/request-list.view';
import { RequestsRepositoryService } from './requests.repository';


export const states = [
   {
      name: 'app.requests.emails.create',
      component: CreateEmailComponent,
      url: '/new/email',
      resolve: {
         source: resolveSource
      },
      data: {
         title: 'New Message',
         permissions: ['mail.send']
      }
   },
   {
      name: 'app.requestList',
      component: RequestListView,
      url: '/request-list',
      params: {
         rolesFilter: null,
         usersFilter: null,
         usePreferences: null,
         initializeSelected: null
      },
      resolve: {
         rolesFilter: rolesFilterResolver,
         usersFilter: usersFilterResolver,
         usePreferences: usePreferencesResolver,
         initializeSelected: initializeSelectedResolver
      },
      data: {
         title: 'List',
         amPermissions: ['requests.view']
      }
   },
   {
      name: 'app.requestListDashboard',
      component: RequestListView,
      url: '/request-list-dashboard',
      params: {
         rolesFilter: null,
         usersFilter: null,
         usePreferences: null,
         initializeSelected: null
      },
      resolve: {
         rolesFilter: rolesFilterResolver,
         usersFilter: usersFilterResolver,
         usePreferences: usePreferencesResolver,
         initializeSelected: initializeSelectedResolver
      },
      data: {
         title: 'List',
         amPermissions: ['requests.view']
      }
   },
];

rolesFilterResolver.$inject = ['$transition$'];
export function rolesFilterResolver($transition$) {
   const { rolesFilter } = $transition$.params();
   return rolesFilter;
}

usersFilterResolver.$inject = ['$transition$'];
export function usersFilterResolver($transition$) {
   const { usersFilter } = $transition$.params();
   return usersFilter;
}

initializeSelectedResolver.$inject = ['$transition$'];
export function initializeSelectedResolver($transition$) {
   const { initializeSelected } = $transition$.params();
   return initializeSelected;
}

usePreferencesResolver.$inject = ['$transition$'];
export function usePreferencesResolver($transition$) {
   const { usePreferences } = $transition$.params();
   return usePreferences;
}

resolveSource.$inject = ['$transition$'];
export function resolveSource(transition: Transition) {
   const { requestId } = transition.params();
   const repository: RequestsRepositoryService = transition.injector().get(RequestsRepositoryService);
   return repository.getBasicInfo(requestId).toPromise().then(
      r => {
         return {
            source: 'Request',
            sourceId: r.id,
            involvementId: r.involvementId,
            sourceNumber: r.idNumber,
            sourceName: r.requesterFullName
         } as SourceModel;
      }
   );
}



import { downgradeInjectable } from '@angular/upgrade/static';

import {
   AuthService,
   ConfigurationProviderService,
   HttpProgressService,
   PreferenceService,
} from './services';

export function coreDowngrader(app: any) {
   app.factory('auth', downgradeInjectable(AuthService))
      .factory('configurationProvider', downgradeInjectable(ConfigurationProviderService))
      .factory('preferences', downgradeInjectable(PreferenceService))
      .factory('httpProgress', downgradeInjectable(HttpProgressService));
}

import { AppConfig } from '@app/types';


export interface State {
   config: AppConfig;
   isLoading: boolean;
   error: string;
}

export const initialState: State = {
   config: null,
   isLoading: false,
   error: null
};
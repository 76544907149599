import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
   selector: 'am-confirmation-dialog',
   templateUrl: './confirmation-dialog.component.html',
   styleUrls: ['./confirmation-dialog.component.scss']
})
export class ConfirmationDialogComponent {
   title: string;
   messageText: string;
   okText: string;
   cancelText: string;

   constructor(
      private dialogRef: MatDialogRef<ConfirmationDialogComponent>,
      @Inject(MAT_DIALOG_DATA) private data: any) {
      this.title = data.title;
      this.messageText = data.messageText;
      this.okText = data.okText || 'OK';
      this.cancelText = data.cancelText || 'CANCEL';
   }

   cancel() {
      this.dialogRef.close(false);
   }

   ok() {
      this.dialogRef.close(true);
   }
}

import * as moment from 'moment';

export function toLocalDateString(value: any) {
   if (!value) return value;

   if (moment.isMoment(value)) {
      return value.format('YYYY-MM-DD');
   }

   return moment(value).format('YYYY-MM-DD');
}

import { Component, Input } from '@angular/core';

@Component({
   selector: 'app-data-field',
   styles: [`
      :host { display: block; }
      :host div { margin: 4px 0; }
      label { color: rgba(0,0,0,0.54); }
   `],
   template: `
<div>
  <label>{{label}}</label>
  <div><ng-content></ng-content></div>
</div>`
})
export class DataFieldComponent {
   @Input() label: string;
}

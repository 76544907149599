import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';

import { TimeRepositoryService } from './time.repository';
import { TimeslipListComponent } from './timeslip-list/timeslip-list.component';
import { FiltersModule } from '../filters/filters.module';
import { UIRouterUpgradeModule } from '@uirouter/angular-hybrid';
import { states } from './time-states';
import { TimeslipButtonUpgradedDirective } from './timeslip-button/timeslip-create-button-upgrader.directive';
import { EditTimeslipButtonUpgradedDirective } from './timeslip-button/timeslip-edit-button-upgrader.directive';
import { TimeslipCalendarComponent } from './timeslip-calendar/timeslip-calendar.component';
import { TimeslipCalendarUpgradedDirective } from './timeslip-calendar/timeslip-calendar-upgrader.directive';

@NgModule({
   imports: [
      UIRouterUpgradeModule.forChild({ states }),
      SharedModule,
      FiltersModule
   ],
   declarations: [
      TimeslipListComponent,
      TimeslipCalendarComponent,
      TimeslipButtonUpgradedDirective,
      EditTimeslipButtonUpgradedDirective,
      TimeslipCalendarUpgradedDirective
   ],
   exports: [
   ],
   providers: [
      TimeRepositoryService
   ]
})
export class TimeModule {
}

import { downgradeComponent, downgradeInjectable } from '@angular/upgrade/static';
import { ClientCaseRepresentationComponent } from './representation/representation.component';
import { ClientCasesRepositoryService } from './client-cases.repository';
import { ClientCaseCovidVaccineComponent } from './covid-vaccine/covid-vaccine.component';

export function clientCaseDowngrader(app: any) {
   app.factory(ClientCasesRepositoryService.injectName, downgradeInjectable(ClientCasesRepositoryService));
   app.directive('amClientCaseRepresentation', downgradeComponent({
      component: ClientCaseRepresentationComponent,
      inputs: ['involvementId', 'sourceId']
   }));
   app.directive('amClientCaseCovidVaccine', downgradeComponent({
      component: ClientCaseCovidVaccineComponent,
      inputs: ['involvementId', 'sourceId']
   }));
}
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NotificationService } from '@app/core';
import { SystemicCaseListService } from '@app/systemic-cases/list/services/systemic-cases-list.service';
import { SystemicCaseCreate } from '@app/systemic-cases/list/types/systemic-case-create';
import { StateService } from '@uirouter/core';
import * as moment from 'moment';

@Component({
  templateUrl: './systemic-cases-create.dialog.html',
  styleUrls: ['./systemic-cases-create.dialog.scss']
})
export class SystemicCasesCreateDialog implements OnInit {
  form: FormGroup;
  today: moment.Moment;
  submitting: boolean;

  constructor(
    private readonly dialogRef: MatDialogRef<SystemicCaseCreate>,
    private readonly formBuilder: FormBuilder,
    private readonly service: SystemicCaseListService,
    private readonly stateService: StateService,
    private notifier: NotificationService,
    @Inject(MAT_DIALOG_DATA)
    public readonly data: SystemicCaseCreate) { }

  static open(dialog: MatDialog, data: SystemicCaseCreate) {
    return dialog.open(SystemicCasesCreateDialog, {
      width: '900px',
      panelClass: 'no-padding',
      disableClose: true,
      autoFocus: true,
      data: data || {}
    });
  }

  ngOnInit(): void {
    this.today = moment().startOf('day');
    this.setupForm();
  }

  private setupForm() {
    const { openDate = null } = this.data ?? {};

    this.form = this.formBuilder.group({
      openDate: [openDate ? moment(openDate) : this.today, Validators.required]
    });
  }

  submit() {
    this.submitting = true;
    const formData = this.form.getRawValue();

    const data: SystemicCaseCreate = {
      openDate: formData.openDate.format('YYYY-MM-DD')
    };

    this.service.createSystemicCase(data)
      .subscribe((result) => {
        this.submitting = false;
        this.stateService.go('app.systemicCases.overview', { systemicCaseId: result });
        this.notifier.notify('Systemic Case Created', 'Done');
        this.dialogRef.close()
      });
  }
}

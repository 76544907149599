import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Sort } from '@angular/material/sort';
import { ClientCasesListComponent } from '@app/client-cases/list/components';
import { ClientCasesCreateDialog } from '@app/client-cases/list/dialogs';
import { ClientCaseStateStore } from '@app/client-cases/list/state/client-case-list.store';
import { ClientCase } from '@app/client-cases/list/types/client-case';
import { ClientCasesListFilters } from '@app/client-cases/list/types/client-cases-filters';
import { ConfirmationService } from '@app/shared';
import { Pagination } from '@app/types/core';
import { UserModel } from '@app/users';
import { UIRouter } from '@uirouter/core';
import { filter } from 'rxjs/operators';


@Component({
   selector: 'app-client-cases-list.view',
   templateUrl: './client-cases-list.view.html',
   styleUrls: ['./client-cases-list.view.scss'],
   providers: [ClientCaseStateStore]
})
export class ClientCasesListView implements OnInit {
   @Input() rolesFilter: string[] = [];
   @Input() usersFilter: UserModel[] = [];
   @Input() initializeSelected: boolean = false;
   @ViewChild(ClientCasesListComponent, { static: true }) list: ClientCasesListComponent;
   vm$ = this.store.vm$;

   constructor(
      private readonly dialog: MatDialog,
      private router: UIRouter,
      private readonly store: ClientCaseStateStore,
      private readonly confirmation: ConfirmationService
   ) { }

   ngOnInit(): void {
      const {
         current: { name: viewId }
      } = this.router.globals;

      this.store.init(viewId);

      if (this.initializeSelected) {
         this.onActiveOnlyChange(true)
         this.onSelectedChange(this.usersFilter, this.rolesFilter);
         this.onClearFilters({ page: 1, pageSize: 25 });
      };
   }

   showClientCaseCreateDialog() {
      ClientCasesCreateDialog.open(this.dialog, {
         openDate: null
      }).afterClosed().pipe(
         filter(value => !!value)
      ).subscribe(clientCase => this.store.addClientCase(clientCase));
   }

   showDeleteDialog(clientCase: ClientCase) {
      const { number } = clientCase;
      this.confirmation.confirm(`Delete Client Case (${number})?`, 'DELETE', 'CANCEL').pipe(
         filter(ok => ok)
      ).subscribe(() => this.store.deleteClientCase(clientCase.involvementId));
   }

   onFiltersChange(filters: ClientCasesListFilters, page: Pagination): void {
      const pageSize = page?.pageSize ?? 25;
      this.store.patchViewState({ filters, pagination: { page: 1, pageSize } });
   }

   onSelectedChange(users: UserModel[], roles: string[]) {
      this.store.patchViewState({ selected: { users, roles } });
   }

   onSearchChange(search: string) {
      this.store.patchViewState({ search });
   }

   onActiveOnlyChange(activeOnly: boolean) {
      this.store.patchViewState({ activeOnly });
   }

   onPageChange(pagination: Pagination) {
      this.store.patchViewState({ pagination });
   }

   onSortChange(sort: Sort) {
      this.store.patchViewState({ sort });
   }

   onToggleChange(value: boolean): void {
      this.store.patchState({ toggle: value });
   }

   onClearFilters(page: Pagination) {
      const filters: ClientCasesListFilters =
      {
         number: null,
         client: null,
         caseName: null,
         primaryStaff: null,
         legalTeam: null,
         funder: null,
         status: null,
         opened: null,
         closed: null
      }
      this.onFiltersChange(filters, page)
   }
}
import { Component, Inject, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import * as moment from 'moment';

import { DateFilterOverlayRef } from './date-filter-overlay-ref';
import { DateFilterChangeEvent, DATE_FILTER_DATA, DATE_FILTER_PRESETS } from './date-filter.models';

@Component({
   templateUrl: './date-filter-overlay.component.html',
   styleUrls: ['./date-filter-overlay.component.scss']
})
export class DateFilterOverlayComponent implements OnInit {
   presets = DATE_FILTER_PRESETS;
   selection = 0;
   specificDate: moment.Moment;
   specificDateControl = new FormControl(null, { updateOn: 'blur' });
   today = moment().startOf('day');

   constructor(
      private overlayRef: DateFilterOverlayRef,
      @Inject(DATE_FILTER_DATA) public data: number | string
   ) { }


   ngOnInit() {
      if (this.data) {
         if (typeof this.data === 'number') {
            this.selection = this.data;
            this.specificDateControl.disable();
         } else if (typeof this.data === 'string') {
            this.selection = -1;
            this.specificDateControl.setValue(moment(this.data, 'YYYY-MM-DD'));
         }
      }
   }

   onSelectionChange() {
      this.specificDate = undefined;
      const fn = this.selection < 0 ? 'enable' : 'disable';
      this.specificDateControl[fn]();
   }

   cancel() {
      this.overlayRef.close();
   }

   submit() {
      if (this.selection === -1 && this.specificDateControl.invalid) {
         this.specificDateControl.markAsTouched();
         return;
      }

      const preset = this.presets.find(p => p.delta === this.selection);
      const value = this.selection >= 0
         ? this.today.add(preset.delta, 'days')
         : this.specificDateControl.value;
      this.overlayRef.close({ preset, value } as DateFilterChangeEvent);
   }
}

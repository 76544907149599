import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { HttpService, PagedList } from '../core';
import { ENV } from '../env.config';
import * as models from './models';

@Injectable()
export class UserService {
   private usersUrl = `${ENV.API_URL}identity-management/users`;

   constructor(private http: HttpService) {
   }

   createUser(user: models.UserModel) {
      const requestUri = `${this.usersUrl}`;
      return this.http.post<models.UserModel>(requestUri, user);
   }

   getSupervisedUsers(q: string): Observable<models.UserModel[]> {
      const url = `${this.usersUrl}/current/supervisedusers`;
      const params = { q };
      return this.http.get<models.UserModel[]>(url, {params});
   }

   getTimeslipUsers(q: string): Observable<models.UserModel[]> {
      const url = `${this.usersUrl}/current/timeslipUsers`;
      const params = { q };
      return this.http.get<models.UserModel[]>(url, {params});
   }

   getCurrentUser(): Observable<models.UserModel> {
      const url = `${this.usersUrl}/current`;
      return this.http.get<models.UserModel>(url);
   }

   public getUsers(q: string) {
      return this.http.get<models.UserModel[]>(`${this.usersUrl}`, { params: { q } }) as any;
   }

   public getUsersById(userIds: string[]) {
      return this.http.get<models.UserModel[]>(`${this.usersUrl}`, { params: { userIds } }) as any;
   }

   getUsers_v1(criteria: models.UserListCriteria): Observable<PagedList<models.UserListItemModel>> {
      const requestUri = `${this.usersUrl}`;
      let params = new HttpParams();
      if (criteria.q) {
         params = params.set('q', criteria.q);
      }
      if (criteria.userName) {
         params = params.set('userName', criteria.userName);
      }
      if (criteria.page) {
         params = params.set('page', criteria.page.toString());
      }
      if (criteria.pageSize) {
         params = params.set('pageSize', criteria.pageSize.toString());
      }
      if (criteria.order) {
         params = params.set('order', criteria.order);
      }
      if (criteria.sortReversed) {
         params = params.set('sortReversed', `${criteria.sortReversed}`);
      }
      return this.http.get<PagedList<models.UserListItemModel>>(requestUri, { params });
   }

   getUser(userId: string): Observable<models.UserModel> {
      const requestUri = `${this.usersUrl}/${userId}`;
      return this.http.get<models.UserModel>(requestUri);
   }

   updateUser(userId: string, user: models.UserModel) {
      const requestUri = `${this.usersUrl}/${userId}`;
      return this.http.put<models.UserModel>(requestUri, user);
   }
}

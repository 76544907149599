import { ChangeDetectionStrategy, ChangeDetectorRef, Component, forwardRef, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { map } from 'rxjs/operators';
import { FilterControl } from '../filter-control';
import { PopoverFilterOption, PopoverFilterSelectionValue, PopoverFilterType } from '../popover-filter.models';

@Component({
   selector: 'au-popover-filter-selection[filterControlName]',
   changeDetection: ChangeDetectionStrategy.OnPush,
   providers: [{ provide: FilterControl, useExisting: forwardRef(() => MultiSelectFilterComponent) }],
   templateUrl: './multi-select-filter.component.html',
   styleUrls: ['./multi-select-filter.component.scss']
})
export class MultiSelectFilterComponent extends FilterControl implements OnInit {
   @Input() loading = false;
   @Input()
   get options(): PopoverFilterOption[] | string[] {
      return this._options;
   }
   set options(values: PopoverFilterOption[] | string[]) {
      this._options = values;
      this.selectOptions = values.map((value: string | PopoverFilterOption) => typeof value === 'string' ? { value, text: value } : value);
      this.cdRef.markForCheck();
   }

   selectOptions: PopoverFilterOption[] = [];

   private _options: PopoverFilterOption[] | string[] = [];

   constructor(
      private cdRef: ChangeDetectorRef) {
      super();
   }

   ngOnInit(): void {
      this.form = new FormGroup({
         values: new FormControl({ value: [], disabled: this.disabled })
      });
   }

   clear(): void {
      this.form.setValue({ values: [] });
   }

   isAllSelected(): boolean {
      const { values } = this.form.value;
      return values.length > 0 && values.length === this.selectOptions.length;
   }

   registerOnChanges(fn: (value: any) => any) {
      this.form.valueChanges.pipe(
         map(({ values }): PopoverFilterSelectionValue | null => values.length ? ({
            type: PopoverFilterType.selection,
            values,
            displayValues: this.selectOptions.filter(o => values.indexOf(o.value) >= 0 && o.text).map(o => o.text ?? '')
         }) : null)
      ).subscribe(fn);
   }

   toggleAll(): void {
      const values = !this.isAllSelected() ? this.selectOptions.map(o => o.value) : [];
      this.form.setValue({ values });
   }

   writeValue(value: any): void {
      const { values = [] } = value ?? {};
      this.form.setValue({ values }, { emitEvent: false });
   }
}

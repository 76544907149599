import { Program, Question } from '@app/funding/eligibility/form/types';

export function getProgramParentName(fundingProgramName: string, programs: Program[]) {
   const program: Program = programs.find(p => p.name === fundingProgramName);
   return program?.parentName;
}

export function getQuestionsByProgramName(fundingProgramName: string, questions: Question[]) {
   if (!questions) return questions;
   const funderQuestions = questions.filter(q => q.fundingProgramName == fundingProgramName);
   var lookup = getQuestionSetQuestions(funderQuestions)
   return funderQuestions.filter(e => !lookup[e.questionSet]);
}

export function getQuestionSetsByProgramName(fundingProgramName: string, questions: Question[]) {
   if (!questions) return questions;

   const funderQuestions = questions.filter(q => q.fundingProgramName === fundingProgramName);
   const lookup = getQuestionSetQuestions(funderQuestions);

   let array = funderQuestions.filter(e => lookup[e.questionSet]);
   let questionSets: Question[][] = [];

   distinct(array).forEach((a) => {
      let array: Question[] = [];
      funderQuestions.forEach((question) => {
         if (question.questionSet === a) {
            array.push(question);
         }
      });
      questionSets.push(array);
   });
   return questionSets;
}

function getQuestionSetQuestions(questions: Question[]) {
   return questions.reduce((a, question) => {
      a[question.questionSet] = ++a[question.questionSet] || 0;
      return a;
   }, {});
}

function distinct(questions: Question[]) {
   return questions.map(item => item.questionSet).filter((value, index, self) => self.indexOf(value) === index)
}
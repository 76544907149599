import { Injectable } from '@angular/core';
import { AppInsights } from 'applicationinsights-js';

@Injectable()
export class MonitoringService {
   enabled = false;

   initialize(endpointUrl: string, instrumentationKey: string) {
      if (instrumentationKey) {
         AppInsights.downloadAndSetup({ endpointUrl, instrumentationKey });
         this.enabled = true;
      }
   }

   logPageView(name?: string, url?: string, properties?: any, measurements?: any, duration?: number) {
      if (this.enabled) {
         AppInsights.trackPageView(name, url, properties, measurements, duration);
      }
   }

   logEvent(name: string, properties?: any, measurements?: any) {
      if (this.enabled) {
         AppInsights.trackEvent(name, properties, measurements);
      }
   }

   logTrace(message: string, properties?: { [name: string]: string }, severityLevel?: number) {
      if (this.enabled) {
         AppInsights.trackTrace(message, properties, severityLevel);
      }
   }

   logException(exception: Error) {
      if (this.enabled) {
         AppInsights.trackException(exception);
      }
   }

   setAuthenticatedUserContext(userName: string) {
      if (this.enabled) {
         AppInsights.setAuthenticatedUserContext(userName);
      }
   }

   clearAuthenticatedUserContext() {
      if (this.enabled) {
         AppInsights.clearAuthenticatedUserContext();
      }
   }
}

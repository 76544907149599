import { Injectable } from '@angular/core';
import { HttpService } from '../core/index';
import { CreateMessageModel } from './models/mail.models';
import { SourceModel } from '../core/models/source.model';

@Injectable()
export class MailService {
   static injectName = 'mailService';
   private baseUrl = 'api/mail';

   constructor(private http: HttpService) { }

    createMessage(data: CreateMessageModel) {
        const url = `${this.baseUrl}/messages`;
        return this.http.post<{ id: string }>(url, data);
    }

   getEmailAddresses(q: string, source: SourceModel) {
      const url = `${this.baseUrl}/addressSearch`;
      return this.http.get<string[]>(url, { params: { q, source: source.source, sourceId: source.sourceId, involvementId: source.involvementId || '' } });
   }

}

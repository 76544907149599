import { Injectable } from '@angular/core';
import { CalendarsRepositoryService } from '../../calendars/calendars.repository';

@Injectable()
export class HomepageCalendarService {
   constructor(
      private calendarsRepository: CalendarsRepositoryService
   ) { }

   getCalendarData(userIds: string[]) {
      return this.calendarsRepository.getNext10Events(userIds);
   }
}

import { Sort } from '@angular/material/sort';
import { EntitySearch } from '../types/entitySearchParams';
import { Page } from '../types/page';

export function createSearch(q: string, type: string, sort: Sort, page: Page): EntitySearch {
    let search: EntitySearch = {
        q: q,
        type: type,
        page: page.pageIndex + 1,
        pageSize: page.pageSize,
        order: sort.active,
        sortDirection: sort.direction
    }
    return search;
}
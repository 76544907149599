import { UIRouter } from '@uirouter/core';

import { authGuard } from './core/guards/auth.guard';
import { featureGuard } from './core/guards/feature.guard';
import { initialRedirectHandler } from './core/routing/initial-redirect.handler';
import { interruptHandler } from './core/routing/interrupt.handler';
import { monitoringHandler } from './core/routing/monitoring.handler';
import { windowTitleHandler } from './core/routing/window-title.handler';

export function routerConfigFn(router: UIRouter) {
   const transitionService = router.transitionService;
   initialRedirectHandler(transitionService);
   featureGuard(transitionService);
   authGuard(transitionService);
   interruptHandler(transitionService);
   windowTitleHandler(transitionService);
   monitoringHandler(transitionService);

   // Uncomment to enable route transition tracing
   // router.trace.enable(Category.TRANSITION);
   // router.plugin(Visualizer);
}

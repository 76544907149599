import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { PaginationCriteria } from '@app/core/models';
import { TypedSort } from '@app/types/core';
import { Entity } from '../../types/Entity';
import { Page } from '../../types/page';


@Component({
  selector: 'app-entity-name-search-results',
  templateUrl: './entity-name-search-results.component.html',
  styleUrls: ['./entity-name-search-results.component.scss']
})
export class EntityNameSearchResultsComponent {
  @Input() loading = false;
  @Input() count: number;
  @Input() sort: Sort;
  @Input() page: Page;
  @Output() sortChange = new EventEmitter<TypedSort<Entity>>();
  @Output() pageChange = new EventEmitter();

  @Input()
  get type(): string {
    return this._type;
  }
  set type(value: string) {
    this._type = value;
    this.setColumns();
  }

  _type: string;

  readonly dataSource = new MatTableDataSource([]);

  ngOnInit() {
    this.setColumns()
  }

  @Input()
  get entities(): Entity[] {
    return this.dataSource.data;
  }
  set entities(value: Entity[]) {
    this.dataSource.data = value;
  }

  columnsToDisplay: string[];
  form: FormGroup;
  submitting: boolean;

  setColumns() {
    this.columnsToDisplay = (this.type == 'Person')
      ? ['fullName', 'entityType', 'gender', 'dateOfBirth']
      : ['fullName', 'entityType', 'address'];
  }

  onSortChange(sort: Sort): void {
    this.sortChange.emit(sort as TypedSort<Entity>);
  }

  onPageChange(page: PaginationCriteria): void {
    this.pageChange.emit(page);
  }
}
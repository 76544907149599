import { Injectable } from '@angular/core';
import { HttpService, PagedList } from '../core/index';
import { GetRequestListParams, RequestBasicInfoModel, RequestListItemModel } from './models/requests.models';
import { Observable } from 'rxjs';
import { HttpResponse } from '@angular/common/http';
import { tap } from 'rxjs/operators';
import { getFileNameFromResponseContentDisposition, saveFile } from '../shared';

@Injectable()
export class RequestsRepositoryService {
   static injectName = 'requestsRepository';
   private baseUrl = 'api/requests';

   constructor(private http: HttpService) { }

   getActiveRequestCount(userIds: string[]) {
      const url = `${this.baseUrl}/activeRequestCount`;
      return this.http.get<number>(url, { params: { userIds: userIds } });
   }

   getBasicInfo(requestId: string) {
      const url = `${this.baseUrl}/${requestId}/basicinfo`;
      return this.http.get<RequestBasicInfoModel>(url);
   }

   getRequestList(data: GetRequestListParams): Observable<PagedList<RequestListItemModel>> {
      const url = `${this.baseUrl}/list`;
      return this.http.post<PagedList<RequestListItemModel>>(url, data);
   }

   getRequestListDownload(data: GetRequestListParams) {
      const url = `${this.baseUrl}/list/csv`;
      return this.http.post<HttpResponse<Blob>>(url, data, { responseType: 'blob', observe: 'response' }).pipe(
         tap(res => {
            const fileName = getFileNameFromResponseContentDisposition(res);
            saveFile(res.body, fileName);
         })
      );
   }

   deleteRequest(id: string) {
      return this.http.delete(`${this.baseUrl}/${id}`);
   }

}

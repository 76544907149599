import { Injectable } from '@angular/core';
import { HttpService } from '../core/index';
import { Next10CalendarEventsModel } from './models/calendars.models';

@Injectable()
export class CalendarsRepositoryService {
   static injectName = 'calendarsRepository';
   private baseUrl = 'api/calendars';

   constructor(private http: HttpService) { }

   getNext10Events(userIds: string[]) {
      const url = `${this.baseUrl}/events/next10Events`;
      return this.http.get<Next10CalendarEventsModel>(url, { params: { userIds: userIds } });
   }
}

import { AppConfig } from '@app/types';
import { createAction, props } from '@ngrx/store';

export const setConfig = createAction(
   '[Config] Set Config',
   props<{ config: AppConfig }>()
);

export const loadRequest = createAction(
   '[Config] Load Request'
);

export const loadFailure = createAction(
   '[Config] Load Failure',
   props<{ error: string }>()
);

export const loadSuccess = createAction(
   '[Config] Load Success'
);
import { ChangeDetectionStrategy, Component, Input, OnInit, Self } from '@angular/core';
import { AbstractControl, ControlValueAccessor, NgControl } from '@angular/forms';
import { Question } from '@app/funding/eligibility/form/types';

@Component({
   selector: 'am-eligibility-questions',
   templateUrl: './eligibility-questions.component.html',
   styleUrls: ['./eligibility-questions.component.scss'],
   changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EligibilityQuestionsComponent implements OnInit, ControlValueAccessor {
   @Input() fundingProgram: string;
   @Input() eligibilityQuestions: Question[] = [];
   @Input() eligibilityQuestionSets: Question[][] = [];
   @Input() readOnly: boolean = false;
   control: AbstractControl;

   constructor(@Self() private ngControl: NgControl) {
      ngControl.valueAccessor = this;
   }

   ngOnInit(): void {
      this.control = this.ngControl.control;
   }

   onQuestionChange(questionId: string, value: boolean, questions?: Question[]) {
      questions?.forEach(question => {
         question.subQuestions?.forEach(subQuestion => {
            if (question.id != questionId && question.clearResponses && value || !value)
               this.control.value[subQuestion.id] = false;
         })
         if (question.id != questionId && question.clearResponses && value)
            this.control.value[question.id] = false;
      });
      this.control.value[questionId] = value;
   }

   onSubQuestionChange(questionId: string, value: boolean) {
      this.control.value[questionId] = value;
   }

   registerOnChange() { }

   registerOnTouched() { }

   writeValue() { }
}
import { Injectable } from "@angular/core";
import { Sort } from "@angular/material/sort";
import { ConfigurationService } from "@app/configuration/configuration.service";
import { LookupModel } from "@app/configuration/models/configuration.models";
import { PagedList } from "@app/core";
import { ProjectListService } from "@app/projects/list/services";
import { GetProjectListPayload, Project, ProjectListFilters } from "@app/projects/list/types";
import { RootStoreState, ViewStateComponentStore, ViewStateComponentStoreState } from "@app/root-store";
import { CaseListFunderOptions } from "@app/shared";
import { Pagination, Selected } from "@app/types/core";
import { PopoverFilterOption } from '@app/shared/filters';
import { tapResponse } from '@ngrx/component-store';
import { Store } from "@ngrx/store";
import { forkJoin, Observable } from "rxjs";
import { concatMap, switchMap, tap, withLatestFrom } from "rxjs/operators";


export interface ProjectListState extends ViewStateComponentStoreState {
   projects: Project[];
   payload: GetProjectListPayload;
   options: CaseListFunderOptions;
   userRoles: LookupModel[];
   userRolesResponses: Response[];
   count: number;
   loading: boolean,
   toggle: boolean
}

export interface ProjectListViewState {
   filters: ProjectListFilters;
   selected: Selected;
   search: string;
   activeOnly: boolean;
   pagination: Pagination;
   sort: Sort;
}

const initialState: ProjectListState = {
   projects: [],
   payload: {
      pagination: null,
      sort: null,
      filters: {},
      selected: {},
      search: null
   },
   options: {
      fundingPrograms: null
   },
   userRoles: [],
   userRolesResponses: [],
   count: 0,
   loading: false,
   toggle: false,
   viewId: null
}

const initialViewState: ProjectListViewState = {
   filters: {
      name: null,
      description: null,
      opened: null,
      closed: null,
      defaultFunder: null
   },
   selected: {
      users: [],
      roles: []
   },
   search: null,
   activeOnly: true,
   pagination: { page: 1, pageSize: 25 },
   sort: { active: 'opened', direction: 'desc' }
};

@Injectable()
export class ProjectStateStore extends ViewStateComponentStore<ProjectListState, ProjectListViewState> {

   constructor(
      rootStore: Store<RootStoreState.State>,
      private service: ProjectListService,
      private configurationService: ConfigurationService,) {
      super(rootStore, initialViewState);
   }

   init(viewId: string): void {
      this.initCore({
         ...initialState,
         viewId
      });
      this.patchState({ loading: true });
      forkJoin([
         this.service.getFilterOptions(),
         this.configurationService.getLookupType('project-user-role')]).subscribe(
            ([options, types]) => {
               this.patchState({ options, userRoles: types.lookups, loading: false }), error => console.log(error);
            })
   }

   readonly funderOptions$: Observable<PopoverFilterOption[]> = this.select(
      this.state$,
      ({ options }) => options.fundingPrograms?.map(value => ({ value, text: value }))
   );

   readonly vm$ = this.select(
      this.funderOptions$,
      this.state$,
      this.viewState$,
      (funderOptions, { projects, payload, userRoles, count, loading, toggle }, { filters, selected, activeOnly, pagination, sort }) => (
         { funderOptions, projects, payload, userRoles, count, loading, toggle, filters, selected, activeOnly, pagination, sort })
   )

   readonly updateProjects = this.updater((state, { list, count }: PagedList<Project>): ProjectListState => ({
      ...state,
      projects: list,
      count,
      loading: false
   }));

   readonly reload = this.effect((origin$: Observable<GetProjectListPayload>) => origin$.pipe(
      tap(payload => this.patchState({ payload, loading: true })),
      switchMap(payload => this.service.getProjectsList(payload).pipe(
         tapResponse(({ count, page, pageSize, list }) => this.updateProjects({ list, page, pageSize, count }), err => console.log(err))
      ))
   ))(this.viewState$);

   readonly addProject = this.updater((state, project: Project) => ({
      ...state,
      projects: state.projects.filter(p => p.id !== project.id).concat(project)
   }));

   readonly deleteProject = this.effect((origin$: Observable<string>) => origin$.pipe(
      tap(() => this.patchState({ loading: true })),
      concatMap(id => this.service.deleteProject(id)),
      withLatestFrom(this.viewState$),
      concatMap(([_, payload]) => this.service.getProjectsList(payload)),
      tapResponse(({ count, page, pageSize, list }) => this.updateProjects({ list, page, pageSize, count }), err => console.log(err))
   ));
}
import { DoBootstrap, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { UpgradeModule } from '@angular/upgrade/static';
import { UIRouterUpgradeModule } from '@uirouter/angular-hybrid';
import { CalendarsModule } from './calendars/calendars.module';
import { CLEModule } from './cle/cle.module';
import { ClientCasesModule } from './client-cases/client-cases.module';
import { ConfigurationModule } from './configuration/configuration.module';
import { CoreModule } from './core/core.module';
import { DocumentsModule } from './documents/documents.module';
import { FiltersModule } from './filters/filters.module';
import { FiscalModule } from './fiscal/fiscal.module';
import { FundingModule } from './funding/funding.module';
import { GrievancesModule } from './grievances/grievances.module';
import { HomePageModule } from './home-page/home-page.module';
import { IAndRsModule } from './i-and-rs/i-and-rs.module';
import { InvolvementsModule } from './involvements/involvements.module';
import { MailModule } from './mail/mail.module';
import { MonitoringModule } from './monitoring/monitoring.module';
import { PersonsModule } from './persons/persons.module';
import { ProjectsModule } from './projects/projects.module';
import { RequestsModule } from './requests/requests.module';
import { RootStoreModule } from './root-store/root-store.module';
// import { ReportsModule } from './reports/reports.module';
import { TenancyModule } from '@app/tenancy/tenancy.module';
import { ActivitiesModule } from './activities/activities.module';
import { routerConfigFn } from './router.config';
import { SearchModule } from './search/search.module';
import { SharedModule } from './shared/shared.module';
import { SystemicCasesModule } from './systemic-cases/systemic-cases.module';
import { TimeModule } from './time/time.module';
import { TrainingRequestsModule } from './training-requests/training-requests.module';
import { TrainingModule } from './training/training.module';
import { UsersModule } from './users/users.module';


@NgModule({
   imports: [
      // 3rd Party Modules
      BrowserModule,
      BrowserAnimationsModule,
      UpgradeModule,
      UIRouterUpgradeModule.forRoot({ config: routerConfigFn }),

      // Core Modules
      CoreModule,
      SharedModule.forRoot(),

      // Feature Modules
      // ReportsModule,
      // NotificationsModule,
      FundingModule,
      HomePageModule,
      UsersModule,
      SearchModule,
      RequestsModule,
      IAndRsModule,
      ClientCasesModule,
      SystemicCasesModule,
      TimeModule,
      CalendarsModule,
      InvolvementsModule,
      MailModule,
      DocumentsModule,
      ProjectsModule,
      PersonsModule,
      CLEModule,
      TrainingModule,
      FiltersModule,
      ConfigurationModule,
      FiscalModule,
      GrievancesModule,
      TrainingRequestsModule,
      MonitoringModule,
      ActivitiesModule,
      TenancyModule,
      RootStoreModule
   ]
})
export class AppModule implements DoBootstrap {
   ngDoBootstrap() { }
}

import { Dictionary } from '@app/types';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { State } from './state';

export const selectViewStateFeatureState = createFeatureSelector<State>('viewState');

export const selectViewState = createSelector(
   selectViewStateFeatureState,
   state => state.viewState
);

export const selectViewStateByKey = createSelector(
   selectViewState,
   (viewState: Dictionary<any>, props: { key: string }): any => viewState[props.key] || {}
);

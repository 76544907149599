import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { configReducer } from './reducer';

@NgModule({
   imports: [
      CommonModule,
      StoreModule.forFeature('config', configReducer)
   ]
})
export class ConfigStoreModule { }
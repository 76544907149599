import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FiltersModule } from '@app/filters/filters.module';
import { SharedModule } from '@app/shared';
import { PopoverFilterModule } from '@app/shared/filters';
import { RequestListComponent, RequestListToolbarComponent } from './components';
import { RequestCreateDialog } from './dialogs';
import { RequestListView } from './view/request-list.view';

@NgModule({
   declarations: [
      RequestListView,
      RequestCreateDialog,
      RequestListComponent,
      RequestListToolbarComponent
   ],
   imports: [
      CommonModule,
      SharedModule,
      FiltersModule,
      PopoverFilterModule
   ],
   exports: [
      RequestListView,
      RequestCreateDialog,
      RequestListComponent,
      RequestListToolbarComponent
   ]
})
export class RequestListModule { }
import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
   selector: 'au-popover-filter-label',
   changeDetection: ChangeDetectionStrategy.OnPush,
   template: '<ng-content></ng-content>',
   styles: [':host { font-size: 12px; color: rgba(0, 0, 0, 0.54); }']
})
export class FilterLabelComponent {
}

import { Injectable } from "@angular/core";
import { EligibilityFormService } from '@app/funding/eligibility/form/services';
import { ComponentStore } from '@ngrx/component-store';
import { forkJoin, Observable, of } from "rxjs";
import { filter, map, switchMap, tap } from "rxjs/operators";
import { Eligibility } from "../../types";
import { getProgramParentName, getQuestionsByProgramName, getQuestionSetsByProgramName } from "../services/funder-eligibility.helper";
import { Program, Question } from '../types';

interface EligibilityFormState {
   involvementId?: string;
   eligibility: Eligibility;
   eligibilityList: Eligibility[];
   sourceType?: string;
   fundingProgram?: string;
   parentFundingProgram?: string;
   eligibilityPrograms?: Program[];
   eligibilityQuestions?: Question[];
   eligibilityQuestionResponses?: Response[];
   loading: boolean,
}

const initialState: EligibilityFormState = {
   eligibility: {
      id: null,
      involvementId: null,
      fundingProgramId: null,
      fundingProgramName: null,
      isEligible: null,
      eligibilityDate: null,
   },
   eligibilityList: [],
   sourceType: null,
   fundingProgram: null,
   eligibilityPrograms: [],
   loading: false,
}

@Injectable()
export class EligibilityFormStore extends ComponentStore<EligibilityFormState>{

   constructor(
      private service: EligibilityFormService) {
      super(initialState);
      this.getParams;
   }

   private readonly eligibility$ = this.select(state => state.eligibility);
   private readonly sourceType$ = this.select(state => state.sourceType);
   private readonly fundingProgram$ = this.select(state => state.fundingProgram);
   private readonly eligibilityPrograms$ = this.select(state => state.eligibilityPrograms);
   private readonly eligibilityQuestionsAll$ = this.select(state => state.eligibilityQuestions);

   private readonly getProgramParams$ = this.select(
      this.sourceType$.pipe(filter(value => !!value)),
      this.eligibility$.pipe(map(value => value?.id)),
      (sourceType, eligibilityId) => ({ sourceType, eligibilityId })
   );

   private readonly eligibilityQuestions$ = this.select(
      this.fundingProgram$,
      this.eligibilityQuestionsAll$,
      (fundingProgram, eligibilityQuestions) => getQuestionsByProgramName(fundingProgram, eligibilityQuestions)
   );

   private readonly eligibilityQuestionSets$ = this.select(
      this.fundingProgram$,
      this.eligibilityQuestionsAll$,
      (fundingProgram, eligibilityQuestions) => getQuestionSetsByProgramName(fundingProgram, eligibilityQuestions)
   );

   private readonly parentFundingProgram$ = this.select(
      this.fundingProgram$,
      this.eligibilityPrograms$,
      (fundingProgram, eligibilityPrograms) => getProgramParentName(fundingProgram, eligibilityPrograms)
   );

   private readonly parentEligibilityQuestions$ = this.select(
      this.parentFundingProgram$,
      this.eligibilityQuestionsAll$,
      (parentProgram, parentEligibilityQuestions) => getQuestionsByProgramName(parentProgram, parentEligibilityQuestions)
   );

   private readonly parentEligibilityQuestionSets$ = this.select(
      this.parentFundingProgram$,
      this.eligibilityQuestionsAll$,
      (parentProgram, eligibilityQuestions) => getQuestionSetsByProgramName(parentProgram, eligibilityQuestions)
   );

   public readonly vm$ = this.select(
      this.state$,
      this.eligibilityQuestions$,
      this.eligibilityQuestionSets$,
      this.parentFundingProgram$,
      this.parentEligibilityQuestions$,
      this.parentEligibilityQuestionSets$,
      ({ involvementId, eligibility, eligibilityList, sourceType, fundingProgram, eligibilityPrograms, eligibilityQuestionResponses, loading },
         eligibilityQuestions, eligibilityQuestionSets, parentFundingProgram, parentEligibilityQuestions, parentEligibilityQuestionSets) =>
      ({
         involvementId, eligibility, eligibilityList, sourceType, fundingProgram, eligibilityPrograms, eligibilityQuestionResponses, loading,
         eligibilityQuestions, eligibilityQuestionSets, parentFundingProgram, parentEligibilityQuestions, parentEligibilityQuestionSets
      })
   );

   private readonly getParams = this.effect((params$: Observable<{ sourceType: string, eligibilityId: string }>) => params$.pipe(
      switchMap(params => this.loadData(params))
   ))(this.getProgramParams$);

   private readonly loadData = (params: { sourceType: string, eligibilityId: string }) => {
      this.updateIsLoading(true);
      return forkJoin([
         this.loadEligibilityQuestionResponses(params.eligibilityId),
         this.loadEligibilityPrograms(params.sourceType),
         this.loadEligibilityQuestions()
      ]).pipe(tap(() => this.updateIsLoading(false)));
   }

   private readonly updateEligibilityPrograms = this.updater((state, eligibilityPrograms: Program[]) => ({
      ...state, eligibilityPrograms
   }));

   private readonly updateEligibilityQuestions = this.updater((state, eligibilityQuestions: Question[]) => ({
      ...state, eligibilityQuestions
   }));

   private readonly updateEligibilityQuestionResponses = this.updater((state, eligibilityQuestionResponses: Response[]) => ({
      ...state, eligibilityQuestionResponses
   }));

   private readonly updateIsLoading = this.updater((state, isLoading: boolean) => ({
      ...state, isLoading
   }));

   public readonly updateEligibility = this.updater((state, eligibility: Eligibility) => ({
      ...state, eligibility
   }));

   public readonly updateInvolvementId = this.updater((state, involvementId: string) => ({
      ...state, involvementId
   }));

   public readonly updateSourceType = this.updater((state, sourceType: string) => ({
      ...state, sourceType
   }));

   public readonly updateFundingProgram = this.updater((state, fundingProgram: string) => ({
      ...state, fundingProgram
   }));

   public readonly updateEligibilityList = this.updater((state, eligibilityList: Eligibility[]) => ({
      ...state, eligibilityList
   }));

   private loadEligibilityQuestions() {
      this.updateIsLoading(true);
      return this.service.getEligibilityQuestions().pipe(
         tap(eligibilityQuestions => {
            this.updateEligibilityQuestions(eligibilityQuestions);
            this.updateIsLoading(false);
         }))
   };

   private loadEligibilityQuestionResponses(eligibilityId: string): Observable<Response[]> {
      if (!eligibilityId) return of([]);
      this.updateIsLoading(true);
      return this.service.getEligibilityQuestionResponses(eligibilityId).pipe(
         tap(eligibilityQuestionResponses => {
            this.updateEligibilityQuestionResponses(eligibilityQuestionResponses);
            this.updateIsLoading(false);
         }))
   };

   private loadEligibilityPrograms(sourceType: string) {
      this.updateIsLoading(true);
      return this.service.getEligibilityPrograms(sourceType).pipe(
         tap(eligibilityPrograms => {
            this.updateEligibilityPrograms(eligibilityPrograms.sort((a, b) => (a.name > b.name) ? 1 : -1));
            this.updateIsLoading(false);
         }))
   };
}
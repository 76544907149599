import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { HasStatutesOfLimitationsDialogComponent } from './statute-of-limitations/has-statutes-of-limitations-dialog.component';
import { StatuteOfLimitationsDialogComponent } from './statute-of-limitations/statute-of-limitations-dialog.component';



@Injectable()
export class InvolvementsDialogService {
   constructor(private dialog: MatDialog) { }

   openStatuteOfLimitationsDialog(involvementId: string, statuteOfLimitationsId: string, date: string, description: string): MatDialogRef<StatuteOfLimitationsDialogComponent> {
      return this.dialog.open(StatuteOfLimitationsDialogComponent, {
         width: '600px',
         height: '350px',
         panelClass: 'no-padding',
         disableClose: true,
         closeOnNavigation: true,
         autoFocus: true,
         data: {
            involvementId,
            statuteOfLimitationsId,
            date,
            description
         }
      });
   }

   openHasStatutesOfLimitationsDialog(involvementId: string): MatDialogRef<HasStatutesOfLimitationsDialogComponent> {
      return this.dialog.open(HasStatutesOfLimitationsDialogComponent, {
         width: '700px',
         panelClass: 'no-padding',
         disableClose: true,
         closeOnNavigation: true,
         autoFocus: true,
         data: {
            involvementId
         }
      });
   }
}

import { Injectable } from '@angular/core';
import { HttpService, PagedList } from '../core/index';
import { CovidVaccineModel, GetIAndRListParams, IAndRBasicInfoModel, IAndRListItemModel } from './models/i-and-rs.models';
import { Observable } from 'rxjs';
import { HttpResponse } from '@angular/common/http';
import { tap } from 'rxjs/operators';
import { getFileNameFromResponseContentDisposition, saveFile } from '../shared';

@Injectable()
export class IAndRsRepositoryService {
   static injectName = 'iAndRsRepository';
   private baseUrl = 'api/informationandreferrals';

   constructor(private http: HttpService) { }

   getActiveCaseCount(userIds: string[], roles: string[]) {
      const url = `${this.baseUrl}/activeCaseCount`;
      return this.http.get<number>(url, { params: { userIds, roles } });
   }

   getBasicInfo(iAndRId: string) {
      const url = `${this.baseUrl}/${iAndRId}/basicinfo`;
      return this.http.get<IAndRBasicInfoModel>(url);
   }

   getIAndRList(data: GetIAndRListParams): Observable<PagedList<IAndRListItemModel>> {
      const url = `${this.baseUrl}/list`;
      return this.http.post<PagedList<IAndRListItemModel>>(url, data);
   }

   getIAndRListDownload(data: GetIAndRListParams) {
      const url = `${this.baseUrl}/list/csv`;
      return this.http.post<HttpResponse<Blob>>(url, data, { responseType: 'blob', observe: 'response' }).pipe(
         tap(res => {
            const fileName = getFileNameFromResponseContentDisposition(res);
            saveFile(res.body, fileName);
         })
      );
   }

   deleteIAndR(id: string) {
      return this.http.delete(`${this.baseUrl}/${id}`);
   }

   getCovidVaccine(ActivityId: string) {
      const url = `${this.baseUrl}/activities/covidVaccine/${ActivityId}`;
      return this.http.get<CovidVaccineModel>(url);
   }

   createCovidVaccine(data: CovidVaccineModel): Observable<CovidVaccineModel> {
      const url = `${this.baseUrl}/activities/${data.caseId}/covidVaccine`;
      return this.http.post<CovidVaccineModel>(url, data);
   }

   updateCovidVaccine(data: CovidVaccineModel) {
      const url = `${this.baseUrl}/activities/covidVaccine/${data.activityId}`;
      return this.http.put<CovidVaccineModel>(url, data);
   }
}

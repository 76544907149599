import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Eligibility } from '../../types';
import { EligibilityFormComponent } from '../components/eligibility-form';
import { EligibilityFormStore } from '../state/eligibility-form.store';

@Component({
   selector: 'app-eligibility-form-view',
   templateUrl: './eligibility-form-view.component.html',
   styleUrls: ['./eligibility-form-view.component.scss'],
   providers: [EligibilityFormStore]
})
export class EligibilityFormViewComponent implements OnInit {
   @Input() involvementId: string;
   @Input() eligibility: Eligibility = {
      id: null,
      involvementId: null,
      fundingProgramId: null,
      fundingProgramName: null,
      isEligible: true,
      eligibilityDate: null,
      modifiedByFullName: null,
      modifiedAt: null
   };
   @Input() eligibilityList: Eligibility[];
   @Input() sourceType: string;
   @Input() readOnly: boolean;
   @ViewChild(EligibilityFormComponent, { static: true }) form: EligibilityFormComponent;
   vm$ = this.store.vm$;

   constructor(private readonly store: EligibilityFormStore) { }

   ngOnInit(): void {
      this.store.updateInvolvementId(this.involvementId || this.eligibility.involvementId);
      this.store.updateEligibility(this.eligibility);
      this.store.updateEligibilityList(this.eligibilityList);
      this.store.updateSourceType(this.sourceType);
   }

   onFundingProgramChange(fundingProgram: string): void {
      this.store.updateFundingProgram(fundingProgram);
   }
}
import { Component, Input } from '@angular/core';

@Component({
   selector: 'am-number-box',
   templateUrl: './number-box.component.html',
   styleUrls: ['./number-box.component.scss']
})
export class NumberBoxComponent {
   @Input() number: number;
   @Input() text: string;
}

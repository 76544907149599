import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';

import { TrainingRepositoryService } from './training.repository';
import { UIRouterUpgradeModule } from '@uirouter/angular-hybrid';
import { states } from './training.states';
import { TrainingListComponent } from './training-list/training-list.component';
import { FiltersModule } from '../filters/filters.module';

@NgModule({
   imports: [
      UIRouterUpgradeModule.forChild({ states }),
      SharedModule,
      FiltersModule
   ],
   declarations: [
      TrainingListComponent
   ],
   exports: [
   ],
   providers: [
      TrainingRepositoryService
   ]
})
export class TrainingModule {
}

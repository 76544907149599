import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';

import { DocumentsService } from './documents.service';
import { UIRouterUpgradeModule } from '@uirouter/angular-hybrid';

@NgModule({
   imports: [
      SharedModule
   ],
   declarations: [
   ],
   exports: [
   ],
   providers: [
      DocumentsService
   ]
})
export class DocumentsModule {
}

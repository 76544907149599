import { ActionReducer, ActionReducerMap, MetaReducer } from '@ngrx/store';
import { storeFreeze } from 'ngrx-store-freeze';
import { localStorageSync } from 'ngrx-store-localstorage';
import { environment } from '../../environments/environment';
import { State } from './state';

export const reducers: ActionReducerMap<State> = {
   // reducers
};

export const localStorageSyncReducer = (reducer: ActionReducer<any>): ActionReducer<any> =>
   localStorageSync({ keys: ['viewState'], rehydrate: true })(reducer);

export const metaReducers: MetaReducer<State>[] = !environment.production
   ? [storeFreeze, localStorageSyncReducer]
   : [localStorageSyncReducer];

import { Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal, PortalInjector } from '@angular/cdk/portal';
import { ComponentRef, ElementRef, Injectable, Injector } from '@angular/core';

import { MORE_DETAIL_POPUP_DATA, MoreDetailPopupComponent, MoreDetailPopupRef } from '../components';

export interface MoreDetailPopupData {
   componentRef: any;
   componentData: any;
}

interface MoreDetailPopupConfig {
   target?: ElementRef;
   hasBackdrop?: boolean;
   backdropClass?: string;
   minHeight?: string;
   minWidth?: string;
   maxWidth?: string;
   maxHeight?: string;
   data: MoreDetailPopupData;
}

const DEFAULT_CONFIG = {
   hasBackdrop: true,
   backdropClass: 'am-overlay-backdrop-transparent',
   minWidth: '220px',
   maxWidth: '220px',
   maxHeight: '184px',
   minHeight: '184px'
};

@Injectable()
export class MoreDetailPopupService {
   constructor(
      private overlay: Overlay,
      private injector: Injector) {
   }

   initialize(component: any) {
   }

   open(config: MoreDetailPopupConfig) {
      const overlayConfig = { ...DEFAULT_CONFIG, ...config };
      const overlayRef = this.createOverlay(overlayConfig);
      const overlayComponentRef = new MoreDetailPopupRef(overlayRef);
      this.attachOverlayComponent(overlayRef, config, overlayComponentRef);

      overlayRef.backdropClick().subscribe(_ => overlayComponentRef.close());

      return overlayComponentRef;
   }

   private attachOverlayComponent(overlayRef: OverlayRef, config: MoreDetailPopupConfig, overlayComponentRef: MoreDetailPopupRef) {
      const injector = this.createInjector(config, overlayComponentRef);

      const conatinerPortal = new ComponentPortal(MoreDetailPopupComponent, null, injector);
      const containerRef: ComponentRef<MoreDetailPopupComponent> = overlayRef.attach(conatinerPortal);

      return containerRef.instance;
   }

   private createInjector(config: MoreDetailPopupConfig, overlayComponentRef: MoreDetailPopupRef): PortalInjector {
      const injectionTokens = new WeakMap();

      injectionTokens.set(MoreDetailPopupRef, overlayComponentRef);
      injectionTokens.set(MORE_DETAIL_POPUP_DATA, config.data);

      return new PortalInjector(this.injector, injectionTokens);
   }

   private createOverlay(config: MoreDetailPopupConfig) {
      const overlayConfig = this.getOverlayConfig(config);
      return this.overlay.create(overlayConfig);
   }

   private getOverlayConfig(config: MoreDetailPopupConfig): OverlayConfig {
      const positionStrategy = this.overlay.position()
         .flexibleConnectedTo(config.target)
         .withViewportMargin(10)
         .withPositions([{
            originX: 'start',
            originY: 'top',
            overlayX: 'start',
            overlayY: 'bottom'
         }]);

      const overlayConfig = new OverlayConfig({
         hasBackdrop: config.hasBackdrop,
         backdropClass: config.backdropClass,
         maxHeight: config.maxHeight,
         minWidth: config.minWidth,
         maxWidth: config.maxWidth,
         scrollStrategy: this.overlay.scrollStrategies.block(),
         positionStrategy
      });

      return overlayConfig;
   }
}

import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';

import { UIRouterUpgradeModule } from '@uirouter/angular-hybrid';
import { IAndRsRepositoryService } from './i-and-rs.repository';
import { states } from './i-and-rs.states';

import { FiltersModule } from '../filters/filters.module';
import { IAndRCovidVaccineComponent } from './covid-vaccine/covid-vaccine.component';
import { IAndRListModule } from './list/i-and-rs-list.module';

@NgModule({
   imports: [
      UIRouterUpgradeModule.forChild({ states }),
      SharedModule,
      FiltersModule,
      IAndRListModule
   ],
   declarations: [
      IAndRCovidVaccineComponent
   ],
   exports: [
   ],
   providers: [
      IAndRsRepositoryService
   ]
})
export class IAndRsModule {
}
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FiltersModule } from '@app/filters/filters.module';
import { SharedModule } from '@app/shared';
import { PopoverFilterModule } from '@app/shared/filters';
import { ClientCasesListComponent, ClientCasesListToolbarComponent } from './components';
import { ClientCasesCreateDialog } from './dialogs';
import { ClientCasesListView } from './view/client-cases-list.view';

@NgModule({
   declarations: [
      ClientCasesListView,
      ClientCasesCreateDialog,
      ClientCasesListComponent,
      ClientCasesListToolbarComponent
   ],
   imports: [
      CommonModule,
      SharedModule,
      FiltersModule,
      PopoverFilterModule
   ],
   exports: [
      ClientCasesListView,
      ClientCasesCreateDialog,
      ClientCasesListComponent,
      ClientCasesListToolbarComponent
   ]
})
export class ClientCasesListModule { }
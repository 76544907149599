import { AfterViewInit, Directive, TemplateRef, ViewContainerRef } from '@angular/core';

import { AuthService } from '../../../core';

@Directive({ selector: '[amAutomonOnly]' })
export class AutomonOnlyDirective implements AfterViewInit {
   constructor(
      private templateRef: TemplateRef<any>,
      private viewContainer: ViewContainerRef,
      private auth: AuthService
   ) {
   }

   ngAfterViewInit() {
      const userName = this.auth.user.userName || '';
      if (userName.toUpperCase().endsWith('@AUTOMON.COM')) {
         this.viewContainer.createEmbeddedView(this.templateRef);
      } else {
         this.viewContainer.clear();
      }
   }
}

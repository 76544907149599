import { EligibilityFormViewComponent } from '@app/funding/eligibility/form';
import { Transition } from '@uirouter/core';
import { SourceModel } from '../core/models/source.model';
import { StatuteOfLimitationsListComponent } from '../involvements/statute-of-limitations/statute-of-limitations-list.component';
import { CreateEmailComponent } from '../mail/create-email/create-email.component';
import { ClientCasesRepositoryService } from './client-cases.repository';
import { ClientCasesListView } from './list/view/client-cases-list.view';

export const states = [
   {
      name: 'app.clientCases.statuteOfLimitations',
      component: StatuteOfLimitationsListComponent,
      url: '/statuteOfLimitations',
      data: {
         title: 'SOL',
      }
   },
   {
      name: 'app.clientCases.emails.create',
      component: CreateEmailComponent,
      url: '/new/email',
      resolve: {
         source: resolveSource
      },
      data: {
         title: 'New Message',
         amPermissions: ['mail.send']
      }
   },
   {
      name: 'app.clientCaseList',
      component: ClientCasesListView,
      url: '/client-case-list',
      params: {
         rolesFilter: null,
         usersFilter: null,
         usePreferences: null,
         initializeSelected: null
      },
      resolve: {
         rolesFilter: rolesFilterResolver,
         usersFilter: usersFilterResolver,
         usePreferences: usePreferencesResolver,
         initializeSelected: initializeSelectedResolver
      },
      data: {
         title: 'List',
         amPermissions: ['client-cases.view']
      }
   },
   {
      name: 'app.clientCaseListDashboard',
      component: ClientCasesListView,
      url: '/client-case-list-dashboard',
      params: {
         rolesFilter: null,
         usersFilter: null,
         usePreferences: null,
         initializeSelected: null
      },
      resolve: {
         rolesFilter: rolesFilterResolver,
         usersFilter: usersFilterResolver,
         usePreferences: usePreferencesResolver,
         initializeSelected: initializeSelectedResolver
      },
      data: {
         title: 'List',
         amPermissions: ['client-cases.view']
      }
   },
   {
      name: 'app.clientCases.funding.eligibility.eligibilityFormView',
      url: '/form',
      component: EligibilityFormViewComponent,
      params: {
         involvementId: null,
         eligibility: {},
         eligibilityList: [],
         readOnly: false,
         sourceType: 'Client Case'
      },
      resolve: {
         involvementId: involvementIdResolver,
         eligibility: eligibilityResolver,
         eligibilityList: eligibilityListResolver,
         readOnly: readOnlyResolver,
         sourceType: sourceTypeResolver
      },
      data: {
         title: 'Eligibility Form',
         amPermissions: ['funding.create']
      }
   },
];

rolesFilterResolver.$inject = ['$transition$'];
export function rolesFilterResolver($transition$) {
   const { rolesFilter } = $transition$.params();
   return rolesFilter;
}

usersFilterResolver.$inject = ['$transition$'];
export function usersFilterResolver($transition$) {
   const { usersFilter } = $transition$.params();
   return usersFilter;
}

initializeSelectedResolver.$inject = ['$transition$'];
export function initializeSelectedResolver($transition$) {
   const { initializeSelected } = $transition$.params();
   return initializeSelected;
}

usePreferencesResolver.$inject = ['$transition$'];
export function usePreferencesResolver($transition$) {
   const { usePreferences } = $transition$.params();
   return usePreferences;
}

involvementIdResolver.$inject = ['$transition$'];
export function involvementIdResolver($transition$) {
   const { involvementId } = $transition$.params();
   return involvementId;
}

readOnlyResolver.$inject = ['$transition$'];
export function readOnlyResolver($transition$) {
   const { readOnly } = $transition$.params();
   return readOnly;
}

sourceTypeResolver.$inject = ['$transition$'];
export function sourceTypeResolver() {
   const sourceType = 'Client Case';
   return sourceType;
}

eligibilityResolver.$inject = ['$transition$'];
export function eligibilityResolver($transition$) {
   const { eligibility } = $transition$.params();
   return eligibility;
}

eligibilityListResolver.$inject = ['$transition$'];
export function eligibilityListResolver($transition$) {
   const { eligibilityList } = $transition$.params();
   return eligibilityList;
}

resolveSource.$inject = ['$transition$'];
export function resolveSource(transition: Transition) {
   const { clientCaseId } = transition.params();
   const repository: ClientCasesRepositoryService = transition.injector().get(ClientCasesRepositoryService);
   return repository.getBasicInfo(clientCaseId).toPromise().then(
      cc => {
         return {
            source: 'Client Case',
            sourceId: cc.id,
            involvementId: cc.involvementId,
            sourceNumber: cc.idNumber,
            sourceName: cc.clientFullName
         } as SourceModel;
      }
   );
}
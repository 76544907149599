import { Injectable } from '@angular/core';
import { StatusChange } from '@app/activities/types';
import { HttpService } from '@app/core/index';

@Injectable()
export class ActivitiesRepositoryService {
   static injectName = 'activitiesService';
   private baseUrl = 'api/activities';

   constructor(private http: HttpService) { }

   getStatus(sourceId: string) {
      const url = `${this.baseUrl}/${sourceId}/status`;
      return this.http.get<{ status: string }>(url);
   }

   createStatusChange(data: StatusChange, source: string): Observable<StatusChange> {
      const url = `api/${source}/${data.sourceId}/statusChange`;
      return this.http.post<StatusChange>(url, data);
   }
}
import { NgModule, Optional, SkipSelf } from '@angular/core';
import { UIRouterUpgradeModule } from '@uirouter/angular-hybrid';
import { throwIfAlreadyLoaded } from '../core';

import { states } from './home-page.states';
import { HomePageComponent } from './home-page/home-page.component';
import { SharedModule } from '../shared';
import { NumberBoxComponent } from './number-box/number-box.component';
import { NoTimeslipsComponent } from './no-timeslips/no-timeslips.component';
import { TimeslipsByPayPeriodComponent } from './timeslips-period/timeslips-period.component';
import { TimeslipsByWorkWeekComponent } from './timeslips-week/timeslips-week.component';
import { UpcomingEventsComponent } from './upcoming-events/upcoming-events.component';
import { HomepageStateService } from './services/home-page-state.service';
import { SearchModule } from '../search/search.module';
import { HomepageCaseService } from './services/home-page-case.service';
import { HomepageCalendarService } from './services/home-page-calendar.service';
import { HomepageTimeslipService } from './services/home-page-timeslip.service';

@NgModule({
   imports: [
      UIRouterUpgradeModule.forChild({ states }),
      SharedModule,
      SearchModule
   ],
   declarations: [
      HomePageComponent,
      NumberBoxComponent,
      NoTimeslipsComponent,
      TimeslipsByPayPeriodComponent,
      TimeslipsByWorkWeekComponent,
      UpcomingEventsComponent
   ],
   providers: [
      HomepageStateService,
      HomepageCaseService,
      HomepageTimeslipService,
      HomepageCalendarService
   ]
})
export class HomePageModule {
}

import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ClientCaseListService } from '@app/client-cases/list/services/client-cases-list.service';
import { ClientCaseCreate } from '@app/client-cases/list/types/client-case-create';
import { NotificationService } from '@app/core';
import { StateService } from '@uirouter/core';
import * as moment from 'moment';


@Component({
   templateUrl: './client-cases-create.dialog.html',
   styleUrls: ['./client-cases-create.dialog.scss']
})
export class ClientCasesCreateDialog implements OnInit {
   form: FormGroup;
   today: moment.Moment;
   submitting: boolean;

   constructor(
      private readonly dialogRef: MatDialogRef<ClientCaseCreate>,
      private readonly formBuilder: FormBuilder,
      private readonly service: ClientCaseListService,
      private readonly stateService: StateService,
      private notifier: NotificationService,
      @Inject(MAT_DIALOG_DATA)
      public readonly data: ClientCaseCreate) { }

   static open(dialog: MatDialog, data: ClientCaseCreate) {
      return dialog.open(ClientCasesCreateDialog, {
         width: '900px',
         panelClass: 'no-padding',
         disableClose: true,
         autoFocus: true,
         data: data || {}
      });
   }

   ngOnInit(): void {
      this.today = moment().startOf('day');
      this.setupForm();
   }

   private setupForm() {
      const { openDate = null } = this.data ?? {};

      this.form = this.formBuilder.group({
         openDate: [openDate ? moment(openDate) : this.today, Validators.required]
      });
   }

   submit(): void {
      this.submitting = true;
      const formData = this.form.getRawValue();

      const data: ClientCaseCreate = {
         openDate: formData.openDate.format('YYYY-MM-DD')
      };

      this.service.createClientCase(data)
         .subscribe((result) => {
            this.submitting = false;
            this.stateService.go('app.clientCases.overview', { clientCaseId: result });
            this.notifier.notify('Client Case Created', 'Done');
            this.dialogRef.close()
         });
   }
}
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatAutocomplete } from '@angular/material/autocomplete';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ConfigurationService } from '@app/configuration/configuration.service';
import { FundingProgramModel, LookupModel } from '@app/configuration/models/configuration.models';
import { NotificationService } from '@app/core';
import { GrievanceListService } from '@app/grievances/list/services';
import { Grievance, GrievanceFormModel } from '@app/grievances/list/types';
import { PersonsService } from '@app/persons/services/persons.service';
import { PersonSelect } from '@app/persons/types/person';
import { CaseListModel } from '@app/shared/types/case-list';
import * as moment from 'moment';
import { combineLatest, forkJoin, of } from 'rxjs';
import { debounceTime, defaultIfEmpty, distinctUntilChanged, map, startWith, switchMap, tap } from 'rxjs/operators';


@Component({
   templateUrl: './grievances-form.dialog.html',
   styleUrls: ['./grievances-form.dialog.scss']
})
export class GrievancesFormDialog implements OnInit {
   @ViewChild(MatAutocomplete, { static: true }) auto: MatAutocomplete;

   cases$: Observable<CaseListModel[]>;
   form: FormGroup;
   fundingPrograms: FundingProgramModel[] = [];
   persons$: Observable<PersonSelect[]>;
   statuses: LookupModel[];
   submitting: boolean;
   today = moment().startOf('day');

   constructor(
      private readonly dialogRef: MatDialogRef<GrievancesFormDialog, Grievance>,
      private readonly formBuilder: FormBuilder,
      private readonly service: GrievanceListService,
      private readonly personsService: PersonsService,
      private readonly configurationService: ConfigurationService,
      private readonly notifier: NotificationService,
      @Inject(MAT_DIALOG_DATA)
      public readonly data: Grievance) { }

   static open(dialog: MatDialog, data: Grievance): MatDialogRef<GrievancesFormDialog, Grievance> {
      return dialog.open(GrievancesFormDialog, {
         width: '665px',
         panelClass: 'no-padding',
         disableClose: true,
         autoFocus: true,
         data
      });
   }

   ngOnInit(): void {
      this.getConfig();
      this.setupForm();
   }

   private setupForm(): void {

      const { id, idNumber, involvementId, caseId, personId, grievant, caseType, fundingProgramId, status, fileDate, escalationDate, replyDueDate, actualReplyDate, description } = this.data ?? {};

      this.form = this.formBuilder.group({
         grievant: [grievant ? { id: personId, fullName: grievant } : null, [Validators.maxLength(100)]],
         case: [caseId ? { id: caseId, caseType, involvementId, caseIdnumber: idNumber, entityId: personId } : null],
         id: id ?? null,
         fileDate: [fileDate ? moment(fileDate) : null, Validators.required],
         escalationDate: [escalationDate ? moment(escalationDate) : null],
         replyDueDate: [replyDueDate ? moment(replyDueDate) : null, Validators.required],
         actualReplyDate: actualReplyDate ? moment(actualReplyDate) : null,
         fundingProgramId: fundingProgramId ?? null,
         description: description ?? null,
         status: [status ?? null, Validators.required]
      });

      const caseControl = this.form.get('case');
      const grievantControl = this.form.get('grievant');

      this.persons$ = grievantControl.valueChanges.pipe(
         defaultIfEmpty(null),
         debounceTime(750),
         distinctUntilChanged(),
         switchMap(value => this.getPersons(value))
      );

      this.cases$ = combineLatest([
         grievantControl.valueChanges.pipe(map(value => typeof value === 'object' && value !== null ? value.id : value)),
         this.auto.optionSelected.pipe(map(({ option }) => option.value.id))]
      ).pipe(
         map(([personId, optionId]) => personId ?? optionId),
         startWith(personId),
         switchMap(personId => personId ? this.service.getGrievantCases(personId) : of([] as CaseListModel[])),
         tap(value => caseId ? caseControl.patchValue(value.find(x => x.id === caseId)) : caseControl.patchValue(null))
      );
   }

   private getConfig(): void {
      forkJoin([
         this.configurationService.getLookupType('grievance-status'),
         this.configurationService.getFundingPrograms(true, 'project')
      ]).pipe(
         map(([lookupType, fundingPrograms]) => {
            this.statuses = lookupType.lookups;
            this.fundingPrograms = fundingPrograms;
         })
      ).subscribe();
   }

   private getPersons(search: string): Observable<PersonSelect[]> {
      return search && typeof search === 'string'
         ? this.personsService.getPersons({ search })
         : of([])
   }

   displayPerson(person: PersonSelect): string {
      return person?.fullName;
   }

   submit(): void {
      this.submitting = true;

      const formData = this.form.getRawValue();
      const {
         id,
         fileDate,
         escalationDate,
         replyDueDate,
         actualReplyDate,
         description,
         grievant,
         case: { id: caseId, caseType, involvementId }
      } = formData;

      const data: GrievanceFormModel = {
         ...formData,
         caseId,
         caseType,
         involvementId,
         personId: grievant.id,
         fileDate: fileDate.format('YYYY-MM-DD'),
         escalationDate: escalationDate?.format('YYYY-MM-DD'),
         replyDueDate: replyDueDate?.format('YYYY-MM-DD'),
         actualReplyDate: actualReplyDate?.format('YYYY-MM-DD'),
         description
      };

      const observable = id
         ? this.service.updateGrievance(data)
         : this.service.createGrievance(data);

      observable.subscribe(result => {
         this.submitting = false;
         this.notifier.notify(id ? 'Grievance Updated' : 'Grievance Created', 'Done');
         this.dialogRef.close({ ...result } as Grievance);
      });
   }
}
import { SelectionModel } from '@angular/cdk/collections';
import { Component, ElementRef, EventEmitter, forwardRef, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ControlContainer, ControlValueAccessor, FormControl, FormGroupDirective, NG_VALIDATORS, NG_VALUE_ACCESSOR, Validator, Validators } from '@angular/forms';
import { MatAutocomplete, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent, MatChipList } from '@angular/material/chips';
import { Observable, of } from 'rxjs';
import { debounceTime, startWith, switchMap } from 'rxjs/operators';

import { highlight } from '../../shared/shared.helpers';

@Component({
   selector: 'am-email-address-chips',
   templateUrl: './email-address-chips.component.html',
   styleUrls: ['./email-address-chips.component.scss'],
   providers: [{
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => EmailAddressChipsComponent),
      multi: true
   }, {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => EmailAddressChipsComponent),
      multi: true
   }],
   viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }]
})
export class EmailAddressChipsComponent implements OnInit, ControlValueAccessor, Validator {
   filteredAddresses: Observable<string[]>;
   control: FormControl;
   highlight = highlight;
   selection = new SelectionModel<string>(true, []);

   @Input() addresses: (q: string) => Observable<string[]>;
   @Input() required: boolean;
   @Input() placeholder: string;
   @Input() autofocus: boolean;

   @ViewChild('input', { static: true }) input: ElementRef<HTMLInputElement>;
   @ViewChild(MatAutocomplete, { static: true }) auto: MatAutocomplete;
   @ViewChild(MatChipList, { static: true }) chipList: MatChipList;

   constructor(
      public form: FormGroupDirective) {
   }

   ngOnInit() {
      this.control = new FormControl();

      if (this.required) {
         this.control.setValidators(Validators.required);
      }

      this.filteredAddresses = this.control.valueChanges.pipe(
      startWith(''),
      debounceTime(750),
      switchMap(q => q ? this.addresses(q) : of([])));
   }

   registerOnChange(fn) {
      this.selection.changed.subscribe(result => fn(result.source.selected));
   }

   registerOnTouched() { }

   setDisabledState(disabled: boolean) {
      disabled ? this.control.disable() : this.control.enable();
   }

   validate() {
      return this.control.errors;
   }

   writeValue(value: any) {
      this.control.setValue(value);
   }


   onChipInputTokenEnd(event: MatChipInputEvent) {
      if (!this.auto.isOpen && !this.validateEmail(event.value)) {
         event.input.value = '';
         this.control.setValue(undefined);
         if (this.required) {
            this.chipList.errorState = this.selection.isEmpty();
         }
      }
   }

   selected(event: MatAutocompleteSelectedEvent) {
      this.selection.select(event.option.value);
      this.input.nativeElement.value = '';
      this.control.setValue(undefined);
   }

   validateEmail = (email) => {
      const expression = /(?!.*\.{2})^([a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+(\.[a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+)*|"((([ \t]*\r\n)?[ \t]+)?([\x01-\x08\x0b\x0c\x0e-\x1f\x7f\x21\x23-\x5b\x5d-\x7e\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|\\[\x01-\x09\x0b\x0c\x0d-\x7f\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))*(([ \t]*\r\n)?[ \t]+)?")@(([a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.)+([a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.?$/i;
      return expression.test(String(email).toLowerCase());
   }

}

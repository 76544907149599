import { Directive, ElementRef, EventEmitter, Injector, Input, Output } from '@angular/core';
import { UpgradeComponent } from '@angular/upgrade/static';

@Directive({
   // tslint:disable-next-line:directive-selector
   selector: 'am-timeslip-calendar-view'
})
export class TimeslipCalendarUpgradedDirective extends UpgradeComponent {
   @Input() users: [];

   constructor(elementRef: ElementRef, injector: Injector) {
      super('ceTimeslipCalendar', elementRef, injector);
   }
}

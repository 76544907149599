import { Component, Input } from '@angular/core';

@Component({
   selector: 'am-involvement-entity-list',
   templateUrl: 'involvement-entities-list.component.html',
   styleUrls: ['involvement-entities-list.component.scss']
})
export class InvolvementEntitiesListComponent {
   @Input() involvementId: string;
}




















import { downgradeComponent, downgradeInjectable } from '@angular/upgrade/static';
import { CheckboxListComponent, RadiobuttonGroupComponent } from './components';
import { DataFieldComponent } from './components/data-field/data-field.component';
import { FlaggedIconComponent } from './components/flagged-icon/flagged-icon.component';
import { ProgressCircleComponent } from './components/progress-circle/progress-circle.component';
import { InterruptService } from './index';


export function sharedDowngrader(app: any) {
   app.factory('interrupt', downgradeInjectable(InterruptService));
   app.directive('appDataField', downgradeComponent({ component: DataFieldComponent }));
   app.directive('amCheckboxList', downgradeComponent({
      component: CheckboxListComponent,
      inputs: ['label', 'stringValues', 'lookupType', 'required']
   }));
   app.directive('amRadiobuttonGroup', downgradeComponent({
      component: RadiobuttonGroupComponent,
      inputs: ['label', 'stringValues', 'lookupType', 'required']
   }));
   app.directive('amProgressCircle', downgradeComponent({
      component: ProgressCircleComponent,
      inputs: ['value']
   }));
   app.directive('amFlaggedIcon', downgradeComponent({
      component: FlaggedIconComponent,
      inputs: ['text']
   }));
}
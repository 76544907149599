import { Component, EventEmitter, Input, Output } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { Project, ProjectListFilters } from '@app/projects/list/types';
import { Pagination, TypedSort } from '@app/types/core';
import { PopoverFilterOption } from '@app/shared/filters';


@Component({
   selector: 'app-projects-list',
   templateUrl: './projects-list.component.html',
   styleUrls: ['./projects-list.component.scss']
})
export class ProjectsListComponent {
   @Input() projects: Project[];
   @Input() funderOptions: PopoverFilterOption[];
   @Input() filters: ProjectListFilters;
   @Input() loading = false;
   @Input() count: number;
   @Input() page: Pagination;
   @Input() sort: TypedSort<Project>;
   @Output() createProject = new EventEmitter<Project>();
   @Output() deleteProject = new EventEmitter<Project>();
   @Output() filtersChange = new EventEmitter<ProjectListFilters>();
   @Output() sortChange = new EventEmitter<TypedSort<Project>>();
   @Output() pageChange = new EventEmitter<Pagination>();
   @Output() clearFilters = new EventEmitter();

   columnsToDisplay: string[] = ['name', 'description', 'defaultFunder', 'opened', 'closed', 'more'];;

   onFilter(value: Partial<ProjectListFilters>): void {
      this.filtersChange.emit({ ...this.filters, ...value });
   }

   onPage({ pageIndex, pageSize }: PageEvent): void {
      this.pageChange.emit({ page: pageIndex + 1, pageSize });
   }

   onSort(sort: Sort): void {
      this.sortChange.emit(sort as TypedSort<Project>);
   }
}
import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as moment from 'moment';
import { IssueModel } from '../models/monitoring.models';
import { StateService } from '@uirouter/core';
import { MonitoringRepositoryService } from '../monitoring.repository';
import { finalize } from 'rxjs/operators';
import { ConfigurationService } from '../../configuration/configuration.service';
import { LookupModel } from '../../configuration/models/configuration.models';
import { InterruptService } from '../../shared';


@Component({
   selector: 'am-monitoring-issue',
   templateUrl: 'issue.component.html',
   styleUrls: ['issue.component.scss']
})
export class MonitoringIssueComponent implements OnInit {
   @Input() activityId: string;
   @Input() involvementId: string;
   @Input() sourceId: string;

   form: FormGroup;
   today: moment.Moment;
   maxDate: moment.Moment;
   submitting: boolean;
   issueType: string[] = ['Abuse', 'Behavioral', 'Environmental', 'Medical', 'Programming'];
   statuses: LookupModel[];

   constructor(
      private formBuilder: FormBuilder,
      private monitoringService: MonitoringRepositoryService,
      private configurationService: ConfigurationService,
      private interruptService: InterruptService,
      private stateService: StateService) {
   }

   ngOnInit() {
      this.today = moment().startOf('day');
      this.maxDate = this.today;
      this.getStatuses();
      this.getStatus();
      this.form = this.formBuilder.group({
         issueDate: this.today,
         issueType: [undefined, Validators.required],
         description: [undefined, Validators.required],
         responseDate: undefined,
         timelyResponse: undefined,
         response: undefined,
         status: undefined
      });
      if (this.activityId) {
         this.getIssue();
      }
   }

   private getIssue() {
      this.monitoringService.getIssue(this.activityId).subscribe(issue => {
         this.form.patchValue({
            issueDate: moment(issue.issueDate),
            issueType: issue.issueType,
            description: issue.description,
            responseDate: issue.responseDate,
            timelyResponse: issue.timelyResponse,
            response: issue.response
         });
      });
   }

   private getStatuses() {
      this.configurationService.getLookupType('monitoring-status').subscribe(lt => {
         this.statuses = lt.lookups;
      });
   }

   private getStatus() {
      this.monitoringService.getStatus(this.sourceId).subscribe(status => {
         if (status) {
            this.form.patchValue({ status: status.status });
         }
      });
   }

   cancel() {
      this.interruptService.allowNavigation();
      this.stateService.go('.^');
   }

   submit() {
      if (this.form.invalid) { return; }
      this.submitting = true;
      const formData = this.form.getRawValue();

      const data: IssueModel = {
         id: this.activityId,
         involvementId: this.involvementId,
         monitoringId: this.sourceId,
         issueDate: formData.issueDate.format('YYYY-MM-DD'),
         issueType: formData.issueType,
         description: formData.description,
         responseDate: formData.responseDate,
         timelyResponse: formData.timelyResponse,
         response: formData.response,
         status: formData.status
      };

      const observable = this.activityId
         ? this.monitoringService.updateIssue(data)
         : this.monitoringService.createIssue(data);

         observable
         .pipe(finalize(() => this.submitting = false))
         .subscribe(result => {
            if (result) {
               this.submitting = false;
               this.interruptService.allowNavigation();
               this.stateService.go('.^');
            }
         });
   }

}

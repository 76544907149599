import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { HttpService } from '../core';
import { ENV } from '../env.config';
import * as models from './models';

@Injectable()
export class RoleService {
   private rolesUrl = `${ENV.API_URL}/identity-management/roles`;

   constructor(private http: HttpService) {
   }

   createRole(role: models.RoleModel) {
      return this.http.post<models.RoleModel>(this.rolesUrl, role);
   }

   deleteRole(roleId: string, params: any) {
      const requestUri = `${this.rolesUrl}/${roleId}`;
      return this.http.delete(requestUri, { params });
   }

   getRoles(): Observable<models.RoleModel[]> {
      return this.http.get<models.RoleModel[]>(this.rolesUrl);
   }

   updateRole(roleId: string, location: models.RoleModel) {
      const requestUri = `${this.rolesUrl}/${roleId}`;
      return this.http.put<models.RoleModel>(requestUri, location);
   }
}

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { GrievanceListService } from '@app/grievances/list/services';
import { GetGrievanceListPayload } from '@app/grievances/list/types';


@Component({
   selector: 'app-grievances-list-toolbar',
   templateUrl: './grievances-list-toolbar.component.html',
   styleUrls: ['./grievances-list-toolbar.component.scss']
})
export class GrievancesListToolbarComponent implements OnInit {
   private _activeOnly: boolean;
   @Input() payload: GetGrievanceListPayload;
   @Input()
   get activeOnly(): boolean {
      return this._activeOnly;
   }
   set activeOnly(value: boolean) {
      this._activeOnly = value;
      this.setActiveOnlyText()
   }

   @Output() addGrievance = new EventEmitter();
   @Output() searchChange = new EventEmitter<string>();
   @Output() activeOnlyChange = new EventEmitter<boolean>();
   @Output() toggleChange = new EventEmitter<boolean>();

   toggle: boolean = false;
   activeOnlyText: string;

   constructor(
      private service: GrievanceListService
   ) { }

   ngOnInit(): void {
      this.searchChange.emit(null)
   }

   export = () => {
      this.service.getGrievancesExport(this.payload).subscribe();
   }

   activeOnlyToggle(): void {
      this.activeOnly = !this.activeOnly;
      this.activeOnlyChange.emit(this.activeOnly);
   }

   setActiveOnlyText(): void {
      this.activeOnlyText = this.activeOnly ? 'Click to include Inactive Grievances' : 'Click to exclude Inactive Grievances'
   }

   onToggle(): void {
      this.toggle = !this.toggle;
      this.toggleChange.emit(this.toggle);
   }
}
import { OverlayRef } from '@angular/cdk/overlay';
import { Subject } from 'rxjs';

import { DateFilterChangeEvent } from './date-filter.models';

export class DateFilterOverlayRef {
   private afterClosedSubject: Subject<DateFilterChangeEvent>;

   constructor(private overlayRef: OverlayRef) { }

   afterClosed() {
      if (!this.afterClosedSubject) {
         this.afterClosedSubject = new Subject<DateFilterChangeEvent>();
      }

      return this.afterClosedSubject.asObservable();
   }

   close(result?: DateFilterChangeEvent): void {
      this.overlayRef.dispose();
      if (this.afterClosedSubject) {
         this.afterClosedSubject.next(result);
         this.afterClosedSubject.complete();
      }
   }
}

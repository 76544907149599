import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { LookupModel } from '@app/configuration/models/configuration.models';
import { Selected } from '@app/types/core';
import { UserModel } from '@app/users';

@Component({
   selector: 'app-case-list-drawer',
   templateUrl: './case-list-drawer.component.html',
   styleUrls: ['./case-list-drawer.component.scss']
})
export class CaseListDrawerComponent {
   @Input() userRoles: LookupModel[] = [];
   @Input() selected: Selected = { users: [], roles: [] };
   @Output() rolesChange = new EventEmitter<string[]>();
   @Output() usersChange = new EventEmitter<UserModel[]>();

   form: FormGroup;

   constructor(
      private formBuilder: FormBuilder
   ) { }

   ngOnInit() {
      this.form = this.formBuilder.group({
         usersFilter: [this.selected.users],
         rolesFilter: [this.selected.roles]
      });
      this.form.get('rolesFilter').valueChanges.subscribe(value =>
         this.rolesChange.emit(value || [])
      );
   }

   onSelectedUsersChange(value: UserModel[]): void {
      this.usersChange.emit(value || []);
   }

   clearFilter() {
      this.form.patchValue({
         usersFilter: [],
         rolesFilter: []
      });
      this.usersChange.emit([]);
   }
}
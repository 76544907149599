import { Component, Input } from '@angular/core';

@Component({
   selector: 'am-progress-circle',
   templateUrl: 'progress-circle.component.html',
   styleUrls: ['progress-circle.component.scss']
})
export class ProgressCircleComponent {
   @Input() value = 0;

   full = 100;

   constructor() { }
}

import { TrainingRequestListComponent } from './training-request-list/training-request-list.component';

export const states = [
   {
      name: 'app.trainingRequestList',
      component: TrainingRequestListComponent,
      url: '/training-request-list',
      data: {
         title: 'List',
         amPermissions: ['training-requests.view']
      }
   }
];

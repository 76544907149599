import { Transition } from '@uirouter/core';
import { SourceModel } from '../core/models/source.model';
import { CreateEmailComponent } from '../mail/create-email/create-email.component';
import { ProjectsListView } from './list/view/projects-list.view';
import { ProjectListComponent } from './project-list/project-list.component';
import { ProjectsRepositoryService } from './projects.repository';

export const states = [
   {
      name: 'app.projects.emails.create',
      component: CreateEmailComponent,
      url: '/new/email',
      resolve: {
         source: resolveSource
      },
      data: {
         title: 'New Message',
         amPermissions: ['mail.send']
      }
   },

   {
      name: 'app.projectList',
      component: ProjectsListView,
      url: '/project-list',
      data: {
         title: 'List',
         amPermissions: ['projects.view']
      }
   },

   {
      name: 'app.projectListOld',
      component: ProjectListComponent,
      url: '/project-list-old',
      data: {
         title: 'List',
         amPermissions: ['projects.view']
      }
   },

];

resolveSource.$inject = ['$transition$'];
export function resolveSource(transition: Transition) {
   const { projectId } = transition.params();
   const repository: ProjectsRepositoryService = transition.injector().get(ProjectsRepositoryService);
   return repository.getProject(projectId).toPromise().then(
      p => {
         return {
            involvementId: null,
            source: 'Project',
            sourceId: p.id,
            sourceName: p.name
         } as SourceModel;
      }
   );
}



import { Component, OnInit } from '@angular/core';

@Component({
   selector: 'am-monitoring-summary',
   templateUrl: './monitoring-summary.component.html',
   styleUrls: ['./monitoring-summary.component.scss']
})
export class MonitoringSummaryComponent implements OnInit {

   ngOnInit() {

   }

}

import { Component, EventEmitter, Input, Output } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { Pagination, TypedSort } from '@app/types/core';
import { PopoverFilterOption } from '@app/shared/filters';
import { SystemicCase } from '../../types/systemic-case';
import { SystemicCasesListFilters } from '../../types/systemic-cases-filters';

@Component({
  selector: 'app-systemic-cases-list',
  templateUrl: './systemic-cases-list.component.html',
  styleUrls: ['./systemic-cases-list.component.scss']
})
export class SystemicCasesListComponent {
  @Input() systemicCases: SystemicCase[];
  @Input() legalTeamOptions: PopoverFilterOption[];
  @Input() funderOptions: PopoverFilterOption[];
  @Input() statusOptions: PopoverFilterOption[];
  @Input() filters: SystemicCasesListFilters;
  @Input() loading = false;
  @Input() count: number;
  @Input() page: Pagination;
  @Input() sort: TypedSort<SystemicCase>;
  @Output() createSystemicCase = new EventEmitter<SystemicCase>();
  @Output() deleteSystemicCase = new EventEmitter<SystemicCase>();
  @Output() filtersChange = new EventEmitter<SystemicCasesListFilters>();
  @Output() sortChange = new EventEmitter<TypedSort<SystemicCase>>();
  @Output() pageChange = new EventEmitter<Pagination>();
  @Output() clearFilters = new EventEmitter();

  public columnsToDisplay: string[] = ['number', 'caseName', 'primaryStaff', 'funder', 'status', 'opened', 'closed', 'more'];

  onFilter(value: Partial<SystemicCasesListFilters>): void {
    this.filtersChange.emit({ ...this.filters, ...value });
  }

  onPage({ pageIndex, pageSize }: PageEvent): void {
    this.pageChange.emit({ page: pageIndex + 1, pageSize });
  }

  onSort(sort: Sort): void {
    this.sortChange.emit(sort as TypedSort<SystemicCase>);
  }
}

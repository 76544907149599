import { Injectable } from '@angular/core';
import { HttpService, PagedList } from '../core/index';
import { GetTrainingListParams, TrainingListItemModel, TrainingModel } from './models/training.models';
import { Observable } from 'rxjs';
import { HttpResponse } from '@angular/common/http';
import { tap } from 'rxjs/operators';
import { getFileNameFromResponseContentDisposition, saveFile } from '../shared';

@Injectable()
export class TrainingRepositoryService {
   static injectName = 'trainingServie';
   private baseUrl = 'api/trainings/training';

   constructor(private http: HttpService) { }

   getTraining(trainingId: string) {
      const url = `${this.baseUrl}/${trainingId}`;
      return this.http.get<TrainingModel>(url);
   }


   getTrainingList(data: GetTrainingListParams): Observable<PagedList<TrainingListItemModel>> {
      const url = `${this.baseUrl}/list`;
      return this.http.post<PagedList<TrainingListItemModel>>(url, data);
   }

   getTrainingListDownload(data: GetTrainingListParams) {
      const url = `${this.baseUrl}/list/csv`;
      return this.http.post<HttpResponse<Blob>>(url, data, { responseType: 'blob', observe: 'response' }).pipe(
         tap(res => {
            const fileName = getFileNameFromResponseContentDisposition(res);
            saveFile(res.body, fileName);
         })
      );
   }

   deleteTraining(id: string) {
      return this.http.delete(`${this.baseUrl}/${id}`);
   }
}

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SystemicCaseListService } from '../../services/systemic-cases-list.service';
import { GetSystemicCaseListPayload } from '../../types/systemic-case-params';

@Component({
  selector: 'app-systemic-cases-list-toolbar',
  templateUrl: './systemic-cases-list-toolbar.component.html',
  styleUrls: ['./systemic-cases-list-toolbar.component.scss']
})
export class SystemicCasesToolbarComponent implements OnInit {
  private _activeOnly: boolean;
  @Input() payload: GetSystemicCaseListPayload;
  @Input()
  get activeOnly(): boolean {
    return this._activeOnly;
  }
  set activeOnly(value: boolean) {
    this._activeOnly = value;
    this.setActiveOnlyText()
  }

  @Output() addSystemicCase = new EventEmitter();
  @Output() searchChange = new EventEmitter<string>();
  @Output() activeOnlyChange = new EventEmitter<boolean>();
  @Output() toggleChange = new EventEmitter<boolean>();

  toggle: boolean = false;
  activeOnlyText: string;

  constructor(
    private service: SystemicCaseListService,
  ) { }

  ngOnInit(): void {
    this.searchChange.emit(null)
  }

  export = () => {
    this.service.getSystemicCasesExport(this.payload).subscribe();
  }

  activeOnlyToggle() {
    this.activeOnly = !this.activeOnly;
    this.activeOnlyChange.emit(this.activeOnly);
  }

  setActiveOnlyText() {
    this.activeOnlyText = this.activeOnly ? 'Click to include Inactive Cases' : 'Click to exclude Inactive Cases'
  }

  onToggle() {
    this.toggle = !this.toggle;
    this.toggleChange.emit(this.toggle);
  }
}

import { Transition, TransitionService } from '@uirouter/core';

import { AuthService } from '../services';

export function authGuard(transitionService: TransitionService) {
   const requiresAuthCriteria = {
      to: (state) => state.data && !state.data.bypassAuthentication
   };

   const redirectToAccessDenied = (transition: Transition) => {
      const { data } = transition.to();
      const $state = transition.router.stateService;
      const accessDenied = $state.target('403');
      const auth: AuthService = transition.injector().get(AuthService);

      return auth.authorize().then(() => {
         if (data.roles && !auth.checkRoles(...data.roles)) {
            return accessDenied;
         }

         auth.currentViewPermissions = data.permissions;

         // if (data.permissions) {
         //    if (transition.$to().includes['app.location']) {
         //       const { locationId } = transition.params();
         //       if (!auth.checkPermissionsAny(locationId, ...data.permissions)) {
         //          return accessDenied;
         //       }
         //    }
         //    else if (transition.$to().includes['app.involvement']) {
         //       return transition.injector().getAsync('involvement').then(i => {
         //          if (!auth.checkPermissionsAny(i.locationId, ...data.permissions)) {
         //             return accessDenied;
         //          }
         //       });
         //    }
         // }
      });
   };

   transitionService.onBefore(requiresAuthCriteria, redirectToAccessDenied, { priority: 10 });
}

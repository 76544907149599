import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';

import { ErrorDialogComponent } from '../components';
import { ErrorDialogData, ErrorDialogOptions } from '../models';

@Injectable()
export class ErrorDialogService {
   constructor(private dialog: MatDialog) { }
   open(options: ErrorDialogOptions): Observable<boolean> {
      const { reason, message, level, listItems, okText, cancelText, ...rest } = options;

      const data: ErrorDialogData = {
         reason,
         message,
         level,
         listItems,
         okText,
         cancelText
      };

      const dialogOptions = {
         width: '400px',
         height: '175px',
         ...rest,
         data,
         autoFocus: false,
         disableClose: true,
         panelClass: 'no-padding'
      };

      return this.dialog.open(ErrorDialogComponent, dialogOptions).afterClosed();
   }
}

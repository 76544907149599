import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NotificationService } from '@app/core';
import { EmailService } from '@app/tenancy/email/services';
import { Email } from '@app/tenancy/email/types';
import { StateService } from '@uirouter/core';

@Component({
    selector: 'app-tenancy-email',
    templateUrl: './tenancy-email.component.html',
    styleUrls: ['./tenancy-email.component.scss']
})
export class TenancyEmailComponent implements OnInit {
    form: FormGroup;
    submitting: boolean;
    hidePassword: boolean = true;
    hideSecret: boolean = true;
    validation: string = "^[{]?[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}[}]?$"

    constructor(
        private readonly formBuilder: FormBuilder,
        private readonly notifier: NotificationService,
        private readonly service: EmailService,
        private readonly stateService: StateService
    ) { }

    ngOnInit(): void {
        this.setupForm();
        this.getEmailIntegration();
    }

    getEmailIntegration(): void {
        this.service.getEmailIntegration().subscribe(email => {
            this.form.patchValue({
                email: email.mailUserName,
                password: email.mailPassword,
                clientId: email.mailClientId,
                tenantId: email.mailTenantId,
                clientSecret: email.mailClientSecret
            });
        });
    }

    setupForm(): void {
        this.form = this.formBuilder.group({
            email: new FormControl('', [Validators.required]),
            password: new FormControl('', [Validators.required, Validators.min(3)]),
            clientId: new FormControl('', [Validators.required]),
            tenantId: new FormControl('', [Validators.required]),
            clientSecret: new FormControl('', [Validators.required]),
        });
    }

    cancel(): void {
        this.stateService.reload();
    }

    submit(): void {
        this.submitting = true;
        const formData = this.form.getRawValue();

        const data: Email = {
            id: formData.id,
            mailUserName: formData.email,
            mailPassword: formData.password,
            mailClientId: formData.clientId,
            mailTenantId: formData.tenantId,
            mailClientSecret: formData.clientSecret
        };

        this.service.updateEmailIntegration(data)
            .subscribe(() => {
                this.submitting = false;
                this.notifier.notify('Email Integration Updated', 'Done');
            });
    }
}
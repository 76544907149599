import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { from, Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { ConfigurationProviderService } from '../services';
import { AuthService } from '../services/auth.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
   constructor(private configurationProvider: ConfigurationProviderService, private auth: AuthService) { }

   intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
      if (!this.configurationProvider.config || this.configurationProvider.config.bypassAuth) {
         return next.handle(req);
      }

      return from(this.auth.loginSilent()).pipe(
         switchMap(() => {
            const clonedRequest = req.clone({
               headers: req.headers.set('Authorization', 'Bearer ' + this.auth.accessToken)
            });
            return next.handle(clonedRequest);
         })
      );
   }
}

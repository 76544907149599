import { downgradeComponent, downgradeInjectable } from '@angular/upgrade/static';
import { InvolvementsService } from './involvements.service';
import { StatuteOfLimitationsSummaryComponent } from './statute-of-limitations/statute-of-limitations-summary.component';

export function involvementsDowngrader(app: any) {
   app.factory(InvolvementsService.injectName, downgradeInjectable(InvolvementsService));
   app.directive('amStatuteOfLimitationsSummary', downgradeComponent({
      component: StatuteOfLimitationsSummaryComponent,
      inputs: ['involvementId']
   }));
}

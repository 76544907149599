import { Component, EventEmitter, Input, Output } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { Pagination, TypedSort } from '@app/types/core';
import { PopoverFilterOption } from '@app/shared/filters';
import { IAndR } from '../../types/i-and-rs';
import { IAndRListFilters } from '../../types/i-and-rs-filters';

@Component({
   selector: 'app-i-and-rs-list',
   templateUrl: './i-and-rs-list.component.html',
   styleUrls: ['./i-and-rs-list.component.scss']
})
export class IAndRsListComponent {
   @Input() iAndRCases: IAndR[];
   @Input() legalTeamOptions: PopoverFilterOption[];
   @Input() funderOptions: PopoverFilterOption[];
   @Input() statusOptions: PopoverFilterOption[];
   @Input() filters: IAndRListFilters;
   @Input() loading = false;
   @Input() count: number;
   @Input() page: Pagination;
   @Input() sort: TypedSort<IAndR>;
   @Output() createIAndR = new EventEmitter<IAndR>();
   @Output() deleteIAndR = new EventEmitter<IAndR>();
   @Output() filtersChange = new EventEmitter<IAndRListFilters>();
   @Output() sortChange = new EventEmitter<TypedSort<IAndR>>();
   @Output() pageChange = new EventEmitter<Pagination>();
   @Output() clearFilters = new EventEmitter();

   public columnsToDisplay: string[] = ['number', 'caller', 'client', 'primaryStaff', 'funder', 'status', 'opened', 'closed', 'more'];

   onFilter(value: Partial<IAndRListFilters>): void {
      this.filtersChange.emit({ ...this.filters, ...value });
   }

   onPage({ pageIndex, pageSize }: PageEvent): void {
      this.pageChange.emit({ page: pageIndex + 1, pageSize });
   }

   onSort(sort: Sort): void {
      this.sortChange.emit(sort as TypedSort<IAndR>);
   }
}
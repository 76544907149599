import { Injectable } from "@angular/core";
import { Sort } from "@angular/material/sort";
import { ConfigurationService } from "@app/configuration/configuration.service";
import { LookupModel } from "@app/configuration/models/configuration.models";
import { PagedList } from "@app/core";
import { IRequest } from '@app/requests/list/types/request';
import { RequestListFilters } from '@app/requests/list/types/request-filters';
import { REQUESTS_CONFIG } from "@app/requests/requests.config";
import { RootStoreState, ViewStateComponentStore, ViewStateComponentStoreState } from "@app/root-store";
import { CaseListFilterOptions } from "@app/shared/types/case-list-filter-options";
import { Pagination, Selected } from "@app/types/core";
import { PopoverFilterOption } from '@app/shared/filters';
import { tapResponse } from '@ngrx/component-store';
import { Store } from "@ngrx/store";
import { forkJoin, Observable } from "rxjs";
import { concatMap, switchMap, tap, withLatestFrom } from "rxjs/operators";
import { RequestListService } from "../services/request-list.service";
import { GetRequestListPayload } from "../types/request-params";

export interface RequestListState extends ViewStateComponentStoreState {
   requests: IRequest[];
   payload: GetRequestListPayload;
   options: CaseListFilterOptions;
   userRoles: LookupModel[];
   userRolesResponses: Response[];
   count: number;
   loading: boolean;
   toggle: boolean
}

export interface RequestListViewState {
   filters: RequestListFilters;
   selected: Selected;
   search: string;
   activeOnly: boolean;
   pagination: Pagination;
   sort: Sort;
}

const initialState: RequestListState = {
   requests: [],
   payload: {
      pagination: null,
      sort: null,
      filters: {},
      selected: {},
      search: null
   },
   options: {
      legalTeams: null,
      fundingPrograms: null,
      caseStatuses: null,
      convertedTo: null
   },
   userRoles: [],
   userRolesResponses: [],
   count: 0,
   loading: false,
   toggle: false,
   viewId: null
}

const initialViewState: RequestListViewState = {
   filters: {
      number: null,
      caller: null,
      pwd: null,
      legalTeam: null,
      requested: null,
      followedUp: null,
      reviewed: null,
      urgentRequest: null,
      status: null,
      convertedTo: null,
      proBonoAttorney: null
   },
   selected: {
      users: [],
      roles: []
   },
   search: null,
   activeOnly: true,
   pagination: { page: 1, pageSize: 25 },
   sort: { active: 'requested', direction: 'desc' }
};

@Injectable()
export class RequestStateStore extends ViewStateComponentStore<RequestListState, RequestListViewState> {

   constructor(
      rootStore: Store<RootStoreState.State>,
      private service: RequestListService,
      private configurationService: ConfigurationService) {
      super(rootStore, initialViewState);
   }

   init(viewId: string): void {
      this.initCore({
         ...initialState,
         viewId
      });
      this.patchState({ loading: true });
      this.service.getFilterOptions().pipe(
         tapResponse(options =>
            this.patchState({ options, loading: false }), error => console.log(error))).subscribe();
      forkJoin([
         this.service.getFilterOptions(),
         this.configurationService.getLookupType('involvement-user-role')]).subscribe(
            ([options, types]) => {
               this.patchState({ options, userRoles: types.lookups, loading: false }), error => console.log(error);
            })
   }

   readonly legalTeamOptions$: Observable<PopoverFilterOption[]> = this.select(
      this.state$,
      ({ options }) => options.legalTeams?.map(value => ({ value, text: value }))
   );

   readonly statusOptions$: Observable<PopoverFilterOption[]> = this.select(
      this.state$,
      ({ options }) => options.caseStatuses?.map(value => ({ value, text: value }))
   );

   readonly convertedToOptions$: Observable<PopoverFilterOption[]> = this.select(
      this.state$,
      () => REQUESTS_CONFIG.convertedStatuses.map(value => ({ value, text: value }))
   );

   readonly vm$ = this.select(
      this.legalTeamOptions$,
      this.statusOptions$,
      this.convertedToOptions$,
      this.state$,
      this.viewState$,
      (legalTeamOptions, statusOptions, convertedToOptions, { requests, payload, userRoles, count, loading, toggle }, { filters, selected, activeOnly, pagination, sort }) => (
         { legalTeamOptions, statusOptions, convertedToOptions, requests, payload, userRoles, count, loading, toggle, filters, selected, activeOnly, pagination, sort })
   )

   readonly updateRequests = this.updater((state, { list, count }: PagedList<IRequest>): RequestListState => ({
      ...state,
      requests: list,
      count,
      loading: false
   }));

   readonly reload = this.effect((origin$: Observable<GetRequestListPayload>) => origin$.pipe(
      tap(payload => this.patchState({ payload, loading: true })),
      switchMap(payload => this.service.getRequestList(payload).pipe(
         tapResponse(({ count, page, pageSize, list }) => this.updateRequests({ list, page, pageSize, count }), err => console.log(err))
      ))
   ))(this.viewState$);

   readonly addRequest = this.updater((state, request: IRequest) => ({
      ...state,
      requests: state.requests.filter(r => r.involvementId !== request.involvementId).concat(request)
   }));

   readonly deleteRequest = this.effect((origin$: Observable<string>) => origin$.pipe(
      tap(() => this.patchState({ loading: true })),
      concatMap(id => this.service.deleteRequest(id)),
      withLatestFrom(this.viewState$),
      concatMap(([_, payload]) => this.service.getRequestList(payload)),
      tapResponse(({ count, page, pageSize, list }) => this.updateRequests({ list, page, pageSize, count }), err => console.log(err))
   ));
}
import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { PersonsService } from './services/persons.service';


@NgModule({
   imports: [
      SharedModule
   ],
   declarations: [
   ],
   exports: [
   ],
   providers: [
      PersonsService
   ]
})
export class PersonsModule {

}
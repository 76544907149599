import { Transition } from '@uirouter/core';
import { SourceModel } from '../core/models/source.model';
import { CreateEmailComponent } from '../mail/create-email/create-email.component';
import { CLEListComponent } from './cle-list/cle-list.component';
import { CLERepositoryService } from './cle.repository';

export const states = [
   {
      name: 'app.cle.emails.create',
      component: CreateEmailComponent,
      url: '/new/email',
      resolve: {
         source: resolveSource
      },
      data: {
         title: 'New Message',
         amPermissions: ['mail.send']
      }
   },
   {
      name: 'app.cleList',
      component: CLEListComponent,
      url: '/cle-list',
      data: {
         title: 'List',
         amPermissions: ['cle-courses.view']
      }
   }
];

resolveSource.$inject = ['$transition$'];
export function resolveSource(transition: Transition) {
   const { cleId } = transition.params();
   const repository: CLERepositoryService = transition.injector().get(CLERepositoryService);
   return repository.getCLE(cleId).toPromise().then(
      c => {
         return {
            source: 'CLE',
            sourceId: c.id,
            sourceName: c.title
         } as SourceModel;
      }
   );
}
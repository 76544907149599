import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators} from '@angular/forms';
import * as moment from 'moment';
import { ContactModel } from '../models/monitoring.models';
import { StateService } from '@uirouter/core';
import { MonitoringRepositoryService } from '../monitoring.repository';
import { finalize } from 'rxjs/operators';
import { ConfigurationService } from '../../configuration/configuration.service';
import { LookupModel } from '../../configuration/models/configuration.models';
import { InterruptService } from '../../shared';


@Component({
   selector: 'am-monitoring-contact',
   templateUrl: 'contact.component.html',
   styleUrls: ['contact.component.scss']
})
export class MonitoringContactComponent implements OnInit {
   @Input() involvementId: string;
   @Input() sourceId: string;
   @Input() activityId: string;

   form: FormGroup;
   today: moment.Moment;
   maxDate: moment.Moment;
   submitting: boolean;
   contactType: string[] = ['In-Person', 'Phone', 'Email', 'Mail', 'Virtual'];
   contactWith: string [] = ['Client', 'Guardian', 'SODC', 'CILA', 'CDS', 'DDD', 'ISC', 'Other'];
   statuses: LookupModel[];

   constructor(
      private formBuilder: FormBuilder,
      private monitoringService: MonitoringRepositoryService,
      private configurationService: ConfigurationService,
      private interruptService: InterruptService,
      private stateService: StateService) {
   }

   ngOnInit() {
      this.today = moment().startOf('day');
      this.maxDate = this.today;
      this.getStatuses();
      this.getStatus();
      this.form = this.formBuilder.group({
         contactDate: this.today,
         contactType: [undefined, Validators.required],
         contactWith: [undefined, Validators.required],
         description: [undefined, Validators.required],
         status: undefined
      });
      if (this.activityId) {
         this.getContact();
      }
   }

   private getContact() {
      this.monitoringService.getContact(this.activityId).subscribe(contact => {
         this.form.patchValue({
            contactDate: moment(contact.contactDate),
            contactType: contact.contactType,
            contactWith: contact.contactWith,
            description: contact.description
         });
      });
   }

   private getStatuses() {
      this.configurationService.getLookupType('monitoring-status').subscribe(lt => {
         this.statuses = lt.lookups;
      });
   }

   private getStatus() {
      this.monitoringService.getStatus(this.sourceId).subscribe(status => {
         if (status) {
            this.form.patchValue({status: status.status});
         }
      });
   }

   cancel() {
      this.stateService.go('.^');
      this.interruptService.allowNavigation();
    }

   submit() {
      if (this.form.invalid) { return; }
      this.submitting = true;
      const formData = this.form.getRawValue();
      const data: ContactModel = {
         id: this.activityId,
         involvementId: this.involvementId,
         monitoringId: this.sourceId,
         contactDate: formData.contactDate,
         contactType: formData.contactType,
         contactWith: formData.contactWith,
         description: formData.description,
         status: formData.status
      };

      const observable = this.activityId
      ? this.monitoringService.updateContact(data)
      : this.monitoringService.createContact(data);

      observable
      .pipe(finalize(() => this.submitting = false))
      .subscribe(result => {
         if (result) {
            this.submitting = false;
            this.interruptService.allowNavigation();
            this.stateService.go('.^');
         }
      });
   }
}

import { Component, Input, OnInit } from '@angular/core';
import { CreateMessageModel } from '../models/mail.models';
import { MailService } from '../mail.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { SourceModel } from '../../core/models/source.model';
import { map } from 'rxjs/operators';
import { DocumentModel } from '../../documents/models/documents.models';
import { DocumentsService } from '../../documents/documents.service';
import { StateService } from '@uirouter/core';
import { InterruptService } from '../../shared/index';

@Component({
   selector: 'am-create-email',
   templateUrl: './create-email.component.html',
   styleUrls: ['./create-email.component.scss']
})
export class CreateEmailComponent implements OnInit {
   @Input() source: SourceModel;
   form: FormGroup;
   toAddresses: string[];
   ccAddresses: string[];
   bccAddresses: string[];
   subject: string;
   body: string;
   documentsIds: string[] = [];
   submitting = false;

   constructor(
      private stateService: StateService,
      public interrupt: InterruptService,
      private formBuilder: FormBuilder,
      private mailService: MailService,
      private documentService: DocumentsService) {
   }

   ngOnInit() {
      let subject: string;
      if (this.source.sourceNumber) {
         subject = `${this.source.source} ${this.source.sourceNumber}: ${this.source.sourceName}`;
      } else {
         subject = `${this.source.source}: ${this.source.sourceName}`;
      }

      this.form = this.formBuilder.group({
          toAddresses: null,
          ccAddresses: null,
          bccAddresses: null,
          subject: [subject, Validators.required],
          body: [null, Validators.required],
          documentIds: null
      });
   }

   getAddresses = (q: string): Observable<string[]> => {
      return this.mailService.getEmailAddresses(q, this.source).pipe(
         map(results => {
            if (results && results.length === 0) {
               if (this.validateEmail(q)) {
                  return [q];
               } else {
                  return results;
               }
            } else {
               return results;
            }
         })
      );
   }

   getDocuments = (q: string): Observable<DocumentModel[]> => {
      return this.documentService.getDocuments(q, this.source.involvementId, this.source.source, this.source.sourceId).pipe(
            map(results => results.list)
      );
   }

   validateEmail = (email) => {
      const expression = /(?!.*\.{2})^([a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+(\.[a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+)*|"((([ \t]*\r\n)?[ \t]+)?([\x01-\x08\x0b\x0c\x0e-\x1f\x7f\x21\x23-\x5b\x5d-\x7e\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|\\[\x01-\x09\x0b\x0c\x0d-\x7f\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))*(([ \t]*\r\n)?[ \t]+)?")@(([a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.)+([a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.?$/i;
      return expression.test(String(email).toLowerCase());
   }

   handleSubmit() {
      if (this.form.valid) {
         this.submitting = true;

         const email = {
            involvementId: this.source.involvementId,
            source: this.source.source,
            sourceId: this.source.sourceId,
         } as CreateMessageModel;

         Object.assign(email, this.form.getRawValue());

         this.mailService.createMessage(email).subscribe(result => {
            this.submitting = false;
            this.interrupt.allowNavigation();
            this.stateService.go('^.');
         });
      }
   }
}

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ClientCaseListService } from '../../services/client-cases-list.service';
import { GetClientCaseListPayload } from '../../types/client-case-params';

@Component({
   selector: 'app-client-cases-list-toolbar',
   templateUrl: './client-cases-list-toolbar.component.html',
   styleUrls: ['./client-cases-list-toolbar.component.scss']
})
export class ClientCasesListToolbarComponent implements OnInit {
   private _activeOnly: boolean;
   @Input() payload: GetClientCaseListPayload;
   @Input()
   set showCaseName(value: boolean) {
      this.searchText = value ? 'Filter by Case Name or Number' : 'Filter by Case Number'
   }
   @Input()
   get activeOnly(): boolean {
      return this._activeOnly;
   }
   set activeOnly(value: boolean) {
      this._activeOnly = value;
      this.setActiveOnlyText()
   }

   @Output() addClientCase = new EventEmitter();
   @Output() searchChange = new EventEmitter<string>();
   @Output() activeOnlyChange = new EventEmitter<boolean>();
   @Output() toggleChange = new EventEmitter<boolean>();

   toggle: boolean = false;
   activeOnlyText: string;
   searchText: string;

   constructor(
      private service: ClientCaseListService
   ) { }

   ngOnInit(): void {
      this.searchChange.emit(null)
   }

   export = () => {
      this.service.getClientCasesExport(this.payload).subscribe();
   }

   activeOnlyToggle() {
      this.activeOnly = !this.activeOnly;
      this.activeOnlyChange.emit(this.activeOnly);
   }

   setActiveOnlyText() {
      this.activeOnlyText = this.activeOnly ? 'Click to include Inactive Cases' : 'Click to exclude Inactive Cases'
   }

   onToggle() {
      this.toggle = !this.toggle;
      this.toggleChange.emit(this.toggle);
   }
}
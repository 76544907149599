import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UIRouterModule } from '@uirouter/angular';
import { NgxMaskModule } from 'ngx-mask';
import * as components from './components';
import * as directives from './directives';
import * as filters from './filters';
import { MaterialModule } from './material.module';
import * as pipes from './pipes';
import * as services from './services';


@NgModule({
   imports: [
      CommonModule,
      FlexLayoutModule,
      FormsModule,
      MaterialModule,
      NgxMaskModule.forRoot(),
      ReactiveFormsModule,
      UIRouterModule
   ],
   declarations: [
      components.CheckboxListComponent,
      components.ConfirmationDialogComponent,
      components.DataFieldComponent,
      components.DateFilterOverlayComponent,
      components.DateFilterToggleComponent,
      components.DatePickerComponent,
      components.ErrorDialogComponent,
      components.FileSelectorComponent,
      components.FlaggedIconComponent,
      components.MoreDetailPopupComponent,
      components.NavBarComponent,
      components.ProgressCircleComponent,
      components.RadiobuttonGroupComponent,
      components.TextWithIconCellComponent,
      components.TimePickerComponent,
      directives.AutocompleteOffDirective,
      directives.AutofocusDirective,
      directives.AutomonOnlyDirective,
      directives.CdkDetailRowDirective,
      directives.DisableControlDirective,
      directives.DropFileDirective,
      directives.ErrorToastDirective,
      directives.FeatureDirective,
      directives.FormInterruptDirective,
      directives.PermissionsDirective,
      directives.RolesDirective,
      directives.SyncSubmittedDirective,
      filters.CaseListDrawerComponent,
      filters.UserFilterCardComponent,
      filters.UserTypeFilterCardComponent,
      pipes.DateDiffPipe,
      pipes.FileSizePipe,
      pipes.HumanizePipe,
      pipes.NullTextPipe,
      pipes.PhoneNumberPipe,
      pipes.YesNoPipe
   ],
   exports: [
      // Modules
      CommonModule,
      FlexLayoutModule,
      FormsModule,
      MaterialModule,
      NgxMaskModule,
      ReactiveFormsModule,
      UIRouterModule,

      // Directives and Components
      components.CheckboxListComponent,
      components.ConfirmationDialogComponent,
      components.DataFieldComponent,
      components.DateFilterOverlayComponent,
      components.DateFilterToggleComponent,
      components.DatePickerComponent,
      components.ErrorDialogComponent,
      components.FileSelectorComponent,
      components.FlaggedIconComponent,
      components.MoreDetailPopupComponent,
      components.NavBarComponent,
      components.RadiobuttonGroupComponent,
      components.TextWithIconCellComponent,
      components.TimePickerComponent,
      directives.AutocompleteOffDirective,
      directives.AutofocusDirective,
      directives.AutomonOnlyDirective,
      directives.CdkDetailRowDirective,
      directives.DisableControlDirective,
      directives.DropFileDirective,
      directives.ErrorToastDirective,
      directives.FeatureDirective,
      directives.FormInterruptDirective,
      directives.PermissionsDirective,
      directives.RolesDirective,
      directives.SyncSubmittedDirective,
      filters.CaseListDrawerComponent,
      filters.UserFilterCardComponent,
      filters.UserTypeFilterCardComponent,
      pipes.DateDiffPipe,
      pipes.FileSizePipe,
      pipes.HumanizePipe,
      pipes.NullTextPipe,
      pipes.PhoneNumberPipe,
      pipes.YesNoPipe
   ],
   entryComponents: [
      components.CheckboxListComponent,
      components.ConfirmationDialogComponent,
      components.DataFieldComponent,
      components.DateFilterOverlayComponent,
      components.DatePickerComponent,
      components.ErrorDialogComponent,
      components.MoreDetailPopupComponent,
      components.ProgressCircleComponent,
      components.RadiobuttonGroupComponent,
      components.TextWithIconCellComponent,
      filters.CaseListDrawerComponent,
      filters.UserFilterCardComponent,
      filters.UserTypeFilterCardComponent,
   ]
})
export class SharedModule {
   static forRoot(): ModuleWithProviders<SharedModule> {
      return {
         ngModule: SharedModule,
         providers: [
            services.ConfirmationService,
            services.ErrorDialogService,
            services.InterruptService,
            services.LookupService,
            services.MoreDetailPopupService
         ]
      };
   }
}

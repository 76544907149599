import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { MatDatepicker, MatDatepickerInputEvent } from '@angular/material/datepicker';
import * as moment from 'moment';
import { fromEvent, merge } from 'rxjs';

export interface CalendarRangeModel {
   min?: moment.Moment;
   max?: moment.Moment;
}

@Component({
   selector: 'am-date-picker',
   template: `
<mat-form-field>
   <input matInput [matDatepicker]="picker"
                   [matDatepickerFilter]="applyFilter"
                   [min]="minDate"
                   [value]="value"
                   (dateChange)="handleDateChange($event)">
   <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
   <mat-datepicker #picker
                   (opened)="handleOpened()"
                   (closed)="handleClosed()"
                   (monthSelected)="handleMonthSelected($event)"></mat-datepicker>
 </mat-form-field>`,
   styleUrls: ['./date-picker.component.scss']
})
export class DatePickerComponent {
   private _value: moment.Moment;
   private _highlightDates: moment.Moment[];

   @Input() filteredDates: moment.Moment[];
   @Input('highlightDates')
   get highlightDates() { return this._highlightDates; }
   set highlightDates(dates: moment.Moment[]) {
      this._highlightDates = dates;
      if (this._highlightDates) { this.tryHighlightDates(); }
   }

   @Input() minDate?: moment.Moment;

   @Input('value')
   get value() { return this._value; }
   set value(date: moment.Moment) {
      this._value = date;
      this.tryAdjustCalendarRange(this._value);
   }
   @Output() calendarRangeChange = new EventEmitter<CalendarRangeModel>();
   @Output() dateChange = new EventEmitter<moment.Moment>();

   @ViewChild(MatDatepicker, { static: true }) picker: MatDatepicker<moment.Moment>;

   private calendarRange: CalendarRangeModel = {};

   applyFilter = (date: moment.Moment) => {
      if (!this.filteredDates) { return true; }

      return this.filteredDates.filter(d => d.diff(date, 'days') === 0).length > 0;
   }

   handleDateChange(event: MatDatepickerInputEvent<moment.Moment>) {
      const date = event.value;

      if (this.minDate && date.isBefore(this.minDate)) { return; }

      this.dateChange.emit(date);
      this.tryAdjustCalendarRange(date);
   }

   handleOpened() {
      setTimeout(() => {
         const previousMonthButton = document.querySelector(`#${this.picker.id} .mat-calendar-previous-button`);
         const nextMonthButton = document.querySelector(`#${this.picker.id} .mat-calendar-next-button`);

         merge(
            fromEvent(previousMonthButton, 'click'),
            fromEvent(nextMonthButton, 'click')
         ).subscribe((event: any) => {
            const monthsToAdd = event.srcElement.classList.contains('mat-calendar-previous-button') ? -1 : 1;
            this.tryAdjustCalendarRange(this.calendarRange.min.clone().add(monthsToAdd, 'month'));
         });

         if (this.highlightDates) {
            this.tryHighlightDates();
         }
      });
   }

   handleClosed() {
      this.tryAdjustCalendarRange(this.value);
   }

   handleMonthSelected(date: moment.Moment) {
      this.tryAdjustCalendarRange(date);
   }

   private tryHighlightDates() {
      for (const date of this.highlightDates) {
         const el = document.querySelector(`#${this.picker.id} .mat-calendar-body-cell[aria-label="${date.format('LL')}"]`);
         if (el) {
            el.classList.add('am-datepicker-highlight');
         }
      }
   }

   private tryAdjustCalendarRange(date: moment.Moment) {
      let min = date.clone().startOf('month');

      if (this.minDate && (date.isBefore(this.minDate) || min.isBefore(this.minDate))) {
         min = this.minDate.clone();
      }

      if (!min.isSame(this.calendarRange.min)) {
         this.calendarRange.min = min;
         this.calendarRange.max = date.clone().endOf('month');
         this.calendarRangeChange.emit(this.calendarRange);
      }
   }
}

import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { from, merge } from 'rxjs';
import { debounceTime, filter, map, switchMap, tap } from 'rxjs/operators';

import { PreferenceService } from '../../core';
import { CheckListItemModel, GetCheckListParams } from '../models/fiscal.models';
import { FiscalService } from '../fiscal.service';
import { ConfirmationService } from '../../shared';

@Component({
   selector: 'am-check-list',
   templateUrl: 'check-list.component.html',
   styleUrls: ['check-list.component.scss']
})
export class CheckListComponent implements OnInit {
   @ViewChild(MatSort, { static: true }) sort: MatSort;
   @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

   dataSource = new MatTableDataSource<CheckListItemModel>([]);
   loading = true;
   columnsToDisplay = ['receivedDate', 'checkDate', 'checkNumber', 'amount',
      'payorName', 'depositedDate', 'createdBy', 'reviewedBy', 'actions'];
   returnedItemsCount = 0;
   checks: CheckListItemModel[];
   filterForm: FormGroup;
   dateFilter = {};
   amountFilter = {};
   showFilters = false;

   constructor(
      private formBuilder: FormBuilder,
      private fiscalService: FiscalService,
      private preferenceService: PreferenceService,
      private confirmation: ConfirmationService) {
   }

   ngOnInit() {
      this.initialize();
      this.subscribe();
      this.getCheckList();
   }

   toggleFilters() {
      this.showFilters = !this.showFilters;
   }

   getParameters() {
      const order = this.sort.active || 'receivedDate';
      this.dateFilter = this.filterForm.get('dateFilter').value;
      this.amountFilter = this.filterForm.get('amountFilter').value;
      this.paginator.pageSize = this.paginator.pageSize || 25;
      const payorNameFilter = this.filterForm.get('payorNameFilter').value;

      this.savePreferences();

      const params: GetCheckListParams = {
         order: this.sort.direction === 'desc' ? '-' + order : order,
         page: this.paginator.pageIndex + 1,
         pageSize: this.paginator.pageSize,
         ...this.dateFilter,
         ...this.amountFilter,
         payorName: payorNameFilter
      };

      return params;
   }

   getCheckList() {
      this.dataSource.data = [];

      this.loading = true;
      const params = this.getParameters();

      return this.fiscalService.getCheckList(params)
         .subscribe(data => {
            this.loading = false;
            this.returnedItemsCount = data.count;
            this.dataSource.data = data.list;
         });
   }

   export = () => {
      const params = this.getParameters();
      this.fiscalService.getCheckListDownload(params)
         .subscribe();
   }

   private subscribe() {
      this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);
      merge(this.sort.sortChange, this.paginator.page, this.filterForm.valueChanges)
      .pipe(
         debounceTime(500)
      )
      .subscribe(() => this.getCheckList());
   }

   private initialize() {
      this.loadPreferences();

      this.filterForm = this.formBuilder.group({
         dateFilter: this.dateFilter,
         amountFilter: this.amountFilter,
         payorNameFilter: null
      });
   }

   private loadPreferences(): any {
      const preferences: any = this.preferenceService.getPreference('checkList');
      if (preferences) {
         Object.assign(this.sort, preferences.sortSettings);
         Object.assign(this.paginator, preferences.paginatorSettings);
         Object.assign(this.dateFilter, preferences.dateFilter);
         Object.assign(this.amountFilter, preferences.dateFilter);
      }
   }

   private savePreferences() {
      const preferences = {
         sortSettings: { active: this.sort.active, direction: this.sort.direction },
         paginatorSettings: { pageSize: this.paginator.pageSize },
         dateFilter: this.dateFilter,
         amountFilter: this.amountFilter
      };
      this.preferenceService.setPreference('checkList', preferences);
   }

   showDeleteDialog(check: CheckListItemModel) {
      this.confirmation.confirm('Delete Check?', 'DELETE', 'CANCEL').pipe(
         filter(ok => ok),
         switchMap(() => this.fiscalService.deleteCheck(check.id))
      ).subscribe(() => {
         const index = this.dataSource.data.indexOf(check);
         this.dataSource.data = [
            ...this.dataSource.data.slice(0, Math.max(index, 0)),
            ...this.dataSource.data.slice(index + 1)
         ];
      });
   }

   clearFilters() {
      this.filterForm.patchValue({
         dateFilter: {},
         amountFilter: {},
         payorNameFilter: null
      });
   }
}




















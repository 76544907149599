import * as moment from 'moment';

export function formatIsoDate(text: string) {
   return moment(text, 'YYYY-MM-DD').format('MM/DD/YYYY');
}

export function humanize(text: string) {
   if (!text) return text;
   return text.replace(/([a-z]|[A-Z]+)([A-Z](?!$))/g, '$1 $2');
}

export function pascalToKebabCase(value: string) {
   if (!value) return value;

   return value.replace(/[\w]([A-Z])/g, m => m[0] + '-' + m[1]).toLowerCase();
}

export function timeToMoment(time: string) {
   return moment(time, 'HH:mm:ss');
}

export function titleCase(value: string) {
   if (!value) return value;

   return value.slice(0, 1).toUpperCase() + value.slice(1);
}

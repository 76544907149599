import { Injectable } from '@angular/core';
import { HttpService } from '../core/index';
import { StatuteOfLimitationsModel } from './models/involvements.models';

@Injectable()
export class InvolvementsService {
    static injectName = 'involvementsService';
    private baseUrl = 'api/involvements';

    constructor(private http: HttpService) { }

    getStatutesOfLimitations(involvementId: string) {
        const url = `${this.baseUrl}/${involvementId}/statutesOfLimitations`;
        return this.http.get<StatuteOfLimitationsModel[]>(url);
    }

    createStatuteOfLimitations(involvementId: string, data: StatuteOfLimitationsModel) {
        const url = `${this.baseUrl}/${involvementId}/statuteOfLimitations`;
        return this.http.post<{ id: string }>(url, data);
    }

    updateStatuteOfLimitations(involvementId: string, data: StatuteOfLimitationsModel) {
        const url = `${this.baseUrl}/${involvementId}/statuteOfLimitations/${data.id}`;
        return this.http.put<{ id: string }>(url, data);
    }

    deleteStatuteOfLimitations(involvementId: string, solId: string) {
        const url = `${this.baseUrl}/${involvementId}/statuteOfLimitations/${solId}`;
        return this.http.delete(url);
    }

    setHasStatutesOfLimitations(involvementId: string, data: any) {
        const url = `${this.baseUrl}/${involvementId}/statuteOfLimitations/hasStatutesOfLimitations`;
        return this.http.post(url, data);
    }
}

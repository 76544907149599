import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Sort } from '@angular/material/sort';
import { ProjectsListComponent } from '@app/projects/list/components';
import { ProjectsCreateDialog } from '@app/projects/list/dialogs';
import { ProjectStateStore } from '@app/projects/list/state';
import { Project, ProjectListFilters } from '@app/projects/list/types';
import { ConfirmationService } from '@app/shared';
import { Pagination } from '@app/types/core';
import { UserModel } from '@app/users';
import { UIRouter } from '@uirouter/core';
import { filter } from 'rxjs/operators';


@Component({
   selector: 'app-projects-list.view',
   templateUrl: './projects-list.view.html',
   styleUrls: ['./projects-list.view.scss'],
   providers: [ProjectStateStore]
})
export class ProjectsListView implements OnInit {
   @Input() rolesFilter: string[] = [];
   @Input() usersFilter: UserModel[] = [];
   @Input() initializeSelected: boolean = false;
   @ViewChild(ProjectsListComponent, { static: true }) list: ProjectsListComponent;
   vm$ = this.store.vm$;

   constructor(
      private readonly dialog: MatDialog,
      private router: UIRouter,
      private readonly store: ProjectStateStore,
      private readonly confirmation: ConfirmationService
   ) { }

   ngOnInit(): void {
      const {
         current: { name: viewId }
      } = this.router.globals;

      this.store.init(viewId);

      if (this.initializeSelected) {
         this.onActiveOnlyChange(true)
         this.onSelectedChange(this.usersFilter, this.rolesFilter);
         this.onClearFilters({ page: 1, pageSize: 25 });
      };
   }

   showProjectCreateDialog() {
      ProjectsCreateDialog.open(this.dialog, {
         id: null,
         name: null,
         description: null,
         openDate: null,
         closeDate: null,
         fundingProgramId: null
      }).afterClosed().pipe(
         filter(value => !!value)
      ).subscribe(project => this.store.addProject(project));
   }

   showDeleteDialog(project: Project) {
      const { name } = project;
      this.confirmation.confirm(`Delete Project (${name})?`, 'DELETE', 'CANCEL').pipe(
         filter(ok => ok)
      ).subscribe(() => this.store.deleteProject(project.id));
   }

   onFiltersChange(filters: ProjectListFilters, page: Pagination): void {
      const pageSize = page?.pageSize ?? 25;
      this.store.patchViewState({ filters, pagination: { page: 1, pageSize } });
   }

   onSelectedChange(users: UserModel[], roles: string[]) {
      this.store.patchViewState({ selected: { users, roles } });
   }

   onSearchChange(search: string) {
      this.store.patchViewState({ search });
   }

   onActiveOnlyChange(activeOnly: boolean) {
      this.store.patchViewState({ activeOnly });
   }

   onPageChange(pagination: Pagination) {
      this.store.patchViewState({ pagination });
   }

   onSortChange(sort: Sort) {
      this.store.patchViewState({ sort });
   }

   onToggleChange(value: boolean): void {
      this.store.patchState({ toggle: value });
   }

   onClearFilters(page: Pagination) {
      const filters: ProjectListFilters =
      {
         name: null,
         description: null,
         opened: null,
         closed: null,
         defaultFunder: null
      }
      this.onFiltersChange(filters, page)
   }
}
import { createAction } from '@ngrx/store';

export enum ActionTypes {
   ['IncrementHttpRequestCount'] = '[App] Increment HTTP Request Count',
   ['DecrementHttpRequestCount'] = '[App] Decrement HTTP Request Count'
}

export const incrementHttpRequestCount = createAction(
   ActionTypes.IncrementHttpRequestCount
);

export const decrementHttpRequestCount = createAction(
   ActionTypes.DecrementHttpRequestCount
);

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'humanize' })
export class HumanizePipe implements PipeTransform {
   transform(value: string) {
      return this.capitalizeTokens(this.tokenize(value)).join(' ');
   }

   private tokenize = (text: string) => {
      text = text.replace(/([A-Z])|([\-|\_])/g, (_, $1) => ' ' + ($1 || ''));
      return text.replace(/\s\s+/g, ' ').trim().toLowerCase().split(' ');
   }

   private capitalizeTokens = (tokens: string[]) => {
      const result = [];
      for (const token of tokens) {
         result.push(token.charAt(0).toUpperCase() + token.substr(1));
      }
      return result;
   }
}

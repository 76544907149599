import { Component, EventEmitter, Input, Output } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { Pagination, TypedSort } from '@app/types/core';
import { PopoverFilterOption } from '@app/shared/filters';
import { ClientCase } from '../../types/client-case';
import { ClientCasesListFilters } from '../../types/client-cases-filters';

@Component({
   selector: 'app-client-cases-list',
   templateUrl: './client-cases-list.component.html',
   styleUrls: ['./client-cases-list.component.scss']
})
export class ClientCasesListComponent {
   @Input() clientCases: ClientCase[];
   @Input() legalTeamOptions: PopoverFilterOption[];
   @Input() funderOptions: PopoverFilterOption[];
   @Input() statusOptions: PopoverFilterOption[];
   @Input() filters: ClientCasesListFilters;
   @Input() loading = false;
   @Input() count: number;
   @Input() page: Pagination;
   @Input() sort: TypedSort<ClientCase>;
   @Input()
   set showCaseName(value: boolean) {
      this.setDisplayColumns(['number', 'client', 'caseName', 'primaryStaff', 'funder', 'status', 'opened', 'closed', 'more'], value);
   }

   @Output() createClientCase = new EventEmitter<ClientCase>();
   @Output() deleteClientCase = new EventEmitter<ClientCase>();
   @Output() filtersChange = new EventEmitter<ClientCasesListFilters>();
   @Output() sortChange = new EventEmitter<TypedSort<ClientCase>>();
   @Output() pageChange = new EventEmitter<Pagination>();
   @Output() clearFilters = new EventEmitter();

   public columnsToDisplay: string[];

   constructor() { }

   private setDisplayColumns(columns: string[], value: boolean) {
      if (!value) columns.splice(columns.indexOf('caseName'), 1);
      this.columnsToDisplay = columns;
   }

   onFilter(value: Partial<ClientCasesListFilters>): void {
      this.filtersChange.emit({ ...this.filters, ...value });
   }

   onPage({ pageIndex, pageSize }: PageEvent): void {
      this.pageChange.emit({ page: pageIndex + 1, pageSize });
   }

   onSort(sort: Sort): void {
      this.sortChange.emit(sort as TypedSort<ClientCase>);
   }
}
import { Injectable } from '@angular/core';
import { HttpService, PagedList } from '../core/index';
import { GetTimeslipListParams, TimeslipListItemModel, TimeslipsPerPayPeriodModel, TimeslipsPerWorkWeekModel } from './models/time.models';
import { Observable } from 'rxjs';
import { HttpResponse } from '@angular/common/http';
import { tap } from 'rxjs/operators';
import { getFileNameFromResponseContentDisposition, saveFile } from '../shared';

@Injectable()
export class TimeRepositoryService {
   static injectName = 'timeRepository';
   private baseUrl = 'api/time';

   constructor(private http: HttpService) { }

   getTimeslipsPerPayPeriod(userIds: string[]) {
      const url = `${this.baseUrl}/slipsPerPayPeriod`;
      return this.http.get<TimeslipsPerPayPeriodModel[]>(url, { params: { userIds: userIds } });
   }

   getTimeslipsPerWorkWeek(userIds: string[]) {
      const url = `${this.baseUrl}/slipsPerWorkWeek`;
      return this.http.get<TimeslipsPerWorkWeekModel[]>(url, { params: { userIds: userIds } });
   }

   getTimeslipList(data: GetTimeslipListParams): Observable<PagedList<TimeslipListItemModel>> {
      const url = `${this.baseUrl}/list`;
      return this.http.post<PagedList<TimeslipListItemModel>>(url, data);
   }

   getTimeslipListDownload(data: GetTimeslipListParams) {
      const url = `${this.baseUrl}/list/csv`;
      return this.http.post<HttpResponse<Blob>>(url, data, { responseType: 'blob', observe: 'response' }).pipe(
         tap(res => {
            const fileName = getFileNameFromResponseContentDisposition(res);
            saveFile(res.body, fileName);
         })
      );
   }

   deleteTimeslip(id: string) {
      return this.http.delete(`${this.baseUrl}/slips/${id}`);
   }
}

import { EligibilityFormViewComponent } from '@app/funding/eligibility/form';
import { Transition } from '@uirouter/core';
import { SourceModel } from '../core/models/source.model';
import { CreateEmailComponent } from '../mail/create-email/create-email.component';
import { IAndRsRepositoryService } from './i-and-rs.repository';
import { IAndRListView } from './list/view/i-and-rs-list.view';

export const states = [
   {
      name: 'app.informationAndReferrals.emails.create',
      component: CreateEmailComponent,
      url: '/new/email',
      resolve: {
         source: resolveSource
      },
      data: {
         title: 'New Message',
         amPermissions: ['mail.send']
      }
   },
   {
      name: 'app.iAndRList',
      component: IAndRListView,
      url: '/i-and-rs-list',
      params: {
         rolesFilter: null,
         usersFilter: null,
         usePreferences: null,
         initializeSelected: null
      },
      resolve: {
         rolesFilter: rolesFilterResolver,
         usersFilter: usersFilterResolver,
         usePreferences: usePreferencesResolver,
         initializeSelected: initializeSelectedResolver
      }, data: {
         title: 'List',
         amPermissions: ['i-and-r.view']
      }
   },
   {
      name: 'app.iAndRListDashboard',
      component: IAndRListView,
      url: '/i-and-rs-list-dashboard',
      params: {
         rolesFilter: null,
         usersFilter: null,
         usePreferences: null,
         initializeSelected: null
      },
      resolve: {
         rolesFilter: rolesFilterResolver,
         usersFilter: usersFilterResolver,
         usePreferences: usePreferencesResolver,
         initializeSelected: initializeSelectedResolver
      }, data: {
         title: 'List',
         amPermissions: ['i-and-r.view']
      }
   },
   {
      name: 'app.informationAndReferrals.funding.eligibility.eligibilityFormView',
      url: '/form',
      params: {
         involvementId: null,
         eligibility: {},
         eligibilityList: [],
         readOnly: false,
         soureType: 'I&R'
      },
      component: EligibilityFormViewComponent,
      resolve: {
         involvementId: involvementIdResolver,
         eligibility: eligibilityResolver,
         eligibilityList: eligibilityListResolver,
         readOnly: readOnlyResolver,
         sourceType: sourceTypeResolver
      },
      data: {
         title: 'Eligibility Form',
         amPermissions: ['funding.create']
      }
   },
];

rolesFilterResolver.$inject = ['$transition$'];
export function rolesFilterResolver($transition$) {
   const { rolesFilter } = $transition$.params();
   return rolesFilter;
}

usersFilterResolver.$inject = ['$transition$'];
export function usersFilterResolver($transition$) {
   const { usersFilter } = $transition$.params();
   return usersFilter;
}

initializeSelectedResolver.$inject = ['$transition$'];
export function initializeSelectedResolver($transition$) {
   const { initializeSelected } = $transition$.params();
   return initializeSelected;
}

usePreferencesResolver.$inject = ['$transition$'];
export function usePreferencesResolver($transition$) {
   const { usePreferences } = $transition$.params();
   return usePreferences;
}

eligibilityResolver.$inject = ['$transition$'];
export function eligibilityResolver($transition$) {
   const { eligibility } = $transition$.params();
   return eligibility;
}

eligibilityListResolver.$inject = ['$transition$'];
export function eligibilityListResolver($transition$) {
   const { eligibilityList } = $transition$.params();
   return eligibilityList;
}

involvementIdResolver.$inject = ['$transition$'];
export function involvementIdResolver($transition$) {
   const { involvementId } = $transition$.params();
   return involvementId;
}

sourceTypeResolver.$inject = ['$transition$'];
export function sourceTypeResolver() {
   const sourceType = 'I&R';
   return sourceType;
}

readOnlyResolver.$inject = ['$transition$'];
export function readOnlyResolver($transition$) {
   const { readOnly } = $transition$.params();
   return readOnly;
}

resolveSource.$inject = ['$transition$'];
export function resolveSource(transition: Transition) {
   const { informationAndReferralId } = transition.params();
   const repository: IAndRsRepositoryService = transition.injector().get(IAndRsRepositoryService);
   return repository.getBasicInfo(informationAndReferralId).toPromise().then(
      ir => {
         return {
            source: 'I&R',
            sourceId: ir.id,
            involvementId: ir.involvementId,
            sourceNumber: ir.idNumber,
            sourceName: ir.requesterFullName
         } as SourceModel;
      }
   );
}

import { InjectionToken } from '@angular/core';
import * as moment from 'moment';

export const DATE_FILTER_DATA = new InjectionToken<number | string>('DATE_FILTER_DATA');

export const DATE_FILTER_PRESETS: DateFilterPreset[] = [
   { delta: 0, label: 'today' },
   { delta: 7, label: 'next 7 days' },
   { delta: 30, label: 'next 30 days' }
];

export interface DateFilterPreset {
   delta: number;
   label: string;
}

export interface DateFilterChangeEvent {
   preset?: DateFilterPreset;
   value: moment.Moment;
}

import { Injectable } from '@angular/core';
import { forkJoin, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { ENV } from '../../env.config';
import { HttpService } from '../services/http.service';
import * as models from '../models';

@Injectable()
export class IdentityService {
   accessToken: string;
   permissions: models.PermissionModel[];
   user: models.UserModel;

   constructor(private http: HttpService) {
   }

   init(accessToken: string, accessTokenObservable: Observable<string>) {
      this.accessToken = accessToken;
      accessTokenObservable.subscribe(token => {
         this.accessToken = token;
      });
      return this.getPermissions();
   }

   public getCurrentUser() {
      const url = `${ENV.API_URL}identity-management/users/current`;
      const options = this.getDefaultOptions();
      return this.http.get<models.UserModel>(url, options).pipe(tap(user => this.user = user));
   }

   private getPermissions() {
      const url = `${ENV.API_URL}identity-management/permissions`;
      const options = this.getDefaultOptions();
      return this.http.get<models.PermissionModel[]>(url, options)
         .pipe(tap(permissions => this.permissions = permissions));
   }

   private getDefaultOptions() {
      return {
         headers: {
            Authorization: 'Bearer ' + this.accessToken
         }
      };
   }
}

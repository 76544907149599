import { createFeatureSelector, createSelector } from '@ngrx/store';
import { State } from './state';

export const selectConfigFeatureState = createFeatureSelector<State>('config');

export const selectError = createSelector(
   selectConfigFeatureState,
   state => state.error
);

export const selectIsLoading = createSelector(
   selectConfigFeatureState,
   state => state.isLoading
);

export const selectConfig = createSelector(
   selectConfigFeatureState,
   state => state.config
);
import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';

import { DateFilterComponent } from './date/date-filter.component';
import { LegalTeamFilterComponent } from './legal-team/legal-team-filter.component';
import { ActiveFilterComponent } from './active/active-filter.component';
import { SupervisedUserFilterComponent } from './supervised-users/supervised-users-filter.component';
import { UserFilterComponent } from './users/users-filter.component';
import { AmountFilterComponent } from './amount/amount-filter.component';
import { LookupFilterComponent } from './lookup/lookup-filter.component';
import { TimeslipUserFilterComponent } from './timeslip-users/timeslip-users-filter.component';

@NgModule({
   imports: [
      SharedModule
   ],
   declarations: [
      DateFilterComponent,
      LegalTeamFilterComponent,
      ActiveFilterComponent,
      SupervisedUserFilterComponent,
      UserFilterComponent,
      AmountFilterComponent,
      LookupFilterComponent,
      TimeslipUserFilterComponent
   ],
   exports: [
      DateFilterComponent,
      LegalTeamFilterComponent,
      ActiveFilterComponent,
      SupervisedUserFilterComponent,
      UserFilterComponent,
      AmountFilterComponent,
      LookupFilterComponent,
      TimeslipUserFilterComponent
   ],
   providers: [
   ]
})
export class FiltersModule {
}

import { Component, Input } from '@angular/core';

@Component({
   selector: 'am-nav-bar',
   template: `
     <a [uiSref]="sref" [uiParams]="params">
       <mat-icon>arrow_back</mat-icon>
       {{title}}
     </a>
   `,
   styles: [`
   :host {
      background-color: #537798;
      display: flex;
      height: 30px;
      line-height: 30px;
      max-height: 30px;
      padding: 0 24px;
   }
   a {
      display: flex;
      color: white;
      align-items: center;
   }
   a .mat-icon {
      margin-right: 8px;
   }
   a:hover, a:focus {
      color: white !important;
   }`]
})
export class NavBarComponent {
   @Input() title: string;
   @Input() sref: string;
   @Input() params: any;
}

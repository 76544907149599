import { NgModule } from '@angular/core';
import { SharedModule } from '../../shared/shared.module';
import { EligibilityFormViewComponent, EligibilityFormComponent, EligibilityQuestionsComponent, EligibilityFormToolbarComponent } from './form';
import { EligibilityListViewComponent, EligibilityListComponent, EligibilityToolbarComponent } from './list';
import { PopoverFilterModule } from '@app/shared/filters';

@NgModule({
   imports: [
      SharedModule,
      PopoverFilterModule
   ],
   declarations: [
      EligibilityFormViewComponent,
      EligibilityFormComponent,
      EligibilityQuestionsComponent,
      EligibilityListViewComponent,
      EligibilityListComponent,
      EligibilityToolbarComponent,
      EligibilityFormToolbarComponent
   ],
   exports: [
      EligibilityFormViewComponent,
      EligibilityFormComponent,
      EligibilityQuestionsComponent,
      EligibilityListViewComponent,
      EligibilityListComponent,
      EligibilityToolbarComponent,
      EligibilityFormToolbarComponent
   ]
})
export class EligibilityModule { }
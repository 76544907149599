import { Component, Input } from '@angular/core';

@Component({
   selector: 'am-flagged-icon',
   templateUrl: './flagged-icon.component.html',
   styleUrls: ['./flagged-icon.component.scss']
})
export class FlaggedIconComponent {
   @Input() text: string;

   constructor() { }
}

import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';

import { TrainingRequestsRepositoryService } from './training-requests.repository';
import { UIRouterUpgradeModule } from '@uirouter/angular-hybrid';
import { states } from './training-requests.states';
import { TrainingRequestListComponent } from './training-request-list/training-request-list.component';
import { FiltersModule } from '../filters/filters.module';

@NgModule({
   imports: [
      UIRouterUpgradeModule.forChild({ states }),
      SharedModule,
      FiltersModule
   ],
   declarations: [
      TrainingRequestListComponent
   ],
   exports: [
   ],
   providers: [
      TrainingRequestsRepositoryService
   ]
})
export class TrainingRequestsModule {
}

import { Component, EventEmitter, forwardRef, Input, OnChanges, OnInit } from '@angular/core';
import { ControlContainer, ControlValueAccessor, FormControl, FormGroupDirective, NG_VALIDATORS, NG_VALUE_ACCESSOR, Validator, Validators } from '@angular/forms';
import { ConfigurationService } from '../../../configuration/configuration.service';
import { map, switchMap, switchMapTo } from 'rxjs/operators';
import { Observable, of } from 'rxjs';

@Component({
   selector: 'am-checkbox-list',
   templateUrl: './checkbox-list.component.html',
   styleUrls: ['./checkbox-list.component.scss'],
   providers: [{
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CheckboxListComponent),
      multi: true
   }, {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => CheckboxListComponent),
      multi: true
   }],
   viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }]
})
export class CheckboxListComponent implements OnInit, OnChanges, ControlValueAccessor, Validator {
   @Input() label: string;
   @Input() stringValues: string[];
   @Input() lookupType: string;
   @Input() required: boolean;

   values: string[] = [];
   emitChange: any;
   selectedValues: string[] = [];

   constructor(
      public form: FormGroupDirective,
      private configurationService: ConfigurationService) { }

   ngOnInit() {
      let getValuesObservable: Observable<string[]>;
      if (this.lookupType) {
         getValuesObservable = this.configurationService.getLookupType(this.lookupType)
            .pipe(
               switchMap(lookupType => of(lookupType.lookups)),
               switchMap(lookups => of(lookups.map(lookup => lookup.value))
               ));
      } else {
         getValuesObservable = of(this.stringValues);
      }

      getValuesObservable.subscribe(values => {
         this.values = values;
      });
   }

   ngOnChanges(changes) {

   }

   validate() {
      if (this.required && this.selectedValues.length === 0) {
         return {required: true};
      } else {
         return null;
      }
   }

   valueChanged(value: string) {
      const valueIndex = this.valueIndex(value);
      if (valueIndex > -1) {
         this.selectedValues.splice(valueIndex, 1);
      } else {
         this.selectedValues.push(value);
      }

      this.callEmitter();
   }

   valueIndex(value: string) {
      return this.selectedValues.findIndex(sv => sv === value);
   }

   valueChecked(value: string) {
      return this.valueIndex(value) > -1;
   }

   callEmitter() {
      if (!this.emitChange) {
         return;
      }

      this.emitChange(this.selectedValues);
   }

   registerOnChange(fn) {
      this.emitChange = fn;
   }

   registerOnTouched() { }

   writeValue(selectedValues: string[]) {
      if (selectedValues) {
         this.selectedValues = selectedValues;
      }
   }
}






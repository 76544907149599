import { Component, Input, OnInit } from '@angular/core';
import { StatuteOfLimitationsModel } from '../models/involvements.models';
import { MatTableDataSource } from '@angular/material/table';
import { InvolvementsService } from '../involvements.service';

@Component({
   selector: 'am-statute-of-limitations-summary',
   templateUrl: './statute-of-limitations-summary.component.html',
   styleUrls: ['./statute-of-limitations-summary.component.scss']
})
export class StatuteOfLimitationsSummaryComponent implements OnInit {
   dataSource = new MatTableDataSource<StatuteOfLimitationsModel>();
   columnsToDisplay: string[] = ['date', 'description'];

   @Input() involvementId: string;

   disabled = false;

   constructor(
      private involvementsService: InvolvementsService
   ) {
   }

   ngOnInit() {
      this.load();
   }

   private load() {
      this.involvementsService.getStatutesOfLimitations(this.involvementId).subscribe(
         result => {
            this.disabled = result.some(x => x.disabled);
            this.dataSource.data = result;
         });
   }
}
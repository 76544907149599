import { Injectable } from '@angular/core';
import { of } from 'rxjs';

import { ConfirmationService } from './confirmation.service';

@Injectable()
export class InterruptService {
   constructor(private confirmationService: ConfirmationService) { }

   private interrupt: boolean;

   isNavigationPrevented = () => this.interrupt;
   allowNavigation = () => (this.interrupt = false);
   preventNavigation = () => (this.interrupt = true);

   confirmNavigation() {
      return this.isNavigationPrevented()
         ? this.confirmationService.confirm('Continue without saving changes?', 'CONTINUE', 'CANCEL')
         : of(true);
   }
}

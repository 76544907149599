import { Component, OnInit } from '@angular/core';

@Component({
   selector: 'am-monitoring-details',
   templateUrl: './monitoring-details.component.html',
   styleUrls: ['./monitoring-details.component.scss']
})
export class MonitoringDetailsComponent implements OnInit {

   ngOnInit() {

   }

}

import { Directive, ElementRef, EventEmitter, Injector, Input, Output } from '@angular/core';
import { UpgradeComponent } from '@angular/upgrade/static';

@Directive({
   // tslint:disable-next-line:directive-selector
   selector: 'am-view-grievance-button'
})
export class ViewGrievanceButtonUpgradedDirective extends UpgradeComponent {
   @Input() grievanceId: any;

   constructor(elementRef: ElementRef, injector: Injector) {
      super('amViewGrievanceButton', elementRef, injector);
   }
}

import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LookupModel } from '@app/configuration/models/configuration.models';
import { StateService } from '@uirouter/core';
import * as moment from 'moment';
import { finalize } from 'rxjs/operators';
import { InterruptService } from '../../shared';
import { IAndRsRepositoryService } from '../i-and-rs.repository';
import { CovidVaccineModel } from '../models/i-and-rs.models';

@Component({
   selector: 'am-information-and-referral-covid-vaccine',
   templateUrl: 'covid-vaccine.component.html',
   styleUrls: ['covid-vaccine.component.scss']
})
export class IAndRCovidVaccineComponent implements OnInit {
   @Input() involvementId: string;
   @Input() sourceId: string;
   @Input() activityId: string;

   get questionsForm() { return this.form.get('questions') as FormArray; }

   form: FormGroup;
   date: moment.Moment;
   today: moment.Moment;
   maxDate: moment.Moment;
   submitting: boolean;
   statuses: LookupModel[];
   covidVaccine: CovidVaccineModel;

   constructor(
      private formBuilder: FormBuilder,
      private IAndRsService: IAndRsRepositoryService,
      private interruptService: InterruptService,
      private stateService: StateService
   ) { }

   ngOnInit() {
      this.today = moment().startOf('day');
      this.maxDate = this.today;
      this.form = this.formBuilder.group({
         activityDate: this.today,
         pWDHelped: [undefined],
         description: [undefined, Validators.required],
         status: undefined
      });
      if (this.activityId) {
         this.getCovidVaccine();
      }
   }

   private getCovidVaccine() {
      this.IAndRsService.getCovidVaccine(this.activityId).subscribe(covidVaccine => {
         this.form.patchValue({
            activityDate: moment(covidVaccine.activityDate),
            pWDHelped: covidVaccine.pwdHelped,
            description: covidVaccine.description
         });
      });
   }

   cancel() {
      this.stateService.go('.^');
      this.interruptService.allowNavigation();
   }

   submit() {
      if (this.form.invalid) { return; }
      this.submitting = true;
      const formData = this.form.getRawValue();
      const data: CovidVaccineModel = {
         activityId: this.activityId,
         involvementId: this.involvementId,
         caseId: this.sourceId,
         activityDate: formData.activityDate,
         pWDHelped: formData.pWDHelped,
         description: formData.description
      };

      const observable = this.activityId
      ? this.IAndRsService.updateCovidVaccine(data)
      : this.IAndRsService.createCovidVaccine(data);

      observable
      .pipe(finalize(() => this.submitting = false))
      .subscribe(result => {
         if (result) {
            this.submitting = false;
            this.interruptService.allowNavigation();
            this.stateService.go('.^');
         }
      });
   }
}
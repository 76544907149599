import { SelectionModel } from '@angular/cdk/collections';
import { Component, ElementRef, EventEmitter, forwardRef, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ControlValueAccessor, FormBuilder, FormControl, FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatAutocomplete, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent, MatChipList } from '@angular/material/chips';
import { Observable, of } from 'rxjs';
import { debounceTime, startWith, switchMap } from 'rxjs/operators';

import { highlight } from '../../shared/shared.helpers';
import { UserModel } from '../../users/models/user.model';
import { UserService } from '../../users';


@Component({
   selector: 'am-timeslip-users-filter',
   templateUrl: './timeslip-users-filter.component.html',
   styleUrls: ['./timeslip-users-filter.component.scss'],
   providers: [{
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TimeslipUserFilterComponent),
      multi: true
   }]
})

export class TimeslipUserFilterComponent implements OnInit, ControlValueAccessor {
   filteredUsers: Observable<UserModel[]>;
   formControl = new FormControl();
   highlight = highlight;
   selection = new SelectionModel<UserModel>(true, []);
   callback: any;
   initialized = false;

   @ViewChild('input', { static: true }) input: ElementRef<HTMLInputElement>;
   @ViewChild(MatAutocomplete, { static: true }) auto: MatAutocomplete;
   @ViewChild(MatChipList, { static: true }) chipList: MatChipList;

   @Output() changed = new EventEmitter<UserModel[]>();

   constructor(
      private userService: UserService) { }

   ngOnInit() {
      this.filteredUsers = this.formControl.valueChanges.pipe(
         startWith(''),
         debounceTime(500),
         switchMap(q => q ? this.getTimeslipUsers(q) : of([])));

      this.selection.changed.subscribe(result => {
         this.changed.emit(result.source.selected);
      });
   }

   registerOnChange(fn) {
      this.callback = fn;
   }

   registerOnTouched() { }


   private callbackOrInitialize() {
      if (this.initialized) {
         this.callCallback();
      } else {
         this.initialized = true;
      }
   }

   writeValue(userIds: string[]) {
      this.selection.clear();

      if (userIds && userIds.length > 0) {
         this.userService.getUsersById(userIds).subscribe((users) => {
            this.selection.select(...users);
            this.callbackOrInitialize();
         });
       } else {
          this.callbackOrInitialize();
       }
}

   onChipInputTokenEnd(event: MatChipInputEvent) {
      if (!this.auto.isOpen) {
         event.input.value = '';
         this.formControl.setValue(undefined);
      }
   }

   selected(event: MatAutocompleteSelectedEvent) {
      this.selection.select(event.option.value);
      this.input.nativeElement.value = '';
      this.formControl.setValue(undefined);
      this.callCallback();
   }

   deselected(user: UserModel) {
      this.selection.deselect(user);
      this.callCallback();
   }

   callCallback() {
      const userIds = this.selection.selected.map(u => u.id);
      this.callback(userIds);
   }

   getTimeslipUsers = (q: string): Observable<UserModel[]> => {
      return this.userService.getTimeslipUsers(q);
   }
}

import { Injectable } from "@angular/core";
import { Sort } from "@angular/material/sort";
import { ConfigurationService } from "@app/configuration/configuration.service";
import { LookupModel } from "@app/configuration/models/configuration.models";
import { PagedList } from "@app/core";
import { RootStoreState, ViewStateComponentStore, ViewStateComponentStoreState } from "@app/root-store";
import { SystemicCaseListService } from '@app/systemic-cases/list/services/systemic-cases-list.service';
import { SystemicCase } from '@app/systemic-cases/list/types/systemic-case';
import { SystemicCasesListFilters } from '@app/systemic-cases/list/types/systemic-cases-filters';
import { Pagination, Selected } from "@app/types/core";
import { PopoverFilterOption } from '@app/shared/filters';
import { tapResponse } from '@ngrx/component-store';
import { Store } from "@ngrx/store";
import { forkJoin, Observable } from "rxjs";
import { concatMap, switchMap, tap, withLatestFrom } from "rxjs/operators";
import { SystemicCaseFilterOptions } from "../types/systemic-case-filter-options";
import { GetSystemicCaseListPayload } from "../types/systemic-case-params";

export interface SystemicCaseListState extends ViewStateComponentStoreState {
    systemicCases: SystemicCase[];
    payload: GetSystemicCaseListPayload;
    options: SystemicCaseFilterOptions;
    userRoles: LookupModel[];
    userRolesResponses: Response[];
    count: number;
    loading: boolean;
    toggle: boolean;
}

export interface SystemicCaseListViewState {
    filters: SystemicCasesListFilters;
    selected: Selected;
    search: string;
    activeOnly: boolean;
    pagination: Pagination;
    sort: Sort;
}

const initialState: SystemicCaseListState = {
    systemicCases: [],
    payload: {
        pagination: null,
        sort: null,
        filters: {},
        selected: {},
        search: null
    },
    options: {
        legalTeams: null,
        fundingPrograms: null,
        caseStatuses: null
    },
    userRoles: [],
    userRolesResponses: [],
    count: 0,
    loading: false,
    toggle: false,
    viewId: null
}

const initialViewState: SystemicCaseListViewState = {
    filters: {
        number: null,
        caseName: null,
        primaryStaff: null,
        legalTeam: null,
        funder: null,
        status: null,
        opened: null,
        closed: null
    },
    selected: {
        users: [],
        roles: []
    },
    search: null,
    activeOnly: true,
    pagination: { page: 1, pageSize: 25 },
    sort: { active: 'opened', direction: 'desc' }
};

@Injectable()
export class SystemicCaseStateStore extends ViewStateComponentStore<SystemicCaseListState, SystemicCaseListViewState> {

    constructor(
        rootStore: Store<RootStoreState.State>,
        private service: SystemicCaseListService,
        private configurationService: ConfigurationService) {
        super(rootStore, initialViewState);
    }

    init(viewId: string): void {
        this.initCore({
            ...initialState,
            viewId
        });
        this.patchState({ loading: true });
        this.service.getFilterOptions().pipe(
            tapResponse(options =>
                this.patchState({ options, loading: false }), error => console.log(error))).subscribe();
        forkJoin([
            this.service.getFilterOptions(),
            this.configurationService.getLookupType('involvement-user-role')]).subscribe(
                ([options, types]) => {
                    this.patchState({ options, userRoles: types.lookups, loading: false }), error => console.log(error);
                })
    }

    readonly legalTeamOptions$: Observable<PopoverFilterOption[]> = this.select(
        this.state$,
        ({ options }) => options.legalTeams?.map(value => ({ value, text: value }))
    );

    readonly funderOptions$: Observable<PopoverFilterOption[]> = this.select(
        this.state$,
        ({ options }) => options.fundingPrograms?.map(value => ({ value, text: value }))
    );

    readonly statusOptions$: Observable<PopoverFilterOption[]> = this.select(
        this.state$,
        ({ options }) => options.caseStatuses?.map(value => ({ value, text: value }))
    );

    readonly vm$ = this.select(
        this.legalTeamOptions$,
        this.funderOptions$,
        this.statusOptions$,
        this.state$,
        this.viewState$,
        (legalTeamOptions, funderOptions, statusOptions, { systemicCases, payload, userRoles, count, loading, toggle }, { filters, selected, activeOnly, pagination, sort }) => (
            { legalTeamOptions, funderOptions, statusOptions, systemicCases, payload, userRoles, count, loading, toggle, filters, selected, activeOnly, pagination, sort })
    )

    readonly updateCases = this.updater((state, { list, count }: PagedList<SystemicCase>): SystemicCaseListState => ({
        ...state,
        systemicCases: list,
        count,
        loading: false
    }));

    readonly reload = this.effect((origin$: Observable<GetSystemicCaseListPayload>) => origin$.pipe(
        tap(payload => this.patchState({ payload, loading: true })),
        switchMap(payload => this.service.getSystemicCasesList(payload).pipe(
            tapResponse(({ count, page, pageSize, list }) => this.updateCases({ list, page, pageSize, count }), err => console.log(err)),
        ))
    ))(this.viewState$);

    readonly addSystemicCase = this.updater((state, systemicCase: SystemicCase) => ({
        ...state,
        systemicCases: state.systemicCases.filter(c => c.involvementId !== systemicCase.involvementId).concat(systemicCase)
    }));

    readonly deleteSystemicCase = this.effect((origin$: Observable<string>) => origin$.pipe(
        tap(() => this.patchState({ loading: true })),
        concatMap(id => this.service.deleteSystemicCase(id)),
        withLatestFrom(this.viewState$),
        concatMap(([_, payload]) => this.service.getSystemicCasesList(payload)),
        tapResponse(({ count, page, pageSize, list }) => this.updateCases({ list, page, pageSize, count }), err => console.log(err)),
    ));
}
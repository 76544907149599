import { Component, OnInit } from '@angular/core';
import { Form, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import * as moment from 'moment';
import { CreateMonitoringModel } from '../models/monitoring.models';
import { StateService } from '@uirouter/core';
import { MonitoringRepositoryService } from '../monitoring.repository';
import { finalize } from 'rxjs/operators';

@Component({
   selector: 'am-create-monitoring',
   templateUrl: 'create-monitoring.component.html',
   styleUrls: ['create-monitoring.component.scss']
})
export class CreateMonitoringComponent implements OnInit {
   form: FormGroup;
   today: moment.Moment;
   maxDate: moment.Moment;
   submitting: boolean;

   constructor(
      private formBuilder: FormBuilder,
      private monitoringService: MonitoringRepositoryService,
      private stateService: StateService) {
   }

   ngOnInit() {
      this.today = moment().startOf('day');
      this.maxDate = this.today;
      this.form = this.formBuilder.group({
         openDate: this.today
      });
   }

   cancel() {
      this.stateService.go('app.monitoringList');
   }

   submit() {
      this.submitting = true;
      const data = this.form.getRawValue() as CreateMonitoringModel;
      this.monitoringService
         .createMonitoring(data).pipe(
            finalize(() => this.submitting = false))
         .subscribe(result => {
            if (result) {
               this.stateService.go('app.monitoring', {monitoringId: result.id});
            }
         });
   }

}

import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { ConfirmationDialogComponent } from '../components/confirmation-dialog/confirmation-dialog.component';

export interface IConfirmationOptions {
   message: string;
   ok?: string;
   cancel?: string;
   title?: string;
}

@Injectable()
export class ConfirmationService {
   constructor(private dialog: MatDialog) { }

   confirm(messageText: string, okText: string = 'OK', cancelText: string = 'CANCEL', title?: string): Observable<boolean> {
      const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
         data: {
            messageText,
            okText,
            cancelText,
            title
         },
         panelClass: 'confirmation-dialog',
         disableClose: true,
         closeOnNavigation: true
      });

      return dialogRef.afterClosed();
   }

   show(options: IConfirmationOptions) {
      return this.confirm(
         options.message,
         options.ok,
         options.cancel,
         options.title
      ).pipe(
         tap(result => {
            if (!result) throw new Error('reject');
         })
      ).toPromise();
   }
}

import { NgModule } from '@angular/core';
import { SharedModule } from '@app/shared/shared.module';
import { TenancyEmailComponent } from '@app/tenancy/email/components';
import { EmailService } from '@app/tenancy/email/services';

@NgModule({
   imports: [
      SharedModule
   ],
   declarations: [
      TenancyEmailComponent
   ],
   exports: [
      TenancyEmailComponent
   ],
   providers: [
      EmailService
   ]
})
export class TenancyModule {

}
import { NgModule, Optional, SkipSelf } from '@angular/core';
import { UIRouterUpgradeModule } from '@uirouter/angular-hybrid';

import { throwIfAlreadyLoaded } from '../core/guards/module-import.guard';
import { SharedModule } from '../shared/shared.module';
import { CaseloadService } from './caseload.service';
import { RoleService } from './role.service';
import { UserService } from './user.service';
import { states } from './users.states';

@NgModule({
   imports: [
      SharedModule,
      UIRouterUpgradeModule.forChild({ states })
   ],
   providers: [
      CaseloadService,
      RoleService,
      UserService
   ]
})
export class UsersModule {
   constructor(@Optional() @SkipSelf() parentModule: UsersModule) {
      throwIfAlreadyLoaded(parentModule, 'UsersModule');
   }
}

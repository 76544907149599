import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';

import { InvolvementsService } from './involvements.service';
import { UIRouterUpgradeModule } from '@uirouter/angular-hybrid';
import { StatuteOfLimitationsListComponent } from './statute-of-limitations/statute-of-limitations-list.component';
import { StatuteOfLimitationsDialogComponent } from './statute-of-limitations/statute-of-limitations-dialog.component';
import { InvolvementsDialogService } from './involvements-dialog.service';
import { StatuteOfLimitationsSummaryComponent } from './statute-of-limitations/statute-of-limitations-summary.component';
import { InvolvementEntityListingUpgradeDirective } from './entities/entity-listing-upgrade.component';
import { InvolvementEntitiesListComponent } from './entities/list/involvement-entities-list.component';
import { HasStatutesOfLimitationsDialogComponent } from './statute-of-limitations/has-statutes-of-limitations-dialog.component';


@NgModule({
   imports: [
      SharedModule
   ],
   declarations: [
      HasStatutesOfLimitationsDialogComponent,
      StatuteOfLimitationsListComponent,
      StatuteOfLimitationsDialogComponent,
      StatuteOfLimitationsSummaryComponent,
      InvolvementEntityListingUpgradeDirective,
      InvolvementEntitiesListComponent
   ],
   exports: [
      InvolvementEntitiesListComponent
   ],
   entryComponents: [
      HasStatutesOfLimitationsDialogComponent,
      StatuteOfLimitationsDialogComponent,
      StatuteOfLimitationsSummaryComponent
   ],
   providers: [
      InvolvementsService,
      InvolvementsDialogService
   ]
})
export class InvolvementsModule {
}

import { Injectable } from '@angular/core';
import { HttpService } from '@app/core/index';
import { Email } from '@app/tenancy/email/types';

@Injectable({
   providedIn: 'root'
})
export class EmailService {
   static injectName = 'emailService';
   private baseUrl = 'api/tenancy/email';

   constructor(private http: HttpService) { }

   getEmailIntegration(): Observable<Email> {
      const url = `${this.baseUrl}/integration`;
      return this.http.get<Email>(url);
   }

   updateEmailIntegration(data: Email) {
      const url = `${this.baseUrl}/integration`;
      return this.http.post<Email>(url, data);
   }
}
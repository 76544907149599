import {
   AfterViewInit,
   Component,
   ComponentFactoryResolver,
   ComponentRef,
   Inject,
   ViewChild,
   ViewContainerRef
} from '@angular/core';

import { MoreDetailPopupData } from '../../services/more-detail-popup.service';
import { MoreDetailPopupRef } from './more-detail-popup-ref';
import { MORE_DETAIL_POPUP_DATA } from './more-detail-popup.tokens';

@Component({
   selector: 'am-more-detail-popup',
   templateUrl: './more-detail-popup.component.html',
   styleUrls: ['./more-detail-popup.component.scss']
})
export class MoreDetailPopupComponent implements AfterViewInit {
   @ViewChild('componentContent', { static: true, read: ViewContainerRef }) contentPort: ViewContainerRef;
   componentRef: ComponentRef<any>;

   constructor(
      private componentFactoryResolver: ComponentFactoryResolver,
      private dialogRef: MoreDetailPopupRef,
      @Inject(MORE_DETAIL_POPUP_DATA) public data: MoreDetailPopupData) {
   }

   ngAfterViewInit() {
      this.populateComponent();
   }

   populateComponent() {
      const componentFactory = this.componentFactoryResolver.resolveComponentFactory(this.data.componentRef);

      this.componentRef = this.contentPort.createComponent(componentFactory);
      this.componentRef.instance.data = this.data.componentData;
   }

   closeDialog() {
      this.dialogRef.close();
   }
}

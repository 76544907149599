import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({ name: 'dateDiff' })
export class DateDiffPipe implements PipeTransform {
   transform(dateValue: Date, unitType: moment.unitOfTime.Diff): number {
      const newValue = moment().diff(dateValue, unitType);
      return newValue;
   }
}

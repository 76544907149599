import { SelectionModel } from '@angular/cdk/collections';
import { Component, ElementRef, EventEmitter, forwardRef, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatAutocomplete, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent, MatChipList } from '@angular/material/chips';
import { Observable, of } from 'rxjs';
import { debounceTime, map, startWith, switchMap, tap } from 'rxjs/operators';

import { highlight } from '../../../shared/shared.helpers';
import { UserService } from '../../../users';
import { UserModel } from '../../../users/models/user.model';


@Component({
   selector: 'am-users-filter-card',
   templateUrl: './users-filter-card.component.html',
   styleUrls: ['./users-filter-card.component.scss'],
   providers: [{
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => UserFilterCardComponent),
      multi: true
   }]
})

export class UserFilterCardComponent implements OnInit, ControlValueAccessor {
   @Input() users: UserModel[] = [];
   @Output() changed = new EventEmitter<UserModel[]>();
   @ViewChild('input', { static: true }) input: ElementRef<HTMLInputElement>;
   @ViewChild(MatAutocomplete, { static: true }) auto: MatAutocomplete;
   @ViewChild(MatChipList, { static: true }) chipList: MatChipList;

   selection: SelectionModel<UserModel>;
   filteredUsers: Observable<UserModel[]>;
   formControl: FormControlTyped<string>;
   highlight = highlight;
   initialized = false;

   constructor(
      private userService: UserService) { }

   ngOnInit() {
      this.selection = new SelectionModel<UserModel>(true, []);
      this.selection.select(...this.users || []);
      this.formControl = new FormControl() as FormControlTyped<string>;

      this.filteredUsers = this.formControl.valueChanges.pipe(
         startWith(''),
         debounceTime(500),
         switchMap(q => q ? this.getUsers(q) : of([])));
   }

   registerOnChange(fn) {
      this.selection.changed.asObservable().pipe(
         map(({ source }) => source.hasValue() ? source.selected : null),
         tap(value => this.changed.emit(value))
      ).subscribe(fn);
   }

   registerOnTouched() { }

   writeValue(value: any[]): void {
      if (value.length === 0 || value == null) this.selection.clear();
   }

   onChipInputTokenEnd(event: MatChipInputEvent) {
      if (!this.auto.isOpen) {
         event.input.value = '';
         this.formControl.setValue(undefined);
      }
   }

   selected(event: MatAutocompleteSelectedEvent) {
      this.selection.select(event.option.value);
      this.input.nativeElement.value = '';
      this.formControl.setValue(undefined);
   }

   deselected(user: UserModel) {
      this.selection.deselect(user);
   }

   getUsers = (q: string): Observable<UserModel[]> => {
      return this.userService.getUsers(q);
   }
}
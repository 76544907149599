import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LookupModel } from '@app/configuration/models/configuration.models';
import { Eligibility } from '@app/funding/eligibility/types';
import { StateService } from '@uirouter/core';
import * as moment from 'moment';
import { EligibilityFormService } from '../../services';
import { Program, Question, Response } from '../../types';
import { ConfigurationService } from '@app/configuration/configuration.service';

@Component({
   selector: 'app-eligibility-form',
   templateUrl: './eligibility-form.component.html',
   styleUrls: ['./eligibility-form.component.scss']
})
export class EligibilityFormComponent implements OnInit {
   @Input() involvementId: string;
   @Input() eligibility: Eligibility;
   @Input() fundingProgram: string;
   @Input() parentFundingProgram: string;
   @Input() eligibilityPrograms: Program[] = [];
   @Input() eligibilityQuestions: Question[] = [];
   @Input() eligibilityQuestionSets: Question[][] = [];
   @Input() parentEligibilityQuestions: Question[] = [];
   @Input() parentEligibilityQuestionSets: Question[][] = [];
   @Input() readOnly: boolean = false;
   @Input() loading: boolean;
   @Input() sourceType: string;
   @Input() canBypass: boolean;
   @Output() fundingProgramChange = new EventEmitter<string>();

   @Input()
   get responses() {
      return this._responses;
   }

   set responses(value: Response[]) {
      this._responses = value;

      if (value) {
         var questions = value?.reduce((x, r) => ({ ...x, [r.questionId]: r.response }), {})
         this.form.get('questions').reset(questions || {});
         this.fundingProgramChange.emit(this.form.get('fundingProgramName').value);
      }
   }

   private _responses: Response[] = []

   form: FormGroup;
   eligibilityId: string;
   eligibilityQuestionsGroup: FormGroup;
   eligibilityQuestionSetsGroup: FormGroup;
   statuses: LookupModel[];
   today: moment.Moment;
   maxDate: moment.Moment;
   submitting: boolean;
   bypassed: boolean;

   constructor(
      private readonly formBuilder: FormBuilder,
      private readonly service: EligibilityFormService,
      private stateService: StateService,
      private configurationService: ConfigurationService) {
   }

   ngOnInit() {
      this.today = moment().startOf('day');
      this.maxDate = this.today;
      this.eligibilityId = this.eligibility.id || null;
      this.setupForm();

      if (this.readOnly) this.form.disable();

      this.configurationService.getConfigurationParameter('I&R.ForceBypassEligibility').subscribe(c => {
         if(!c) {
            this.canBypass = false;
         }
         else {
            this.canBypass = (c === 'true');
         }
         if(this.sourceType == "I&R") {
            this.bypassed = this.canBypass;
         }
      })
   }

   private setupForm() {
      const { fundingProgramName = null, eligibilityDate = null } = this.eligibility ?? {};
      this.form = this.formBuilder.group({
         eligibilityDate: [eligibilityDate ? moment(eligibilityDate) : this.today, Validators.required],
         fundingProgramName: [fundingProgramName, Validators.required],
         questions: [{}]
      });
   }

   onFundingProgramChange(): void {
      const formData = this.form.getRawValue();
      this.fundingProgram = formData.fundingProgramName;
      this.fundingProgramChange.emit(formData.fundingProgramName);
      this.form.get('questions').reset({});
   }

   isNotEligible(): boolean {
      return false;
   }

   canBypassEligibility(): boolean {
      return this.sourceType == "I&R" && this.canBypass && (this.eligibilityQuestions?.length > 0 || this.eligibilityQuestionSets?.length > 0);
   }

   onBypassEligibility(): void {
      this.bypassed = !this.bypassed;
   }

   determineEligibility(questions: Question[], questionSets: Question[][]): boolean {
      var valid: boolean = true;

      //Required Questions
      if (questions.length != questions.filter(q => q.response).length)
         valid = false

      //Question Sets
      questionSets.forEach(qs => {
         if (qs.filter(q => q.response).length < 1)
            valid = false
      })

      //SubQuestions
      questions.forEach(q => {
         if (q.subQuestions.filter(q => q.response).length < q.subQuestionCount && q.response)
            valid = false
      })

      //QuestionSet SubQuestions 
      questionSets.forEach(qs => {
         qs.forEach(q => {
            if (q.subQuestions.filter(q => q.response).length < q.subQuestionCount && q.response)
               valid = false
         })
      })

      return valid;
   }

   isEligible(): boolean {
      return this.determineEligibility(this.eligibilityQuestions, this.eligibilityQuestionSets) && this.determineEligibility(this.parentEligibilityQuestions, this.parentEligibilityQuestionSets)
   }

   questionAnswers(questions: Question[], questionSet: Question[][]) {
      const formData = this.form.getRawValue();

      questions.forEach(q => {
         q.response = formData.questions[q.id] || false
         q.subQuestions.forEach(sq => sq.response = formData.questions[sq.id] || false)
      })

      questionSet.forEach(qs => {
         qs.forEach(q => {
            q.response = formData.questions[q.id] || false
            q.subQuestions.forEach(sq => sq.response = formData.questions[sq.id] || false)
         })
      })
   }

   submit() {
      this.submitting = true;
      const formData = this.form.getRawValue();

      this.questionAnswers(this.eligibilityQuestions, this.eligibilityQuestionSets);
      this.questionAnswers(this.parentEligibilityQuestions, this.parentEligibilityQuestionSets);

      const flattenedArray = [].concat(...this.eligibilityQuestionSets, ...this.parentEligibilityQuestionSets);
      const questions = [...this.eligibilityQuestions, ...this.parentEligibilityQuestions, ...flattenedArray]

      let program: Program = this.eligibilityPrograms.find(p => p.name === formData.fundingProgramName);

      const data: Eligibility = {
         ...this.eligibility,
         eligibilityDate: formData.eligibilityDate.format('YYYY-MM-DD'),
         involvementId: this.involvementId,
         fundingProgramName: program.name,
         fundingProgramId: program.fundingProgramId,
         isEligible: this.isEligible() || this.bypassed,
         questions: questions
      };

      const observable = this.eligibilityId
         ? this.service.updateEligibility(data)
         : this.service.createEligibility(data);

      observable.subscribe(() => {
         this.submitting = false;
         this.stateService.go('.^');
      });
   }


}
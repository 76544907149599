import { Component, OnInit } from '@angular/core';

@Component({
   selector: 'am-monitoring-overview',
   templateUrl: './overview.component.html',
   styleUrls: ['./overview.component.scss']
})
export class MonitoringOverviewComponent implements OnInit {

   ngOnInit() {

   }

}

import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { LookupTypeModel } from '@app/configuration/models/configuration.models';
import { StateService } from '@uirouter/core';
import * as moment from 'moment';
import { forkJoin, of } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { ConfigurationService } from '../../configuration/configuration.service';
import { InterruptService } from '../../shared';
import { ClientCasesRepositoryService } from '../client-cases.repository';
import { RepresentationModel, RepresentationOption, RepresentationOutcome } from '../models/client-cases-representation.models';

@Component({
   selector: 'am-client-case-representation',
   templateUrl: 'representation.component.html',
   styleUrls: ['representation.component.scss']
})
export class ClientCaseRepresentationComponent implements OnInit {
   @Input() involvementId: string;
   @Input() sourceId: string;
   @Input() activityId: string;

   get questionsForm() { return this.form.get('questions') as FormArray; }

   form: FormGroup;
   date: moment.Moment;
   maxDate: moment.Moment;
   submitting: boolean;
   outcome: RepresentationOutcome;

   representationTypes: string[] = [];
   options: RepresentationOption[];
   representation: RepresentationModel;

   constructor(
      private formBuilder: FormBuilder,
      private clientCasesService: ClientCasesRepositoryService,
      private configurationService: ConfigurationService,
      private interruptService: InterruptService,
      private stateService: StateService
   ) { }

   ngOnInit() {
      this.date = moment().startOf('day');
      this.maxDate = this.date;
      this.setupForm();

      let getRepresentationCall = of({ outcomes: [] } as RepresentationModel);

      if (this.activityId) {
         getRepresentationCall = this.clientCasesService.getRepresentation(this.activityId);
      }

      forkJoin([getRepresentationCall,
         this.clientCasesService.getRepresentationOptions(),
         this.configurationService.getLookupType('representation-type')]).subscribe(
            ([representation, options, types]) => {
               this.processRepresentationTypes(types);
               this.processRepresentationOptions(options, representation);
               this.createQuestionForms();
               this.form.controls['date'].setValue(representation.date || this.date);
               this.form.controls['representationType'].setValue(representation.representationType);
            })
   }

   private processRepresentationOptions(options: RepresentationOption[], representation: RepresentationModel) {
      options.forEach(o => o.outcome = this.getOutcome(o.id, representation.outcomes));
      this.options = options;
   }

   private createQuestionForms() {
      const representationQuestions = this.options.filter(q => q.optionType === 'RepresentationQuestion');
      representationQuestions.forEach(p => this.questionsForm.push(this.createQuestionForm(p)));
   }

   private setupForm() {
      this.form = this.formBuilder.group({
         date: this.date,
         representationType: [undefined, Validators.required],
         questions: this.formBuilder.array([])
      });
   }

   private processRepresentationTypes(lt: LookupTypeModel) {
      lt.lookups.forEach(l => this.representationTypes.push(l.value));
   }

   getOutcome(optionId: number, outcomes: RepresentationOutcome[]): string {
      const outcome = outcomes.find(outcome => outcome.optionId == optionId);
      return outcome ? outcome.outcome : null;
   }

   private createQuestionForm(option = {} as RepresentationOption) {
      const questionControl = new FormControl(option, [Validators.required]);

      const group = this.formBuilder.group({
         question: questionControl
      });

      return group;
   }

   cancel() {
      this.stateService.go('.^');
      this.interruptService.allowNavigation();
   }

   submit() {
      if (this.form.invalid) { return; }
      this.submitting = true;
      const formData = this.form.getRawValue();

      let outcomes: RepresentationOutcome[] = [];

      formData.questions.forEach(q => {
         const { id, outcome, sequence } = q.question;
         var questionOutcome: RepresentationOutcome = {
            activityId: this.activityId,
            optionId: id,
            outcome: outcome,
            sequence: sequence
         }
         outcomes.push(questionOutcome);
      });

      const data: RepresentationModel = {
         activityId: this.activityId,
         involvementId: this.involvementId,
         caseId: this.sourceId,
         date: formData.date,
         representationType: formData.representationType,
         outcomes
      };

      const observable = this.activityId
         ? this.clientCasesService.updateRepresentation(data)
         : this.clientCasesService.createRepresentation(data);

      observable
         .pipe(finalize(() => this.submitting = false))
         .subscribe(result => {
            if (result) {
               this.submitting = false;
               this.interruptService.allowNavigation();
               this.stateService.go('.^');
            }
         });
   }
}
import { PAATIntakeInfoComponent } from './paat/intake/paat-intake-info.component';

export const states = [
   {
      name: 'app.clientCases.funding.enrollment.paatIntakeInfoUnused',
      url: '/paat-intake-info/{enrollmentId}',
      component: PAATIntakeInfoComponent,
      resolve: {
         enrollmentId: enrollmentIdResolver
      }
   }
];

enrollmentIdResolver.$inject = ['$transition$'];
export function enrollmentIdResolver($transition$) {
   const { enrollmentId } = $transition$.params();
   return enrollmentId;
}

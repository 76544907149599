import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpService, PagedList } from '@app/core/index';
import { Monitoring } from '@app/monitoring/list/types/monitoring';
import { getFileNameFromResponseContentDisposition, saveFile } from '@app/shared';
import { CaseListFilterOptions } from '@app/shared/types/case-list-filter-options';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { MonitoringCreate } from '../types/monitoring-create';
import { GetMonitoringListPayload } from '../types/monitoring-params';

@Injectable({
   providedIn: 'root'
})
export class MonitoringListService {
   static injectName = 'MonitoringService';
   private baseUrl = 'api/monitoring';

   constructor(private http: HttpService) { }

   getMonitoringList(criteria: GetMonitoringListPayload): Observable<PagedList<Monitoring>> {
      const url = `${this.baseUrl}/list/filtered`;
      return this.http.post<PagedList<Monitoring>>(url, criteria);
   }

   getMonitoringExport(criteria: GetMonitoringListPayload) {
      const url = `${this.baseUrl}/list/export`;
      return this.http.post<HttpResponse<Blob>>(url, criteria, { responseType: 'blob', observe: 'response' }).pipe(
         tap(res => {
            const fileName = getFileNameFromResponseContentDisposition(res);
            saveFile(res.body, fileName);
         })
      );
   }

   createMonitoring(data: MonitoringCreate): Observable<Monitoring> {
      const url = `${this.baseUrl}`;
      return this.http.post<Monitoring>(url, data);
   }

   deleteMonitoring(id: string) {
      const url = `${this.baseUrl}/${id}`;
      return this.http.delete(url);
   }

   getFilterOptions(): Observable<CaseListFilterOptions> {
      const url = `api/configuration/list/options`;
      return this.http.post<CaseListFilterOptions>(url, { CaseType: 'monitoring' });
   }
}
import { ComponentStore } from '@ngrx/component-store';
import { Store } from '@ngrx/store';
import { State } from '../state';
import * as Actions from './actions';
import * as Selectors from './selectors';

export interface ViewStateComponentStoreState {
   viewId: string;
}

export class ViewStateComponentStore<S extends ViewStateComponentStoreState, V> extends ComponentStore<S> {
   readonly viewId$ = this.select(s => s.viewId);
   readonly viewState$: Observable<V> = this.select(
      this.viewId$,
      this.rootStore.select(Selectors.selectViewState),
      (viewId, viewState) => ({ ...this.defaultViewState, ...viewState[viewId] })
   );

   constructor(
      protected rootStore: Store<State>,
      protected defaultViewState: V) {
      super();
   }

   patchViewState(value: Partial<V>): void {
      const viewId = this.get(s => s.viewId);
      this.rootStore.dispatch(Actions.patchViewState({ key: viewId, value: { ...value } }));
   }

   protected initCore(initialState: S): void {
      this.setState(initialState);
   }

   protected logError = err => console.log(err);
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BooleanFilterComponent } from './boolean-filter/boolean-filter.component';
import { DateFilterComponent } from './date-filter/date-filter.component';
import { FilterLabelComponent } from './filter-label/filter-label.component';
import { MultiSelectFilterComponent } from './multi-select-filter/multi-select-filter.component';
import { NumberFilterComponent } from './number-filter/number-filter.component';
import { PopoverFilterContentDirective } from './popover-filter-content/popover-filter-content.directive';
import { PopoverFilterComponent } from './popover-filter/popover-filter.component';
import { TextFilterComponent } from './text-filter/text-filter.component';

@NgModule({
   declarations: [
      BooleanFilterComponent,
      DateFilterComponent,
      FilterLabelComponent,
      MultiSelectFilterComponent,
      NumberFilterComponent,
      PopoverFilterComponent,
      PopoverFilterContentDirective,
      TextFilterComponent
   ],
   imports: [
      CommonModule,
      FormsModule,
      ReactiveFormsModule,
      FlexLayoutModule,
      MatButtonModule,
      MatCheckboxModule,
      MatDatepickerModule,
      MatListModule,
      MatIconModule,
      MatInputModule,
      MatMomentDateModule,
      MatSelectModule,
      MatTooltipModule
   ],
   exports: [
      BooleanFilterComponent,
      DateFilterComponent,
      FilterLabelComponent,
      MultiSelectFilterComponent,
      NumberFilterComponent,
      PopoverFilterComponent,
      PopoverFilterContentDirective,
      TextFilterComponent
   ]
})
export class PopoverFilterModule { }

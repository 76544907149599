import { Transition, TransitionService } from '@uirouter/core';
import { AuthService, PreferenceService } from '../services';

export function initialRedirectHandler(transitionService: TransitionService) {
   const redirectCriteria = {
      to: 'app'
   };

   const redirect = (transition: Transition) => {
      const $state = transition.router.stateService;
      const auth: AuthService = transition.injector().get(AuthService);
      const preferences: PreferenceService = transition.injector().get(PreferenceService);

      return auth.authorize().then(() => {
         return $state.target('app.homepage');
      });
   };

   transitionService.onBefore(redirectCriteria, redirect, { priority: 100 });
}

import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { delay, map, startWith } from 'rxjs/operators';

@Component({
   selector: 'am-time-picker',
   templateUrl: 'time-picker.component.html',
   styleUrls: ['time-picker.component.scss']
})
export class TimePickerComponent implements OnInit {
   @Input() control: AbstractControl;
   @Input() placeholder = 'Time';
   @Input() required = false;
   // Optional input to allow styling of mat-form-field (ex: [appearance]='outline')
   @Input() appearance = '';
   // Optional flag to highlight the form field when changes have been made
   @Input() highlightAsChanged = false;
   // Optional flag to set time picker to appear as a dense form field
   @Input() dense = false;
   times: string[] = [];
   filteredTimes: Observable<string[]>;

   constructor() { }

   ngOnInit() {
      const currentTime = new Date().getHours();
      let displayHour: number;
      let hour: number = currentTime;
      let meridiem: string;

      for (let i = 0; i < 24; i++) {
         displayHour = hour;

         if (hour >= 24) { hour = 0; }
         if (hour > 12) { displayHour = hour - 12; }
         if (hour === 0) { displayHour = 12; }
         meridiem = hour < 12 ? 'am' : 'pm';

         this.times.push(displayHour + ':00' + meridiem);
         this.times.push(displayHour + ':30' + meridiem);
         hour++;
      }

      this.filteredTimes = this.control
         .valueChanges
         .pipe(
            map(time => time ? this.filterTimes(time) : this.times.slice()),
            startWith(this.times.slice()),
            delay(0)
         );
   }

   private filterTimes(filter: string) {
      return this.times.filter(time => time.startsWith(filter));
   }
}

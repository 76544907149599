import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Eligibility } from '@app/funding/eligibility/types';
import { EligibilityListFilters } from '@app/funding/eligibility/list/types';
import { PopoverFilterOption } from '@app/shared/filters';
import { TypedSort } from '@app/types/core';


@Component({
   selector: 'app-eligibility-list',
   templateUrl: './eligibility-list.component.html',
   styleUrls: ['./eligibility-list.component.scss']
})
export class EligibilityListComponent implements OnInit {
   @Input() involvementId: string;
   @Input() fundingProgramNameOptions: PopoverFilterOption[];
   @Input() modifiedByOptions: PopoverFilterOption[];
   @Input() filters: EligibilityListFilters;
   @Input() loading = false;
   @Input() sort: TypedSort<Eligibility>;
   @Output() editEligibility = new EventEmitter<Eligibility>();
   @Output() deleteEligibility = new EventEmitter<Eligibility>();
   @Output() showEligibility = new EventEmitter<Eligibility>();
   @Output() filtersChange = new EventEmitter<EligibilityListFilters>();
   @Output() sortChange = new EventEmitter<TypedSort<Eligibility>>();

   @Input()
   get sourceType() {
      return this._sourceType;
   }

   set sourceType(value: string) {
      this._sourceType = value;
      this.sref = `app.${this.sourceType}.funding.eligibility.eligibilityFormView`;
   }

   private _sourceType: string;
   sref: string;

   constructor() { }

   ngOnInit(): void {
   }

   @ViewChild(MatPaginator) set matPaginator(paginator: MatPaginator) {
      this.dataSource.paginator = paginator;
   }
   @ViewChild(MatSort) set matSort(sort: MatSort) {
      this.dataSource.sort = sort;
   }

   readonly dataSource = new MatTableDataSource([]);
   columnsToDisplay: string[] = ['eligibilityDate', 'fundingProgramName', 'isEligible', 'modifiedBy', 'more'];

   @Input()
   get eligibilityList(): Eligibility[] {
      return this.dataSource.data;
   }
   set eligibilityList(value: Eligibility[]) {
      this.dataSource.data = value;
   }

   emitEligibilityReadDialog(eligibility: Eligibility) {
      this.showEligibility.emit(eligibility);
   }

   emitEligibilityWriteDialog(eligibility: Eligibility) {
      this.editEligibility.emit(eligibility);
   }

   emitDeleteDialog(eligibility: Eligibility) {
      this.deleteEligibility.emit(eligibility);
   }

   onFilterChange(value: Partial<EligibilityListFilters>): void {
      this.filtersChange.emit({ ...this.filters, ...value });
   }

   onSort(sort: Sort): void {
      this.sortChange.emit(sort as TypedSort<Eligibility>);
   }
}
import { enableProdMode, NgZone } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { UpgradeModule } from '@angular/upgrade/static';
import { activitiesDowngrader } from '@app/activities/activities.downgrader';
import { TenancyDowngrader } from '@app/tenancy/tenancy.downgrader';
import { UIRouter, UrlService } from '@uirouter/core';
import { appModule as app } from './app-js/app.module';
import { AppModule } from './app/app.module';
import { clientCaseDowngrader } from './app/client-cases/client-cases.downgrader';
import { coreDowngrader } from './app/core/core.downgrader';
import { fundingDowngrader } from './app/funding/funding.downgrader';
import { iAndRsDowngrader } from './app/i-and-rs/i-and-rs.downgrader';
import { involvementsDowngrader } from './app/involvements/involvements.downgrader';
import { monitoringDowngrader } from './app/monitoring/monitoring.downgrader';
import { searchDowngrader } from './app/search/search.downgrader';
import { sharedDowngrader } from './app/shared/shared.downgrader';
import { environment } from './environments/environment';


if (environment.production) {
   enableProdMode();
}

app.config(['$urlServiceProvider', ($urlService: UrlService) => $urlService.deferIntercept()]);

platformBrowserDynamic()
   .bootstrapModule(AppModule)
   .then(platformRef => {
      coreDowngrader(app);
      sharedDowngrader(app);
      activitiesDowngrader(app);
      involvementsDowngrader(app);
      monitoringDowngrader(app);
      clientCaseDowngrader(app);
      iAndRsDowngrader(app);
      fundingDowngrader(app);
      searchDowngrader(app);
      TenancyDowngrader(app);
      const upgrade = platformRef.injector.get(UpgradeModule) as UpgradeModule;
      upgrade.bootstrap(document.body, [app.name], { strictDi: true });

      const { urlService } = platformRef.injector.get(UIRouter);

      // Instruct UIRouter to listen to URL changes
      function startUIRouter() {
         urlService.listen();
         urlService.sync();
      }

      platformRef.injector.get<NgZone>(NgZone).run(startUIRouter);
   });

import { NgHybridStateDeclaration } from '@uirouter/angular-hybrid';
import { HomePageComponent } from './home-page/home-page.component';

export const homepageState: NgHybridStateDeclaration = {
   name: 'app.homepage',
   url: '/homepage',
   component: HomePageComponent,
   data: { title: 'Home Page'}
};

export const states = [
   homepageState
];

import { createSelector } from '@ngrx/store';
import { ConfigStoreSelectors } from './config-store';

export const selectError = createSelector(
   ConfigStoreSelectors.selectError,
   error => error
);

export const selectIsLoading = createSelector(
   ConfigStoreSelectors.selectIsLoading,
   isLoading => isLoading
);
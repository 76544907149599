import { downgradeComponent, downgradeInjectable } from '@angular/upgrade/static';
import { MonitoringContactComponent } from './contact/contact.component';
import { MonitoringRepositoryService } from './monitoring.repository';
import { MonitoringMeetingComponent } from './meeting/meeting.component';
import { MonitoringIssueComponent } from './issue/issue.component';

export function monitoringDowngrader(app: any) {
   app.factory(MonitoringRepositoryService.injectName, downgradeInjectable(MonitoringRepositoryService));
   app.directive('amMonitoringContact', downgradeComponent({
      component: MonitoringContactComponent,
      inputs: ['involvementId', 'sourceId']
   }));
   app.directive('amMonitoringMeeting', downgradeComponent({
      component: MonitoringMeetingComponent,
      inputs: ['involvementId', 'sourceId']
   }));
   app.directive('amMonitoringIssue', downgradeComponent({
      component: MonitoringIssueComponent,
      inputs: ['involvementId', 'sourceId']
   }));
}
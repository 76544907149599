import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { HttpService, PagedList } from '../core/index';
import { getFileNameFromResponseContentDisposition, saveFile } from '../shared';
import { GetTrainingRequestListParams, TrainingRequestListItemModel, TrainingRequestModel } from './models/training-requests.models';

@Injectable()
export class TrainingRequestsRepositoryService {
   static injectName = 'trainingRequestService';
   private baseUrl = 'api/trainings/request';

   constructor(private http: HttpService) { }

   getTrainingRequests(trainingId: string) {
      const url = `${this.baseUrl}/${trainingId}`;
      return this.http.get<TrainingRequestModel>(url);
   }


   getTrainingRequestList(data: GetTrainingRequestListParams): Observable<PagedList<TrainingRequestListItemModel>> {
      const url = `${this.baseUrl}/list`;
      return this.http.post<PagedList<TrainingRequestListItemModel>>(url, data);
   }

   getTrainingRequestListDownload(data: GetTrainingRequestListParams) {
      const url = `${this.baseUrl}/list/csv`;
      return this.http.post<HttpResponse<Blob>>(url, data, { responseType: 'blob', observe: 'response' }).pipe(
         tap(res => {
            const fileName = getFileNameFromResponseContentDisposition(res);
            saveFile(res.body, fileName);
         })
      );
   }

   deleteTrainingRequest(id: string) {
      return this.http.delete(`${this.baseUrl}/${id}`);
   }
}

import { Component, EventEmitter, forwardRef, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { ControlContainer, ControlValueAccessor, FormBuilder, FormControl, FormGroup, FormGroupDirective, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';
import { Moment } from 'moment';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';

export interface DateFilterModel {
   fromDate: Moment;
   toDate: Moment;
}

@Component({
   selector: 'am-date-filter',
   templateUrl: './date-filter.component.html',
   styleUrls: ['./date-filter.component.scss'],
   providers: [{
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DateFilterComponent),
      multi: true
   }],
   // viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }]
})
export class DateFilterComponent implements OnInit, ControlValueAccessor {
   @Input() fromDate: Moment;
   @Input() toDate: Moment;
   filterForm: FormGroup;
   // control: FormControl;

   valueChanged: EventEmitter<DateFilterModel> = new EventEmitter<DateFilterModel>();

   constructor(
      private formBuilder: FormBuilder) {
   }

   ngOnInit() {
     // this.control = new FormControl();
      this.filterForm = this.formBuilder.group({
         fromDate: this.fromDate,
         toDate: this.toDate,
      });
   }

   registerOnChange(fn) {
      this.valueChanged.subscribe(result => {
         fn(result); });
   }

   registerOnTouched() { }

   writeValue(value: any) {
      this.filterForm.patchValue({
         fromDate: value ? value.fromDate : null,
         toDate:  value ? value.toDate : null
      });
   }

   fromDateChanged($event: MatDatepickerInputEvent<Moment>) {
      this.valueChanged.emit(this.filterForm.getRawValue());
   }

   toDateChanged($event: MatDatepickerInputEvent<Moment>) {
      this.valueChanged.emit(this.filterForm.getRawValue());   }

}

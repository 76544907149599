import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { finalize, share } from 'rxjs/operators';

import { HttpProgressService } from '../services/http-progress.service';

@Injectable()
export class HttpProgressInterceptor implements HttpInterceptor {
   constructor(private progress: HttpProgressService) {

   }

   intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
      this.progress.increment();
      return next.handle(req).pipe(
         share(),
         finalize(() => this.progress.decrement())
      );
   }
}

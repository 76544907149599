import { Injectable } from '@angular/core';
import { HttpService, PagedList } from '../core/index';
import { CheckListItemModel, GetCheckListParams } from './models/fiscal.models';
import { Observable } from 'rxjs';
import { HttpResponse } from '@angular/common/http';
import { tap } from 'rxjs/operators';
import { getFileNameFromResponseContentDisposition, saveFile } from '../shared';

@Injectable()
export class FiscalService {
   static injectName = 'fiscalService';
   private baseUrl = 'api/fiscal';

   constructor(private http: HttpService) { }

   getCheckList(data: GetCheckListParams): Observable<PagedList<CheckListItemModel>> {
      const url = `${this.baseUrl}/checks/list`;
      return this.http.post<PagedList<CheckListItemModel>>(url, data);
   }

   getCheckListDownload(data: GetCheckListParams) {
      const url = `${this.baseUrl}/checks/list/csv`;
      return this.http.post<HttpResponse<Blob>>(url, data, { responseType: 'blob', observe: 'response' }).pipe(
         tap(res => {
            const fileName = getFileNameFromResponseContentDisposition(res);
            saveFile(res.body, fileName);
         })
      );
   }

   deleteCheck(id: string) {
      return this.http.delete(`${this.baseUrl}/checks/${id}`);
   }
}

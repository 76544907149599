import { createReducer, on } from '@ngrx/store';
import * as AppActions from './actions';
import { initialValues, State } from './state';

export const reducer = createReducer<State>(
   initialValues,
   on(AppActions.incrementHttpRequestCount, (state): State => ({
      ...state,
      activeHttpRequestCount: state.activeHttpRequestCount + 1
   })),
   on(AppActions.decrementHttpRequestCount, (state): State => ({
      ...state,
      activeHttpRequestCount: Math.max(0, state.activeHttpRequestCount - 1)
   }))
);

import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { from, merge } from 'rxjs';
import { debounceTime, filter, map, switchMap, tap } from 'rxjs/operators';

import { PreferenceService } from '../../core';
import { GetProjectListParams, ProjectListItemModel } from '../models/projects.models';
import { ProjectsRepositoryService } from '../projects.repository';
import { ConfirmationService } from '../../shared';

@Component({
   selector: 'am-project-list',
   templateUrl: 'project-list.component.html',
   styleUrls: ['project-list.component.scss']
})
export class ProjectListComponent implements OnInit {
   @Input() usersFilter: [];
   @ViewChild(MatSort, { static: true }) sort: MatSort;
   @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

   dataSource = new MatTableDataSource<ProjectListItemModel>([]);
   loading = true;
   columnsToDisplay = ['name', 'description', 'openDate', 'closeDate',
      'funding', 'actions'];
   returnedItemsCount = 0;
   projects: ProjectListItemModel[];
   filterForm: FormGroup;
   activeFilter = true;
   dateFilter = {};
   showFilters = false;

   constructor(
      private formBuilder: FormBuilder,
      private projectService: ProjectsRepositoryService,
      private preferenceService: PreferenceService,
      private confirmation: ConfirmationService) {
   }

   ngOnInit() {
      this.initialize();
      this.subscribe();
      this.getProjectList();
   }

   toggleFilters() {
      this.showFilters = !this.showFilters;
   }

   getParameters() {
      const order = this.sort.active || 'openDate';
      this.dateFilter = this.filterForm.get('dateFilter').value;
      this.activeFilter = this.filterForm.get('activeFilter').value;
      this.usersFilter = this.filterForm.get('usersFilter').value;
      this.paginator.pageSize = this.paginator.pageSize || 25;
      const projectNameFilter = this.filterForm.get('projectNameFilter').value;

      this.savePreferences();

      const params: GetProjectListParams = {
         order: this.sort.direction === 'desc' ? '-' + order : order,
         page: this.paginator.pageIndex + 1,
         pageSize: this.paginator.pageSize,
         ...this.dateFilter,
         isActive: this.activeFilter,
         userIds: this.usersFilter,
         projectName: projectNameFilter
      };

      return params;
   }

   private getProjectList() {
      this.dataSource.data = [];

      this.loading = true;
      const params = this.getParameters();

      return this.projectService.getProjectList(params)
         .subscribe(data => {
            this.loading = false;
            this.returnedItemsCount = data.count;
            this.dataSource.data = data.list;
         });
   }

   export = () => {
      const params = this.getParameters();
      this.projectService.getProjectListDownload(params)
         .subscribe();
   }

   private subscribe() {
      this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);
      merge(this.sort.sortChange, this.paginator.page, this.filterForm.valueChanges)
      .pipe(
         debounceTime(500)
      )
      .subscribe(() => this.getProjectList());
   }

   private initialize() {
      this.loadPreferences();

      this.filterForm = this.formBuilder.group({
         dateFilter: this.dateFilter,
         activeFilter: this.activeFilter,
         usersFilter: [this.usersFilter],
         projectNameFilter: null
      });
   }

   private loadPreferences(): any {
      const preferences: any = this.preferenceService.getPreference('projectList');
      if (preferences) {
         Object.assign(this.sort, preferences.sortSettings);
         Object.assign(this.paginator, preferences.paginatorSettings);
         this.activeFilter = preferences.activeFilter;
         this.usersFilter = preferences.usersFilter;
         Object.assign(this.dateFilter, preferences.dateFilter);
      }
   }

   private savePreferences() {
      const preferences = {
         sortSettings: { active: this.sort.active, direction: this.sort.direction },
         paginatorSettings: { pageSize: this.paginator.pageSize },
         dateFilter: this.dateFilter,
         activeFilter: this.activeFilter,
         usersFilter: this.usersFilter
      };
      this.preferenceService.setPreference('projectList', preferences);
   }

   showDeleteDialog(project: ProjectListItemModel) {
      this.confirmation.confirm('Delete Project?', 'DELETE', 'CANCEL').pipe(
         filter(ok => ok),
         switchMap(() => this.projectService.deleteProject(project.id))
      ).subscribe(() => {
         const index = this.dataSource.data.indexOf(project);
         this.dataSource.data = [
            ...this.dataSource.data.slice(0, Math.max(index, 0)),
            ...this.dataSource.data.slice(index + 1)
         ];
      });
   }

   clearFilters() {
      this.filterForm.patchValue({
         dateFilter: {},
         activeFilter: null,
         usersFilter: [],
         projectNameFilter: null
      });
   }
}




















import { Injectable } from '@angular/core';
import { HttpService } from '../core/index';
import { DocumentModel, GetDocumentsResult } from './models/documents.models';
import { Observable } from 'rxjs';

@Injectable()
export class DocumentsService {
   static injectName = 'documentsService';
   private baseUrl = 'api/document-management';

   constructor(private http: HttpService) { }

   getDocuments(q: string, involvementId: string, source: string, sourceId: string) {
      const url = `${this.baseUrl}/documents`;
      return this.http.get<GetDocumentsResult>(url, { params: { q, involvementId: involvementId || '', source, sourceId } });
   }
}

import { Component, Input, OnInit } from '@angular/core';
import { Eligibility } from '@app/funding/eligibility/types';

@Component({
   selector: 'app-eligibility-toolbar',
   templateUrl: './eligibility-toolbar.component.html',
   styleUrls: ['./eligibility-toolbar.component.scss']
})
export class EligibilityToolbarComponent implements OnInit {
   @Input() public involvementId: string;

   @Input()
   get sourceType() {
      return this._sourceType;
   }

   set sourceType(value: string) {
      this._sourceType = value;
      this.sref = `app.${this.sourceType}.funding.eligibility.eligibilityFormView`;
   }

   @Input()
   get eligibilityList(): Eligibility[] {
      return this._eligibilityList;
   }
   set eligibilityList(value: Eligibility[]) {
      this._eligibilityList = value;
   }

   private _eligibilityList: Eligibility[];
   private _sourceType: string;
   sref: string;

   constructor() { }

   ngOnInit(): void {
   }
}
import { CheckListComponent } from './check-list/check-list.component';

export const states = [
   {
      name: 'app.checkList',
      component: CheckListComponent,
      url: '/check-list',
      data: {
         title: 'List',
         amPermissions: ['checks.view']
      }
   },
];

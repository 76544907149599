export enum PopoverFilterType {
   boolean = 'Boolean',
   date = 'DateRange',
   selection = 'MultiSelect',
   number = 'Number',
   text = 'Text'
};

export enum DateFilterOperator {
   isEqualTo = 'IsEqualTo',
   isNotEqualTo = 'IsNotEqualTo',
   isBefore = 'IsBefore',
   isOnOrBefore = 'IsOnOrBefore',
   isAfter = 'IsAfter',
   isOnOrAfter = 'IsOnOrAfter',
   isBetween = 'IsBetween'
};

export enum NumberFilterOperator {
   isEqualTo = 'IsEqualTo',
   isNotEqualTo = 'IsNotEqualTo',
   isLessThan = 'IsLessThan',
   isLessThanOrEqualTo = 'IsLessThanOrEqualTo',
   isGreaterThan = 'IsGreaterThan',
   isGreaterThanOrEqualTo = 'IsGreaterThanOrEqualTo',
   isBetween = 'IsBetween'
};

export enum TextFilterOperator {
   contains = 'Contains',
   isEqualTo = 'IsEqualTo',
   startsWith = 'StartsWith'
};

export interface PopoverFilterOption {
   text?: string;
   value: string;
}

export interface PopoverFilterValue {
   type: PopoverFilterType;
}

export interface PopoverFilterBooleanValue extends PopoverFilterValue {
   values: boolean[];
   displayValues?: string[];
}

export interface PopoverFilterDateValue extends PopoverFilterValue {
   operator: DateFilterOperator;
   date: string | null;
   date2?: string | null;
}

export interface PopoverFilterSelectionValue extends PopoverFilterValue {
   values: string[];
   displayValues?: string[];
}

export interface PopoverFilterTextValue extends PopoverFilterValue {
   operator: TextFilterOperator;
   text: string;
}

export interface PopoverFilterNumberValue extends PopoverFilterValue {
   operator: NumberFilterOperator;
   number: number;
   number2?: number;
}

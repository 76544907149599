import { AfterViewInit, Directive, ElementRef, Input } from '@angular/core';

import { AuthService } from '../../../core';

@Directive({ selector: '[amRoles]' })
export class RolesDirective implements AfterViewInit {
   constructor(
      private elementRef: ElementRef,
      private auth: AuthService,
   ) {
   }

   @Input('amRoles') roles: string[];

   ngAfterViewInit() {
      if (!this.auth.checkRoles(...this.roles)) {
         this.elementRef.nativeElement.disabled = true;
      }
   }
}

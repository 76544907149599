import { Transition, TransitionService } from '@uirouter/core';
import { MonitoringService } from '../services';

export function monitoringHandler(transitionService: TransitionService) {
   const logPageView = (transition: Transition) => {
      const monitor: MonitoringService = transition.injector().get(MonitoringService);

      const properties = {};
      if (screen && screen.width) {
         properties['Screen Resolution'] = screen.width + 'x' + screen.height;
      }

      monitor.logPageView(transition.to().name, null, properties);
   };

   const logError = (transition: Transition) => {
      const monitor: MonitoringService = transition.injector().get(MonitoringService);
      const error = transition.error();
      monitor.logTrace(error.message, error.detail, 3);
   };

   transitionService.onSuccess({}, logPageView);
   transitionService.onError({}, logError);
}

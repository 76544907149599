import { Injectable } from "@angular/core";
import { ComponentStore } from '@ngrx/component-store';
import { Observable } from "rxjs";
import { filter, switchMap, tap } from "rxjs/operators";
import { EligibilityListService } from '@app/funding/eligibility/list/services';
import { Eligibility } from '../../types';
import { EligibilityListFilters } from '../types';
import { filterItems } from '@app/shared/filters';
import { uniq } from 'lodash';
import { TypedSort } from '@app/types/core';

interface EligibilityListState {
   involvementId?: string;
   fundingProgramId?: string;
   eligibilityList: Eligibility[];
   filters: EligibilityListFilters;
   sort: TypedSort<Eligibility>;
   loading: boolean,
}

const initialState: EligibilityListState = {
   involvementId: null,
   fundingProgramId: null,
   eligibilityList: [],
   filters: {
      eligibilityDate: null,
      fundingProgramName: null,
      isEligible: null,
      modifiedBy: null,
      modifiedAt: null
   },
   sort: { active: 'eligibilityDate', direction: 'desc' },
   loading: false,
}

@Injectable()
export class EligibilityListStore extends ComponentStore<EligibilityListState> {

   constructor(
      private service: EligibilityListService) {
      super(initialState);
      this.getEligibilityList;
   }

   private readonly involvementId$ = this.select(state => state.involvementId);
   private readonly eligibilityList$ = this.select(state => state.eligibilityList);

   private readonly getEligibilityParams$ = this.select(
      this.involvementId$.pipe(filter(value => !!value)),
      involvementId => (involvementId)
   );

   private readonly filters$ = this.select(this.state$, state => state.filters);

   readonly fundingProgramNameOptions$ = this.select(
      this.eligibilityList$,
      eligibilityList => uniq(eligibilityList.map(e => e.fundingProgramName)).map(value => ({ value, text: value }))
   );

   readonly modifiedByOptions$ = this.select(
      this.eligibilityList$,
      eligibilityList => uniq(eligibilityList.map(e => e.modifiedByFullName)).map(value => ({ value, text: value }))
   );

   readonly filteredEligibilityList$ = this.select(
      this.eligibilityList$,
      this.filters$,
      (activities, filters) => filterItems(activities, filters)
   );

   readonly vm$ = this.select(
      this.state$,
      this.fundingProgramNameOptions$,
      this.modifiedByOptions$,
      this.filteredEligibilityList$,
      this.filters$,
      ({ involvementId, loading, sort }, fundingProgramNameOptions, modifiedByOptions, eligibilityList, filters) =>
         ({ involvementId, loading, sort, fundingProgramNameOptions, modifiedByOptions, eligibilityList, filters })
   )

   private readonly getEligibilityList = this.effect((involvementId$: Observable<string>) => involvementId$.pipe(
      switchMap((involvementId) => this.loadEligibilityList(involvementId))
   ))(this.getEligibilityParams$);

   public readonly updateInvolvementId = this.updater((state, involvementId: string) => ({
      ...state, involvementId
   }));

   public readonly updateFundingProgramId = this.updater((state, fundingProgramId: string) => ({
      ...state, fundingProgramId
   }));

   private readonly updateEligibilityList = this.updater((state, eligibilityList: Eligibility[]) => ({
      ...state, eligibilityList
   }));

   private readonly updateIsLoading = this.updater((state, isLoading: boolean) => ({
      ...state, isLoading
   }));

   public readonly addEligibility = this.updater((state, eligibility: Eligibility) => ({
      ...state,
      eligibilityList: state.eligibilityList.filter(e => e.id !== eligibility.id).concat(eligibility)
   }));

   public readonly deleteEligibility = this.updater((state, eligibility: Eligibility) => ({
      ...state,
      eligibilityList: state.eligibilityList.filter(e => e.id != eligibility.id)
   }));

   public readonly updateFilters = this.updater((state, filters: EligibilityListFilters) => ({
      ...state, filters
   }));

   private loadEligibilityList(involvementId: string) {
      this.updateIsLoading(true);
      return this.service.getEligibilityList(involvementId).pipe(
         tap(eligibilityList => {
            this.updateEligibilityList(eligibilityList);
            this.updateIsLoading(false);
         }))
   };

   public onDelete(id: string) {
      this.service.deleteEligibility(id)
      return id;
   }
}
import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { states } from './funding.states';
import { PAATIntakeInfoComponent } from './paat/intake/paat-intake-info.component';
import { PAATIntakeFormComponent } from './paat/intake/paat-intake-form.component';
import { UIRouterUpgradeModule } from '@uirouter/angular-hybrid';
import { FundingService } from './funding.service';
import { PopoverFilterModule } from '@app/shared/filters';
import { EligibilityModule } from '@app/funding/eligibility/eligibility.module'

@NgModule({
   imports: [
      SharedModule,
      PopoverFilterModule,
      EligibilityModule,
      UIRouterUpgradeModule.forChild({ states })
   ],
   declarations: [
      PAATIntakeInfoComponent,
      PAATIntakeFormComponent
   ],
   exports: [],
   entryComponents: [],
   providers: [FundingService]
})
export class FundingModule { }

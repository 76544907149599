import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FiltersModule } from '@app/filters/filters.module';
import { SharedModule } from '@app/shared';
import { PopoverFilterModule } from '@app/shared/filters';
import { SystemicCasesListComponent, SystemicCasesToolbarComponent } from './components';
import { SystemicCasesCreateDialog } from './dialogs';
import { SystemicCasesListView } from './view/systemic-cases-list.view';

@NgModule({
  declarations: [
    SystemicCasesListView,
    SystemicCasesCreateDialog,
    SystemicCasesListComponent,
    SystemicCasesToolbarComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    FiltersModule,
    PopoverFilterModule
  ],
  exports: [
    SystemicCasesListView,
    SystemicCasesCreateDialog,
    SystemicCasesListComponent,
    SystemicCasesToolbarComponent
  ]
})
export class SystemicCasesListModule { }
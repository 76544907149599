import { Injectable } from '@angular/core';
import { HttpService } from '@app/core/index';
import { Observable } from 'rxjs';
import { GetPersonsParams } from '../types/params';
import { PersonSelect } from '../types/person';

@Injectable({
   providedIn: 'root'
})
export class PersonsService {
   static injectName = 'personsService';
   private baseUrl = 'api/persons';

   constructor(private http: HttpService) { }

   getPersons(params: GetPersonsParams): Observable<PersonSelect[]> {
      const url = `${this.baseUrl}/list`;
      return this.http.post<PersonSelect[]>(url, params);
   }
}
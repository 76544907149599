import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FiltersModule } from '@app/filters/filters.module';
import { GrievancesListComponent, GrievancesListToolbarComponent } from '@app/grievances/list/components';
import { GrievancesFormDialog } from '@app/grievances/list/dialogs';
import { GrievancesListView } from '@app/grievances/list/view';
import { SharedModule } from '@app/shared';
import { PopoverFilterModule } from '@app/shared/filters';


@NgModule({
   declarations: [
      GrievancesListView,
      GrievancesFormDialog,
      GrievancesListComponent,
      GrievancesListToolbarComponent
   ],
   imports: [
      CommonModule,
      SharedModule,
      FiltersModule,
      PopoverFilterModule
   ],
   exports: [
      GrievancesListView,
      GrievancesFormDialog,
      GrievancesListComponent,
      GrievancesListToolbarComponent
   ]
})
export class GrievancesListModule { }
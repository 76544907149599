import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ConfirmationService } from '@app/shared/services/confirmation.service';
import { merge } from 'rxjs';
import { debounceTime, filter, switchMap } from 'rxjs/operators';

import { PreferenceService } from '../../core';
import { GetTrainingRequestListParams, TrainingRequestListItemModel } from '../models/training-requests.models';
import { TrainingRequestsRepositoryService } from '../training-requests.repository';

@Component({
   selector: 'am-training-request-list',
   templateUrl: 'training-request-list.component.html',
   styleUrls: ['training-request-list.component.scss']
})
export class TrainingRequestListComponent implements OnInit {
   @ViewChild(MatSort, { static: true }) sort: MatSort;
   @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

   dataSource = new MatTableDataSource<TrainingRequestListItemModel>([]);
   loading = true;
   columnsToDisplay = ['receivedDate', 'trainingType', 'result', 'more'];
   returnedItemsCount = 0;
   cases: TrainingRequestListItemModel[];
   filterForm: FormGroup;
   usersFilter = [];
   activeFilter = true;
   dateFilter = {};
   showFilters = false;

   constructor(
      private formBuilder: FormBuilder,
      private trainingRequestService: TrainingRequestsRepositoryService,
      private preferenceService: PreferenceService,
      private confirmation: ConfirmationService) {
   }

   ngOnInit() {
      this.initialize();
      this.subscribe();
      this.getTrainingRequestList();
   }

   toggleFilters() {
      this.showFilters = !this.showFilters;
   }

   getParameters() {
      const order = this.sort.active || 'receivedDate';
      this.dateFilter = this.filterForm.get('dateFilter').value;
      this.activeFilter = this.filterForm.get('activeFilter').value;
      this.paginator.pageSize = this.paginator.pageSize || 25;

      this.savePreferences();

      const params: GetTrainingRequestListParams = {
         order: this.sort.direction === 'desc' ? '-' + order : order,
         page: this.paginator.pageIndex + 1,
         pageSize: this.paginator.pageSize,
         ...this.dateFilter,
         isActive: this.activeFilter,
      };

      return params;
   }

   private getTrainingRequestList() {
      this.dataSource.data = [];

      this.loading = true;
      const params = this.getParameters();

      return this.trainingRequestService.getTrainingRequestList(params)
         .subscribe(data => {
            this.loading = false;
            this.returnedItemsCount = data.count;
            this.dataSource.data = data.list;
         });
   }

   export = () => {
      const params = this.getParameters();
      this.trainingRequestService.getTrainingRequestListDownload(params)
         .subscribe();
   }

   private subscribe() {
      this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);
      merge(this.sort.sortChange, this.paginator.page, this.filterForm.valueChanges)
         .pipe(
            debounceTime(500)
         )
         .subscribe(() => this.getTrainingRequestList());
   }

   private initialize() {
      this.loadPreferences();

      this.filterForm = this.formBuilder.group({
         dateFilter: this.dateFilter,
         activeFilter: this.activeFilter,
      });
   }

   private loadPreferences(): any {
      const preferences: any = this.preferenceService.getPreference('trainingRequestList');
      if (preferences) {
         Object.assign(this.sort, preferences.sortSettings);
         Object.assign(this.paginator, preferences.paginatorSettings);
         this.activeFilter = preferences.activeFilter;
         Object.assign(this.dateFilter, preferences.dateFilter);
      }
   }

   private savePreferences() {
      const preferences = {
         sortSettings: { active: this.sort.active, direction: this.sort.direction },
         paginatorSettings: { pageSize: this.paginator.pageSize },
         dateFilter: this.dateFilter,
         activeFilter: this.activeFilter,
      };
      this.preferenceService.setPreference('trainingRequestList', preferences);
   }

   showDeleteDialog(request: TrainingRequestListItemModel) {
      this.confirmation.confirm('Delete Training Request?', 'DELETE', 'CANCEL').pipe(
         filter(ok => ok),
         switchMap(() => this.trainingRequestService.deleteTrainingRequest(request.id))
      ).subscribe(() => {
         const index = this.dataSource.data.indexOf(request);
         this.dataSource.data = [
            ...this.dataSource.data.slice(0, Math.max(index, 0)),
            ...this.dataSource.data.slice(index + 1)
         ];
      });
   }

   clearFilters() {
      this.filterForm.patchValue({
         dateFilter: {},
         activeFilter: null,
      });
   }
}




















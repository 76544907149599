import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ConfigurationService } from '@app/configuration/configuration.service';
import { FundingProgramModel } from '@app/configuration/models/configuration.models';
import { NotificationService } from '@app/core';
import { ProjectListService } from '@app/projects/list/services';
import { ProjectCreate } from '@app/projects/list/types';
import { StateService } from '@uirouter/core';
import * as moment from 'moment';


@Component({
   templateUrl: './projects-create.dialog.html',
   styleUrls: ['./projects-create.dialog.scss']
})
export class ProjectsCreateDialog implements OnInit {
   form: FormGroup;
   today: moment.Moment;
   submitting: boolean;
   fundingPrograms: FundingProgramModel[] = [];

   constructor(
      private readonly dialogRef: MatDialogRef<ProjectCreate>,
      private readonly formBuilder: FormBuilder,
      private readonly service: ProjectListService,
      private readonly stateService: StateService,
      private configurationService: ConfigurationService,
      private notifier: NotificationService,
      @Inject(MAT_DIALOG_DATA)
      public readonly data: ProjectCreate) { }

   static open(dialog: MatDialog, data: ProjectCreate) {
      return dialog.open(ProjectsCreateDialog, {
         width: '665px',
         panelClass: 'no-padding',
         disableClose: true,
         autoFocus: true,
         data: data || {}
      });
   }

   ngOnInit(): void {
      this.today = moment().startOf('day');
      this.getFunderOptions();
      this.setupForm();
   }

   private setupForm() {
      const { id, name, description, openDate, closeDate, fundingProgramId } = this.data ?? {};

      this.form = this.formBuilder.group({
         id: id || null,
         name: name || null,
         description: description || null,
         openDate: [openDate ? moment(openDate) : this.today, Validators.required],
         closeDate: [closeDate ? moment(closeDate) : null],
         fundingProgramId: fundingProgramId || null
      });
   }

   private getFunderOptions() {
      this.configurationService.getFundingPrograms(true, 'project').subscribe(
         p => this.fundingPrograms = p
      )
   }

   submit(): void {
      this.submitting = true;
      const formData = this.form.getRawValue();

      const data: ProjectCreate = {
         id: formData.id,
         name: formData.name,
         description: formData.description,
         openDate: formData.openDate.format('YYYY-MM-DD'),
         closeDate: formData.closeDate,
         fundingProgramId: formData.fundingProgramId
      };

      this.service.createProject(data)
         .subscribe((result) => {
            this.submitting = false;
            this.stateService.go('app.projects.overview', { projectId: result });
            this.notifier.notify('Project Created', 'Done');
            this.dialogRef.close()
         });
   }
}
import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';

import { CLERepositoryService } from './cle.repository';
import { UIRouterUpgradeModule } from '@uirouter/angular-hybrid';
import { states } from './cle.states';
import { CLEListComponent } from './cle-list/cle-list.component';
import { FiltersModule } from '../filters/filters.module';

@NgModule({
   imports: [
      UIRouterUpgradeModule.forChild({ states }),
      SharedModule,
      FiltersModule
   ],
   declarations: [
      CLEListComponent
   ],
   exports: [
   ],
   providers: [
      CLERepositoryService
   ]
})
export class CLEModule {
}

import { ChangeDetectionStrategy, Component, forwardRef, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { map } from 'rxjs/operators';
import { FilterControl } from '../filter-control';
import { PopoverFilterBooleanValue, PopoverFilterType } from '../popover-filter.models';

@Component({
   selector: 'au-popover-filter-boolean',
   changeDetection: ChangeDetectionStrategy.OnPush,
   providers: [{ provide: FilterControl, useExisting: forwardRef(() => BooleanFilterComponent) }],
   templateUrl: './boolean-filter.component.html',
   styleUrls: ['./boolean-filter.component.scss']
})
export class BooleanFilterComponent extends FilterControl implements OnInit {
   ngOnInit(): void {
      this.form = new FormGroup({
         values: new FormControl([])
      });
   }

   clear(): void {
      this.form.setValue({ values: [] });
   }

   registerOnChanges(fn: (value: any) => any): void {
      this.form.valueChanges.pipe(
         map(({ values }): PopoverFilterBooleanValue | null => values.length ? { values, type: PopoverFilterType.boolean } : null)
      ).subscribe(fn);
   }

   writeValue(value: any): void {
      const { values = [] } = value ?? {};
      this.form.setValue({ values }, { emitEvent: false });
   }
}

import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as moment from 'moment';
import { finalize } from 'rxjs/operators';
import { InvolvementsService } from '../involvements.service';
import { StatuteOfLimitationsModel } from '../models/involvements.models';

interface StatuteOfLimitationsFormData {
   date: moment.Moment;
   description: string;
}

export interface StatuteOfLimitationsDialogData {
   involvementId: string;
   statuteOfLimitationsId?: string;
   date?: Date;
   description: string;
}

@Component({
   selector: 'am-statute-of-limitations-dialog',
   templateUrl: './statute-of-limitations-dialog.component.html',
   styleUrls: ['./statute-of-limitations-dialog.component.scss']
})
export class StatuteOfLimitationsDialogComponent implements OnInit {
   form: FormGroup;
   submitting = false;
   today: moment.Moment;

   constructor(
      private formBuilder: FormBuilder,
      private dialogRef: MatDialogRef<StatuteOfLimitationsDialogComponent>,
      private involvementsService: InvolvementsService,
      @Inject(MAT_DIALOG_DATA) public dialogData: StatuteOfLimitationsDialogData) {
   }

   ngOnInit() {
      this.today = moment().startOf('day');
      this.form = this.formBuilder.group({
         date: [this.dialogData.date, [Validators.required]],
         description: [this.dialogData.description, [Validators.required]]
      });
   }

   cancel() {
      this.dialogRef.close();
   }

   submit() {
      if (this.form.invalid) return;
      this.submitting = true;

      const formData = <StatuteOfLimitationsFormData>this.form.getRawValue();
      let dateString = '';
      if (typeof formData.date !== 'string') {
         dateString = formData.date.format('YYYY-MM-DD');
      } else {
         dateString = formData.date;
      }

      const { statuteOfLimitationsId, involvementId } = this.dialogData

      const data: StatuteOfLimitationsModel = {
         id: statuteOfLimitationsId,
         involvementId,
         date: dateString,
         description: formData.description
      };

      const observable = statuteOfLimitationsId
         ? this.involvementsService.updateStatuteOfLimitations(involvementId, data)
         : this.involvementsService.createStatuteOfLimitations(involvementId, data);


      observable.pipe(
         finalize(() => this.submitting = false)
      ).subscribe(result => {
         if (result) {
            this.dialogRef.close(result);
         }
      });
   }
}

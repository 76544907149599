import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ConfigurationService } from '@app/configuration/configuration.service';
import { LegalTeamModel, LookupModel } from '@app/configuration/models/configuration.models';
import { NotificationService } from '@app/core';
import { RequestListService } from '@app/requests/list/services/request-list.service';
import { RequestCreate } from '@app/requests/list/types/request-create';
import { StateService } from '@uirouter/core';
import * as moment from 'moment';
import { forkJoin } from 'rxjs';


@Component({
   templateUrl: './request-create.dialog.html',
   styleUrls: ['./request-create.dialog.scss']
})
export class RequestCreateDialog implements OnInit {
   form: FormGroup;
   today: moment.Moment;
   submitting: boolean;
   methods: LookupModel[] = [];
   regions: LookupModel[] = [];
   legalTeams: LegalTeamModel[] = [];

   constructor(
      private readonly dialogRef: MatDialogRef<RequestCreate>,
      private readonly formBuilder: FormBuilder,
      private readonly service: RequestListService,
      private readonly stateService: StateService,
      private configurationService: ConfigurationService,
      private notifier: NotificationService,
      @Inject(MAT_DIALOG_DATA)
      public readonly data: RequestCreate) { }

   static open(dialog: MatDialog, data: RequestCreate) {
      return dialog.open(RequestCreateDialog, {
         width: '665px',
         panelClass: 'no-padding',
         disableClose: true,
         autoFocus: true,
         data: data || {}
      });
   }

   ngOnInit(): void {
      this.today = moment().startOf('day');
      this.getLookups()
      this.setupForm();
   }

   private setupForm() {
      const { requestedAt, requestMethod, urgentRequest, legalTeamId, region, description } = this.data ?? {};

      this.form = this.formBuilder.group({
         requestedAt: [requestedAt ? moment(requestedAt) : this.today, Validators.required],
         requestedTime: [requestedAt, Validators.required],
         requestMethod: requestMethod || null,
         urgentRequest: urgentRequest || null,
         legalTeamId: legalTeamId || null,
         region: region || null,
         description: description || null,
      });
   }

   private getLookups() {
      forkJoin([
         this.configurationService.getLookupType('request-method'),
         this.configurationService.getLookupType('region'),
         this.configurationService.getLegalTeams(true)]).subscribe(
            ([methods, regions, legalTeams]) => {
               this.methods = methods.lookups;
               this.regions = regions.lookups;
               this.legalTeams = legalTeams;
            })
   }

   submit(): void {
      this.submitting = true;
      const formData = this.form.getRawValue();

      const data: RequestCreate = {
         requestedAt: moment(formData.requestedAt.format('YYYY-MM-DD') + ' ' + (formData.requestedTime)).toDate(),
         requestMethod: formData.requestMethod,
         urgentRequest: formData.urgentRequest,
         legalTeamId: formData.legalTeamId,
         region: formData.region,
         description: formData.description
      };

      this.service.createRequest(data)
         .subscribe((result) => {
            this.submitting = false;
            this.stateService.go('app.requests.overview', { requestId: result });
            this.notifier.notify('Request Created', 'Done');
            this.dialogRef.close()
         });
   }
}
/* eslint-disable prefer-arrow/prefer-arrow-functions */
import moment from 'moment';
import {
   DateFilterOperator,
   NumberFilterOperator,
   PopoverFilterBooleanValue,
   PopoverFilterDateValue,
   PopoverFilterNumberValue,
   PopoverFilterSelectionValue,
   PopoverFilterTextValue,
   PopoverFilterType,
   PopoverFilterValue,
   TextFilterOperator
} from './popover-filter.models';

const toIsoDate = (text: string): string => {
   return moment(text, 'YYYY-MM-DD').format('MM/DD/YYYY');
};

const humanize = (text: string): string => {
   if (!text) return text;
   return text.replace(/([a-z]|[A-Z]+)([A-Z](?!$))/g, '$1 $2');
};

export function booleanFilterFn(value: boolean, filter: PopoverFilterBooleanValue): boolean {
   return filter.values.some(v => value === v);
}

export function dateFilterFn(value: moment.Moment | string, filter: PopoverFilterDateValue): boolean {
   if (!value) return false;

   value = typeof value === 'string' ? moment(value) : value;

   const beginDate = moment(filter.date);

   switch (filter.operator) {
      case DateFilterOperator.isAfter:
         return value.isAfter(beginDate);
      case DateFilterOperator.isBefore:
         return value.isBefore(beginDate);
      case DateFilterOperator.isBetween:
         return value.isBetween(beginDate, moment(filter.date2), null, '[]');
      case DateFilterOperator.isEqualTo:
         return value.isSame(beginDate);
      case DateFilterOperator.isNotEqualTo:
         return !value.isSame(beginDate);
      case DateFilterOperator.isOnOrAfter:
         return value.isSameOrAfter(beginDate);
      case DateFilterOperator.isOnOrBefore:
         return value.isSameOrBefore(beginDate);
      default:
         return false;
   }
}

export function numberFilterFn(value: number, filter: PopoverFilterNumberValue): boolean {
   if (!value && value !== 0) return false;

   const min = filter.number;
   const max = filter.number2;

   switch (filter.operator) {
      case NumberFilterOperator.isLessThan:
         return value < min;
      case NumberFilterOperator.isLessThanOrEqualTo:
         return value <= min;
      case NumberFilterOperator.isEqualTo:
         return value === min;
      case NumberFilterOperator.isNotEqualTo:
         return value !== min;
      case NumberFilterOperator.isGreaterThanOrEqualTo:
         return value >= min;
      case NumberFilterOperator.isGreaterThan:
         return value > min;
      case NumberFilterOperator.isBetween:
         return value >= min && value <= min!;
      default:
         return false;
   }
}

export function selectionFilterFn(value: string, filter: PopoverFilterSelectionValue): boolean {
   const tmp = value ? value.toUpperCase() : null;
   return filter.values.some(v => tmp === (v ? v.toUpperCase() : null));
}

export function textFilterFn(value: string, filter: PopoverFilterTextValue): boolean {
   if (!value) return false;

   value = value.toUpperCase();
   const search = filter.text?.toUpperCase();

   switch (filter.operator) {
      case TextFilterOperator.contains:
         return value.indexOf(search) >= 0;
      case TextFilterOperator.isEqualTo:
         return value === search;
      case TextFilterOperator.startsWith:
         return value.startsWith(search);
      default:
         throw new Error(`Operator ${filter.operator} not supported`);
   }
}

export function filterValueToString(filterType: PopoverFilterType, filterValue: any) {
   if (!filterValue) return 'All';

   if (filterType === PopoverFilterType.boolean) {
      const { displayValues } = filterValue;
      return (displayValues as string[]).map(v => 'Is ' + v).join('\nOr\n');
   } else if (filterType === PopoverFilterType.date) {
      const { operator, date, date2 } = filterValue;
      let result = humanize(operator) + ' ' + toIsoDate(date);
      if (date2) result += ' and\n' + toIsoDate(date2);
      return result;
   } else if (filterType === PopoverFilterType.selection) {
      const { displayValues } = filterValue;
      return (displayValues as string[]).map(v => 'Is ' + v).join('\nOr\n');
   } else if (filterType === PopoverFilterType.text) {
      const { operator, text } = filterValue;
      return humanize(operator) + ' ' + text;
   } else if (filterType === PopoverFilterType.number) {
      const { operator, number, number2 } = filterValue;
      let result = humanize(operator) + ' ' + number;
      if (number2) result += ' and\n' + number2;
      return result;
   } else {
      throw new Error(`Filter type '${filterType}' is not supported`);
   }
}

type FilterFn = (value: any, filter: any) => boolean;

const FUNCTION_MAP: { [name: string]: FilterFn } = {
   [PopoverFilterType.boolean]: booleanFilterFn,
   [PopoverFilterType.date]: dateFilterFn,
   [PopoverFilterType.selection]: selectionFilterFn,
   [PopoverFilterType.number]: numberFilterFn,
   [PopoverFilterType.text]: textFilterFn
};

export function filterItems<T>(items: T[], filters: any, mappingOverrides: { [name: string]: string } = {}): T[] {
   const filterFn = (item: any): boolean => {
      for (const key of Object.keys(filters)) {
         const filterValue = filters[key] as PopoverFilterValue;
         if (filterValue) {
            const value = item[mappingOverrides[key] ?? key];
            const fn = FUNCTION_MAP[filterValue.type];
            if (fn && !fn(value, filterValue)) {
               return false;
            }
         }
      }
      return true;
   };

   return items.filter(filterFn);
};

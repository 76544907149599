import { createReducer, on } from '@ngrx/store';
import * as Actions from './actions';
import { initialState, State } from './state';

export const configReducer = createReducer<State>(
   initialState,
   on(Actions.setConfig, (state, action): State => ({
      ...state,
      config: action.config,
      error: null,
      isLoading: true
   })),
   on(Actions.loadRequest, (state): State => ({
      ...state,
      error: null,
      isLoading: true
   })),
   on(Actions.loadSuccess, (state, action): State => ({
      ...state,
      error: null,
      isLoading: false
   })),
   on(Actions.loadFailure, (state, action): State => ({
      ...state,
      error: action.error,
      isLoading: false
   })),
);

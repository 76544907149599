import { HttpClient, HttpErrorResponse, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError as observableThrowError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { NotificationService } from './notification.service';

@Injectable()
export class HttpService {
   constructor(
      private httpClient: HttpClient,
      private notifier: NotificationService
   ) { }

   get<T>(url: string, options?): Observable<T> {
      return this.httpClient.get<T>(url, options).pipe(
         catchError(this.handleError)
      );
   }

   post<T>(url: string, body?: any, options?): Observable<T> {
      return this.httpClient.post<T>(url, body, options).pipe(
         catchError(this.handleError)
      );
   }

   put<T>(url: string, options?): Observable<T> {
      return this.httpClient.put<T>(url, options).pipe(
         catchError(this.handleError)
      );
   }

   delete<T>(url: string, options?): Observable<T> {
      return this.httpClient.delete<T>(url, options).pipe(
         catchError(this.handleError)
      );
   }

   request(req: HttpRequest<any>) {
      return this.httpClient.request(req).pipe(
         catchError(this.handleError)
      );
   }

   private handleError = (err: Response | any): Observable<any> => {
      let message: string;
      if (err instanceof HttpErrorResponse) {
         const error = err.error;

         if (err.status >= 400 && err.status < 500) {
            return observableThrowError(err);
         }

         if (error && error.message) {
            message = error.message;
         } else {
            message = err.error || err.message;
         }
      } else {
         message = err.message ? err.message : err.toString();
      }


      console.error(err);
      // this.notifier.notify(message);
      return observableThrowError(message);
   }
}

import { Directive, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Directive()
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export abstract class FilterControl {
   @Input() disabled?: boolean;
   @Input() label?: string;
   @Input() filterControlName!: string;

   form!: FormGroup;

   setDisabledState(disabled: boolean): void {
      this.disabled = disabled;
      setTimeout(() => {
         if (this.form && this.form.disabled !== disabled) {
            const fn = disabled ? 'disable' : 'enable';
            this.form[fn]({ emitEvent: false });
         }
      });
   }

   abstract clear(): void;
   abstract registerOnChanges(fn: (value: any) => any): void;
   abstract writeValue(value: any): void;
}

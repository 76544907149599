import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { viewStateReducer } from './reducer';

@NgModule({
   imports: [
      StoreModule.forFeature('viewState', viewStateReducer)
   ]
})
export class ViewStateStoreModule { }

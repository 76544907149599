import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpService } from '../core/index';
import { FundingProgramModel, LegalTeamModel, LookupTypeModel } from './models/configuration.models';

@Injectable()
export class ConfigurationService {
   static injectName = 'configurationService';
   private baseUrl = 'api/configuration';

   constructor(private http: HttpService) { }

   getLegalTeams(activeOnly: boolean): Observable<LegalTeamModel[]> {
      const url = `${this.baseUrl}/legal-teams?activeOnly=${activeOnly}`;
      return this.http.get<LegalTeamModel[]>(url);
   }

   getLookupType(lookupTypeId: string): Observable<LookupTypeModel> {
      const url = `${this.baseUrl}/lookup-types/${lookupTypeId}`;
      return this.http.get<LookupTypeModel>(url);
   }

   getConfigurationParameter(parameterName: string): Observable<string> {
      const name = encodeURIComponent(parameterName);
      const url = `${this.baseUrl}/parameters?name=${name}`
      return this.http.get<string>(url);
   }

   getFundingPrograms(activeOnly: boolean, caseType: string): Observable<FundingProgramModel[]> {
      const url = `${this.baseUrl}/funding-programs`;
      return this.http.post<FundingProgramModel[]>(url, { caseType, activeOnly });
   }
}
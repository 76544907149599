import { Directive, EventEmitter, HostBinding, HostListener, Output } from '@angular/core';
import { forEach } from '@uirouter/core';

@Directive({
   selector: '[amDropFile]'
})
export class DropFileDirective {
   @HostBinding('style.background') background = '#eee';
   @Output() filesChange = new EventEmitter<File[]>();

   @HostListener('dragover', ['$event']) ondragover(event) {
      event.preventDefault();
      event.stopPropagation();
      this.background = '#999';
   }

   @HostListener('dragleave', ['$event']) public onDragLeave(event) {
      event.preventDefault();
      event.stopPropagation();
      this.background = '#eee';
   }

   @HostListener('drop', ['$event']) onDrop(event) {
      event.preventDefault();
      event.stopPropagation();
      this.background = 'eee';
      const files: File[] = [];
      const fileList: FileList = event.dataTransfer.files;
      if (fileList.length > 0) {
         forEach(fileList, (file: File) => files.push(file));
         this.filesChange.emit(files);
         this.background = '#eee';
      }
   }
}

import { Component, EventEmitter, Input, Output } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { Pagination, TypedSort } from '@app/types/core';
import { PopoverFilterOption } from '@app/shared/filters';
import { Monitoring } from '../../types/monitoring';
import { MonitoringListFilters } from '../../types/monitoring-filters';

@Component({
   selector: 'app-monitoring-list',
   templateUrl: './monitoring-list.component.html',
   styleUrls: ['./monitoring-list.component.scss']
})
export class MonitoringListComponent {
   @Input() monitoringCases: Monitoring[];
   @Input() legalTeamOptions: PopoverFilterOption[];
   @Input() funderOptions: PopoverFilterOption[];
   @Input() statusOptions: PopoverFilterOption[];
   @Input() filters: MonitoringListFilters;
   @Input() loading = false;
   @Input() count: number;
   @Input() page: Pagination;
   @Input() sort: TypedSort<Monitoring>;
   @Output() createMonitoring = new EventEmitter<Monitoring>();
   @Output() deleteMonitoring = new EventEmitter<Monitoring>();
   @Output() filtersChange = new EventEmitter<MonitoringListFilters>();
   @Output() sortChange = new EventEmitter<TypedSort<Monitoring>>();
   @Output() pageChange = new EventEmitter<Pagination>();
   @Output() clearFilters = new EventEmitter();

   public columnsToDisplay: string[] = ['number', 'client', 'caseName', 'primaryStaff', 'funder', 'status', 'opened', 'closed', 'more'];

   onFilter(value: Partial<MonitoringListFilters>): void {
      this.filtersChange.emit({ ...this.filters, ...value });
   }

   onPage({ pageIndex, pageSize }: PageEvent): void {
      this.pageChange.emit({ page: pageIndex + 1, pageSize });
   }

   onSort(sort: Sort): void {
      this.sortChange.emit(sort as TypedSort<Monitoring>);
   }
}
import { ChangeDetectionStrategy, Component, forwardRef, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { map } from 'rxjs/operators';
import { FilterControl } from '../filter-control';
import { PopoverFilterTextValue, PopoverFilterType, TextFilterOperator } from '../popover-filter.models';

@Component({
   selector: 'au-popover-filter-text[filterControlName]',
   changeDetection: ChangeDetectionStrategy.OnPush,
   templateUrl: './text-filter.component.html',
   providers: [{ provide: FilterControl, useExisting: forwardRef(() => TextFilterComponent) }],
   styleUrls: ['./text-filter.component.scss']
})
export class TextFilterComponent extends FilterControl implements OnInit {
   ngOnInit(): void {
      this.form = new FormGroup({
         operator: new FormControl(TextFilterOperator.startsWith, Validators.required),
         text: new FormControl(null, Validators.required)
      });
   }

   clear(): void {
      this.form.setValue({
         operator: TextFilterOperator.startsWith,
         text: null
      });
   }

   registerOnChanges(fn: (value: any) => any): void {
      this.form.valueChanges.pipe(
         map((value): PopoverFilterTextValue | null => value.text ? ({
            type: PopoverFilterType.text,
            ...value
         }) : null)
      ).subscribe(fn);
   }

   writeValue(value: any): void {
      const {
         operator = TextFilterOperator.startsWith,
         text = null
      } = value ?? {};

      this.form.setValue({ operator, text }, { emitEvent: false });
   }
}

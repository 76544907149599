import { downgradeComponent, downgradeInjectable } from '@angular/upgrade/static';
import { EligibilityListViewComponent } from './eligibility/list/views/eligibility-list-view.component';
import { FundingService } from './funding.service';

export function fundingDowngrader(app: any) {
   app.factory(FundingService.injectName, downgradeInjectable(FundingService));
   app.directive('amFundingEligibilityView', downgradeComponent({
      component: EligibilityListViewComponent,
      inputs: ['involvementId', 'sourceType']
   }));
}
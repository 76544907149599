import { Component, EventEmitter, forwardRef, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, FormBuilder, FormControl, FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
   selector: 'am-active-filter',
   templateUrl: './active-filter.component.html',
   styleUrls: ['./active-filter.component.scss'],
   providers: [{
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ActiveFilterComponent),
      multi: true
   }],
})
export class ActiveFilterComponent implements OnInit, ControlValueAccessor {
   isActive: FormControl;
   valueChanged: EventEmitter<boolean> = new EventEmitter<boolean>();

   constructor(
      private formBuilder: FormBuilder) {
   }

   ngOnInit() {
      this.isActive = new FormControl();
   }

   registerOnChange(fn) {
      this.isActive.valueChanges.subscribe(result => {
         switch (true) {
            case result.length === 1 && result[0] === 0:
               fn(false);
               break;
            case result.length === 1 && result[0] === 1:
               fn(true);
               break;
            default:
               fn(null);
         }
      });
   }

   registerOnTouched() { }

   writeValue(value: boolean) {
      switch (value) {
         case true:
            this.isActive.setValue([1]);
            break;
         case false:
            this.isActive.setValue([0]);
            break;
         default:
            this.isActive.setValue([0, 1]);
      }
   }
}

import { Component, EventEmitter, forwardRef, Input, OnChanges, OnInit } from '@angular/core';
import { AbstractControl, ControlContainer, ControlValueAccessor, FormControl, FormGroup, FormGroupDirective, NG_VALIDATORS, NG_VALUE_ACCESSOR, Validator, Validators } from '@angular/forms';
import { ConfigurationService } from '../../../configuration/configuration.service';
import { map, switchMap, switchMapTo } from 'rxjs/operators';
import { Observable, of } from 'rxjs';

@Component({
   selector: 'am-radiobutton-group',
   templateUrl: './radiobutton-group.component.html',
   styleUrls: ['./radiobutton-group.component.scss'],
   providers: [{
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RadiobuttonGroupComponent),
      multi: true
   }, {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => RadiobuttonGroupComponent),
      multi: true
   }],
   viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }]
})
export class RadiobuttonGroupComponent implements OnInit, OnChanges, ControlValueAccessor, Validator {
   @Input() label: string;
   @Input() stringValues: string[];
   @Input() lookupType: string;
   @Input() required: boolean;

   values: string[] = [];
   radiobuttonGroup: FormControl;
   selectedValue: string;

   constructor(
      public form: FormGroupDirective,
      private configurationService: ConfigurationService) { }

   ngOnInit() {
      this.radiobuttonGroup = new FormControl();
      if (this.required) {
         this.radiobuttonGroup.setValidators(Validators.required);
      }

      let getValuesObservable: Observable<string[]>;
      if (this.lookupType) {
         getValuesObservable = this.configurationService.getLookupType(this.lookupType)
            .pipe(
               switchMap(lookupType => of(lookupType.lookups)),
               switchMap(lookups => of(lookups.map(lookup => lookup.value))
               ));
      } else {
         getValuesObservable = of(this.stringValues);
      }

      getValuesObservable.subscribe(values => {
         this.values = values;
      });

   }

   ngOnChanges(changes) {

   }

   validate() {
      return this.radiobuttonGroup.errors;
   }

   registerOnChange(fn) {
      this.radiobuttonGroup.valueChanges.subscribe(fn);
   }

   registerOnTouched() { }

   writeValue(selectedValue: string) {
      this.radiobuttonGroup.setValue(selectedValue);
   }
}






import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MonitoringListService } from '../../services/monitoring-list.service';
import { GetMonitoringListPayload } from '../../types/monitoring-params';

@Component({
   selector: 'app-monitoring-list-toolbar',
   templateUrl: './monitoring-list-toolbar.component.html',
   styleUrls: ['./monitoring-list-toolbar.component.scss']
})
export class MonitoringListToolbarComponent implements OnInit {
   private _activeOnly: boolean;
   @Input() payload: GetMonitoringListPayload;
   @Input()
   get activeOnly(): boolean {
      return this._activeOnly;
   }
   set activeOnly(value: boolean) {
      this._activeOnly = value;
      this.setActiveOnlyText()
   }
   @Output() addMonitoring = new EventEmitter();
   @Output() searchChange = new EventEmitter<string>();
   @Output() activeOnlyChange = new EventEmitter<boolean>();
   @Output() toggleChange = new EventEmitter<boolean>();

   toggle: boolean = false;
   activeOnlyText: string;

   constructor(
      private service: MonitoringListService
   ) { }

   ngOnInit(): void {
      this.searchChange.emit(null)
   }

   export = () => {
      this.service.getMonitoringExport(this.payload).subscribe();
   }

   activeOnlyToggle() {
      this.activeOnly = !this.activeOnly;
      this.activeOnlyChange.emit(this.activeOnly);
   }

   setActiveOnlyText() {
      this.activeOnlyText = this.activeOnly ? 'Click to include Inactive Cases' : 'Click to exclude Inactive Cases'
   }

   onToggle() {
      this.toggle = !this.toggle;
      this.toggleChange.emit(this.toggle);
   }
}
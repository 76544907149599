import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LookupModel } from '@app/configuration/models/configuration.models';
import { NotificationService } from '@app/core';
import { IAndRListService } from '@app/i-and-rs/list/services/i-and-rs-list.service';
import { IAndRCreate } from '@app/i-and-rs/list/types/i-and-rs-create';
import { StateService } from '@uirouter/core';
import * as moment from 'moment';


@Component({
   templateUrl: './i-and-rs-create.dialog.html',
   styleUrls: ['./i-and-rs-create.dialog.scss']
})
export class IAndRCreateDialog implements OnInit {
   form: FormGroup;
   statuses: LookupModel[];
   today: moment.Moment;
   submitting: boolean;

   constructor(
      private readonly dialogRef: MatDialogRef<IAndRCreate>,
      private readonly formBuilder: FormBuilder,
      private readonly service: IAndRListService,
      private readonly stateService: StateService,
      private notifier: NotificationService,
      @Inject(MAT_DIALOG_DATA)
      public readonly data: IAndRCreate) { }

   static open(dialog: MatDialog, data: IAndRCreate) {
      return dialog.open(IAndRCreateDialog, {
         width: '900px',
         panelClass: 'no-padding',
         disableClose: true,
         autoFocus: true,
         data: data || {}
      });
   }

   ngOnInit(): void {
      this.today = moment().startOf('day');
      this.setupForm();
   }

   private setupForm() {
      const { openDate = null } = this.data ?? {};

      this.form = this.formBuilder.group({
         openDate: [openDate ? moment(openDate) : this.today, Validators.required]
      });
   }

   submit(): void {
      this.submitting = true;
      const formData = this.form.getRawValue();

      const data: IAndRCreate = {
         openDate: formData.openDate.format('YYYY-MM-DD')
      };

      this.service.createIAndRCase(data)
         .subscribe((result) => {
            this.submitting = false;
            this.stateService.go('app.informationAndReferrals.overview', { informationAndReferralId: result });
            this.notifier.notify('I&R Case Created', 'Done');
            this.dialogRef.close()
         });
   }
}
import { GrievanceListComponent } from './grievance-list/grievance-list.component';
import { GrievancesListView } from './list/view/grievances-list.view';

export const states = [
   {
      name: 'app.grievanceListOld',
      component: GrievanceListComponent,
      url: '/grievance-list-old',
      data: {
         title: 'List',
         amPermissions: ['grievances.view']
      },
   },
   {
      name: 'app.grievanceList',
      component: GrievancesListView,
      url: '/grievance-list',
      data: {
         title: 'List',
         amPermissions: ['grievances.view']
      }
   },
];
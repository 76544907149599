import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { LookupModel } from '../../../configuration/models/configuration.models';

@Component({
   selector: 'am-user-type-filter-card',
   templateUrl: './user-type-filter-card.component.html',
   styleUrls: ['./user-type-filter-card.component.scss'],
   providers: [{
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => UserTypeFilterCardComponent),
      multi: true
   }],
})
export class UserTypeFilterCardComponent implements OnInit, ControlValueAccessor {
   @Input() roles: LookupModel[];
   roleIds: FormControlTyped<string[]>;

   ngOnInit() {
      this.roleIds = new FormControl([]);
   }

   registerOnChange(fn) {
      this.roleIds.valueChanges.subscribe(fn);
   }

   registerOnTouched() { }

   writeValue(value: string[]) {
      this.roleIds.setValue(Object.values(value || []));
   }
}
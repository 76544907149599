import { Component, Input } from '@angular/core';
import { Next10CalendarEventsModel } from '../../calendars/models/calendars.models';

@Component({
      selector: 'am-upcoming-events',
      templateUrl: './upcoming-events.component.html',
      styleUrls: ['./upcoming-events.component.scss']
   })
export class UpcomingEventsComponent {
   @Input() events: Next10CalendarEventsModel;

   columnsToDisplay: string[] = ['start', 'staff', 'title', 'description'];
}

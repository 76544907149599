import { Transition } from '@uirouter/core';
import { SourceModel } from '../core/models/source.model';
import { CreateEmailComponent } from '../mail/create-email/create-email.component';
import { TrainingListComponent } from './training-list/training-list.component';
import { TrainingRepositoryService } from './training.repository';

export const states = [
   {
      name: 'app.training.emails.create',
      component: CreateEmailComponent,
      url: '/new/email',
      resolve: {
         source: resolveSource
      },
      data: {
         title: 'New Message',
         amPermissions: ['mail.send']
      }
   },
   {
      name: 'app.trainingList',
      component: TrainingListComponent,
      url: '/training-list',
      data: {
         title: 'List',
         amPermissions: ['trainings.view']
      }
   }
];

resolveSource.$inject = ['$transition$'];
export function resolveSource(transition: Transition) {
   const { trainingId } = transition.params();
   const repository: TrainingRepositoryService = transition.injector().get(TrainingRepositoryService);
   return repository.getTraining(trainingId).toPromise().then(
      t => {
         return {
            source: 'Training',
            sourceId: t.id,
            sourceName: t.title
         } as SourceModel;
      }
   );
}



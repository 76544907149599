import { Injectable } from '@angular/core';
import { HttpService } from '@app/core/index';
import { Observable } from 'rxjs';
import { Program, Question } from '@app/funding/eligibility/form/types';
import { Eligibility } from '../../types';


@Injectable({
   providedIn: 'root'
})
export class EligibilityFormService {
   static injectName = 'eligibilityService';
   private baseUrl = 'api/funding/eligibility';

   constructor(private http: HttpService) { }

   getEligibilityPrograms(sourceType: string): Observable<Program[]> {
      const url = `${this.baseUrl}/programs`;
      return this.http.get<Program[]>(url, { params: { sourceType: sourceType } });
   }

   getEligibilityQuestions(): Observable<Question[]> {
      const url = `${this.baseUrl}/questions`;
      return this.http.get<Question[]>(url);
   }

   getEligibilityQuestionResponses(eligibilityId: string): Observable<Response[]> {
      const url = `${this.baseUrl}/${eligibilityId}/responses`;
      return this.http.get<Response[]>(url);
   }

   createEligibility(data: Eligibility): Observable<Eligibility> {
      const url = `${this.baseUrl}/${data.involvementId}/eligibility`;
      return this.http.post<Eligibility>(url, data);
   }

   updateEligibility(data: Eligibility) {
      const url = `${this.baseUrl}/${data.involvementId}`;
      return this.http.put<Eligibility>(url, data);
   }
}
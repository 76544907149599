import { ChangeDetectionStrategy, Component, forwardRef, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import moment from 'moment';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { FilterControl } from '../filter-control';
import { DateFilterOperator, PopoverFilterDateValue, PopoverFilterType } from '../popover-filter.models';

@Component({
   selector: 'au-popover-filter-date[filterControlName]',
   changeDetection: ChangeDetectionStrategy.OnPush,
   providers: [{ provide: FilterControl, useExisting: forwardRef(() => DateFilterComponent) }],
   templateUrl: './date-filter.component.html',
   styleUrls: ['./date-filter.component.scss']
})
export class DateFilterComponent extends FilterControl implements OnInit {
   ngOnInit(): void {
      const operator = new FormControl(DateFilterOperator.isEqualTo, Validators.required);
      const date = new FormControl(null, Validators.required);
      const date2 = new FormControl({ value: null, disabled: true }, Validators.required);

      this.form = new FormGroup({
         operator: operator,
         date: date,
         date2: date2
      }) as FormGroup;

      operator.valueChanges.pipe(
         map((value: DateFilterOperator) => value === DateFilterOperator.isBetween),
         distinctUntilChanged()
      ).subscribe(enabled => enabled ? date2.enable() : date2.disable());
   }

   isBetween(): boolean {
      return this.form.value.operator === DateFilterOperator.isBetween;
   }

   clear(): void {
      this.form.setValue({
         operator: DateFilterOperator.isEqualTo,
         date: null,
         date2: null
      });
   }

   registerOnChanges(fn: (value: any) => any): void {
      this.form.valueChanges.pipe(
         map((value): PopoverFilterDateValue | null => this.form.valid ? ({
            type: PopoverFilterType.date,
            operator: value.operator,
            date: value.date ? moment(value.date).format('YYYY-MM-DD') : null,
            date2: value.date2 ? moment(value.date2).format('YYYY-MM-DD') : null
         }) : null)
      ).subscribe(fn);
   }

   writeValue(value: any): void {
      const {
         operator = DateFilterOperator.isEqualTo,
         date = null,
         date2 = null
      } = value ?? {};

      this.form.setValue({ operator, date, date2 }, { onlySelf: false });
   }
}

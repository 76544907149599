import { Injectable } from '@angular/core';
import { HttpService, PagedList } from '../core/index';
import { GetProjectListParams, ProjectListItemModel, ProjectModel } from './models/projects.models';
import { Observable } from 'rxjs';
import { HttpResponse } from '@angular/common/http';
import { tap } from 'rxjs/operators';
import { getFileNameFromResponseContentDisposition, saveFile } from '../shared';

@Injectable()
export class ProjectsRepositoryService {
   static injectName = 'projectsServie';
   private baseUrl = 'api/projects';

   constructor(private http: HttpService) { }

   getProject(projectId: string) {
      const url = `${this.baseUrl}/${projectId}`;
      return this.http.get<ProjectModel>(url);
   }

   getProjectList(data: GetProjectListParams): Observable<PagedList<ProjectListItemModel>> {
      const url = `${this.baseUrl}/list`;
      return this.http.post<PagedList<ProjectListItemModel>>(url, data);
   }

   getProjectListDownload(data: GetProjectListParams) {
      const url = `${this.baseUrl}/list/csv`;
      return this.http.post<HttpResponse<Blob>>(url, data, { responseType: 'blob', observe: 'response' }).pipe(
         tap(res => {
            const fileName = getFileNameFromResponseContentDisposition(res);
            saveFile(res.body, fileName);
         })
      );
   }

   deleteProject(id: string) {
      return this.http.delete(`${this.baseUrl}/${id}`);
   }
}

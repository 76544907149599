import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';

import { UIRouterUpgradeModule } from '@uirouter/angular-hybrid';
import { FiltersModule } from '../filters/filters.module';
import { ProjectsListModule } from './list/projects-list.module';
import { ProjectListComponent } from './project-list/project-list.component';
import { ProjectsRepositoryService } from './projects.repository';
import { states } from './projects.states';

@NgModule({
   imports: [
      UIRouterUpgradeModule.forChild({ states }),
      SharedModule,
      FiltersModule,
      ProjectsListModule
   ],
   declarations: [
      ProjectListComponent
   ],
   exports: [
   ],
   providers: [
      ProjectsRepositoryService
   ]
})
export class ProjectsModule {
}

import { Directive, OnDestroy, OnInit } from '@angular/core';
import { FormGroupDirective } from '@angular/forms';
import { Subscription } from 'rxjs';

import { InterruptService } from '../../services/interrupt.service';

@Directive({
   selector: '[amFormInterrupt]'
})
export class FormInterruptDirective implements OnInit, OnDestroy {
   constructor(
      private formGroup: FormGroupDirective,
      private interrupt: InterruptService
   ) { }

   private subsc: Subscription;

   ngOnInit() {
      this.subsc = this.formGroup.statusChanges.subscribe(() => this.handler());
      this.handler();
   }

   ngOnDestroy() {
      if (this.subsc) {
         this.subsc.unsubscribe();
      }
      if (this.interrupt) {
         this.interrupt.allowNavigation();
      }
   }

   private handler() {
      if (this.formGroup.pristine) {
         this.interrupt.allowNavigation();
      } else {
         this.interrupt.preventNavigation();
      }
   }
}

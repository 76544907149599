import { NgModule } from '@angular/core';
import { ActivitiesStatusChangeComponent } from '@app/activities/status-change/status-change.component';
import { FiltersModule } from '../filters/filters.module';
import { SharedModule } from '../shared/shared.module';
import { ActivitiesRepositoryService } from './activities.repository';


@NgModule({
   imports: [
      SharedModule,
      FiltersModule
   ],
   declarations: [
      ActivitiesStatusChangeComponent
   ],
   exports: [
   ],
   providers: [
      ActivitiesRepositoryService
   ],
   entryComponents: [

   ]
})
export class ActivitiesModule {

}

import { downgradeComponent, downgradeInjectable } from '@angular/upgrade/static';
import { IAndRsRepositoryService } from './i-and-rs.repository';
import { IAndRCovidVaccineComponent } from './covid-vaccine/covid-vaccine.component';

export function iAndRsDowngrader(app: any) {
   app.factory(IAndRsRepositoryService.injectName, downgradeInjectable(IAndRsRepositoryService));
   app.directive('amInformationAndReferralsCovidVaccine', downgradeComponent({
      component: IAndRCovidVaccineComponent,
      inputs: ['involvementId', 'sourceId']
   }));
}
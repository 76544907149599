import { EligibilityFormViewComponent } from '@app/funding/eligibility/form';
import { Transition } from '@uirouter/core';
import { SourceModel } from '../core/models/source.model';
import { StatuteOfLimitationsListComponent } from '../involvements/statute-of-limitations/statute-of-limitations-list.component';
import { CreateEmailComponent } from '../mail/create-email/create-email.component';
import { SystemicCasesListView } from './list/view/systemic-cases-list.view';
import { SystemicCasesRepositoryService } from './systemic-cases.repository';

export const states = [
   {
      name: 'app.systemicCases.statuteOfLimitations',
      component: StatuteOfLimitationsListComponent,
      url: '/statuteOfLimitations',
      data: {
         title: 'SOL',
      }
   },
   {
      name: 'app.systemicCases.emails.create',
      component: CreateEmailComponent,
      url: '/new/email',
      resolve: {
         source: resolveSource
      },
      data: {
         title: 'New Message',
         amPermissions: ['mail.send']
      }
   },
   {
      name: 'app.systemicCaseList',
      component: SystemicCasesListView,
      url: '/systemic-case-list',
      params: {
         rolesFilter: null,
         usersFilter: null,
         usePreferences: null,
         initializeSelected: null
      },
      resolve: {
         rolesFilter: rolesFilterResolver,
         usersFilter: usersFilterResolver,
         usePreferences: usePreferencesResolver,
         initializeSelected: initializeSelectedResolver
      },
      data: {
         title: 'List',
         amPermissions: ['systemic-cases.view']
      }
   },
   {
      name: 'app.systemicCaseListDashboard',
      component: SystemicCasesListView,
      url: '/systemic-case-list-dashboard',
      params: {
         rolesFilter: null,
         usersFilter: null,
         usePreferences: null,
         initializeSelected: null
      },
      resolve: {
         rolesFilter: rolesFilterResolver,
         usersFilter: usersFilterResolver,
         usePreferences: usePreferencesResolver,
         initializeSelected: initializeSelectedResolver
      },
      data: {
         title: 'List',
         amPermissions: ['systemic-cases.view']
      }
   },
   {
      name: 'app.systemicCases.funding.eligibility.eligibilityFormView',
      url: '/form',
      params: {
         involvementId: null,
         eligibility: {},
         eligibilityList: [],
         readOnly: false,
         sourceType: 'Systemic Case'
      },
      component: EligibilityFormViewComponent,
      resolve: {
         involvementId: involvementIdResolver,
         eligibility: eligibilityResolver,
         eligibilityList: eligibilityListResolver,
         readOnly: readOnlyResolver,
         sourceType: sourceTypeResolver
      },
      data: {
         title: 'Eligibility Form',
         amPermissions: ['funding.create']
      }
   }
];

rolesFilterResolver.$inject = ['$transition$'];
export function rolesFilterResolver($transition$) {
   const { rolesFilter } = $transition$.params();
   return rolesFilter;
}

usersFilterResolver.$inject = ['$transition$'];
export function usersFilterResolver($transition$) {
   const { usersFilter } = $transition$.params();
   return usersFilter;
}

initializeSelectedResolver.$inject = ['$transition$'];
export function initializeSelectedResolver($transition$) {
   const { initializeSelected } = $transition$.params();
   return initializeSelected;
}

usePreferencesResolver.$inject = ['$transition$'];
export function usePreferencesResolver($transition$) {
   const { usePreferences } = $transition$.params();
   return usePreferences;
}

eligibilityResolver.$inject = ['$transition$'];
export function eligibilityResolver($transition$) {
   const { eligibility } = $transition$.params();
   return eligibility;
}

eligibilityListResolver.$inject = ['$transition$'];
export function eligibilityListResolver($transition$) {
   const { eligibilityList } = $transition$.params();
   return eligibilityList;
}

involvementIdResolver.$inject = ['$transition$'];
export function involvementIdResolver($transition$) {
   const { involvementId } = $transition$.params();
   return involvementId;
}

sourceTypeResolver.$inject = ['$transition$'];
export function sourceTypeResolver() {
   const sourceType = 'Systemic Case';
   return sourceType;
}

readOnlyResolver.$inject = ['$transition$'];
export function readOnlyResolver($transition$) {
   const { readOnly } = $transition$.params();
   return readOnly;
}

resolveSource.$inject = ['$transition$'];
export function resolveSource(transition: Transition) {
   const { systemicCaseId } = transition.params();
   const repository: SystemicCasesRepositoryService = transition.injector().get(SystemicCasesRepositoryService);
   return repository.getBasicInfo(systemicCaseId).toPromise().then(
      sc => {
         return {
            source: 'Systemic Case',
            sourceId: sc.id,
            involvementId: sc.involvementId,
            sourceNumber: sc.idNumber,
            sourceName: sc.name
         } as SourceModel;
      }
   );
}

import { Component } from '@angular/core';
import { Input } from '@angular/core';

@Component({
   selector: 'am-timeslips-period',
   templateUrl: './timeslips-period.component.html',
   styleUrls: ['./timeslips-period.component.scss']
})
export class TimeslipsByPayPeriodComponent {
   @Input() payPeriods: any[];
}

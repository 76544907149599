import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { EntityNameSearchResultsComponent, EntityNameSearchView } from './advanced-search';
import { DocumentChipsComponent } from './document-chips/document-chips.component';
import { EmailAddressChipsComponent } from './email-address-chips/email-address-chips.component';
import { SearchRepositoryService } from './search.repository';
import { UserChipsComponent } from './user-chips/user-chips.component';


@NgModule({
   imports: [
      SharedModule
   ],
   declarations: [
      UserChipsComponent,
      EmailAddressChipsComponent,
      DocumentChipsComponent,
      EntityNameSearchView,
      EntityNameSearchResultsComponent
   ],
   providers: [
      SearchRepositoryService
   ],
   exports: [
      UserChipsComponent,
      EmailAddressChipsComponent,
      DocumentChipsComponent
   ],
   entryComponents: [
   ]
})
export class SearchModule { }

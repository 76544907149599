import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ProjectListService } from '@app/projects/list/services';
import { GetProjectListPayload } from '@app/projects/list/types';

@Component({
   selector: 'app-projects-list-toolbar',
   templateUrl: './projects-list-toolbar.component.html',
   styleUrls: ['./projects-list-toolbar.component.scss']
})
export class ProjectsListToolbarComponent implements OnInit {
   private _activeOnly: boolean;
   @Input() payload: GetProjectListPayload;
   @Input()
   get activeOnly(): boolean {
      return this._activeOnly;
   }
   set activeOnly(value: boolean) {
      this._activeOnly = value;
      this.setActiveOnlyText()
   }

   @Output() addProject = new EventEmitter();
   @Output() searchChange = new EventEmitter<string>();
   @Output() activeOnlyChange = new EventEmitter<boolean>();
   @Output() toggleChange = new EventEmitter<boolean>();

   toggle: boolean = false;
   activeOnlyText: string;

   constructor(
      private service: ProjectListService
   ) { }

   ngOnInit(): void {
      this.searchChange.emit(null)
   }

   export = () => {
      this.service.getProjectsExport(this.payload).subscribe();
   }

   activeOnlyToggle() {
      this.activeOnly = !this.activeOnly;
      this.activeOnlyChange.emit(this.activeOnly);
   }

   setActiveOnlyText() {
      this.activeOnlyText = this.activeOnly ? 'Click to include Inactive Projects' : 'Click to exclude Inactive Projects'
   }

   onToggle() {
      this.toggle = !this.toggle;
      this.toggleChange.emit(this.toggle);
   }
}
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable, of, Subject } from 'rxjs';

import { ConfigurationProviderService } from './configuration-provider.service';
import { ENV } from '@app/env.config';
declare var hello: any;
const helloNetwork = {
   b2c_sisu: 'b2c_sisu',
   b2c_reset: 'b2c_reset'
};

const policies = {
   signInSignUp: 'b2c_1_susi',
   resetPassword: 'b2c_1_reset'
};

const scope = ENV.OID_SCOPE;
const response_type = 'token id_token';
const redirect_uri = window.location.href.split('?')[0].split('#')[0] + 'callback';

const loginDisplayType = {
   Popup: 'popup',
   None: 'none',
   Page: 'page'
};

/**
 * Wrapper for hello.js
 */
@Injectable()
export class OidcService {
   private accessTokenSubject = new Subject<string>();
   accessTokenObservable = this.accessTokenSubject.asObservable();

   constructor(
      private snackBar: MatSnackBar,
      private configurationProvider: ConfigurationProviderService) {
   }

   /**
    * Login returns an Observable<string> containing
    * a user's latest access token
    */
   login(): Observable<string> {
      if (this.configurationProvider.config.bypassAuth) {
         return of('BYPASS');
      }

      const { applicationId } = this.configurationProvider.config;
      const network = {
         b2c_sisu: applicationId,
         b2c_reset: applicationId
      };

      const options = {
         redirect_uri,
         scope,
         response_type
      };

      hello.init(network, options);

      hello.on('auth.login', auth => {
         this.accessTokenSubject.next(auth.authResponse.access_token);
      });

      if (this.isPasswordResetCanceled()) {
         this.clearPasswordResetSession();
      } else if (this.isPasswordResetRequested()) {
         this.resetPassword();
      } else if (!this.isOnline()) {
         this.policyLogin(helloNetwork.b2c_sisu, loginDisplayType.Page);
      }

      return this.accessTokenObservable;
   }

   loginSilent() {
      if (this.configurationProvider.config.bypassAuth) {
         return of('BYPASS').toPromise();
      }

      return hello(helloNetwork.b2c_sisu)
         .login({ display: loginDisplayType.None, force: false })
         .then(auth => { }, e => {
            if ('Iframe was blocked' in e.error.message) {
               this.policyLogin(helloNetwork.b2c_sisu, loginDisplayType.Page);
               return;
            }
            this.snackBar.open('Signin error: ' + e.error.message);
         });
   }

   /**
    * Start logout lifecycle
    */
   logout() {
      this.policyLogout(helloNetwork.b2c_sisu);
   }

   private clearPasswordResetSession() {
      // Clear password reset session state
      hello.utils.store(helloNetwork.b2c_reset, null);
   }

   private isOnline() {
      return this.policyIsOnline(helloNetwork.b2c_sisu);
   }

   private isPasswordResetCanceled() {
      const session = hello(helloNetwork.b2c_reset).getAuthResponse();
      if (session && session.error) {
         const { message } = session.error;
         if (message && message.match(/AADB2C90091/)) {
            return true;
         }
      }

      return false;
   }

   private isPasswordResetRequested() {
      const session = hello(helloNetwork.b2c_sisu).getAuthResponse();
      if (session && session.error) {
         const { message } = session.error;
         if (message && message.match(/AADB2C90118/)) {
            return true;
         }
      }

      return false;
   }

   private policyIsOnline(network: string) {
      const session = hello(network).getAuthResponse();
      const currentTime = (new Date()).getTime() / 1000;
      return session && session.access_token && session.expires > currentTime;
   }

   private policyLogin(network: string, display: string) {
      if (!display) {
         display = loginDisplayType.Page;
      }

      // In case of silent renew, check if the session is still active, otherwise ask the user to login again
      if (!this.isOnline() && display === loginDisplayType.None) {
         const snackBarRef = this.snackBar.open('Session expired', 'Login');

         snackBarRef.onAction()
            .subscribe(() => this.policyLogin(network, loginDisplayType.Page));

         return;
      }

      hello(network)
         .login({ display }, console.log)
         .then(auth => { }, e => {
            if ('Iframe was blocked' in e.error.message) {
               this.policyLogin(network, loginDisplayType.Page);
               return;
            }
            this.snackBar.open('Signin error: ' + e.error.message);
         });
   }

   private policyLogout(network: string) {
      if (this.policyIsOnline(network)) {
         hello.logout(network, { force: true }).then(null, e => {
            this.snackBar.open('Logout error: ' + e.error.message);
         });
      }
   }

   private resetPassword() {
      this.policyLogin(helloNetwork.b2c_reset, loginDisplayType.Page);

      // Clear sign in/sign up session state
      hello.utils.store(helloNetwork.b2c_sisu, null);
   }
}

import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NotificationService } from '@app/core';
import { MonitoringListService } from '@app/monitoring/list/services/monitoring-list.service';
import { MonitoringCreate } from '@app/monitoring/list/types/monitoring-create';
import { StateService } from '@uirouter/core';
import * as moment from 'moment';


@Component({
   templateUrl: './monitoring-create.dialog.html',
   styleUrls: ['./monitoring-create.dialog.scss']
})
export class MonitoringCreateDialog implements OnInit {
   form: FormGroup;
   today: moment.Moment;
   submitting: boolean;

   constructor(
      private readonly dialogRef: MatDialogRef<MonitoringCreate>,
      private readonly formBuilder: FormBuilder,
      private readonly service: MonitoringListService,
      private readonly stateService: StateService,
      private notifier: NotificationService,
      @Inject(MAT_DIALOG_DATA)
      public readonly data: MonitoringCreate) { }

   static open(dialog: MatDialog, data: MonitoringCreate) {
      return dialog.open(MonitoringCreateDialog, {
         width: '900px',
         panelClass: 'no-padding',
         disableClose: true,
         autoFocus: true,
         data: data || {}
      });
   }

   ngOnInit(): void {
      this.today = moment().startOf('day');
      this.setupForm();
   }

   private setupForm() {
      const { openDate = null } = this.data ?? {};

      this.form = this.formBuilder.group({
         openDate: [openDate ? moment(openDate) : this.today, Validators.required]
      });
   }

   submit(): void {
      this.submitting = true;
      const formData = this.form.getRawValue();

      const data: MonitoringCreate = {
         openDate: formData.openDate.format('YYYY-MM-DD')
      };

      this.service.createMonitoring(data)
         .subscribe((result) => {
            this.submitting = false;
            this.stateService.go('app.monitoring.overview', { monitoringId: result });
            this.notifier.notify('Monitoring Created', 'Done');
            this.dialogRef.close()
         });
   }
}
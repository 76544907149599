import { createReducer, on } from '@ngrx/store';
import * as Actions from './actions';
import { initialState, State } from './state';

export const viewStateReducer = createReducer<State>(
   initialState,
   on(Actions.patchViewState, (state, action): State => ({
      ...state,
      viewState: {
         ...state.viewState,
         [action.key]: {
            ...state.viewState[action.key],
            ...action.value
         }
      }
   }))
);

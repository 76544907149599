import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { HttpService, PagedList } from '../core';
import { ENV } from '../env.config';
import { CaseloadModel, GetCaseloadsCriteria } from './models';

@Injectable()
export class CaseloadService {
   constructor(private http: HttpService) { }

   getCaseloads(criteria: GetCaseloadsCriteria): Observable<PagedList<CaseloadModel>> {
      let params = new HttpParams();
      if (criteria.page) {
         params = params.set('page', `${criteria.page}`);
      }
      if (criteria.pageSize) {
         params = params.set('pageSize', `${criteria.pageSize}`);
      }
      if (criteria.order) {
         params = params.set('order', `${criteria.order}`);
      }
      if (criteria.sortReversed) {
         params = params.set('sortReversed', `${criteria.sortReversed}`);
      }
      if (criteria.locationId) {
         params = params.set('locationId', `${criteria.locationId}`);
      }

      const requestUri = `${ENV.API_URL}/identity-management/caseloads`;
      return this.http.get<PagedList<CaseloadModel>>(requestUri, { params });
   }
}

import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { from, merge } from 'rxjs';
import { debounceTime, filter, map, switchMap, tap } from 'rxjs/operators';

import { AuthService, PreferenceService } from '../../core';
import { GetTimeslipListParams, TimeslipListItemModel } from '../models/time.models';
import { TimeRepositoryService } from '../time.repository';
import { ConfirmationService } from '../../shared';
import { UserService } from '../../users';

@Component({
   selector: 'am-timeslip-list',
   templateUrl: 'timeslip-list.component.html',
   styleUrls: ['timeslip-list.component.scss']
})
export class TimeslipListComponent implements OnInit {
   @Input() currentUserId: string;
   @ViewChild(MatSort, { static: true }) sort: MatSort;
   @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

   dataSource = new MatTableDataSource<TimeslipListItemModel>([]);
   loading = true;
   columnsToDisplay = ['date', 'staff', 'hours',
      'taskDescription', 'source', 'funding', 'actions'];
   returnedItemsCount = 0;
   timeslips: TimeslipListItemModel[];
   filterForm: FormGroup;
   usersFilter = [];
   dateFilter = {};
   showFilters = false;
   canUnlockTimeslips: boolean;
   totalHours = '0';

   constructor(
      private formBuilder: FormBuilder,
      private timeService: TimeRepositoryService,
      private preferenceService: PreferenceService,
      private confirmation: ConfirmationService,
      private userService: UserService,
      private auth: AuthService) {
   }

   ngOnInit() {
      this.initialize();
      this.subscribe();
      this.getTimeslipList();
   }

   toggleFilters() {
      this.showFilters = !this.showFilters;
   }

   checkCanUnlockTimeslips() {
      this.canUnlockTimeslips = this.auth.checkPermissions('timeslips.unlock');
   }

   getParameters() {
      const order = this.sort.active;
      this.dateFilter = this.filterForm.get('dateFilter').value;
      this.usersFilter = this.filterForm.get('usersFilter').value;
      this.paginator.pageSize = this.paginator.pageSize || 25;

      this.savePreferences();

      const params: GetTimeslipListParams = {
         order: this.sort.direction === 'desc' ? '-' + order : order,
         page: this.paginator.pageIndex + 1,
         pageSize: this.paginator.pageSize,
         ...this.dateFilter,
         userIds: this.usersFilter
      };

      return params;
   }

   getTimeslipList() {
      this.dataSource.data = [];

      this.loading = true;
      const params = this.getParameters();

      return this.timeService.getTimeslipList(params)
         .subscribe(data => {
            this.loading = false;
            this.returnedItemsCount = data.count;
            this.dataSource.data = data.list;
            const hours = data.list.map(t =>t.hours);
            if (data.list.length > 0) {
               this.totalHours = hours.reduce((total, num) =>total + num).toFixed(1);
            }
         });
   }

   export = () => {
      const params = this.getParameters();
      this.timeService.getTimeslipListDownload(params)
         .subscribe();
   }

   private subscribe() {
      this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);
      merge(this.sort.sortChange, this.paginator.page, this.filterForm.valueChanges)
      .pipe(
         debounceTime(500)
      )
      .subscribe(() => this.getTimeslipList());
   }

   private initialize() {
      this.canUnlockTimeslips = this.auth.checkPermissions('timeslips.unlock');

      this.loadPreferences();

      this.filterForm = this.formBuilder.group({
         dateFilter: this.dateFilter,
         usersFilter: [this.usersFilter]
      });
   }

   private loadPreferences(): any {
      const preferences: any = this.preferenceService.getPreference('timeslipList');
      if (preferences) {
         Object.assign(this.sort, preferences.sortSettings);
         Object.assign(this.paginator, preferences.paginatorSettings);
         this.usersFilter = preferences.usersFilter;
         Object.assign(this.dateFilter, preferences.dateFilter);
      }

      if (this.usersFilter.length === 0) {
         this.usersFilter = [this.currentUserId];
      }
   }

   private savePreferences() {
      const preferences = {
         sortSettings: { active: this.sort.active, direction: this.sort.direction },
         paginatorSettings: { pageSize: this.paginator.pageSize },
         dateFilter: this.dateFilter,
         usersFilter: this.usersFilter
      };
      this.preferenceService.setPreference('timeslipList', preferences);
   }

   showDeleteDialog(timeslip: TimeslipListItemModel) {
      this.confirmation.confirm('Delete Timeslip?', 'DELETE', 'CANCEL').pipe(
         filter(ok => ok),
         switchMap(() => this.timeService.deleteTimeslip(timeslip.id))
      ).subscribe(() => {
         const index = this.dataSource.data.indexOf(timeslip);
         this.dataSource.data = [
            ...this.dataSource.data.slice(0, Math.max(index, 0)),
            ...this.dataSource.data.slice(index + 1)
         ];
      });
   }

   clearFilters() {
      this.filterForm.patchValue({
         dateFilter: {},
         usersFilter: []
      });
   }
}




















import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { ErrorDialogData } from '../../models/error-dialog.models';

@Component({
   selector: 'am-error-dialog',
   templateUrl: './error-dialog.component.html',
   styleUrls: ['./error-dialog.component.scss']
})
export class ErrorDialogComponent {
   constructor(
      private dialogRef: MatDialogRef<ErrorDialogComponent>,
      @Inject(MAT_DIALOG_DATA) public data: ErrorDialogData) {
   }

   close(result: boolean = false) {
      this.dialogRef.close(result);
   }
}

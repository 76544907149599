import { Directive, Input, OnDestroy, OnInit } from '@angular/core';
import { FormGroupDirective, NgControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MatSnackBar, MatSnackBarRef, SimpleSnackBar } from '@angular/material/snack-bar';

@Directive({
   selector: '[amErrorToast]'
})
export class ErrorToastDirective implements OnInit, OnDestroy {
   private unsubscribe$ = new Subject();
   private _snackbarRef: MatSnackBarRef<SimpleSnackBar>;

   constructor(
      private form: FormGroupDirective,
      private snackbar: MatSnackBar) {
   }

   ngOnInit() {
      this.unsubscribe$.subscribe(() => {
         if (this._snackbarRef) {
            this._snackbarRef.dismiss();
            this._snackbarRef = null;
         }
      });

      this.form.ngSubmit.subscribe(() => {
         if (this.form.invalid) {
            this.showToast();
         }
      });
   }

   ngOnDestroy() {
      this.unsubscribe$.next();
      this.unsubscribe$.complete();
   }

   private showToast() {
      if (this._snackbarRef) return;

      this._snackbarRef = this.snackbar.open('Review the errors below', '', {
         verticalPosition: 'top',
         panelClass: 'am-snackbar-error'
      });

      const subscription = this.form.statusChanges.pipe(
         takeUntil(this.unsubscribe$)
      ).subscribe(status => {
         if (status !== 'INVALID') {
            this.unsubscribe$.next();
         }
      });
   }
}

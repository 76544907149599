import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';

import { CreateEmailComponent } from './create-email/create-email.component';
import { MailService } from './mail.service';
import { UIRouterUpgradeModule } from '@uirouter/angular-hybrid';
import { SearchModule } from '../search/search.module';
import { DocumentsModule } from '../documents/documents.module';

@NgModule({
   imports: [
      SharedModule,
      SearchModule,
      DocumentsModule
   ],
   declarations: [
      CreateEmailComponent
   ],
   exports: [
    ],
    entryComponents: [
    ],
   providers: [
       MailService
   ]
})
export class MailModule {
}

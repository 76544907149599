import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators} from '@angular/forms';
import * as moment from 'moment';
import { MeetingModel } from '../models/monitoring.models';
import { StateService } from '@uirouter/core';
import { MonitoringRepositoryService } from '../monitoring.repository';
import { finalize } from 'rxjs/operators';
import { ConfigurationService } from '../../configuration/configuration.service';
import { LookupModel } from '../../configuration/models/configuration.models';
import { InterruptService } from '../../shared';


@Component({
   selector: 'am-monitoring-meeting',
   templateUrl: 'meeting.component.html',
   styleUrls: ['meeting.component.scss']
})
export class MonitoringMeetingComponent implements OnInit {
   @Input() involvementId: string;
   @Input() sourceId: string;
   @Input() activityId: string;

   form: FormGroup;
   today: moment.Moment;
   maxDate: moment.Moment;
   submitting: boolean;
   meetingType: string[] = ['Transition Meeting', 'Team Meeting', 'Status Meeting'];
   statuses: LookupModel[];

   constructor(
      private formBuilder: FormBuilder,
      private monitoringService: MonitoringRepositoryService,
      private configurationService: ConfigurationService,
      private interruptService: InterruptService,
      private stateService: StateService) {
   }

   ngOnInit() {
      this.today = moment().startOf('day');
      this.maxDate = this.today;
      this.getStatuses();
      this.getStatus();
      this.form = this.formBuilder.group({
         meetingDate: this.today,
         meetingType: [undefined, Validators.required],
         description: [undefined, Validators.required],
         status: undefined
      });
      if (this.activityId) {
         this.getMeeting();
      }
   }

   private getStatuses() {
      this.configurationService.getLookupType('monitoring-status').subscribe(lt => {
         this.statuses = lt.lookups;
      });
   }

   private getMeeting() {
      this.monitoringService.getMeeting(this.activityId).subscribe(meeting => {
         this.form.patchValue({
            meetingDate: moment(meeting.meetingDate),
            meetingType: meeting.meetingType,
            description: meeting.description
         });
      });
   }

   private getStatus() {
      this.monitoringService.getStatus(this.sourceId).subscribe(status => {
         if (status) {
            this.form.patchValue({status: status.status});
         }
      });
   }

   cancel() {
      this.interruptService.allowNavigation();
      this.stateService.go('.^');
   }

   submit() {
      if (this.form.invalid) { return; }
      this.submitting = true;
      const formData = this.form.getRawValue();
      const data: MeetingModel = {
         id: this.activityId,
         involvementId: this.involvementId,
         monitoringId: this.sourceId,
         meetingDate: formData.meetingDate,
         meetingType: formData.meetingType,
         description: formData.description,
         status: formData.status
      };

      const observable = this.activityId
      ? this.monitoringService.updateMeeting(data)
      : this.monitoringService.createMeeting(data);

      observable
      .pipe(finalize(() => this.submitting = false))
      .subscribe(result => {
         if (result) {
            this.submitting = false;
            this.interruptService.allowNavigation();
            this.stateService.go('.^');
         }
      });
   }

}

import { ConnectionPositionPair, Overlay, PositionStrategy } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import {
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  HostListener,
  Injector,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import * as moment from 'moment';
import { filter, finalize } from 'rxjs/operators';

import { DateFilterOverlayRef } from './date-filter-overlay-ref';
import { DateFilterOverlayComponent } from './date-filter-overlay.component';
import { DateFilterChangeEvent, DATE_FILTER_DATA, DATE_FILTER_PRESETS } from './date-filter.models';

@Component({
   selector: 'app-date-filter',
   templateUrl: './date-filter-toggle.component.html',
   styleUrls: ['./date-filter-toggle.component.scss']
})
export class DateFilterToggleComponent implements OnInit {
   constructor(
      private el: ElementRef,
      private injector: Injector,
      private overlay: Overlay
   ) { }

   data: DateFilterChangeEvent;
   private overlayRef: DateFilterOverlayRef;

   @HostBinding('class') classes = 'app-date-filter';
   @Input() value: number | string;
   @Output() change = new EventEmitter<DateFilterChangeEvent>();

   ngOnInit() {
      const today = moment().startOf('day');
      const preset = DATE_FILTER_PRESETS.find(p => p.delta === this.value);
      const value = preset ? today.add(preset.delta, 'days') : moment(<string>this.value, 'YYYY-MM-DD');
      this.data = { preset, value };
   }

   @HostListener('click')
   handleClick() {
      if (!this.overlayRef) {
         this.openMenu();
      }
   }

   private closeMenu() {
      this.overlayRef.close();
   }

   private createInjector(filterDropdownOverlayRef: DateFilterOverlayRef) {
      return Injector.create({
         parent: this.injector,
         providers: [
            { provide: DateFilterOverlayRef, useValue: filterDropdownOverlayRef },
            { provide: DATE_FILTER_DATA, useValue: this.value }
         ]
      });
   }

   private createPositionStrategy(target: ElementRef): PositionStrategy {
      return this.overlay
         .position()
         .flexibleConnectedTo(target)
         .withPositions([new ConnectionPositionPair({ originX: 'end', originY: 'bottom' }, { overlayX: 'end', overlayY: 'top' })]);
   }

   private openMenu() {
      const positionStrategy = this.createPositionStrategy(this.el);
      const overlayRef = this.overlay.create({
         positionStrategy,
         hasBackdrop: true,
         backdropClass: 'am-overlay-backdrop-transparent'
      });
      this.overlayRef = new DateFilterOverlayRef(overlayRef);
      const filterDropdownPortal = new ComponentPortal(
         DateFilterOverlayComponent,
         null,
         this.createInjector(this.overlayRef)
      );

      overlayRef.attach(filterDropdownPortal);

      // Close filter on click outside
      overlayRef.backdropClick().subscribe(() => this.closeMenu());

      this.overlayRef.afterClosed().pipe(
         filter(result => !!result),
         finalize(() => this.overlayRef = null)
      ).subscribe(result => {
         this.data = result;
         this.change.emit(this.data);
      });
   }
}

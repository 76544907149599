import { Injectable } from "@angular/core";
import { Sort } from "@angular/material/sort";
import { PaginationCriteria } from "@app/core/models";
import { ComponentStore, tapResponse } from '@ngrx/component-store';
import { filter, switchMap } from "rxjs/operators";
import { createSearch } from "../services/entity-name-search.helper";
import { EntityNameSearchService } from "../services/entity-name-search.service";
import { Entity } from "../types/entity";
import { EntitySearch } from "../types/entitySearchParams";
import { Page } from "../types/page";


interface EntityNameSearchState {
   q: string;
   type: string;
   entities: Entity[];
   count: number;
   page: Page;
   sort: Sort;
   loading: boolean,
}

const initialState: EntityNameSearchState = {
   q: null,
   type: 'Person',
   entities: [],
   count: 0,
   page: {
      pageIndex: 0,
      pageSize: 10
   },
   sort: {
      active: null,
      direction: null
   },
   loading: false,
}

@Injectable()
export class EntityNameSearchStore extends ComponentStore<EntityNameSearchState>{

   constructor(
      private service: EntityNameSearchService) {
      super(initialState);
   }

   private readonly q$ = this.select(state => state.q);
   private readonly type$ = this.select(state => state.type);
   private readonly sort$ = this.select(state => state.sort);
   private readonly page$ = this.select(state => state.page);

   private readonly search$: Observable<EntitySearch> = this.select(
      this.q$,
      this.type$,
      this.sort$,
      this.page$,
      (q, type, sort, page) => createSearch(q, type, sort, page)
   );

   public readonly vm$ = this.select(
      this.state$,
      this.search$,
      ({ entities, type, page, sort, count, loading }) =>
         ({ entities, type, page, sort, count, loading })
   );

   public readonly updateQuery = this.updater((state, q: string) => ({
      ...state, q
   }));

   public readonly updateType = this.updater((state, type: string) => ({
      ...state, type
   }));

   public readonly updateEntities = this.updater((state, entities: Entity[]) => ({
      ...state, entities
   }));

   public readonly updateSort = this.updater((state, sort: Sort) => ({
      ...state, sort
   }));

   public readonly updatePage = this.updater((state, page: PaginationCriteria) => ({
      ...state, page
   }));

   public readonly updateCount = this.updater((state, count: number) => ({
      ...state, count
   }));

   private readonly updateIsLoading = this.updater((state, isLoading: boolean) => ({
      ...state, isLoading
   }));

   public readonly loadEntities = this.effect((origin$: Observable<EntitySearch>) => origin$.pipe(
      filter(params => !!params.q),
      switchMap(param => {
         this.updateIsLoading(true);
         return this.service.advancedSearchEntity(param).pipe(
            tapResponse(entities => {
               this.updateEntities(entities.list);
               this.updateCount(entities.count);
               this.updateIsLoading(false);
            }, error => console.log(error)))
      })
   ))(this.search$);
}
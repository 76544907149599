import { Component, Input, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { filter, switchMap, tap } from 'rxjs/operators';
import { ConfirmationService } from '../../shared/index';
import { InvolvementsDialogService } from '../involvements-dialog.service';
import { InvolvementsService } from '../involvements.service';
import { StatuteOfLimitationsModel } from '../models/involvements.models';


@Component({
   selector: 'am-statute-of-limitations-list',
   templateUrl: './statute-of-limitations-list.component.html',
   styleUrls: ['./statute-of-limitations-list.component.scss']
})
export class StatuteOfLimitationsListComponent implements OnInit {
   dataSource = new MatTableDataSource<StatuteOfLimitationsModel>();
   columnsToDisplay: string[] = ['date', 'description', 'by', 'actions'];
   noStatutesOfLimitationsColumns: string[] = ['description', 'actions'];

   @Input() case: any;

   get involvementId() { return this.case.involvementId; }
   get hasStatutesOfLimitations() { return this.case.hasStatutesOfLimitations; }
   set hasStatutesOfLimitations(value: any) { this.case.hasStatutesOfLimitations = value; }

   constructor(
      private involvementsDialog: InvolvementsDialogService,
      private involvementsService: InvolvementsService,
      private confirmation: ConfirmationService) {
   }

   ngOnInit() {
      if (this.case.hasStatutesOfLimitations === undefined) {
         this.case.hasStatutesOfLimitations = null;
      }
      this.refresh();
   }

   private refresh() {
      this.involvementsService.getStatutesOfLimitations(this.involvementId).subscribe(
         result => this.dataSource.data = result);
   }

   private showAddDialog() {
      this.involvementsDialog
         .openStatuteOfLimitationsDialog(this.involvementId, null, null, null)
         .afterClosed()
         .subscribe(result => {
            if (!result) {
               this.cancelHasStatuesOfLimitations();
            } else {
               this.refresh();
            }
         });
   }

   private showHasStatuesOfLimitationsDialog() {
      this.involvementsDialog
         .openHasStatutesOfLimitationsDialog(this.involvementId)
         .afterClosed().pipe(
            filter(result => result != null),
            tap(result => this.hasStatutesOfLimitations = result)
         ).subscribe(() => {
            if (this.hasStatutesOfLimitations) {
               this.showAddDialog();
            } else {
               this.refresh();
            }
         });
   }

   private cancelHasStatuesOfLimitations() {
      if (this.dataSource.data.length > 0) return;
      this.involvementsService
         .setHasStatutesOfLimitations(this.involvementId, {})
         .subscribe(() => this.hasStatutesOfLimitations = null);
   }

   showDialog() {
      if (this.hasStatutesOfLimitations === null) {
         this.showHasStatuesOfLimitationsDialog();
      } else if (this.hasStatutesOfLimitations) {
         this.showAddDialog();
      }
   }

   showEditDialog(statuteOfLimitations: StatuteOfLimitationsModel) {
      this.involvementsDialog
         .openStatuteOfLimitationsDialog(
            statuteOfLimitations.involvementId,
            statuteOfLimitations.id,
            statuteOfLimitations.date,
            statuteOfLimitations.description)
         .afterClosed()
         .subscribe(result => {
            if (result) this.refresh();
         });
   }

   showDeleteDialog(statuteOfLimitations: StatuteOfLimitationsModel) {
      this.confirmation.confirm('Delete Statute Of Limitations?', 'DELETE', 'CANCEL').pipe(
         filter(ok => ok),
         switchMap(() => this.involvementsService.deleteStatuteOfLimitations(statuteOfLimitations.involvementId, statuteOfLimitations.id))
      ).subscribe(() => {
         const index = this.dataSource.data.indexOf(statuteOfLimitations);
         this.dataSource.data = [
            ...this.dataSource.data.slice(0, Math.max(index, 0)),
            ...this.dataSource.data.slice(index + 1)
         ];
         if (this.dataSource.data.length < 1) {
            this.hasStatutesOfLimitations = null;
         }
      });
   }
}
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';

import { ENV } from '../../env.config';
import { ConfigModel } from '../models';

@Injectable()
export class ConfigurationProviderService {
   config: ConfigModel;

   constructor(private http: HttpClient) {
   }

   load(): Promise<ConfigModel> {
      const apiRootUrl = ENV.API_URL;

      return this.http.get<ConfigModel>(`${apiRootUrl}app/settings`).pipe(
         tap(result => {
            this.config = { ...result, apiRootUrl };
         })
      ).toPromise();
   }
}

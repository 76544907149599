import { Directive, SkipSelf } from '@angular/core';
import { FormGroupDirective } from '@angular/forms';

@Directive({
   selector: '[syncSubmitted]'
})
export class SyncSubmittedDirective {
   constructor(
      @SkipSelf() private parentFormGroupDirective: FormGroupDirective,
      private formGroupDirective: FormGroupDirective) {
      this.parentFormGroupDirective.ngSubmit.asObservable().subscribe(() => {
         (this.formGroupDirective as any).submitted = true;
         this.formGroupDirective.ngSubmit.emit();
      });
   }
}

import { Component, forwardRef, OnInit } from '@angular/core';
import { ControlContainer, ControlValueAccessor, FormControl, FormGroupDirective, NG_VALIDATORS, NG_VALUE_ACCESSOR, Validator, Validators } from '@angular/forms';
import { map } from 'rxjs/operators';
import { RepresentationOption } from '../../models/client-cases-representation.models';

@Component({
   selector: 'am-representation-question',
   templateUrl: './representation-question.component.html',
   styleUrls: ['./representation-question.component.scss'],
   providers: [{
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RepresentationQuestionComponent),
      multi: true
   }, {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => RepresentationQuestionComponent),
      multi: true
   }],
   viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }]
})
export class RepresentationQuestionComponent implements OnInit, ControlValueAccessor, Validator {
   control: FormControl;
   option: RepresentationOption;
   selectedValue: string;
   values: string[] = ['Yes','No','N/A'];
   
   constructor(public form: FormGroupDirective) { }

   ngOnInit() {
      this.option = {} as RepresentationOption;
      this.control = new FormControl();
      this.control.setValidators(Validators.required);
   }
  
   validate() {
      return this.control.errors;
   }

   registerOnChange(fn) {
      this.control.valueChanges.pipe(
         map(value => {
            this.option.outcome = value;
            return this.option;
         })
      ).subscribe(fn);
   }

   registerOnTouched() { }

   writeValue(option: RepresentationOption) {
      this.control.setValue(option.outcome);
      this.option = option;
   }
}
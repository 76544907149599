import { Component, EventEmitter, forwardRef, Input, OnInit} from '@angular/core';
import { ControlValueAccessor, FormBuilder, FormControl, FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ConfigurationService } from '../../configuration/configuration.service';
import { LegalTeamModel, LookupModel } from '../../configuration/models/configuration.models';

@Component({
   selector: 'am-lookup-filter',
   templateUrl: './lookup-filter.component.html',
   styleUrls: ['./lookup-filter.component.scss'],
   providers: [{
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => LookupFilterComponent),
      multi: true
   }],
})
export class LookupFilterComponent implements OnInit, ControlValueAccessor {
   @Input() lookupTypeId: string;
   @Input() label: string;
   lookupValues: LookupModel[];
   lookupControl: FormControl;

   constructor(
      private configurationService: ConfigurationService) {
   }

   ngOnInit() {
      this.lookupControl = new FormControl();
      this.configurationService.getLookupType(this.lookupTypeId)
      .subscribe(lookupType => {
         this.lookupValues = lookupType.lookups.filter(l => l.isActive === true);
      });
      this.lookupControl.setValue([]);
   }

   registerOnChange(fn) {
      this.lookupControl.valueChanges.subscribe(result => fn(result));
   }

   registerOnTouched() { }

   writeValue(value: any) {
      this.lookupControl.setValue(value || []);
   }
}

import { Injectable } from '@angular/core';
import { HttpService, PagedList } from '@app/core/index';
import { Observable } from 'rxjs';
import { Entity } from '../types/Entity';
import { EntitySearch } from '../types/entitySearchParams';


@Injectable({
   providedIn: 'root'
})
export class EntityNameSearchService {
   static injectName = 'entityNameSearchService';
   private baseUrl = 'api/entities';

   constructor(private http: HttpService) { }

   advancedSearchEntity(q: EntitySearch): Observable<PagedList<Entity>> {
      const url = `${this.baseUrl}/advancedsearch/names`;
      return this.http.post<PagedList<Entity>>(url, q);
   }
}
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from '../../environments/environment';
import { AppStoreActions, AppStoreModule } from './app-store';
import { ConfigStoreModule } from './config-store';
import { metaReducers, reducers } from './reducer';
import { ViewStateStoreModule } from './view-state-store';

@NgModule({
   imports: [
      CommonModule,
      StoreModule.forRoot(reducers, { metaReducers }),
      StoreDevtoolsModule.instrument({
         name: 'PANDA App DevTools',
         maxAge: 25,
         logOnly: environment.production,
         actionsBlocklist: [
            AppStoreActions.ActionTypes.IncrementHttpRequestCount,
            AppStoreActions.ActionTypes.DecrementHttpRequestCount
         ]
      }),
      EffectsModule.forRoot([]),

      // Feature State Modules
      AppStoreModule,
      ConfigStoreModule,
      ViewStateStoreModule
   ]
})
export class RootStoreModule { }
import { Component } from '@angular/core';
import { Input } from '@angular/core';

@Component({
   selector: 'am-timeslips-week',
   templateUrl: './timeslips-week.component.html',
   styleUrls: ['./timeslips-week.component.scss']
})
export class TimeslipsByWorkWeekComponent {
   @Input() workWeeks: any[];
}

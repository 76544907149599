import { Injectable } from "@angular/core";
import { Sort } from "@angular/material/sort";
import { ClientCase } from '@app/client-cases/list/types/client-case';
import { ClientCasesListFilters } from '@app/client-cases/list/types/client-cases-filters';
import { ConfigurationService } from "@app/configuration/configuration.service";
import { LookupModel } from "@app/configuration/models/configuration.models";
import { PagedList } from "@app/core";
import { RootStoreState, ViewStateComponentStore, ViewStateComponentStoreState } from "@app/root-store";
import { CaseListFilterOptions } from "@app/shared/types/case-list-filter-options";
import { Pagination, Selected } from "@app/types/core";
import { PopoverFilterOption } from '@app/shared/filters';
import { tapResponse } from '@ngrx/component-store';
import { Store } from "@ngrx/store";
import { forkJoin, Observable } from "rxjs";
import { concatMap, switchMap, tap, withLatestFrom } from "rxjs/operators";
import { ClientCaseListService } from "../services/client-cases-list.service";
import { GetClientCaseListPayload } from "../types/client-case-params";

export interface ClientCaseListState extends ViewStateComponentStoreState {
   clientCases: ClientCase[];
   payload: GetClientCaseListPayload;
   options: CaseListFilterOptions;
   userRoles: LookupModel[];
   userRolesResponses: Response[];
   count: number;
   loading: boolean,
   toggle: boolean
}

export interface ClientCaseListViewState {
   filters: ClientCasesListFilters;
   selected: Selected;
   search: string;
   activeOnly: boolean;
   showCaseName: boolean;
   pagination: Pagination;
   sort: Sort;
}

const initialState: ClientCaseListState = {
   clientCases: [],
   payload: {
      pagination: null,
      sort: null,
      filters: {},
      selected: {},
      search: null
   },
   options: {
      legalTeams: null,
      fundingPrograms: null,
      caseStatuses: null
   },
   userRoles: [],
   userRolesResponses: [],
   count: 0,
   loading: false,
   toggle: false,
   viewId: null
}

const initialViewState: ClientCaseListViewState = {
   filters: {
      number: null,
      client: null,
      caseName: null,
      primaryStaff: null,
      legalTeam: null,
      funder: null,
      status: null,
      opened: null,
      closed: null
   },
   selected: {
      users: [],
      roles: []
   },
   search: null,
   activeOnly: true,
   showCaseName: true,
   pagination: { page: 1, pageSize: 25 },
   sort: { active: 'opened', direction: 'desc' }
};

@Injectable()
export class ClientCaseStateStore extends ViewStateComponentStore<ClientCaseListState, ClientCaseListViewState> {

   constructor(
      rootStore: Store<RootStoreState.State>,
      private service: ClientCaseListService,
      private configurationService: ConfigurationService,) {
      super(rootStore, initialViewState);
   }

   init(viewId: string): void {
      this.initCore({
         ...initialState,
         viewId
      });
      this.patchState({ loading: true });
      this.service.getFilterOptions().pipe(
         tapResponse(options =>
            this.patchState({ options, loading: false }), error => console.log(error))).subscribe();
      forkJoin([
         this.service.getFilterOptions(),
         this.configurationService.getLookupType('involvement-user-role'),
         this.configurationService.getConfigurationParameter('ClientCase.ShowCaseName')]).subscribe(
            ([options, types, param]) => {
               var showCaseName = (!param || param === 'false') ? false : true;
               this.patchViewState({ showCaseName });
               this.patchState({ options, userRoles: types.lookups, loading: false });
            })
   }

   readonly legalTeamOptions$: Observable<PopoverFilterOption[]> = this.select(
      this.state$,
      ({ options }) => options.legalTeams?.map(value => ({ value, text: value }))
   );

   readonly funderOptions$: Observable<PopoverFilterOption[]> = this.select(
      this.state$,
      ({ options }) => options.fundingPrograms?.map(value => ({ value, text: value }))
   );

   readonly statusOptions$: Observable<PopoverFilterOption[]> = this.select(
      this.state$,
      ({ options }) => options.caseStatuses?.map(value => ({ value, text: value }))
   );

   readonly vm$ = this.select(
      this.legalTeamOptions$,
      this.funderOptions$,
      this.statusOptions$,
      this.state$,
      this.viewState$,
      (legalTeamOptions, funderOptions, statusOptions, { clientCases, payload, userRoles, count, loading, toggle }, { filters, selected, activeOnly, showCaseName, pagination, sort }) => (
         { legalTeamOptions, funderOptions, statusOptions, clientCases, payload, userRoles, count, loading, toggle, filters, selected, activeOnly, showCaseName, pagination, sort })
   )

   readonly updateCases = this.updater((state, { list, count }: PagedList<ClientCase>): ClientCaseListState => ({
      ...state,
      clientCases: list,
      count,
      loading: false
   }));

   readonly reload = this.effect((origin$: Observable<GetClientCaseListPayload>) => origin$.pipe(
      tap(payload => this.patchState({ payload, loading: true })),
      switchMap(payload => this.service.getClientCasesList(payload).pipe(
         tapResponse(({ count, page, pageSize, list }) => this.updateCases({ list, page, pageSize, count }), err => console.log(err))
      ))
   ))(this.viewState$);

   readonly addClientCase = this.updater((state, clientCase: ClientCase) => ({
      ...state,
      clientCases: state.clientCases.filter(c => c.involvementId !== clientCase.involvementId).concat(clientCase)
   }));

   readonly deleteClientCase = this.effect((origin$: Observable<string>) => origin$.pipe(
      tap(() => this.patchState({ loading: true })),
      concatMap(id => this.service.deleteClientCase(id)),
      withLatestFrom(this.viewState$),
      concatMap(([_, payload]) => this.service.getClientCasesList(payload)),
      tapResponse(({ count, page, pageSize, list }) => this.updateCases({ list, page, pageSize, count }), err => console.log(err))
   ));
}
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class HttpProgressService {
   private count = 0;
   progress = new Subject<number>();

   increment() {
      this.progress.next(++this.count);
   }

   decrement() {
      if (this.count > 0) {
         this.progress.next(--this.count);
      }
   }
}

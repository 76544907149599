import { AbstractControl, FormControl, ValidatorFn, Validators } from '@angular/forms';

interface NumberValidatorParams {
   min?: number;
   max?: number;
}

export class CustomValidators {
   static greaterThanOrEqualTo(dateField1: string, dateField2: string, validatorField: { [key: string]: boolean }): ValidatorFn {
      return (c: AbstractControl): { [key: string]: boolean } | null => {
         const hiDate = c.get(dateField1).value;
         const lowDate = c.get(dateField2).value;
         if ((!!hiDate && !!lowDate) && hiDate < lowDate) {
            return validatorField;
         }
         return null;
      };
   }

   static minmax(min: number, max) {
      return CustomValidators.number({ min, max });
   }

   static number(params: NumberValidatorParams = {}): ValidatorFn {
      return (control: FormControl): { [key: string]: any } => {
         if (!!Validators.required(control)) {
            return null;
         }

         const val: number = control.value;
         let result: any;

         if (isNaN(val)) {
            result = { 'number': true };
         } else if (!isNaN(params.min) && !isNaN(params.max)) {
            result = val < params.min || val > params.max ? { 'number': true } : null;
         } else if (!isNaN(params.min)) {
            result = val < params.min ? { 'number': true } : null;
         } else if (!isNaN(params.max)) {
            result = val > params.max ? { 'number': true } : null;
         } else {
            result = null;
         }

         return result;
      };
   }
}
